import React, { useEffect, useState, useRef } from "react";
import commonstyles from "../../../../styles/Management_Common.module.css";
import SearchComponent from "../../SearchManagement/SearchComponent";
import styles from "../../../../styles/PaymentNew.module.css";
import exportIcon from "../../../../../src/assets/images/export_icon.svg";
import excelIcon from "../../../../../src/assets/images/excel_icon.svg";
import pdf_icon from "../../../../../src/assets/images/pdf_icon.svg";
import productTotalOrder from "../../../../../src/assets/images/products_total_orders.svg";
import Shoping from "../../../../../src/assets/images/shoping.svg";
import Conversations from "../../../../../src/assets/images/conversations.svg";
import Shopingitem from "../../../../../src/assets/images/shopingitem.svg";
import arrowGreen from "../../../../../src/assets/images/bar_chart_arrow_green.svg";
import arrowRed from "../../../../../src/assets/images/bar_chart_arrow_red.svg";
import barChartGreen from "../../../../../src/assets/images/bar_chart_green.svg";
import barChartRed from "../../../../../src/assets/images/bar_chart_red.svg";
import ButtonComponent from "../../../modules/ReusableComponent/ButtonComponent";
import AbandonedCheckoutList from "../Abandoned/AbandonedCheckoutList";
import CreateEmail from "../Abandoned/CreateEmail";
import Dashboard from "../Abandoned/Dashboard";
import EmailLogsList from "../Abandoned/EmailLogsList";
import { AxiosInstance, PORTS } from "../../../../utils/apiService";
import LoadingIndicator from "../../../modules/ReusableComponent/LoadingIndicator";
import { clone, upperCase, upperFirst } from "lodash";
import * as XLSX from "xlsx";
import moment from "moment";
import { useHistory } from "react-router";
import { Tooltip } from "antd";
const tabs_ = [
  {
    id: 1,
    name: "Dashboard",
    isActive: true,
  },
  {
    id: 2,
    name: "Abandoned Checkout",
    isActive: false,
  },
  {
    id: 3,
    name: "Add Email",
    isActive: false,
  },
  {
    id: 4,
    name: "Abandoned Email Logs",
    isActive: false,
  },
];

export default function AbandonedDashboard(props) {
  let permissionsArr = JSON.parse(localStorage.getItem("permissions")) || [];
  const [buttons, setButtons] = useState(tabs_);
  const [activeBtnName, setActiveBtnName] = useState("Dashboard");
  const [headerDetails, setHeaderDetails] = useState([]);
  const [isLoadingHeader, setIsLoadingHeader] = useState(false);
  const [regionId, setRegionId] = useState("");
  const pdfExportComponent = useRef(null);
  const [selectedFilter, setSelectedFilter] = useState({});
  const history = useHistory();

  useEffect(() => {
    getHeaderDetails();
    const cloneButtons = [...buttons];
    setRegionId("");
    if (history?.isAbandonDet) {
      history["isAbandonDet"] = false;
      cloneButtons?.forEach((x) => {
        if (x.name === "Abandoned Email Logs") {
          x.isActive = true;
        } else {
          x.isActive = false;
        }
      });
      setActiveBtnName("Abandoned Email Logs");
    } else {
      cloneButtons?.forEach((x) => {
        if (x.name === "Dashboard") {
          x.isActive = true;
        } else {
          x.isActive = false;
        }
      });
      setActiveBtnName("Dashboard");
    }
    setButtons(cloneButtons);
  }, []);
  /**
   * @author Rajkumar
   * @param {data}
   * To get header details
   */
  function getHeaderDetails(data) {
    setIsLoadingHeader(true);
    AxiosInstance(process.env.REACT_APP_ADMIN_NEWSLETTER, "application/json")
      .get(`admin/getAbandonedCheckoutDetails`)
      .then((res) => {
        if (res.data.status === 200 || res.data.status === "200") {
          setIsLoadingHeader(false);
          setHeaderDetails(res.data);
        } else {
          setIsLoadingHeader(false);
        }
      })
      .catch((er) => {
        console.log(er);
        return er;
      })
      .finally(() => {
        setIsLoadingHeader(false);
      });
  }

  const selectedBtn = (item) => {
    const cloneButtons = [...buttons];
    cloneButtons?.forEach((x) => {
      if (x.id === item.id) {
        x.isActive = true;
      } else {
        x.isActive = false;
      }
    });
    setButtons(cloneButtons);
    setActiveBtnName(item.name);
  };

  const updateFilter = (arr) => {
    setSelectedFilter(arr);
  };

  const exportToCSV = async (e) => {
    let filterOptions = {};
    let url = "";
    if (activeBtnName === "Dashboard") {
      if (localStorage.getItem('accessregion')) {
        filterOptions.regionId = localStorage.getItem('accessregion');
      } else if (regionId) {
        filterOptions.regionId = regionId;
      }
      url = "admin/exportDashboard";
    }
    if (activeBtnName === "Abandoned Checkout") {
      filterOptions = selectedFilter;
      url = "admin/exportAbandonedCheckoutList";
    }
    if (activeBtnName === "Abandoned Email Logs") {
      filterOptions = selectedFilter;
      url = "admin/exportAbandonedEmailLogs";
    }
    if (!filterOptions.search) delete filterOptions.search;
    if (!filterOptions.regionId) delete filterOptions.regionId;
    if (filterOptions.sortOrder === "" || filterOptions.sortOrder === null) delete filterOptions.sortOrder;
    if (!filterOptions.startDate) delete filterOptions.startDate;
    if (!filterOptions.endDate) delete filterOptions.endDate;
    AxiosInstance(process.env.REACT_APP_ADMIN_NEWSLETTER, "application/json")
      .post(url, filterOptions)
      .then((resp) => {
        const response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          let data = "";
          let und_res = [];
          if (activeBtnName === "Dashboard") {
            data = response.data;
            und_res = [data];
          } else if (activeBtnName === "Abandoned Checkout") {
            data = response.data?.abandonedCheckoutList;
            data?.forEach(item => {
              if (item['Last Updated']) {
                item['Last Updated'] = moment(item['Last Updated']).format('MMM Do YYYY, h:mm:ss A');
              }
            })
            und_res = data;
          } else if (activeBtnName === "Abandoned Email Logs") {
            data = response.data?.abandonedEmails;
            und_res = data;
            data?.forEach(item => {
              if (item['Date']) {
                item['Date'] = moment(item['Date']).format('MMM Do YYYY, h:mm:ss A');
              }
            })
          }
          const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
          const fileExtension = ".xlsx";
          const ws = XLSX.utils.json_to_sheet(und_res);
          ws["A1"].v = upperCase(ws["A1"]?.v);
          ws["B1"].v = upperCase(ws["B1"]?.v);
          ws["C1"].v = upperCase(ws["C1"]?.v);
          ws["D1"].v = upperCase(ws["D1"]?.v);
          ws["E1"].v = upperCase(ws["E1"]?.v);
          if (activeBtnName !== "Abandoned Email Logs" && activeBtnName !== "Abandoned Checkout") {
            ws["F1"].v = upperCase(ws["F1"]?.v);
          }
          //   ws["G1"].v = upperCase(ws["G1"]?.v);

          var wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "People");
          /* generate an XLSX file */
          XLSX.writeFile(
            wb,
            `Abandoned checkout-${moment(new Date())
              .format("YYYY-MM-DD")
              .split("-")
              .join("")}.xlsx`
          );
          // FileSaver.saveAs(data, "WeeklyPlans" + fileExtension);
        } else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
        }
      })
      .catch((err) => {
        return err;
      });
  };

  function filterOnChange(e) {
    // const { name, value } = e.target;
    // if (name === "regionId") {
    setRegionId(e);
    // }
  }

  const exportToPDF = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  };

  return (
    <div
      className={`${commonstyles.management_contents} ${styles.management_contents}`}
    >
      <SearchComponent />
      <div className={commonstyles.management_header}>
        <h1 className={commonstyles.management_header_heading}>
          Abandoned Checkout
        </h1>

        {activeBtnName !== "Add Email" &&
          permissionsArr.length > 0 &&
          permissionsArr.find((x) => x === "Marketing-Abandoned-view") ===
          "Marketing-Abandoned-view" && (
            <div >
            <span className={commonstyles.management_header_downloads_text}>
              <Tooltip
                placement="topRight"
                title="Download pdf"
              >
                <img className={commonstyles.export_icon} src={pdf_icon} style={{ cursor: "pointer" }} onClick={(e) => exportToPDF(e)} />
              </Tooltip>
              <Tooltip
                placement="topRight"
                title="Download excel"
              >
                <img src={excelIcon} className={commonstyles.management_header_downloads_icon} alt="icon" style={{ cursor: "pointer" }} onClick={(e) => exportToCSV(e)} />
              </Tooltip>
            </span>
            <span className={commonstyles.management_header_downloads_text_ex}>
              Export <img src={exportIcon} alt="icon" />
            </span>
          </div>
          )}
      </div>
      {isLoadingHeader === true ? (
        <div
          style={{
            marginLeft: "70vh",
            marginTop: "10vh",
          }}
        >
          <tr className="bg-transparent">
            <td colSpan="100%">
              <LoadingIndicator loadingText="Fetching Checkout details..." />
            </td>
          </tr>
        </div>
      ) : (
        <div>
          {permissionsArr.length > 0 &&
            permissionsArr.find((x) => x === "Marketing-Abandoned-view") ===
            "Marketing-Abandoned-view" && (
              <div className="row mb-4">
                <div
                  className={`${styles.management_details_contents} ${styles.abandoned_dashboard_items} d-flex`}
                >
                  <img
                    className={`mr-3 ${styles.media_img}`}
                    src={Shopingitem}
                    alt=""
                  />
                  <div className={`${styles.abandoned_dashboard_items_box}`}>
                    <p>Abandoned Items</p>
                    <h5>{headerDetails?.totalAbandonedItems?.toFixed(2)}</h5>
                    <div
                      className={`${styles.abandoned_dashboard_items_graph} d-flex justify-content-between`}
                    >
                      <div>
                        <p
                          className={
                            headerDetails?.totalAbandonedItemsPercentage > 0 ?
                            styles.abandoned_dashboard_items_green : styles.abandoned_dashboard_items_red
                          }
                        >
                          <img
                            className={`mr-1 ${styles.media_img}`}
                            src={
                              headerDetails?.totalAbandonedItemsPercentage > 0
                                ? arrowGreen
                                : arrowRed
                            }
                            alt=""
                          />
                          {headerDetails?.totalAbandonedItemsPercentage?.toFixed(
                            2
                          )}
                          % than last week
                        </p>
                      </div>
                    
                    </div>
                  </div>
                </div>

                <div
                  className={`${styles.management_details_contents} ${styles.abandoned_dashboard_items} d-flex`}
                >
                  <img
                    className={`mr-3 ${styles.media_img}`}
                    src={Conversations}
                    alt=""
                  />
                  <div className={`${styles.abandoned_dashboard_items_box}`}>
                    <p>Abandoned Conversations</p>
                    <h5>
                      {headerDetails?.totalAbandonedCoversation?.toFixed(2)}
                    </h5>
                    <div
                      className={`${styles.abandoned_dashboard_items_graph} d-flex justify-content-between`}
                    >
                      <div>
                        <p
                          className={
                            headerDetails?.totalAbandonedCoversationPercentage >
                            0 ? styles.abandoned_dashboard_items_green : styles.abandoned_dashboard_items_red
                          }
                        >
                          <img
                            className={`mr-1 ${styles.media_img}`}
                            src={
                              headerDetails?.totalAbandonedCoversationPercentage >
                                0
                                ? arrowGreen
                                : arrowRed
                            }
                            alt=""
                          />
                          {headerDetails?.totalAbandonedCoversationPercentage?.toFixed(
                            2
                          )}
                          % than last week
                        </p>
                      </div>
                      
                    </div>
                  </div>
                </div>

                <div
                  className={`${styles.management_details_contents} ${styles.abandoned_dashboard_items} d-flex`}
                >
                  <img
                    className={`mr-3 ${styles.media_img}`}
                    src={Shoping}
                    alt=""
                  />
                  <div className={`${styles.abandoned_dashboard_items_box}`}>
                    <p>Avg Value of Abandoned checkout</p>
                    <h5>
                      {headerDetails?.avgValueOfAbandonedCheckout
                        ?.toFixed(2)
                        ?.toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </h5>
                    <div
                      className={`${styles.abandoned_dashboard_items_graph} d-flex justify-content-between`}
                    >
                      <div>
                        <p
                          className={
                            headerDetails?.avgValueOfAbandonedCheckoutPercentage >
                            0 ? styles.abandoned_dashboard_items_green : styles.abandoned_dashboard_items_red
                          }
                        >
                          <img
                            className={`mr-1 ${styles.media_img}`}
                            src={
                              headerDetails?.avgValueOfAbandonedCheckoutPercentage >
                                0
                                ? arrowGreen
                                : arrowRed
                            }
                            alt=""
                          />
                          {headerDetails?.avgValueOfAbandonedCheckoutPercentage?.toFixed(
                            2
                          )}
                          % than last week
                        </p>
                      </div>
                     
                    </div>
                  </div>
                </div>
              </div>
            )}
          <div
            className={`${commonstyles.management_header} ${commonstyles.abandone_tab_btn} justify-content-start pb-0`}
          >
            {buttons?.length > 0 &&
              buttons?.map((item) => (
                <button
                  key={item.id}
                  className={`${commonstyles.sidebarButton} 
                                ${item.isActive &&
                    commonstyles.sidebarButton_active
                    } 
                                d-inline-block mb-0 mr-2`}
                  onClick={() => selectedBtn(item)}
                >
                  {item.name}
                </button>
              ))}
          </div>
          <div className={styles.management_details}>
            {activeBtnName === "Dashboard" && (
              <div>
                <Dashboard
                  regionId={regionId}
                  filterOnChange={filterOnChange}
                  pdfExportComponent={pdfExportComponent}
                />
              </div>
            )}
            {activeBtnName === "Abandoned Checkout" && (
              <AbandonedCheckoutList
                pdfExportComponent={pdfExportComponent}
                updateFilter={updateFilter}
              />
            )}
            {activeBtnName === "Add Email" && <CreateEmail />}
            {activeBtnName === "Abandoned Email Logs" && (
              <EmailLogsList
                pdfExportComponent={pdfExportComponent}
                updateFilter={updateFilter}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}
