import commonstyles from "../../../../styles/Management_Common.module.css";
import Search from "../../../../assets/images/search_icon.svg";
import { Link } from "react-router-dom";
import adminProductEdit from "../../../../assets/images/admin_product_edit_icon.svg";
import adminProductDelete from "../../../../assets/images/admin_delete.svg";
import arrowDown from "../../../../assets/images/arrow_down.svg";
import dots from "../../../../assets/images/column_dots.svg";
import exportIcon from "../../../../assets/images/export_icon.svg";
import pdf_icon from "../../../../assets/images/pdf_icon.svg";
import pdfIcon from "../../../../assets/images/excel_icon.svg";
import excelIcon from "../../../../assets/images/excel_icon.svg";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import React, { useEffect, useRef, useState, createRef } from "react";
import { AxiosInstance, PORTS } from "../../../../utils/apiService";
import Pagination from "../../../modules/ReusableComponent/Pagination";
import { useHistory } from "react-router-dom";
import ButtonComponent from "../../../modules/ReusableComponent/ButtonComponent";
import AntSelectComponent from "../../../modules/ReusableComponent/AntSelectComponent";
import AddDiscount from "../../DiscountManagement/AddDiscount";
import LoadingIndicator from "../../../modules/ReusableComponent/LoadingIndicator";
import EditOrderComponent from "../../Order Management/OrderDetails";
import InputComponent from "../../../modules/ReusableComponent/InputComponent";
import AntTableComponent from "../../../modules/ReusableComponent/AntTableComponent";
import MovableListComponent from "../../../modules/ReusableComponent/MovableListComponent";
import moment from "moment";
import  {format}  from 'date-fns';
import { Checkbox } from "antd";
import { clone, upperCase, upperFirst } from "lodash";
import * as XLSX from "xlsx";
import {
  Button,
  Dropdown,
  Menu,
  Slider,
  Tooltip,
  message as massages,
  Select,
  notification,
  Empty,
} from "antd";
import { arrayMove } from "react-movable";
import AntDateRangeSelectComponent from "../../../modules/ReusableComponent/AntDateRangeSelectComponent";
import SearchComponent from "../../SearchManagement/SearchComponent";
import ConfirmationBox from "../../../modules/ReusableComponent/ConfirmationBox";
import discountstyles from "../../../../styles/AdminDiscount.module.css";

const pageSizes = [10, 20, 50];
const defaultPageSize = 10;
const originalHeaders = [
  // { _id: 1, status: 1, name: "Select", value: "select" },
  { _id: 2, status: 1, name: "To", value: "To" },
  { _id: 3, status: 1, name: "Date", value: "Date" },
  { _id: 4, status: 1, name: "Subject", value: "Subject" },
  { _id: 5, status: 1, name: "Status", value: "Status" },
  { _id: 6, status: 1, name: "Recovery Status", value: "RecoveryStatus" },
  { _id: 7, status: 1, name: "Action", value: "Action" },
];

const daysObj = [
  { _id: 1, name: "Today", value: 1 },
  { _id: 2, name: "Last 7 Days", value: 7 },
  { _id: 3, name: "Last 30 Days", value: 30 },
  { _id: 4, name: "Last 70 Days", value: 70 },
  { _id: 5, name: "Last 90 Days", value: 90 },
  { _id: 6, name: "Last 12 Months", value: 365 },
  { _id: 7, name: "Custom", value: "custom" },
];

const filters = [
  // { id: 1, isChecked: false, name: "Region", value: "region" },
  { id: 2, isChecked: false, name: "Date", value: "Date" },
  // { id: 3, isChecked: false, name: "Recovery Status", value: "RecoveryStatus" },
];

const bulkAction = [];

let date = new Date();
let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
const placeholder = [
  moment(firstDay).format("DD-MM-YYYY"),
  moment(lastDay).format("DD-MM-YYYY"),
];
const dateFormatList = ["DD-MM-YYYY", "DD-MM-YYYY"];

export default function EmailLogsList(props) {
  let permissionsArr = JSON.parse(localStorage.getItem("permissions")) || [];
  const [orderList, setOrderList] = useState([]);
  const [search, setSearch] = useState("");
  const [nextPage, setNextPage] = useState(1);
  const [visible, setVisible] = useState(false);
  const [filterdayArr, setFilterdayArr] = useState(daysObj);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [filterDaysName, setFilterDaysName] = useState("Date");
  const [pagination, setPagination] = useState({});
  const [showAddCustomerVisibility, setshowAddCustomerVisibility] =
    useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [childComponentVisibility, setChildComponentVisibility] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [regionId, setRegionId] = useState("");
  // const [orderStatus, setOrderStatus] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const timeoutId = useRef();
  const [regions, setRegions] = useState([]);
  const [regionName, setRegionName] = useState("All Region");
  const [filterDays, setFilterDays] = useState("");
  const [weekplanexport, setWeekPlanExport] = useState([]);
  const [headers, setHeaders] = useState(originalHeaders);
  const [items, setItems] = useState(originalHeaders);
  const [selectFilter, setSelectFilter] = useState(filters);
  const [sortorder, setSortorder] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [bulkActionFilter, setBulkActionFilter] = useState(bulkAction);
  const [filteredDate, setFilteredDate] = useState({
    startDate: "",
    endDate: "",
  });
  const columns_ = [
    // {
    //   visible: false,
    //   key: "select",
    //   render: (text, record, index) => (
    //     <input
    //       style={{ cursor: "pointer" }}
    //       type="checkbox"
    //       // checked={record.isChecked}
    //       // onChange={(e) => childCheckbox(e, record)}
    //     ></input>
    //   ),
    // },
    {
      title: "To",
      visible: false,
      key: "To",
      render: (text, record, index) => <span>{upperFirst(record.To)}</span>,
      sorter: true,
    },
    {
      title: "Date",
      visible: false,
      key: "Date",
      render: (text, record, index) => (
        <span>{moment.parseZone(record.Date).format("DD/MM/YYYY | hh:mm A")}</span>
      ),
    },
    {
      title: "Subject",
      visible: false,
      key: "Subject",
      render: (text, record, index) => (
        <span>{upperFirst(record.Subject)}</span>
      ),
    },
    {
      title: "Status",
      visible: false,
      key: "Status",
      render: (text, record, index) => (
        <td>
          <span
            className={
              record.status === "Sent"
                ? discountstyles.status_active
                : record.status === "Failed"
                ? discountstyles.status_inactive
                : discountstyles.status_pending
            }
          >
            {record.status}
          </span>
        </td>
      ),
    },
    {
      title: "Recovery Status",
      visible: false,
      key: "RecoveryStatus",
      render: (text, record, index) => (
        // <td>
        <span
          className={
            record.recoveryStatus === "Recovered"
              ? discountstyles.status_active
              : discountstyles.status_inactive
          }
        >
          {upperFirst(record.recoveryStatus)}
        </span>
        // </td>
      ),
    },
    {
      title: "Action",
      visible: false,
      key: "Action",
      render: (text, record, index) =>
        permissionsArr.length > 0 &&
        ( permissionsArr.find((x) => x === "Marketing-Abandoned-view") === "Marketing-Abandoned-view" ||
        permissionsArr.find((x) => x === "Marketing-Abandoned-edit") === "Marketing-Abandoned-edit" ) && (
          <span>
            <Tooltip
              placement="topRight"
              title="Edit email log"
              // className="tooltip_list"
            >
              <img
                src={adminProductEdit}
                className={commonstyles.admin_table_edit_img}
                onClick={() => editCustomer(record)}
                alt="View icon"
                // title="Edit email"
                style={{ cursor: "pointer" }}
              />
            </Tooltip>
          </span>
        ),
    },
  ];
  const [columns, setColumns] = useState(columns_);
  const [selectedOrder, setSelectedOrder] = useState({});

  const childCheckbox = (e, record) => {};

  const parentCheckbox = (e) => {};

  function editCustomer(user) {
    history.push({
      pathname: "/abandonedDashboard/AdandonedCheckoutDetails",
      state: user,
    });
  }

  useEffect(() => {
    set_new_header(headers);
    getOrderDetails(pageSize, nextPage, "");
    getRegionList();
    return () => {};
  }, []);

  const set_new_header = (response) => {
    var newArr = [];
    const data = response;
    for (let i = 0; i < data.length; i++) {
      const columnsClone = [...columns];
      let objIndex = columnsClone.findIndex((obj) => obj.key === data[i].value);
      if (columnsClone[objIndex] !== undefined) {
        columnsClone[objIndex].visible = data[i].status === 1 ? true : false;
        newArr.push(columnsClone[objIndex]);
      }
    }
    setColumns(newArr);
  };

  useEffect(() => {
    const filterOptions = {
      search: search,
      regionId: regionId,
      sortOrder: sortorder,
      startDate: filteredDate.startDate,
      endDate: filteredDate.endDate,
    };
    props.updateFilter(filterOptions);
  }, [search, regionId, sortorder, filteredDate]);
  /**
   * @author Rajkumar
   * @param {pageSize, nextPage, filterarr, type}
   * SHOW ORDER DETAILS
   */
  function getOrderDetails(pageSize, nextPage, filterarr, type) {
    if (!type) {
      setIsLoading(true);
    }
    const filterOptions = {
      pagesize: pageSize,
      nextpage: nextPage,
    };
    if (filterarr && filterarr.search) {
      filterOptions.search = filterarr.search;
    } else if (search !== "") {
      if (filterarr && filterarr.search !== "") {
        filterOptions.search = search;
      }
    }
    if (filterarr && filterarr.regionId) {
      filterOptions.regionId = filterarr.regionId;
    } else if (regionId !== "") {
      if (filterarr && filterarr.regionId !== "") {
        filterOptions.regionId = regionId;
      }
    }
    if (filterarr && filterarr.sortOrder !== "") {
      filterOptions.sortOrder = Number(filterarr.sortOrder);
    } else if (sortorder !== "" && sortorder !== null) {
      if (filterarr && sortorder !== "") {
        filterOptions.sortOrder = Number(sortorder);
      }
    }
    if (filterarr.filterDays) {
      filterOptions.filterDays = Number(filterarr.filterDays);
    } else {
      filterOptions.filterDays = null;
    }
    if (filterarr && filterarr.startDate) {
      filterOptions.startDate = filterarr.startDate;
    } else if (filteredDate.startDate !== "") {
      if (filterarr && filterarr.startDate !== "") {
        filterOptions.startDate = filteredDate.startDate;
      }
    }
    if (filterarr && filterarr.endDate) {
      filterOptions.endDate = filterarr.endDate;
    } else if (filteredDate.endDate !== "") {
      if (filterarr && filterarr.endDate !== "") {
        filterOptions.endDate = filteredDate.endDate;
      }
    }
    if(type==true && filterarr?.tableFilter==true){
      filterOptions.filterDays = (filterDays && filterDays!="custom")?filterDays:null
    }
    if (filterOptions?.sortOrder === 2) delete filterOptions.sortOrder;
    AxiosInstance(process.env.REACT_APP_ADMIN_NEWSLETTER, "application/json")
      .post("admin/getAbandonedEmailLogs", filterOptions)
      .then((res) => {
        if (res && res.data && res.status == "200") {
          setIsLoading(false);
          res.data?.data?.abandonedEmails?.forEach((item, indx) => {
            item["key"] = indx + 1;
            item["isChecked"] = false;
          });
          setOrderList(res.data?.data?.abandonedEmails);
          setPagination(res.data.pagination);
        }
      })
      .catch((er) => {
        const data = { type: "error" };
        if (er.response) {
          data.message = "Error";
          data.description = "Failed to get data";
          setIsLoading(false);
          showNotification(data);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function handlePaginationChanges(value, type) {
    let filter = { sortOrder: 2 };
    if (type === "pageSize") {
      setPageSize(value);
      setNextPage(1);
      getOrderDetails(value, 1, filter);
    } else if (type === "nextPage") {
      setNextPage(value);
      getOrderDetails(pageSize, value, filter);
    }
  }

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  function handleInput(e, type) {
    // setNextPage(1);
    // setSearch(e.value);
    // const { value } = e.target;
    // if (value.trim() !== "") {
    //   const updatedFilter = {
    //     search: e.target?.value || "",
    //     nextpage: 1,
    //   };
    //   setSearch(e.target.value);
    //   debounceSearch(updatedFilter);
    // } else if (search) {
    //   const updatedFilter = {
    //     search: "",
    //     nextpage: 1,
    //   };
    //   setSearch("");
    //   debounceSearch(updatedFilter);
    // }

    const { value } = e.target;
    setSearch(value);

    if (!value) {
      const updatedFilter = {
        search: "",
        nextpage: 1,
      };
      debounceSearch(updatedFilter);
    }
  }

  function debounceSearch(filter) {
    setNextPage(1);
    getOrderDetails(pageSize, 1, filter);
  }

  function getRegionList() {
    AxiosInstance(PORTS.REGION_SERVICE, "application/json")
      .get("/admin/region/getRegionList")
      .then((res) => {
        if (res.status === 200 && res.data.status === 200) {
          let obj = { name: "All Region", value: "", _id: "" };
          res.data?.daaata?.unshift(obj);
          setRegions(res.data.daaata);
        }
      })
      .catch((er) => {
        console.log(er);
      });
  }

  function filterOnChange(name, value) {
    // const { name, value } = e.target;
    let updatedFilter = {
      search: search,
      nextpage: 1,
      regionId: regionId,
      sortOrder: sortorder,
      filterDays: filterDays,
    };
    if (name === "regionId") {
      updatedFilter.regionId = value._id;
      setRegionId(value._id);
      setRegionName(value?.name);
    }
    if (name === "filterDays") {
      updatedFilter.filterDays = value.value;
      updatedFilter.startDate = "";
      updatedFilter.endDate = "";
      setFilterDays(value.value);
      setFilterDaysName(value.name);
    }
    getOrderDetails(pageSize, nextPage, updatedFilter);
  }

  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
  /**
   * @author Rajkumar
   * @param {props}
   * TO SHOW TABLE HEADERS BASED ON STATUS
   */
  const renderHeader = (props) => {
    return (
      <tr>
        {columns.map((item, idx) => {
          if (item.visible) return props.children[idx];
        })}
      </tr>
    );
  };
  /**
   * @author Rajkumar
   * @param {props}
   * TO SHOW TABLE DATA BASED ON HEADER STATUS
   */
  const renderBody = (props) => {
    if (props.children.length > 0) {
      return (
        <tr className={props.className}>
          {columns.map((item, idx) => {
            if (item.visible) {
              return props.children[idx];
            }
          })}
        </tr>
      );
    } else {
      return (
        <tr className="bg-transparent text-info">
          <td colSpan="3">No data found!</td>
        </tr>
      );
    }
  };

  const rearrange = (oldIndex, newIndex) => {
    oldIndex = oldIndex === 0 ? 2 : oldIndex;
    newIndex = newIndex === 0 ? 2 : newIndex;
    if (oldIndex !== 1 && newIndex !== 1) {
      setItems(arrayMove(items, oldIndex, newIndex));
      const itemClone = [...items];
      let values = rearrange_array(itemClone, oldIndex, newIndex);
      fetchNewOrder(values);
    }
  };

  const rearrange_array = (arr, oldIndex, newIndex) => {
    if (newIndex >= arr.length) {
      let i = newIndex - arr.length + 1;
      while (i--) {
        arr.push(undefined);
      }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    return arr;
  };

  const fetchNewOrder = async (items) => {
    let newStatus = [];
    items.map((item, index) => {
      let obj = {};
      obj.order = index;
      obj.status = item.status;
      obj.value = item.value;
      obj._id = item._id;
      newStatus.push(obj);
    });
    setItems(newStatus);
    set_new_header(newStatus);
  };

  const on_field_check_handler = (e, id) => {
    const itemClone = [...items];
    let objIndex = items.findIndex((obj) => obj._id === id);
    itemClone[objIndex].status = e.target.checked ? 1 : id === 2 ? 1 : 0;
    setItems(itemClone);
    fetchNewOrder(items);
  };

  const menu = (
    <Menu className="columns_list">
      <MovableListComponent
        listProps={{
          values: items,
          onChange: ({ oldIndex, newIndex }) => rearrange(oldIndex, newIndex),
          renderList: ({ children, props }) => (
            <ul {...props} style={{ listStyleType: "none" }}>
              {children}
            </ul>
          ),
          renderItem: ({ value, props }) => (
            <li {...props} key={value._id} className="columns_list_item">
              {value.value !== "select" && (
                <>
                  <input
                    type="checkbox"
                    checked={value.status === 1 ? true : false}
                    onChange={(event) =>
                      on_field_check_handler(event, value._id)
                    }
                  />
                  <span class="checkmark" style={{ cursor: "move" }}></span>
                  <span>
                    {value.value
                      .replace(/([A-Z])/g, " $1")
                      .replace(/^./, function (str) {
                        return str.toUpperCase();
                      })}
                    <img src={dots} alt="image" />
                  </span>
                </>
              )}
            </li>
          ),
        }}
      />
    </Menu>
  );

  const commonFilterHandler = (e, data) => {
    const { checked } = e.target;
    let cloneSelectedFilters = [...selectedFilters];
    const cloneSelectFilter = [...selectFilter];
    let updatedFilter = {
      search: search,
      nextpage: 1,
      regionId: regionId,
    };

    cloneSelectFilter.forEach((item) => {
      if (item.id === data.id) {
        item.isChecked = checked;
        if (checked) {
          cloneSelectedFilters.push(item.value);
        } else {
          cloneSelectedFilters = cloneSelectedFilters.filter(
            (item) => data.value !== item
          );
        }
      }
      if (!item.isChecked && item.id === data.id) {
        if (item.value === "region") {
          updatedFilter.regionId = "";
          setRegionId("");
          setRegionName("All Region");
        }
        if (item.value === "Date") {
          const clonefilteredDate = { ...filteredDate };
          clonefilteredDate.startDate = "";
          clonefilteredDate.endDate = "";
          updatedFilter.startDate = "";
          updatedFilter.endDate = "";
          setFilteredDate(clonefilteredDate);
        }
        debounceSearch(updatedFilter);
      }
    });
    setSelectedFilters(cloneSelectedFilters);
    setSelectFilter(cloneSelectFilter);
  };

  // *** DATE RANGE ONCHANGE FUNCTION *** //
  const dateRangeOnchangeHandler = (date, dateString) => {
    const clonefilteredDate = { ...filteredDate };
    clonefilteredDate.startDate = dateString[0]
      ? moment(dateString[0], "DD-MM-YYYY").format("YYYY-MM-DD")
      : "";
    clonefilteredDate.endDate = dateString[1]
      ? moment(dateString[1], "DD-MM-YYYY").format("YYYY-MM-DD")
      : "";
    setFilteredDate(clonefilteredDate);
    let updatedFilter = {
      search: search,
      nextpage: 1,
      regionId: regionId,
      // orderStatus: orderStatus,
      startDate: dateString[0]
        ? moment(dateString[0], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      endDate: dateString[1]
        ? moment(dateString[1], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      filterDays: null,
    };
    getOrderDetails(pageSize, nextPage, updatedFilter);
  };

  const filterMenu = (
    <Menu className="filter_dropdown">
      <div className="card">
        <div className="card_contents">
          <div className="card_info">Filter by</div>
          <div className="row no-gutters">
            <div className="card_dropdown col-md-6">
              <div className="filter_heading card_dropdown_heading">
                Select Filter <img src={arrowDown} width={10} height={20} />
              </div>
              <div className="card_dropdown_contents">
                {Array.isArray(selectFilter)
                  ? selectFilter.map((filter) => (
                      <span key={filter._id}>
                        <input
                          type="checkbox"
                          checked={filter.isChecked}
                          onChange={(e) => commonFilterHandler(e, filter)}
                        ></input>
                        <label>{filter.name}</label>
                      </span>
                    ))
                  : null}
              </div>
            </div>
            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "region") === "region" && (
                <div className="card_dropdown show col-md-6">
                  {/* <select
                    className="form_control"
                    name="regionId"
                    value={regionId}
                    onChange={filterOnChange}
                  >
                    <option value="">All Region</option>
                    {Array.isArray(regions)
                      ? regions.map((region) => (
                          <option key={region._id} value={region._id}>
                            {region.name}
                          </option>
                        ))
                      : null}
                  </select> */}
                  <div
                    style={{ width: "100%" }}
                    className="filter_heading card_dropdown_heading"
                  >
                    {regionName}
                    <img alt="" src={arrowDown} width={10} height={20} />
                  </div>
                  <div className="card_dropdown_contents">
                    {Array.isArray(regions)
                      ? regions.map((region) => (
                          <span
                            key={region._id}
                            onClick={() => filterOnChange("regionId", region)}
                          >
                            <label>{region.name}</label>
                          </span>
                        ))
                      : null}
                  </div>
                </div>
              )}
            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "Date") === "Date" && (
                <div className="card_dropdown show col-md-6">
                  {/* <select
                      className="form_control"
                      name="filterDays"
                      value={filterDays}
                      onChange={filterOnChange}
                    >
                      <option value="">Order Date</option>
                      {Array.isArray(filterdayArr)
                        ? filterdayArr.map((day) => (
                            <option key={day._id} value={day.value}>
                              {day.name}
                            </option>
                          ))
                        : null}
                    </select> */}
                  <div
                    style={{ width: "100%" }}
                    className="filter_heading card_dropdown_heading"
                  >
                    {filterDaysName}
                    <img alt="" src={arrowDown} width={10} height={20} />
                  </div>
                  <div className="card_dropdown_contents card_slect_hover card_dropdown_no_padding">
                    {Array.isArray(filterdayArr)
                      ? filterdayArr.map((day) => (
                          <span
                            key={day._id}
                            onClick={() => filterOnChange("filterDays", day)}
                          >
                            <label>{day.name}</label>
                          </span>
                        ))
                      : null}
                  </div>
                </div>
              )}
            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "Date") === "Date" &&
              filterDays === "custom" && (
                <div className="card_dropdown show col-md-12">
                  <AntDateRangeSelectComponent
                    selectProps={{
                      placeholder: placeholder,
                      onChange: dateRangeOnchangeHandler,
                      name: "sessionDateRange",
                      format: dateFormatList,
                    }}
                  />
                </div>
              )}
            {/* RecoveryStatus */}
          </div>
        </div>
      </div>
    </Menu>
  );

  // *** BUILD STATUS CLICK HANDLER *** //
  const bulkStatusClickHandler = (data) => {
    const cloneBulkActionFilter = [...bulkActionFilter];
    cloneBulkActionFilter.forEach((item) => {
      if (item._id === data._id) {
        item.isChecked = true;
      } else {
        item.isChecked = false;
      }
    });
    setBulkActionFilter(cloneBulkActionFilter);
  };

  const applyBulkActionHandler = (item) => {};

  const submitBulkAction = (data) => {};

  const removeOrder = () => {};

  /**
   * @author Rajkumar
   * @param { sorting  pagination, pagination} value
   *   User List sort filter  functionality
   */
  const handleTableChange = (pagination, filters, sorter) => {
    let updatedFilter = {
      search: search,
      nextpage: nextPage,
      regionId: regionId,
      sortOrder: sorter.order === "descend" ? 1 : 0,
      tableFilter:true
    };
    setSortorder(sorter.order === "descend" ? 1 : 0);
    // setNextPage(1);
    getOrderDetails(pageSize, nextPage, updatedFilter, true);
  };

  const exportToCSV = async (e) => {
    let filterOptions = {};
    if (!filterOptions.search) delete filterOptions.search;
    if (filterOptions.sortOrder === "") delete filterOptions.sortOrder;
    if (!filterOptions.startDate) delete filterOptions.startDate;
    if (!filterOptions.endDate) delete filterOptions.endDate;
    AxiosInstance(process.env.REACT_APP_ADMIN_NEWSLETTER, "application/json")
      .post("admin/exportNewLetterSubscriberList", filterOptions)
      .then((resp) => {
        const response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          let data = response.data;
          let und_res = data;
          const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
          const fileExtension = ".xlsx";
          const ws = XLSX.utils.json_to_sheet(und_res);
          ws["A1"].v = upperCase(ws["A1"]?.v);
          ws["B1"].v = upperCase(ws["B1"]?.v);
          ws["C1"].v = upperCase(ws["C1"]?.v);
          ws["D1"].v = upperCase(ws["D1"]?.v);
          ws["E1"].v = upperCase(ws["E1"]?.v);
          ws["F1"].v = upperCase(ws["F1"]?.v);
          //   ws["G1"].v = upperCase(ws["G1"]?.v);

          var wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "People");
          /* generate an XLSX file */
          XLSX.writeFile(
            wb,
            `customer-export-${moment(new Date())
              .format("YYYY-MM-DD")
              .split("-")
              .join("")}.xlsx`
          );
          // FileSaver.saveAs(data, "WeeklyPlans" + fileExtension);
        } else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
        }
      })
      .catch((err) => {
        return err;
      });
  };

  function searchClick() {
    let updatedFilter = {};
    getOrderDetails(pageSize, nextPage, updatedFilter);
  }

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  return (
    permissionsArr.length > 0 &&
    permissionsArr.find((x) => x === "Marketing-Abandoned-view") ===
      "Marketing-Abandoned-view" && (
      <div className={`${commonstyles.management_contents} pl-0 pr-0`}>
        <div
          className={`${commonstyles.management_contents_search} ${commonstyles.management_contents_search_sale}`}
        >
          <div
            className={`${commonstyles.management_contents_search_bar} input-group`}
          >
            <InputComponent
              className="with-icon rnd theme-txt-xsm"
              formControl={{
                placeholder: "Search by Email...",
                name: "search",
                style: { height: "38px" },
                value: search,
                onChange: handleInput,
                ["aria-label"]: "Search category",
                ["aria-describedby"]: "search-category",
              }}
            />
            <img src={Search} alt="" />
          </div>
          <ButtonComponent
            className="theme-btn rnd pl-3 pr-4 ml-2"
            attributes={{
              onClick: () => {
                searchClick();
              },
            }}
          >
            Search
          </ButtonComponent>
          <Dropdown
            overlay={filterMenu}
            onVisibleChange={handleVisibleChange}
            visible={visible}
            className="usermanage_table_filter_columns ml-3"
          >
            <div
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
            >
              Filter <img src={arrowDown} width={10} height={20} />
            </div>
          </Dropdown>
          <Dropdown
            overlay={menu}
            className="usermanage_table_filter_columns  ml-3"
          >
            <div
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
            >
              Columns <img src={arrowDown} width={10} height={20} />
            </div>
          </Dropdown>
        </div>

        <div className={`${commonstyles.management_table} table-responsive`}>
          {/* {!isLoading && orderList.length > 0 && (
          <input
            type="checkbox"
            className={`${commonstyles.parentCheckbox}`}
            onChange={parentCheckbox}
          ></input>
        )} */}
          {isLoading === true ? (
            <div
              style={{
                marginLeft: "70vh",
                marginTop: "10vh",
              }}
            >
              <tr className="bg-transparent">
                <td colSpan="100%">
                  <LoadingIndicator loadingText="Fetching Email log details..." />
                </td>
              </tr>
            </div>
          ) : (
            <PDFExport
              ref={props.pdfExportComponent}
              paperSize="auto"
              margin={40}
              fileName={`OrderList for ${new Date().getFullYear()}`}
              author="Damro Team"
            >
              <AntTableComponent
                className="order_table"
                tableProps={{
                  columns: columns,
                  dataSource: orderList,
                  pagination: false,
                  onChange: handleTableChange,
                  components: {
                    header: {
                      row: (props) => renderHeader(props),
                    },
                    body: {
                      row: (props) => renderBody(props),
                    },
                  },
                }}
              />
            </PDFExport>
          )}
        </div>
        {!isLoading && Array.isArray(orderList) && orderList.length ? (
          <Pagination
            pageSizes={pageSizes}
            pageSize={pageSize}
            nextPage={nextPage}
            data={pagination}
            handlePaginationChanges={handlePaginationChanges}
          />
        ) : null}
      </div>
    )
  );
}
