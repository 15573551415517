import React, { useState, useEffect } from "react";
import styles from "../../../styles/PaymentNew.module.css";
import BlackLeftArrow from "../../../assets/images/black_left_arrow.svg";
import { useHistory } from "react-router";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import { notification, Select } from "antd";
import ButtonComponent from "../../modules/ReusableComponent/ButtonComponent";
import { lte } from "lodash";
import { runValidationChecks } from "../../../utils";
import InputComponent from "../../modules/ReusableComponent/InputComponent";
import SelectComponent from "../../modules/ReusableComponent/SelectComponent";
import FormErrorText from "../../modules/ProductManagement/FormErrorText";
import {
  addressRegx,
  alphaNum,
  alphanumericwithdashHyphen,
  alphanumericwithdashHyphenWithLimit,
  emailRegx,
  passwordReg,
  phoneNumber,
  phoneRegx,
  userNameWithSpace,
} from "../../../utils/RegularExp";
import styless from "../../../styles/AddProduct.module.css";
import LoadingIndicator from "../../modules/ReusableComponent/LoadingIndicator";
import commonstyles from "../../../styles/Management_Common.module.css";
import validationRules from "../../../utils/validationRules";
import { isObject, isString, isEqual } from "lodash";
import ImagePreviewChip from "../../modules/ProductManagement/ImagePreviewChip";
import ProductCategoryTreeSelectComponent from "../../modules/ProductManagement/ProductCategoryTreeSelectComponent";
import Plus from "../../../assets/images/plus_lynch_icon.svg";
import exclusionIcon from "../../../assets/images/exclusion.svg";
import MainLeftBanner from "../CMSManagement/MainLeftBanner";
import LeftRightTopBanner from "../CMSManagement/LeftRightTopBanner";
import MadeForBanner from "../CMSManagement/MadeForBanner";
import moment from "moment";
import LoginWithOtpBanner from "./LoginWithOtpBanner";
import ConfirmationBox from "../../modules/ReusableComponent/ConfirmationBox";
import Compressor from "compressorjs";

const { Option } = Select;
const initialFormState = {
  name: "",
  slug: "",
  regionId: [],
  discount: "",
  banners: [],
  images: [],
  categoryId: [],
  page: 1,
  type: "",
};

export default function BannerManagement() {
  const rules = {
    name: [validationRules.required],
    region: [validationRules.required],
    banners: [
      (val, data) => (!url && !file ? "Delivery image required" : true),
    ],
  };
  const firstMainLeftBanner = JSON.parse(JSON.stringify(initialFormState));
  const firstMainLeftBanner1 = JSON.parse(JSON.stringify(initialFormState));
  const firstMainLeftBanner2 = JSON.parse(JSON.stringify(initialFormState));
  const firstMainLeftBanner3 = JSON.parse(JSON.stringify(initialFormState));

  const history = useHistory();
  const [loading, setLoading] = useState({ details: false, update: false });
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [regions, setRegions] = useState([]);
  const [filterRegions, setFilterRegions] = useState([]);
  const [regionId, setRegionId] = useState("");
  const { state } = history.location;
  const [url, setUrl] = useState(null);
  const [file, setFile] = useState(null);
  const [mainLeftBanner, setMainLeftBanner] = useState([firstMainLeftBanner]);
  const [leftRightTopBanner, setLeftRightTopBanner] = useState([
    firstMainLeftBanner1,
  ]);
  const [madeForBanner, setMadeForBanner] = useState([firstMainLeftBanner2]);
  const [loginWithOtpBanner, setLoginWithOtpBanner] = useState([
    firstMainLeftBanner3,
  ]);
  const [mainLeftBannerErrors, setMainLeftBannerErrors] = useState({});
  const [leftRightTopBannerErrors, setLeftRightTopBannerErrors] = useState({});
  const [madeForBannerErrors, setMadeForBannerErrors] = useState({});
  const [remainingRegions, setRemainingRegions] = useState({
    mainLeftBanner: [],
    leftRightTopBanner: [],
    madeForBanner: [],
    loginWithOtpBanner: [],
  });
  const [page, setPage] = useState(state);
  const [categoryNames, setCategoryNames] = useState([]);
  const [bannerDetail, setBannerDetail] = useState([]);
  const [isShowConfirmModal, setisShowConfirmModal] = useState(false);
  const [selectedBannerDetails, setSelectedBannerDetails] = useState({});
  const [compressedImage, setCompressedImage] = useState({});

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  useEffect(() => {
    setPage(state ? state : 1);
    getCategories();
    getRegionList(state ? state : 1);
    return () => {
      history["isBanner"] = true;
    };
  }, []);
  /**
   * @author Rajkumar
   * @param {idss}
   * To get region list
   */
  function getRegionList(idss) {
    setIsLoading(true);
    AxiosInstance(PORTS.REGION_SERVICE, "application/json")
      .get("/admin/region/getRegionList")
      .then((res) => {
        if (res.status === 200 && res.data.status === 200) {
          const data = res.data.daaata;
          setRegions(data);
          setFilterRegions(data);
          let type =
            idss === 1 ? [5, 6, 7] : idss === 2 ? [8, 9] : [1, 2, 3, 4];
          let paramss = { page: idss, type: type };
          getBannerDetails(paramss, data, true);
        } else {
          setIsLoading(false);
        }
      })
      .catch((er) => {
        console.log(er);
        setIsLoading(false);
      });
  }
  /**
   * @author Rajkumar
   * @param {idss}
   * To get categories
   */
  function getCategories() {
    AxiosInstance(PORTS.CATALOG_SERVICE, "application/json")
      .get("/admin/catalog/getCategoryName/")
      .then((res) => {
        if (res && res.data && res.status == "200") {
          setCategoryNames(res.data.daaata);
        }
      })
      .catch((er) => {
        console.log(er);
      });
  }
  /**
   * @author Rajkumar
   * @param {params, regs, type}
   * To get banner details
   */
  function getBannerDetails(params, regs, type) {
    if (!params?.regionId) {
      delete params.regionId;
    }
    AxiosInstance(PORTS.DASHBOARD_SERVICE, "application/json")
      .post(`/banner/getBanner`, params)
      .then((res) => {
        if (res.data.status === 200) {
          let data = res.data.data;
          const cloneRemainingRegions = { ...remainingRegions };
          if (data?.length > 0) {
            data.forEach((item) => {
              item?.banner?.forEach((sub) => {
                sub.images = sub.banners;
                let regionIds = [];
                if (sub.regionId?.length > 0) {
                  regs.forEach((x) => {
                    sub.regionId.forEach((y) => {
                      if (x._id === y) {
                        regionIds.push(x.name);
                      }
                    });
                  });
                  sub.regionId = regionIds;
                } else {
                  sub.regionId = ["All"];
                }
              });
              if (
                item.type[0] === 5 ||
                item.type[0] === 8 ||
                item.type[0] === 1
              ) {
                let reg = [];
                item.banner.forEach((x) => {
                  x?.regionId?.forEach((y) => {
                    reg.push(y);
                  });
                });
                if (type) {
                  cloneRemainingRegions.mainLeftBanner = regs.filter(
                    (o) => !reg.includes(o.name)
                  );
                }
                let accessibleBanners = item.banner?.filter(x => x?.regionId?.length > 0) || [];
                setMainLeftBanner(accessibleBanners);
              }
              if (
                item.type[0] === 6 ||
                item.type[0] === 9 ||
                item.type[0] === 2
              ) {
                let reg = [];
                item.banner.forEach((x) => {
                  x?.regionId?.forEach((y) => {
                    reg.push(y);
                  });
                });
                if (type) {
                  cloneRemainingRegions.leftRightTopBanner = regs.filter(
                    (o) => !reg.includes(o.name)
                  );
                }
                let accessibleBanners = item.banner?.filter(x => x?.regionId?.length > 0) || [];
                setLeftRightTopBanner(accessibleBanners);
              }
              if (item.type[0] === 7 || item.type[0] === 3) {
                let reg = [];
                item.banner.forEach((x) => {
                  x?.regionId?.forEach((y) => {
                    reg.push(y);
                  });
                });
                if (type) {
                  cloneRemainingRegions.madeForBanner = regs.filter(
                    (o) => !reg.includes(o.name)
                  );
                }
                let accessibleBanners = item.banner?.filter(x => x?.regionId?.length > 0) || [];
                setMadeForBanner(accessibleBanners);
              }
              if (item.type[0] === 4) {
                let reg = [];
                item.banner.forEach((x) => {
                  x?.regionId?.forEach((y) => {
                    reg.push(y);
                  });
                });
                if (type) {
                  cloneRemainingRegions.loginWithOtpBanner = regs.filter(
                    (o) => !reg.includes(o.name)
                  );
                }
                let accessibleBanners = item.banner?.filter(x => x?.regionId?.length > 0) || [];
                setLoginWithOtpBanner(accessibleBanners);
              }
              setBannerDetail(data);
            });
            let check1,
              check2,
              check3,
              check4 = false;
            data.forEach((x) => {
              if (x?.type[0] === 5 || x?.type[0] === 8 || x?.type[0] === 1) {
                check1 = true;
              }
              if (x?.type[0] === 6 || x?.type[0] === 9 || x?.type[0] === 2) {
                check2 = true;
              }
              if (x?.type[0] === 7 || x?.type[0] === 3) {
                check3 = true;
              }
              if (x?.type[0] === 4) {
                check4 = true;
              }
            });
            if (!check1) {
              setMainLeftBanner([]);
            }
            if (!check2) {
              setLeftRightTopBanner([]);
            }
            if (!check3) {
              setMadeForBanner([]);
            }
            if (!check4) {
              setLoginWithOtpBanner([]);
            }
            setIsUpdating(false);
          } else {
            setBannerDetail([]);
            setMainLeftBanner([]);
            setLeftRightTopBanner([]);
            setMadeForBanner([]);
            setLoginWithOtpBanner([]);
            setIsUpdating(false);
          }
          setIsLoading(false);
          setRemainingRegions(cloneRemainingRegions);
        } else {
          setIsLoading(false);
        }
      })
      .catch((er) => {
        console.log(er);
        return er;
      })
      .finally(() => {
        setIsLoading(false);
        setIsUpdating(false);
      });
  }

  function returnComponent(componentName) {
    if (componentName === "mainLeftBanner") {
      return [...mainLeftBanner];
    } else if (componentName === "leftRightTopBanner") {
      return [...leftRightTopBanner];
    } else if (componentName === "madeForBanner") {
      return [...madeForBanner];
    } else {
      return [...loginWithOtpBanner];
    }
  }

  function bannerReturn(componentName, returnVal) {
    if (componentName === "mainLeftBanner") {
      setMainLeftBanner(returnVal);
    } else if (componentName === "leftRightTopBanner") {
      setLeftRightTopBanner(returnVal);
    } else if (componentName === "madeForBanner") {
      setMadeForBanner(returnVal);
    } else {
      setLoginWithOtpBanner(returnVal);
    }
  }
  /**
   * @author Rajkumar
   * @param {value, name, indx, bannerName}
   * ON CHANGE SEARCH & SELECT
   */
  const searchSelectOnChange = (value, name, indx, bannerName) => {
    let selectedVal = [];
    let isAll = false;
    if (value?.length > 0) {
      value.forEach((item, index) => {
        if (value[value.length - 1] === "All") {
          isAll = true;
        }
      });
    };
    if (isAll) {
      selectedVal.push("All");
    } else {
      selectedVal = value?.filter((x, indx) => x !== "All");
    }
    let cloneBanner = returnComponent(bannerName);
    const cloneRemainingRegions = { ...remainingRegions };
    let arr = [];
    if (name === "regionId" && cloneBanner?.length > 1 && value[value.length - 1] === "All") {
      let isOtherRegion = false;
      cloneBanner?.forEach(item => {
        item?.regionId?.forEach(regionID => {
          regions?.forEach(orgReg => {
            if (regionID === orgReg?.name) {
              isOtherRegion = true;
            }
          })
        })
      });
      if (isOtherRegion) {
        let params = {
          cloneBanner, indx, selectedVal, cloneRemainingRegions, bannerName
        }
        setSelectedBannerDetails(params);
        setisShowConfirmModal(true);
      } else {
        continueToAddRegion(cloneBanner, indx, selectedVal, cloneRemainingRegions, bannerName);
      }
    } else {
      if (name === "regionId") {
        continueToAddRegion(cloneBanner, indx, selectedVal, cloneRemainingRegions, bannerName);
      }
    }
    if (name === "categoryId") {
      cloneBanner.forEach((x, index) => {
        if (index === indx) {
          x["categoryId"] = selectedVal;
        }
      });
    }
    bannerReturn(bannerName, cloneBanner);
  };

  function deleteCustomer() {
    setisShowConfirmModal(false);
    continueToAddRegion(selectedBannerDetails?.cloneBanner,
      selectedBannerDetails?.indx, selectedBannerDetails?.selectedVal,
      selectedBannerDetails?.cloneRemainingRegions, selectedBannerDetails?.bannerName);
  }

  function continueToAddRegion(cloneBanner, indx, selectedVal, cloneRemainingRegions, bannerName) {
    let arr = [];
    cloneBanner.forEach((x, index) => {
      if (index === indx) {
        x["regionId"] = selectedVal;
      }
      x.regionId?.forEach((y) => arr.push(y));
    });
    cloneRemainingRegions[bannerName] = regions.filter(
      (o) => !arr.includes(o.name)
    );
    setRemainingRegions(cloneRemainingRegions);
  }
// old
  // const handleFormInput = (e, indx, bannerName) => {
  //   const { name, value } = e.target;
  //   const newValue = Array.isArray(value) ? value : value.trim();
  //   let cloneBanner = returnComponent(bannerName);
  //   if (value.trim()) {
  //     cloneBanner.forEach((x, index) => {
  //       if (index === indx) {
  //         if (name === "discount") {
  //           // e.preentDefault()
  //           const inputtedValue = e.currentTarget.value
  //           if (isNaN(inputtedValue)) return
  //           x[name] = inputtedValue <= 100 ? inputtedValue : 100
  //         } else {
  //           x[name] = name === "slug" ? value.replace(' ', '') : (name === "discount" ? (parseFloat(value) ? value : '') : value);
  //         }
  //       }
  //     });
  //   } else {
  //     cloneBanner.forEach((x, index) => {
  //       if (index === indx) {
  //         x[name] = "";
  //       }
  //     });
  //   }
  //   bannerReturn(bannerName, cloneBanner);
  // };


  // old

  // new--slug only
  // const handleFormInput = (e, indx, bannerName) => {
  //   const { name, value } = e.target;
  //   let cloneBanner = returnComponent(bannerName);
  
  //   if (name === "slug") {
  //     // Handle slug input
  //     const slugs = value.split(',').map(s => s.trim().replace(/\s+/g, '')); // Split by comma and clean
  //     cloneBanner.forEach((banner, index) => {
  //       if (index === indx) {
  //         // Get the number of images for this banner
  //         const imageCount = banner.images?.length || 0;
          
  //         // Create an array of slugs matching the image count
  //         let processedSlugs = [];
  //         for (let i = 0; i < imageCount; i++) {
  //           // Use provided slug if available, otherwise use placeholder
  //           processedSlugs[i] = slugs[i] || '#';
  //         }
          
  //         // If no images yet, store at least one slug
  //         if (imageCount === 0 && slugs.length > 0) {
  //           processedSlugs = [slugs[0]];
  //         }
          
  //         // Store the processed slugs joined by commas
  //         banner[name] = processedSlugs.join(',');
  //       }
  //     });
  //   } else if (name === "discount") {
  //     // Handle discount input
  //     cloneBanner.forEach((banner, index) => {
  //       if (index === indx) {
  //         const inputtedValue = e.currentTarget.value;
  //         if (!isNaN(inputtedValue)) {
  //           banner[name] = inputtedValue <= 100 ? inputtedValue : 100;
  //         }
  //       }
  //     });
  //   } else {
  //     // Handle all other inputs
  //     const newValue = Array.isArray(value) ? value : value.trim();
  //     if (newValue) {
  //       cloneBanner.forEach((banner, index) => {
  //         if (index === indx) {
  //           banner[name] = newValue;
  //         }
  //       });
  //     } else {
  //       cloneBanner.forEach((banner, index) => {
  //         if (index === indx) {
  //           banner[name] = "";
  //         }
  //       });
  //     }
  //   }
  
  //   bannerReturn(bannerName, cloneBanner);
  // };

  // new slug and drag
//   const handleFormInput = (e, indx, bannerName) => {
//     const { name, value } = e.target;
//     let cloneBanner = returnComponent(bannerName);
    
//     if (name === "slug") {
//         const slugs = value.split(',').map(s => s.trim().replace(/\s+/g, '')); 
//         cloneBanner = cloneBanner.map((banner, index) => {
//             if (index === indx) {
//                 const imageCount = banner.images?.length || 0;
//                 let processedSlugs = [];
                
//                 // Match slugs to current image order
//                 for (let i = 0; i < imageCount; i++) {
//                     processedSlugs[i] = slugs[i] || '#';
//                 }
                
//                 return {
//                     ...banner,
//                     [name]: processedSlugs.join(',')
//                 };
//             }
//             return banner;
//         });
//     } else if (name === "discount") {
//         cloneBanner = cloneBanner.map((banner, index) => {
//             if (index === indx) {
//                 const inputtedValue = e.currentTarget.value;
//                 return {
//                     ...banner,
//                     [name]: !isNaN(inputtedValue) ? (inputtedValue <= 100 ? inputtedValue : 100) : banner[name]
//                 };
//             }
//             return banner;
//         });
//     } else {
//         const newValue = Array.isArray(value) ? value : value.trim();
//         cloneBanner = cloneBanner.map((banner, index) => {
//             if (index === indx) {
//                 return {
//                     ...banner,
//                     [name]: newValue || ""
//                 };
//             }
//             return banner;
//         });
//     }
    
//     bannerReturn(bannerName, cloneBanner);
// };

// new slug remove image
const handleFormInput = (e, indx, bannerName) => {
  const { name, value } = e.target;
  let cloneBanner = returnComponent(bannerName);
  
  if (name === "slug") {
    const inputElement = e.target; // Get the input field reference
        const cursorPosition = inputElement.selectionStart; // 
      let slugs = value.split(',').map(s => s.trim().replace(/\s+/g, '')); 
      // console.log("slugs",slugs, slugs.map(s => s.includes('#') && s.length > 1 ? s.replace(/#/g, '') : s));
      
      slugs = slugs.map(s => s.includes('#') && s.length > 1 ? s.replace(/#/g, '') : s);
      cloneBanner = cloneBanner.map((banner, index) => {
          if (index === indx) {
              const imageCount = banner.images?.length || 0;
              let processedSlugs = [];
              
              // Match slugs to current image count
              for (let i = 0; i < imageCount; i++) {
                  processedSlugs[i] = slugs[i] || '#';
              }
              
              return {
                  ...banner,
                  [name]: processedSlugs.join(',')
              };
          }
          return banner;
      });

      bannerReturn(bannerName, cloneBanner);

        setTimeout(() => {
            inputElement.setSelectionRange(cursorPosition, cursorPosition);
        }, 0);
  } else if (name === "discount") {
      cloneBanner = cloneBanner.map((banner, index) => {
          if (index === indx) {
              const inputtedValue = e.currentTarget.value;
              return {
                  ...banner,
                  [name]: !isNaN(inputtedValue) ? (inputtedValue <= 100 ? inputtedValue : 100) : banner[name]
              };
          }
          return banner;
      });
  } else {
      const newValue = Array.isArray(value) ? value : value.trim();
      cloneBanner = cloneBanner.map((banner, index) => {
          if (index === indx) {
              return {
                  ...banner,
                  [name]: newValue || ""
              };
          }
          return banner;
      });
  }
  
  bannerReturn(bannerName, cloneBanner);
 
};
// old
  // const handleImageInput = (e, indx, bannerName) => {
  //   const { name, files: inputFiles } = e.target;
  //   let cloneBanner = returnComponent(bannerName);
  //   const file_name = inputFiles[0].name.toLowerCase();
  //   if (name) {
  //     if (!file_name.match(/\.(jpg|jpeg|png|web|gif)$/)) {
  //       showNotification({
  //         type: "error",
  //         message: "There were few errors",
  //         description: "Please upload only jpg,jpeg and png format images",
  //       });
  //       return;
  //     } else if (inputFiles[0].size > 1024 * 1024 * 3) {
  //       showNotification({
  //         type: "error",
  //         message: "There were few errors",
  //         description: "Please upload file less than 3MB size",
  //       });
  //       return;
  //     } else {
  //       let isAct = false;
  //       cloneBanner.forEach((x, index) => {
  //         if (index === indx) {
  //           isAct = x['isActive']?x['isActive']:false;
  //         }
  //       });
  //       if(isAct) {
  //         // setCompressedImage
  //         new Compressor(inputFiles[0], {
  //           convertTypes: "image/png",
  //           quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
  //           success: (compressedResult) => {
  //             let obj = {
  //               indx,
  //               bannerName,
  //               compressedResult
  //             }
  //             setCompressedImage(obj);
  //           }
  //         })
  //       } else {
  //         cloneBanner.forEach((x, index) => {
  //           if (index === indx) {
  //             if (
  //               (bannerName === "madeForBanner" ||
  //                 bannerName === "loginWithOtpBanner") &&
  //               x["images"].length === 0
  //             ) {
  //               x["images"].push(inputFiles[0]);
  //             } else if (
  //               bannerName === "mainLeftBanner" ||
  //               bannerName === "leftRightTopBanner"
  //             ) {
  //               x["images"].push(inputFiles[0]);
  //             } else {
  //               showNotification({
  //                 type: "error",
  //                 message: "There were few errors",
  //                 description:
  //                   "You can't able to add more than one image for this banner",
  //               });
  //               return;
  //             }
  //           }
  //         });
  //         bannerReturn(bannerName, cloneBanner);
  //       }
  //     }
  //   }
  // };

  // old

  // new
  const handleImageInput = (e, indx, bannerName) => {
    const { name, files: inputFiles } = e.target;
    // console.log("7022",name);
    
    let cloneBanner = returnComponent(bannerName);
    const file_name = inputFiles[0].name.toLowerCase();

    if (name) {
        if (!file_name.match(/\.(jpg|jpeg|png|gif)$/)) {
            showNotification({
                type: "error",
                message: "There were few errors",
                description: "Please upload only jpg,jpeg and png format images",
            });
            return;
        } else if (inputFiles[0].size > 1024 * 1024 * 3) {
            showNotification({
                type: "error",
                message: "There were few errors",
                description: "Please upload file less than 3MB size",
            });
            return;
        } else {
            let isAct = false;
            cloneBanner.forEach((x, index) => {
                if (index === indx) {
                  
                    isAct = x['isActive'] ? x['isActive'] : false;
                }
            });
            
            if(isAct) {
          
                new Compressor(inputFiles[0], {
                    convertTypes: "image/png",
                    quality: 0.6,
                    success: (compressedResult) => {
                        let obj = {
                            indx,
                            bannerName,
                            compressedResult
                        }
                        setCompressedImage(obj);
                      
                        // Update slugs after compression
                        setTimeout(() => {
                          updateSlugsAfterImageChange(indx, bannerName);
                      }, 0);
                    }
                })
            } else {
                cloneBanner = cloneBanner.map((banner, index) => {
                    if (index === indx) {
                        let updatedImages = [...(banner.images || [])];
                        
                        if (bannerName === "madeForBanner" || bannerName === "loginWithOtpBanner") {
                            if (updatedImages.length === 0) {
                                updatedImages = [inputFiles[0]];
                            } else {
                                showNotification({
                                    type: "error",
                                    message: "There were few errors",
                                    description: "You can't able to add more than one image for this banner",
                                });
                                return banner;
                            }
                        } else if (bannerName === "mainLeftBanner" || bannerName === "leftRightTopBanner") {
                            updatedImages.push(inputFiles[0]);
                        }
                        
                        // Get current slugs
                        const currentSlugs = banner.slug ? banner.slug.split(',') : [];
                        // Add new '#' for the new image
                        const updatedSlugs = [...currentSlugs];
                        while (updatedSlugs.length < updatedImages.length) {
                            updatedSlugs.push('#');
                        }
                        
                        return {
                            ...banner,
                            images: updatedImages,
                            slug: updatedSlugs.join(',')
                        };
                    }
                    return banner;
                });
                
                bannerReturn(bannerName, cloneBanner);
            }
        }
    }
};

const updateSlugsAfterImageChange = (indx, bannerName) => {
    let cloneBanner = returnComponent(bannerName);
    // debugger;
    cloneBanner = cloneBanner?.map((banner, index) => {
        if (index === indx) {
            const imageCount = banner?.images?.length || 0;
            const currentSlugs = banner?.slug ? banner.slug.split(',') : [];
            let updatedSlugs = [...currentSlugs];
            
            // Adjust slugs to match image count
            while (updatedSlugs.length < imageCount) {
                updatedSlugs.push('#');
            }
            // Trim excess slugs if images were removed
            updatedSlugs = updatedSlugs.slice(0, imageCount);
            
            return {
                ...banner,
                slug: updatedSlugs.join(',')
            };
        }
        return banner;
    });
    
    bannerReturn(bannerName, cloneBanner);
};
  // new

  useEffect(() => {
    let bannerName = compressedImage?.bannerName;
    let cloneBanner = returnComponent(bannerName);
    cloneBanner.forEach((x, index) => {
      if (index === compressedImage?.indx) {
        if (
          (bannerName === "madeForBanner" ||
            bannerName === "loginWithOtpBanner") &&
          x["images"].length === 0
        ) {
          x["images"].push(compressedImage?.compressedResult);
        } else if (
          bannerName === "mainLeftBanner" ||
          bannerName === "leftRightTopBanner"
        ) {
          x["images"].push(compressedImage?.compressedResult);
        } else {
          showNotification({
            type: "error",
            message: "There were few errors",
            description:
              "You can't able to add more than one image for this banner",
          });
          return;
        }
      }
    });
    bannerReturn(bannerName, cloneBanner);
  }, [compressedImage])
// old
  // function removeImages(img, img_indx, Banner_indx, bannerName) {
  //   let cloneBanner = returnComponent(bannerName);
  //   cloneBanner.forEach((x, index) => {
  //     if (index === Banner_indx) {
  //       x.images = x.images?.filter((x, indx) => indx !== img_indx);
  //       x.banners = x.banners?.filter((x, indx) => x !== (!img?.name && img));
  //     }
  //   });
  //   bannerReturn(bannerName, cloneBanner);
  // }
// old
// new
const removeImages = (img, img_indx, Banner_indx, bannerName) => {
  let cloneBanner = returnComponent(bannerName);
  
  cloneBanner = cloneBanner.map((banner, index) => {
      if (index === Banner_indx) {
          // Remove the image at the specified index
          const updatedImages = banner.images?.filter((_, indx) => indx !== img_indx) || [];
          const updatedBanners = banner.banners?.filter((x, indx) => x !== (!img?.name && img)) || [];
          
          // Get current slugs and update them
          const currentSlugs = banner.slug ? banner.slug.split(',') : [];
          // Remove the slug at the same index as the removed image
          const updatedSlugs = currentSlugs.filter((_, indx) => indx !== img_indx);
          
          return {
              ...banner,
              images: updatedImages,
              banners: updatedBanners,
              // Join the remaining slugs back into a comma-separated string
              slug: updatedSlugs.join(',')
          };
      }
      return banner;
  });
  
  bannerReturn(bannerName, cloneBanner);
};
// new
  function addBanners(bannerName, type) {
    let cloneBanner = returnComponent(bannerName);
    let lastArray = cloneBanner.length;
    if (
      cloneBanner[lastArray - 1].name &&
      cloneBanner[lastArray - 1].regionId?.length > 0 &&
      (cloneBanner[lastArray - 1].images?.length > 0 ||
        cloneBanner[lastArray - 1].banners?.length > 0)
    ) {
      let newBanner = JSON.parse(JSON.stringify(initialFormState));
      if (regions?.length === 1) {
        newBanner.regionId.push(regions[0]?.name)
      }
      if(type == 9){
        newBanner = {...newBanner, type: 9, page: 2}
      }
      cloneBanner.push(newBanner);
      bannerReturn(bannerName, cloneBanner);
    } else {
      showNotification({
        type: "warning",
        message: "There were few errors",
        description: "Please add name/regions/images to continue",
      });
    }
  }
  /**
   * @author Rajkumar
   * @param {Banner_indx, bannerName}
   * To remove banners
   */
  function removeBanners(Banner_indx, bannerName) {
    let cloneBanner = returnComponent(bannerName);
    cloneBanner?.forEach((item, indx) => {
      if (indx === Banner_indx) {
        if (item?._id) {
          AxiosInstance(PORTS.DASHBOARD_SERVICE, "application/json")
            .get(`/banner/bannerRemove/${item._id}`)
            .then((res) => {
              let remainingBanners = cloneBanner.filter((x, index) => (index !== Banner_indx));
              bannerReturn(bannerName, remainingBanners);
            })
            .catch((er) => {
              console.log(er);
            });
        } else {
          let remainingBanners = cloneBanner.filter((x, index) => (index !== Banner_indx));
          bannerReturn(bannerName, remainingBanners);
        }
      }
    })
  }

  function submit() {
    let error = false;
    let sortingOccurred = false;


    mainLeftBanner.forEach((item,index) => {
      if(item.discount < 10){
        showNotification({
          type: "warning",
          message: "There were few errors",
          description: "Value must be greater than or equal to 10.",
        });
        error = true;
      }
      if(item?.images?.length < 2){
        showNotification({
          type: "warning",
          message: "Only Few banners ",
          description: "Value must be greater than or equal to 2.",
        });
        sortingOccurred = false;
      }
      if (
        !item.name ||
        item.regionId.length === 0 ||
        (item.images.length === 0 && item.banners.length === 0)
      ) {
        showNotification({
          type: "warning",
          message: "There were few errors",
          description: `Please add name/regions/images in ${item?.name}`,
        });
        error = true;
      }
      if(item?.images?.length > 2){
      if (!error && JSON.stringify(item.banners) !== JSON.stringify(item.images)) {
        sortingOccurred = true;
        mainLeftBanner[index].banners = [...item.images];  // update bannersarray to be the same as imagesarr
    }
  }
  //   if (!sortingOccurred && mainLeftBanner.length === 1) {
  //     showNotification({
  //         type: "info",
  //         message: "Single Banner Sorting",
  //         description: "Only one banner is present. No sorting changes made.",
  //     });
  //     return;  // Early return if only one banner was present and no sorting happened
  // }
    });
    leftRightTopBanner.forEach((item) => {
      if (
        !error &&
        (!item.name ||
          item.regionId.length === 0 ||
          (item.images.length === 0 && item.banners.length === 0))
      ) {
        showNotification({
          type: "warning",
          message: "There were few errors",
          description: `Please add name/regions/images in ${item?.name}`,
        });
        error = true;
      }
      if(item?.discount < 10 && item?.page !== 2 && item.type !== 9 ){
        showNotification({
          type: "warning",
          message: "There were few errors",
          description: "Value must be greater than or equal to 10.",
        });
        error = true;
      }
    });
    if (page !== 2) {
      madeForBanner.forEach((item) => {
        if (
          !error &&
          (!item.name ||
            item.regionId.length === 0 ||
            (item.images.length === 0 && item.banners.length === 0))
        ) {
          showNotification({
            type: "warning",
            message: "There were few errors",
            description: `Please add name/regions/images in ${item?.name}`,
          });
          error = true;
        }
        if(item?.discount < 10){
          showNotification({
            type: "warning",
            message: "There were few errors",
            description: "Value must be greater than or equal to 10.",
          });
          error = true;
        }
      });
    }
    if (page === 3) {
      loginWithOtpBanner.forEach((item) => {
        if (
          !error &&
          (!item.name ||
            item.regionId.length === 0 ||
            (item.images.length === 0 && item.banners.length === 0))
        ) {
          showNotification({
            type: "warning",
            message: "There were few errors",
            description: `Please add name/regions/images in ${item?.name}`,
          });
          error = true;
        }
        if(item?.discount < 10){
          showNotification({
            type: "warning",
            message: "There were few errors",
            description: "Value must be greater than or equal to 10.",
          });
          error = true;
        }
      });
    }
    if (!error) {
      setLoading({ details: false, update: true });
      continueSave();
    }
  }

  function continueSave() {
    setIsUpdating(true);
    let compainedBanner = [];
    let images = [];
    mainLeftBanner.forEach((item) => {
      item.type = page === 1 ? 5 : page === 2 ? 8 : 1;
      compainedBanner.push(item);
    });
    leftRightTopBanner.forEach((item) => {
      item.type = page === 1 ? 6 : page === 2 ? 9 : 2;
      compainedBanner.push(item);
    });
    if (page !== 2) {
      madeForBanner.forEach((item) => {
        item.type = page === 1 ? 7 : 3;
        compainedBanner.push(item);
      });
    }
    if (page === 3) {
      loginWithOtpBanner.forEach((item) => {
        item.type = page === 1 ? 7 : 3;
        compainedBanner.push(item);
      });
    }
    compainedBanner.forEach((item, indx) => {
      if (item?.images.length > 0) {
        item.images.forEach((x, index) => {
          if (x?.name) {
            x["bannertype"] = indx;
          }
          images.push(x);
        });
      }
      let regionIds = [];
      let isAllRegion = false;
      item?.regionId?.forEach(reg => {
        if (reg === "All") {
          isAllRegion = true;
        }
      });
      if (isAllRegion) {
        item.regionId = [];
      } else {
        regions?.forEach((x) => {
          item?.regionId?.forEach((y) => {
            if (x.name === y) {
              regionIds.push(x._id);
            }
          });
        });
      }
      let catId = [];
      if (item?.categoryId?.length > 0) {
        item?.categoryId.forEach((z) => {
          if (!z?.value && z) {
            catId.push(z);
          } else if (z?.value) {
            catId.push(z.value);
          }
        });
      }
      item.categoryId = catId;
      item.regionId = regionIds;
      item.page = page;
      item.discount = parseFloat(item.discount);
      // if (!item.slug) {
      //   delete item.slug;
      // }
      delete item.images;
      delete item.createdAt;
      delete item?.isActive;
      item.banners = item.banners.filter((x) => !x?.name);
    });
    // images.forEach((item, index) => {
    //     if(item?.name) {
    //         item['bannertype'] = index
    //     }
    // })
    let data = { banners: compainedBanner };
    const formData = new FormData();
    formData.append("data", JSON.stringify(data));
    images.forEach((field, indx) => {
      let _name = "banner_image_" + field.bannertype;
      delete field.bannertype;
      if (field?.name) {
        formData.append(_name, field);
      }
    });
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    AxiosInstance(PORTS.DASHBOARD_SERVICE)
      .post("/banner/createBanner", formData, config)
      .then((res) => {
        if (res.data.status === 200) {
          showNotification({
            type: "success",
            message: "Success",
            description: res.data.msg,
          });
          // history.push("/Content");
          setLoading({ details: false, update: false });
          // setIsUpdating(false);
          getRegionList(page ? page : 1);
        }
      })
      .catch((er) => {
        showNotification({
          type: "error",
          message: "Adding Banners failed",
          description: "Error occured",
        });
        setLoading({ details: false, update: false });
        setIsUpdating(false);
      })
      .finally(() => {
        setLoading({ details: false, update: false });
        // setIsLoading(false);
        // setIsUpdating(false);
      });
  }

  const filterOnChangeforRegion = (e) => {
    const { name, value } = e.target;
    if (name === "regionId") {
      setRegionId(value);
      let type = page === 1 ? [5, 6, 7] : page === 2 ? [8, 9] : [1, 2, 3, 4];
      let paramss = { page: page, type: type, regionId: value };
      setIsLoading(true);
      getBannerDetails(paramss, regions, false);
    }
  };

  const pageWiseOnclickHandler = (data) => {
    setIsLoading(true);
    let type = data === 1 ? [5, 6, 7] : data === 2 ? [8, 9] : [1, 2, 3, 4];
    setRegionId("");
    setPage(data);
    let paramss = { page: data, type: type };
    getBannerDetails(paramss, regions, true);
  };

  function cancelDelete() {
    setSelectedBannerDetails({});
    setisShowConfirmModal(false);
  }

  // function handleCheckboxChange(e, indx, bannerName) {
  //   const { name, checked } = e.target;
  //   let cloneBanner = returnComponent(bannerName);
  //   cloneBanner.forEach((x, index) => {
  //     if (index === indx) {
  //       x['isActive'] = checked
  //     }
  //   });
  //   bannerReturn(bannerName, cloneBanner);
  // }


  // new try --old
  // const updateImages = (updatedImages, bannerName, indx) => {
  //   const updatedBanners = [...mainLeftBanner];
  //   updatedBanners[indx] = {
  //     ...updatedBanners[indx],
  //     images: updatedImages
  //   };
  //   console.log("updated banners",updatedBanners);
    

  //   setMainLeftBanner(updatedBanners); 
  // };

  // new url-slug
 
//  -compressimage
function handleCheckboxChange(e, indx, bannerName) {
  const { checked } = e.target;
  let cloneBanner = returnComponent(bannerName);

  cloneBanner = cloneBanner.map((banner, index) => {
      if (index === indx) {
          let updatedSlugs = banner.slug ? banner.slug.split(',') : [];
          if (checked) {
              // Add '#' for active banners if slugs don't exist
              while (updatedSlugs.length < (banner.images || []).length) {
                  updatedSlugs.push('#');
              }
          } 
          // else {
          //     // Clear slugs if the banner is not active
          //     updatedSlugs = [];
          // }

          return {
              ...banner,
              isActive: checked,
              slug: updatedSlugs.join(','),
          };
      }
      return banner;
  });

  bannerReturn(bannerName, cloneBanner);
}


 
  const updateImages = (updatedBanner, bannerName, indx) => {
    const cloneBanner = returnComponent(bannerName).map((banner, index) => {
        if (index === indx) {
            return {
                ...banner,
                images: updatedBanner.images,
                slug: updatedBanner.slug
            };
        }
        return banner;
    });
    
    bannerReturn(bannerName, cloneBanner);
};
  return (
    <div className={styles.management_contents}>
      <div className={styles.management_header}>
        <h1>
          <img
            src={BlackLeftArrow}
            alt=""
            className="cursor-pointer"
            onClick={() => history.push("/Content")}
          />
          {state?._id ? "Edit Delivery" : "Banners Management"}
        </h1>
      </div>
      <div
        className={`${styles.management_header} ${styles.banner_tab_btn} banner_tab_btn`}
      >
        <div className={styles.management_contents_upload}>
          <ButtonComponent
            className={`rnd ${page === 1 ? "active" : ""}`}
            attributes={{
              onClick: () => pageWiseOnclickHandler(1),
            }}
          >
            Home Page
          </ButtonComponent>
          <ButtonComponent
            className={`rnd ${page === 2 ? "active" : ""}`}
            attributes={{
              onClick: () => pageWiseOnclickHandler(2),
            }}
          >
            Listing Page
          </ButtonComponent>
          <ButtonComponent
            className={`rnd ${page === 3 ? "active" : ""}`}
            attributes={{
              onClick: () => pageWiseOnclickHandler(3),
            }}
          >
            Modal Page
          </ButtonComponent>
        </div>
      </div>
      <div className={`${styles.management_header} ${styles.banner_city_list}`}>
        <div className={`${styles.banner_citys}`}>
          <SelectComponent
            className="form-control-md"
            attributes={{
              name: "regionId",
              value: regionId,
              onChange: filterOnChangeforRegion,
            }}
          >
            {filterRegions?.length > 1 && <option value="">All Region</option>}
            {Array.isArray(filterRegions)
              ? filterRegions.map((region) => (
                <option key={region._id} value={region._id}>
                  {region.name}
                </option>
              ))
              : null}
          </SelectComponent>
          <div className={`${styles.banner_citys_update}`}>
            {bannerDetail[0]?.userName && (
              <p>
                Last update by: <span>{bannerDetail[0]?.userName}</span>
              </p>
            )}
            {bannerDetail[0]?.updatedAt && (
              <p>
                Last update on:{" "}
                <span>
                  {moment(bannerDetail[0]?.updatedAt).format(
                    "DD/MM/YYYY | hh:mm A"
                  )}
                </span>
              </p>
            )}
          </div>
        </div>

        {(loginWithOtpBanner?.length > 0 || madeForBanner?.length > 0 || leftRightTopBanner?.length > 0 || mainLeftBanner?.length > 0) && (
          <div className={styles.management_contents_upload}>
            <ButtonComponent
              className="rnd mr-3 outline_cancel_btn"
              attributes={{
                onClick: () => history.push(`/Content`),
              }}
            >
              Cancel
            </ButtonComponent>
            <ButtonComponent
              className="rnd"
              attributes={{
                disabled: loading.update,
                onClick: submit,
              }}
            >
              {loading.update ? "Saving" : "Save"}
            </ButtonComponent>
          </div>
        )}
      </div>
      {(isLoading || isUpdating) ? (
        <tr className="bg-transparent d-flex justify-content-center">
          <td colSpan="100%">
            <LoadingIndicator loadingText={isUpdating ? "Updating Banner..." : "Fetching banner details..."} />
          </td>
        </tr>
      ) : (
        <div className={styles.management_details}>
          <MainLeftBanner
            regions={regions}
            searchSelectOnChange={searchSelectOnChange}
            mainLeftBanner={mainLeftBanner}
            remainingRegions={remainingRegions}
            mainLeftBannerErrors={mainLeftBannerErrors}
            handleFormInput={handleFormInput}
            categoryNames={categoryNames}
            handleImageInput={handleImageInput}
            addBanners={addBanners}
            removeBanners={removeBanners}
            regionId={regionId}
            page={page}
            removeImages={removeImages}
            handleCheckboxChange={handleCheckboxChange}
            updateImages={updateImages}
          />
         
          <LeftRightTopBanner
            regions={regions}
            searchSelectOnChange={searchSelectOnChange}
            leftRightTopBanner={leftRightTopBanner}
            remainingRegions={remainingRegions}
            mainLeftBannerErrors={mainLeftBannerErrors}
            handleFormInput={handleFormInput}
            categoryNames={categoryNames}
            handleImageInput={handleImageInput}
            addBanners={addBanners}
            removeBanners={removeBanners}
            regionId={regionId}
            page={page}
            removeImages={removeImages}
            handleCheckboxChange={handleCheckboxChange}
          />
          {page !== 2 && (
            <MadeForBanner
              regions={regions}
              searchSelectOnChange={searchSelectOnChange}
              madeForBanner={madeForBanner}
              remainingRegions={remainingRegions}
              mainLeftBannerErrors={mainLeftBannerErrors}
              handleFormInput={handleFormInput}
              categoryNames={categoryNames}
              handleImageInput={handleImageInput}
              addBanners={addBanners}
              removeBanners={removeBanners}
              regionId={regionId}
              page={page}
              removeImages={removeImages}
              handleCheckboxChange={handleCheckboxChange}
            />
          )}
          {page === 3 && (
            <LoginWithOtpBanner
              regions={regions}
              searchSelectOnChange={searchSelectOnChange}
              loginWithOtpBanner={loginWithOtpBanner}
              remainingRegions={remainingRegions}
              mainLeftBannerErrors={mainLeftBannerErrors}
              handleFormInput={handleFormInput}
              categoryNames={categoryNames}
              handleImageInput={handleImageInput}
              addBanners={addBanners}
              removeBanners={removeBanners}
              regionId={regionId}
              page={page}
              removeImages={removeImages}
              handleCheckboxChange={handleCheckboxChange}
            />
          )}
           {/* <h1>Special Offer banner</h1> */}
           
          {/* Region select confirmation modal  */}
          <ConfirmationBox
            modal={{
              show: isShowConfirmModal,
              size: "md",
              centered: true,
            }}
            handleCancel={cancelDelete}
            handleConfirm={deleteCustomer}
            title="Warning!"
            confirmText="Delete"
          >
            <div className="container-fluid">
              <div className="row">
                <p
                  className="theme-txt-detail"
                // style={{ textTransform: "capitalize" }}
                >
                  Banner already created for other regions. If you click okay, It will overwrite the other region banners?
                </p>
              </div>
            </div>
          </ConfirmationBox>
          {/* Region select confirmation modal ends*/}
        </div>
      )}
    </div>
  );
}