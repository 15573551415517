import commonstyles from "../../../styles/Management_Common.module.css";
import Search from "../../../assets/images/search_icon.svg";
import { Link } from "react-router-dom"
import adminProductEdit from "../../../assets/images/admin_product_edit_icon.svg";
import adminProductDelete from "../../../assets/images/admin_delete.svg";
import exportIcon from "../../../assets/images/export_icon.svg";
import pdf_icon from "../../../assets/images/pdf_icon.svg";
import pdfIcon from "../../../assets/images/excel_icon.svg";
import excelIcon from "../../../assets/images/excel_icon.svg";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import arrowDown from "../../../assets/images/arrow_down.svg";
import dots from "../../../assets/images/column_dots.svg";
import React, { useEffect, useRef, useState } from "react";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import Pagination from "../../modules/ReusableComponent/Pagination";
import { useHistory } from "react-router-dom";
import ButtonComponent from "../../modules/ReusableComponent/ButtonComponent";
import AddDiscount from "../DiscountManagement/AddDiscount";
import LoadingIndicator from "../../modules/ReusableComponent/LoadingIndicator";
import EditOrderComponent from "../Order Management/OrderDetails";
import InputComponent from "../../modules/ReusableComponent/InputComponent";
import AntTableComponent from "../../modules/ReusableComponent/AntTableComponent";
import MovableListComponent from "../../modules/ReusableComponent/MovableListComponent";
import moment from "moment";
import { upperCase } from "lodash";
import * as XLSX from "xlsx";
import { set } from "date-fns";
import { Dropdown, Menu, message as massages, notification, Tooltip,Modal } from "antd";
import { arrayMove } from "react-movable";
import AntDateRangeSelectComponent from "../../modules/ReusableComponent/AntDateRangeSelectComponent";
import SearchComponent from "../SearchManagement/SearchComponent";
import ModalComponent from "../../modules/ReusableComponent/ModalComponent";
import FormErrorText from "../../modules/ProductManagement/FormErrorText";
import styles from "../../../styles/OrderDetails.module.css";
import Compressor from 'compressorjs'; 
import bannerimgdelete from "../../../assets/images/Iconly-Bulk-Close Square.svg"
import Close from "../../../assets/images/Close.svg"

const pageSizes = [10, 20, 50];
const defaultPageSize = 10;
const originalHeaders = [
//   { _id: 1, status: 1, name: "Select", value: "select" },
//   "Order Id,order_id"
  { _id: 2, status: 1, name: "Name", value: "name" },
  // { _id: 3, status: 1, name: "Order Events", value: "order Events" },
//   { _id: 4, status: 1, name: "Amount", value: "amount" },
{ _id: 4, status: 1, name: "Mobile Number", value: "mobilenumber" },
//   { _id: 5, status: 1, name: "Purchase Date", value: "purchase_date" },
{ _id: 5, status: 1, name: "Location", value: "location" },
  // { _id: 6, status: 1, name: "Stage", value: "stage" },
//   { _id: 7, status: 1, name: "Approvals", value: "approvals" },
{ _id: 7, status: 1, name: "City", value: "city" },
{ _id: 7, status: 1, name: "Submission Date", value: "Submission Date" },
//   { _id: 9, status: 1, name: "Operate", value: "operate" }
];

const filters = [
  // { id: 1, isChecked: false, name: 'Region', value: 'region' },
  { id: 2, isChecked: false, name: 'Submission Date', value: 'date' },
  // { id: 3, isChecked: false, name: 'Payment Method', value: 'PaymentMethod' },
  // { id: 4, isChecked: false, name: 'Delivery Status', value: 'DeliveryStatus' },
  // { id: 5, isChecked: false, name: 'Cancel or Refund', value: 'CancelOrRefund' }
];

const daysObj = [
  { _id: 8, name: 'All', value: '' },
  { _id: 1, name: 'Today', value: 1 },
  { _id: 2, name: 'Last 7 Days', value: 7 },
  { _id: 3, name: 'Last 30 Days', value: 30 },
  { _id: 4, name: 'Last 70 Days', value: 70 },
  { _id: 5, name: 'Last 90 Days', value: 90 },
  { _id: 6, name: 'Last 12 Months', value: 365 },
  { _id: 7, name: 'Custom', value: 'custom' },
];

const bulkAction = [
  // { _id: 1, isChecked: true, name: 'Order placed successfully', value: 1 },
  { _id: 2, isChecked: true, name: 'Order under processing', value: 1 },
  { _id: 3, isChecked: false, name: 'On-Hold', value: 6 },
  { _id: 4, isChecked: false, name: 'Out for Delivery', value: 7 },
  { _id: 5, isChecked: false, name: 'Delivered successfully', value: 3 },
]

let date = new Date();
let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
const placeholder = [moment(firstDay).format('DD-MM-YYYY'), moment(lastDay).format('DD-MM-YYYY')];
const dateFormatList = ['DD-MM-YYYY', 'DD-MM-YYYY'];

export default function LeadManagement() {
    const [open, setOpen] = useState(false);
    const [commonTitleData, setCommonTitleData] = useState({
        // maintitle: "",
        // mainsubtitle: "",
        // type:"",
        // offer:"",
        // offertype:"",
        // viewallurl: "",
        // duration:5,
        bannerimages:[]
      });
function showModal(){
    setOpen(true);
 
  };

 const handleCancel = () => {
    setOpen(false)
 }

 const handleImageUpload = (event) => {
    const files = Array.from(event.target.files);
    console.log("files",files);
    
    // Check if we already have an image
    if (commonTitleData?.bannerimages?.length >= 1) {
      showNotification({
        type: "error",
        message: "Error",
        description: "Only one image allowed. Please remove existing image first.",
      });
      return;
    }

    // Take only the first file if multiple are selected
    const file = files[0];
    if (!file) return;

    const fileName = file.name.toLowerCase();
    
    // Validate file type
    if (!fileName.match(/\.(jpg|jpeg|png)$/)) {
      showNotification({
        type: "error",
        message: "Error",
        description: "Please upload only jpg, jpeg, and png format images",
      });
      return;
    }
    
    // Validate file size (3MB limit)
    if (file.size > 1024 * 1024 * 3) {
      showNotification({
        type: "error",
        message: "Error",
        description: "Please upload file less than 3MB size",
      });
      return;
    }

    // Compress and create preview
    new Compressor(file, {
      quality: 0.6,
      success: (compressedResult) => {
        const imageUrl = URL.createObjectURL(compressedResult);
        
        setCommonTitleData(prevForm => ({
          ...prevForm,
          bannerimages: [
            {
              key: `image-${Date.now()}-${0}`,
              url: imageUrl,
              order: 0,
              file: compressedResult
            }
          ]
        }));
console.log("bannerimages",commonTitleData);

        // Clear the input value to allow uploading the same file again after removal
        // event.target.value = '';
      },
      error: (err) => {
        showNotification({
          type: "error",
          message: "Error",
          description: "Error processing image",
        });
        console.error(err);
      }
    });
  };
  const removeImage = (indexToRemove) => {
    setCommonTitleData(prevForm => {
      // Revoke object URL to prevent memory leaks
      if (prevForm.bannerimages[indexToRemove]?.url) {
        URL.revokeObjectURL(prevForm.bannerimages[indexToRemove].url);
      }
      
      return {
        ...prevForm,
        bannerimages: []
      };
    });
  };
//   
// new
const handleOk = () => {
    // Validate if banner image exists
    if (!commonTitleData.bannerimages || commonTitleData.bannerimages.length === 0) {
      showNotification({
        type: "error",
        message: "Validation Error",
        description: "Please Upload Offer banner image",
      });
      return;
    }
  
    // Prepare FormData
    const formData = new FormData();
    formData.append('name','test')
    const bannerImage = commonTitleData.bannerimages[0]?.file;
  
    if (bannerImage) {
      formData.append('images', bannerImage);
    } else {
      showNotification({
        type: "error",
        message: "Validation Error",
        description: "Invalid file data. Please re-upload the image.",
      });
      return;
    }
  let obj={
    images:commonTitleData.bannerimages[0]
    // bannerImage
  }
    // Make API Call
    AxiosInstance(PORTS.MARKETING, "application/json") // Use appropriate content type
    .post(`/qrleadsimage`, formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
      .then((res) => {
        if (res.data.status === 200) {
          showNotification({
            type: "success",
            message: "Success",
            description: "Offer Banner Image Added Successfully",
          });
          setOpen(false); // Close modal or dialog
        } else {
          showNotification({
            type: "error",
            message: "Upload Failed",
            description: res.data?.msg || "Error occurred while uploading.",
          });
        }
      })
      .catch((err) => {
        console.error("API Error:", err);
        showNotification({
          type: "error",
          message: "Server Error",
          description: "An error occurred while uploading. Please try again.",
        });
      });
  };
  
// const handleOk = () => {
//     if(commonTitleData.bannerimages.length < 0){
//         showNotification({
//           type: "error",
//           message: "Validation Error",
//           description: "Please Upload Offer banner image",
//         });
//         return;
//       }
//       console.log("commonTitleData.bannerimages",commonTitleData.bannerimages);
//       let obj={
//         image:commonTitleData.bannerimages
//       }
//       formData.append("testimage",commonTitleData.bannerimages)
//       const formData = new FormData();
//       if (commonTitleData.bannerimages && commonTitleData.bannerimages.length >0 ) {
//         console.log("commonTitleData.bannerimages",commonTitleData.bannerimages);
//          const imageFile = commonTitleData.bannerimages[0].file != undefined ? commonTitleData.bannerimages[0].file:JSON.stringify(commonTitleData.bannerimages[0]); 
         
//          console.log("images files",imageFile);
//          debugger
         
//          formData.append('images', imageFile);
//        }
//        console.log("commonTitleData.bannerimages",commonTitleData.bannerimages);
//        for (let [key, value] of formData.entries()) {
//         console.log(`${key}: ${value}`);
//       }
      
//     AxiosInstance(PORTS.MARKETING, "application/json")
//     .post(`/qrleadsimage`, formData,

//       {
//         headers: {
//           'Content-Type': 'multipart/form-data'
//         }
//       }
//     )
//     .then((res) => {
//       if (res.data.status === 200) {
//         showNotification({
//           type: "success",
//           message: "Success",
//           description: "Cities Title & Content Added Successfully",
//         });
//         // setIsUpdating(true);
//         // setIsLoading(false);
//         // history.push(`/Content/testimonial`);
//         // setIsUpdating(false);
//         // getCityList()
//       } else if (res.data.status === 400) {
//         showNotification({
//           type: "error",
//           message: "Adding Cities Common Section Failed",
//           description: res.data?.msg || "Error occured",
//         });
//       } else {
//         // setIsUpdating(false);
//       }
//     })
//     .catch((er) => {
//       return er;
//     })
//     .finally(() => {
//       // setIsLoading(false);
//       // setIsUpdating(false);
//     });
      
//     AxiosInstance(PORTS.MARKETING, "application/json")
//     .post(`/qrleadsimage`, formData,

//     //   {
//     //     headers: {
//     //       'Content-Type': 'multipart/form-data'
//     //     }
//     //   }
//     )
//     .then((res) => {
//       if (res.data.status === 200) {
//         console.log("respone",res);
        
//         showNotification({
//           type: "success",
//           message: "Success",
//           description: "Offer Banner Images Added Successfully",
//         });
//         // setIsUpdating(true);
//         // setIsLoading(false);
//         // history.push(`/Content/testimonial`);
//         // setIsUpdating(false);
//         // getCityList()
//       } else if (res.data.status === 400) {
//         showNotification({
//           type: "error",
//           message: "Adding Offer banner image Failed",
//           description: res.data?.msg || "Error occured",
//         });
//       } else {
//         // setIsUpdating(false);
//       }
//     })
//     .catch((er) => {
//       return er;
//     })
//     .finally(() => {
//       // setIsLoading(false);
//       // setIsUpdating(false);
//     });
//     setOpen(false)
//  }
  const accessregion = localStorage.getItem('accessregion');
  let permissionsArr = JSON.parse(localStorage.getItem("permissions")) || [];
  const [orderList, setOrderList] = useState([]);
  const [search, setSearch] = useState("");
  const [nextPage, setNextPage] = useState(1);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [pagination, setPagination] = useState({});
  const [showAddCustomerVisibility, setshowAddCustomerVisibility] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [childComponentVisibility, setChildComponentVisibility] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [regionId, setRegionId] = useState("");
  const [regionName, setRegionName] = useState("All Region");
  const [orderStatus, setOrderStatus] = useState("");
  const [cancelOrRefund, setcancelOrRefund] = useState();
  const [cancelOrRefundOption, setcancelOrRefundOption] = useState("All cancelled orders");
  const [orderName, setrderName] = useState("All Delivery Status")
  const [paymentMethod, setPaymentMethod] = useState("");
  const [paymentName, setPaymentName] = useState("All Payment")
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const timeoutId = useRef();
  const [regions, setRegions] = useState([]);
  const [filterDays, setFilterDays] = useState('');
  const [filterDaysName, setFilterDaysName] = useState("Date");
  const [filterdayArr, setFilterdayArr] = useState(daysObj);
  const [weekplanexport, setWeekPlanExport] = useState([]);
  const [headers, setHeaders] = useState(originalHeaders);
  const [items, setItems] = useState(originalHeaders);
  const [selectFilter, setSelectFilter] = useState(filters);
  const pdfExportComponent = useRef(null);
  const [payMethods, setPayMethods] = useState([
    { _id: 1, name: 'CC Avenue', value: 'CCAvenue' },
    { _id: 2, name: 'payU', value: 'payU' }
  ]);
  const [deliveryStatus, setDeliveryStatus] = useState([
    { _id: 1, name: 'Pending', value: 2 },
    { _id: 2, name: 'Completed', value: 1 }
  ]);
  const [cancelOrRefundStatus, setCancelOrRefundStatus] = useState([
    { _id: 1, name: 'Cancel', value: 1 },
    { _id: 2, name: 'Refund', value: 2 }
  ]);
  const [filteredDate, setFilteredDate] = useState({
    startDate: '',
    endDate: ''
  });
  const [sortorder, setSortorder] = useState();
  const [amountOrder, setAmountOrder] = useState();
  const [purchaseOrder,setPurchaseOrder] = useState();
  const [totalItems, setTotalItems] = useState(0);
  const [filterOptionsGlobal,setFilterOptionsGlobal] = useState();
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [bulkActionFilter, setBulkActionFilter] = useState(bulkAction);
  const [visible, setVisible] = useState(false);
  const [columns, setColumns] = useState([
    // {
    //   visible: false,
    //   key: "select",
    //   render: (text, record, index) => (
    //     <input style={{ cursor: "pointer" }} type="checkbox" checked={record.isChecked} onChange={(e) => childCheckbox(e, record)} className={`${commonstyles.childCheckbox}`} ></input>
    //   ),
    // },
    {
        // Order Id
      title: "Name",
      visible: false,
      key: "name",
      render: (text, record, index) => (
        // <span>{"Name"}</span>
        <span>{record.name}</span>
      ),
    //   sorter: (a, b) => a.orderId - b.orderId,
    },
    // {
    //   title: "Order Events",
    //   visible: false,
    //   key: "order Events",
    //   render: (text, record, index) => (
    //     <span>{record.status}</span>
    //   ),
    // },
    {
      title: "Mobile Number",
      visible: false,
      key: "mobilenumber",
      render: (text, record, index) => (
        // <span>{"Mobile Number"}</span>
        <span>{record.mobilenumber}</span>
      ),
      sorter: false
    },
    {
      title: "Location",
      visible: false,
      key: "location",
      render: (text, record, index) => (
        <span>{record.location}</span>

        // <span>{moment(record.orderedAt).format("MMM Do YYYY, h:mm:ss A")}</span>
      ),
      sorter:false
    },
    // {
    //   title: "Stage",
    //   visible: false,
    //   key: "stage",
    //   render: (text, record, index) => (
    //     <span>stage</span>
    //   ),
    // },
    {
      title: "City",
      visible: false,
      key: "city",
      render: (text, record, index) => (
        <span>
          {/* {record.city} */}
          {record.city.charAt(0).toUpperCase() + record.city.slice(1).toLowerCase()}
          </span>

        // <span
        //   class={
        //     record.status == 0
        //       ? commonstyles.status_pending
        //       : record.status === 1
        //         ? commonstyles.status_active
        //         : record.status === 2
        //           ? commonstyles.status_pending
        //           : commonstyles.status_inactive
        //   }
        // >
        //   {record.status === 0
        //     ? "Pending"
        //     : record.status === 1
        //       ? "Approved"
        //       : record.status === 2
        //         ? "Admin approval"
        //         : "Rejected"}
        // </span>
      ),
    },
    {
      title: "Submission Date",
      visible: false,
      key: "Submission Date",
      render: (text, record, index) =>
        //  (
      {
        const formattedDate = moment(record.createdAt).format("MMM Do YYYY, h:mm:ss A");
        return <span>{formattedDate}</span>;
      },
        // <span>
        //   {/* {record.city} */}
        //   {record.createdAt}
        //   </span>
      // ),
    },
    // {
    //   title: "Action",
    //   visible: false,
    //   key: "operate",
    //   render: (text, record, index) => (
    //     permissionsArr.length > 0 &&
    //     permissionsArr.find((x) => x === "Sales-CancelledOrders-edit") === "Sales-CancelledOrders-edit" && (
    //       <span>
    //         <Tooltip
    //           placement="topRight"
    //           title="Edit order"
    //         >
    //           <Link to={`/refund/${record._id}`}>
    //             <img
    //               src={adminProductEdit}
    //               className={commonstyles.admin_table_edit_img}
    //               alt="Edit action icon"
    //               style={{ cursor: "pointer" }}
    //             />{" "}
    //           </Link>
    //         </Tooltip>
    //       </span>
    //     )
    //   ),
    // },
  ]);

  /**
    * @author Mithun Prabhu
    * @param {event,record}
    * To get state of checked and update each records directly woth isChecked parameter
    */
  const childCheckbox = (e, record) => {
    const { checked } = e.target;
    const cloneOrderList = JSON.parse(localStorage.getItem('orderList'));
    let cloneSelectedOrder = [];
    cloneOrderList.forEach(item => {
      if (record._id === item._id) {
        item.isChecked = checked;
      }
    });
    if (localStorage.getItem('selectedOrder')) {
      cloneSelectedOrder = JSON.parse(localStorage.getItem('selectedOrder'));
      if (checked) {
        cloneSelectedOrder.push(record._id);
      } else {
        cloneSelectedOrder = cloneSelectedOrder.filter(item => record._id !== item);
      }
    } else if (checked) {
      cloneSelectedOrder.push(record._id);
    }
    localStorage.setItem('selectedOrder', JSON.stringify(cloneSelectedOrder));
    localStorage.setItem('orderList', JSON.stringify(cloneOrderList));
    setOrderList(cloneOrderList);
    // setSelectedOrder(cloneSelectedOrder);
  }

  /**
   * @author Mithun Prabhu
   * @param {event}
   * To get state of checked and update each records directly woth isChecked parameter
   */
  const parentCheckbox = (e) => {
    localStorage.removeItem('selectedOrder');
    const { checked } = e.target;
    const cloneOrderList = JSON.parse(localStorage.getItem('orderList'));
    cloneOrderList.forEach(item => {
      item.isChecked = checked;
    });
    let cloneSelectedOrder = [];
    if (localStorage.getItem('selectedOrder')) {
      cloneSelectedOrder = JSON.parse(localStorage.getItem('selectedOrder'));
      if (checked) {
        cloneOrderList.forEach(item => {
          cloneSelectedOrder.push(item._id);
        });
      } else {
        cloneSelectedOrder = [];
      }
    } else if (checked) {
      cloneOrderList.forEach(item => {
        cloneSelectedOrder.push(item._id);
      });
    }
    localStorage.setItem('selectedOrder', JSON.stringify(cloneSelectedOrder));
    localStorage.setItem('orderList', JSON.stringify(cloneOrderList));
    setOrderList(cloneOrderList);
  }

  useEffect(() => {
    set_new_header(headers);
    getOrderDetails(pageSize, nextPage, "");
    getRegionList();
    localStorage.removeItem('selectedOrder');
    return () => {
      localStorage.removeItem('orderList');
      localStorage.removeItem('selectedOrder');
      filters?.forEach(item => {
        item.isChecked = false;
      })
    }
  }, []);

  const set_new_header = (response) => {
    var newArr = [];
    const data = response;
    for (let i = 0; i < data.length; i++) {
      const columnsClone = [...columns];
      let objIndex = columnsClone.findIndex(
        (obj) => obj.key === data[i].value
      );
      if (columnsClone[objIndex] !== undefined) {
        columnsClone[objIndex].visible = data[i].status === 1 ? true : false;
        newArr.push(columnsClone[objIndex]);
      }
    }
    setColumns(newArr);
  };


  /**
 * @author Mithun Prabhu
 * @param {pageSize, nextPage, filterarr, type}
 * CALLING GET COUPON  RECORDS
 */
  function getOrderDetails(pageSize, nextPage, filterarr, type) {
    // debugger;
    // setIsLoading(true);
    if (!type) {
      setIsLoading(true);
    }
    const filterOptions = {
      pagesize: pageSize,
      nextpage: nextPage,
      // search:
      //   filterarr && filterarr.search
      //     ? filterarr.search
      //     : !filterarr
      //     ? search
      //     : "",
    };
    if (filterarr && filterarr.search) {
      filterOptions.search = filterarr.search;
    } else if (search !== "") {
      if (filterarr && filterarr.search !== "") {
        filterOptions.search = search;
      }
    }
    if (localStorage.getItem('accessregion')) {
      filterOptions.regionId = localStorage.getItem('accessregion');
    } else if (filterarr && filterarr.regionId) {
      filterOptions.regionId = filterarr.regionId;
    } else if (regionId !== "") {
      if (filterarr && filterarr.regionId !== "") {
        filterOptions.regionId = regionId;
      }
    }
    if (filterarr && filterarr.paymentMethod) {
      filterOptions.paymentMethod = filterarr.paymentMethod;
    } else if (paymentMethod !== "") {
      if (filterarr && filterarr.paymentMethod !== "") {
        filterOptions.paymentMethod = paymentMethod;
      }
    }
    if (filterarr && filterarr.orderStatus !== "") {
      filterOptions.status = parseFloat(filterarr.orderStatus);
    } else if (orderStatus !== "") {
      filterOptions.status = parseFloat(orderStatus);
    }
    if (filterarr && filterarr.startDate) {
      filterOptions.startDate = (filterarr.startDate);
    } else if (filteredDate.startDate !== "") {
      if (filterarr && filterarr.startDate !== "") {
        filterOptions.startDate = (filteredDate.startDate);
      }
    }
    if (filterarr && filterarr.endDate) {
      filterOptions.endDate = (filterarr.endDate);
    } else if (filteredDate.endDate !== "") {
      if (filterarr && filterarr.endDate !== "") {
        filterOptions.endDate = (filteredDate.endDate);
      }
    }
    if (filterarr && filterarr.filterDays) {
      filterOptions.filterDays = Number(filterarr.filterDays);
    } else if (filteredDate.filterDays !== "") {
      if (filterarr && filterarr.filterDays !== "") {
        filterOptions.filterDays = Number(filteredDate.filterDays);
      }
    }
    if (filterarr && filterarr.sortOrder !== "") {
      filterOptions.sortOrder = Number(filterarr.sortOrder);
    } else if (filteredDate.sortOrder !== "") {
      if (filterarr && filterarr.sortOrder !== "") {
        filterOptions.sortOrder = Number(sortorder);
      }
    }
    if (filterarr && filterarr.amountOrder !== "") {
      filterOptions.amountOrder = Number(filterarr.amountOrder);
    } else if (amountOrder !== "" && amountOrder !== null) {
      if (filterarr && amountOrder !== "") {
        filterOptions.amountOrder = Number(amountOrder);
      }
    }

    if (filterarr && filterarr.purchaseOrder !== "") {
      filterOptions.purchaseOrder = Number(filterarr.purchaseOrder);
    } else if (sortorder !== "" && sortorder !== null) {
      if (filterarr && sortorder !== "") {
        filterOptions.purchaseOrder = Number(sortorder);
      }
    }

    if (filterarr && filterarr.cancelOrRefund !== "") {
      filterOptions.cancelOrRefund = Number(filterarr.cancelOrRefund);
    }

    if (!filterOptions?.filterDays) delete filterOptions?.filterDays;
    if (filterOptions?.sortOrder === 2) delete filterOptions.sortOrder;
    if (!filterOptions?.status) delete filterOptions.status;
    if (!filterOptions?.cancelOrRefund) delete filterOptions.cancelOrRefund
    
    setFilterOptionsGlobal(filterOptions);
    // PORTS.INVOICE
    AxiosInstance(PORTS.MARKETING, "application/json")
      .post("/qrleads", filterOptions)
      .then((res) => {
        console.log(res);
        // debugger
        if (res.status === 200 || res.status === "200") {
          setIsLoading(false);
          if (res?.data?.data?.length > 0) {
            res.data.data.forEach((item, indx) => {
              item['key'] = indx + 1;
              item['isChecked'] = false;
            });
            localStorage.setItem('orderList', JSON.stringify(res.data.data));
            setOrderList(res.data.data);
            // console.log("images",res?.data?.data[0].name);
            // debugger
            if (res?.data && res?.data?.data[0]?.images) {
                // console.log("images",res?.data?.data?.images);
                // debugger
                setCommonTitleData(prevData => ({
                  ...prevData,
                  bannerimages: res?.data?.data[0]?.images,
                }));
              }
            setTotalItems(res.data.pagination.totalItems)
          } else {
            setOrderList([]);
          }
          setPagination(res?.data?.pagination);
        }
      })
      .catch((er) => {
        const data = { type: "error" };
        if (er.response) {
          data.message = "Error";
          data.description = "Failed to get data";
          setIsLoading(false);
          showNotification(data);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function handlePaginationChanges(value, type) {
    let updatedFilter = {
      search: search,
      nextpage: nextPage,
      regionId: regionId,
      paymentMethod: paymentMethod,
      orderStatus: orderStatus,
      filterDays: filterDays,
      startDate: filteredDate?.startDate,
      endDate: filteredDate?.endDate,
      amountOrder: amountOrder,
      sortOrder: sortorder,
      purchaseOrder:purchaseOrder
      // sortOrder: sorter.order === "descend" ? 1 : 0
    };
    if (type === "pageSize") {
      setPageSize(value);
      setNextPage(1);
      getOrderDetails(value, 1, updatedFilter, type);
    } else if (type === "nextPage") {
      setNextPage(value);
      getOrderDetails(pageSize, value, updatedFilter, type);
    }
  }

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  //   if (showAddCustomerVisibility) {
  //     return (
  //       <AddCustomer
  //         changeAddCustomerVisibility={() => setshowAddCustomerVisibility(false)}
  //       />
  //     );
  //   }

  function editCustomer(user) {
    setSelectedCustomer(user);
    // console.log(user);
    setChildComponentVisibility({
      ...childComponentVisibility,
      editProduct: true,
    });
    window.scrollTo(0, 0);
  }

  function closeEditProduct() {
    getOrderDetails(pageSize, nextPage);
    setSelectedCustomer({});
    setChildComponentVisibility({
      ...childComponentVisibility,
      editProduct: false,
    });
  }

  /* function deleteCustomer(user) {
    const sureDelete = window.confirm("Are you sure want to delete???");
    if (sureDelete === true) {
      setSelectedCustomer(user);
      AxiosInstance(PORTS.ORDER_SERVICE, "application/json")
        .get(`offers/coupon/${user._id}`)
        .then((res) => {
          // console.log(res);
          if (res.data.status === 200) {
            showNotification({
              type: "success",
              message: "Success",
              description: "Offer Deleted Successfully",
            });
            getOrderDetails(pageSize, nextPage);
          } else if (res.data.status === 400) {
            const errors = formatServerValidtionErrors(res.data.error);
            setFormErrors(errors);
          }
        })
        .catch((er) => {
          showNotification({
            type: "error",
            message: "Failed",
            description: "Error occured",
          });
        });
    } else {
      getOrderDetails(pageSize, nextPage);
    }
  } */

  if (childComponentVisibility.addProduct) {
    // console.log(childComponentVisibility.addProduct);
    return (
      <AddDiscount
        handleClose={() =>
          setChildComponentVisibility({
            ...childComponentVisibility,
            addProduct: false,
          })
        }
      />
    );
  } else if (childComponentVisibility.editProduct) {
    return (
      <EditOrderComponent
        customer={selectedCustomer}
        handleClose={closeEditProduct}
      />
    );
  }

  function handleInput(e) {
    const { value } = e.target;

    setSearch(value);
    if (!value) {
      const updatedFilter = {
        search: "",
      nextpage: 1,
      regionId: regionId,
      paymentMethod: paymentMethod,
      orderStatus: orderStatus,
      filterDays: filterDays,
      sortOrder: sortorder,
      amountOrder: amountOrder,
      purchaseOrder:purchaseOrder
      };
      debounceSearch(updatedFilter);
    }
  }

  function debounceSearch(filter) {
    // clearTimeout(timeoutId.current);
    setNextPage(1);
    // timeoutId.current = setTimeout(() => {
    getOrderDetails(pageSize, 1, filter,true);
    // }, 500);
  }

  function getRegionList() {
    AxiosInstance(PORTS.REGION_SERVICE, "application/json")
      .get("/admin/region/getRegionList")
      .then((res) => {
        if (res.status === 200 && res.data.status === 200) {
          if(res.data.daaata?.length > 1) {
            let obj = { name: "All Region", value: "", _id: "" }
            res.data?.daaata?.unshift(obj);
            setRegions(res.data.daaata);
            if (!accessregion) {
              let checkDuplicates = filters.filter(item => item.name === 'Region')?.length > 0 ? true : false;
              if (!checkDuplicates) {
                filters.push({ id: 1, isChecked: false, name: "Region", value: "region" })
              }
            };
          }
        }
      })
      .catch((er) => {
        console.log(er);
      });
  }
  function getQRleadsList() {
    AxiosInstance(PORTS.MARKETING, "application/json")
      .get("/qrleads")
      .then((res) => {
        if (res.status === 200 && res.data.status === 200) {
          if(res.data.daaata?.length > 1) {
            let obj = { name: "All Region", value: "", _id: "" }
            res.data?.daaata?.unshift(obj);
            setRegions(res.data.daaata);
            if (!accessregion) {
              let checkDuplicates = filters.filter(item => item.name === 'Region')?.length > 0 ? true : false;
              if (!checkDuplicates) {
                filters.push({ id: 1, isChecked: false, name: "Region", value: "region" })
              }
            };
          }
        }
      })
      .catch((er) => {
        console.log(er);
      });
  }
  function filterRegionChange(name, value) {
    let updatedFilter = {
      search: search,
      nextpage: 1,
      regionId: regionId,
      paymentMethod: paymentMethod,
      orderStatus: orderStatus,
      filterDays: filterDays,
      sortOrder: sortorder,
      amountOrder: amountOrder,
      purchaseOrder:purchaseOrder,
      cancelOrRefund
    };
    if (name === "regionId") {
      updatedFilter.regionId = value._id;
      setRegionId(value._id);
      setRegionName(value?.name);
    }

    if (name === "filterDays") {
      updatedFilter.filterDays = value.value;
      setFilterDays(value.value);
      setFilterDaysName(value.name);
    }
    if (value !== "custom") {
      const clonefilteredDate = { ...filteredDate };
      clonefilteredDate.startDate = "";
      clonefilteredDate.endDate = "";
      updatedFilter.startDate = "";
      updatedFilter.endDate = "";
      setFilteredDate(clonefilteredDate);
    }
    if (name === "paymentMethod") {
      updatedFilter.paymentMethod = value?.value;
      setPaymentMethod(value?.value);
      setPaymentName(value?.name);
    }
    if (name === "orderStatus") {
      updatedFilter.orderStatus = parseInt(value.value);
      setOrderStatus(value.value);
      setrderName(value.name);
    }
    if(name === "cancelOrRefund"){
      updatedFilter.cancelOrRefund = value.value
      setcancelOrRefund(value.value)
      setcancelOrRefundOption(value.name)
    }
    debounceSearch(updatedFilter);
  }

  /**
  * @author Mithun Prabhu
  * @param {date, dateString}
  * DATE RANGE ONCHANGE FUNCTION
  */
  const dateRangeOnchangeHandler = (date, dateString) => {
    const clonefilteredDate = { ...filteredDate };
    clonefilteredDate.startDate = dateString[0] ? moment(dateString[0], 'DD-MM-YYYY').format('YYYY-MM-DD') : '';
    clonefilteredDate.endDate = dateString[1] ? moment(dateString[1], 'DD-MM-YYYY').format('YYYY-MM-DD') : '';
    setFilteredDate(clonefilteredDate);
    let updatedFilter = {
      search: search,
      nextpage: 1,
      regionId: regionId,
      paymentMethod: paymentMethod,
      orderStatus: orderStatus,
      startDate: dateString[0] ? moment(dateString[0], 'DD-MM-YYYY').format('YYYY-MM-DD') : '',
      endDate: dateString[1] ? moment(dateString[1], 'DD-MM-YYYY').format('YYYY-MM-DD') : '',
      filterDays: filterDays,
      sortOrder: sortorder,
      amountOrder: amountOrder,
      purchaseOrder:purchaseOrder
    };
    debounceSearch(updatedFilter);
  }
  const exportToPDF = () => {
    // console.log(pdfExportComponent);
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  }
  const exportToCSV = async (e) => {
    let data = {
      pagesize: totalItems < 5 ? 5 : totalItems,
      nextpage: 1
    }
    if(regionId){
      data.regionId = regionId
    }
    if(filterDays){
      if (filterDays === 'custom') {
        data.startDate = filteredDate.startDate;
        data.endDate = filteredDate.endDate;
      } else {
        data.filterDays = filterDays;
      }
    } if(paymentMethod){
      data.paymentMethod= paymentMethod
    }
    if(orderStatus){
      data.status = orderStatus
    }
    if(cancelOrRefund){
      data.cancelOrRefund = cancelOrRefund
    }
    AxiosInstance(PORTS.MARKETING, "application/json")
      .post("/qrleads", data)
      .then((resp) => {
        const response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          const data = response.data;
          console.log("data",data);
        //   debugger
          data?.forEach(item => {
            // delete item['status']
            // delete item['CREATED AT']
            // delete item['requestType']
            delete item['_id']
            delete item['updatedAt']
            delete item['__v']
            delete item['images']
            // delete item['recordStatus']
            // delete item['paymentMethod']
            // delete item['regionId']
            // delete item['createdAt'] 
            // item['refundType'] = 1
            // delete item['refundType']
            if (item['createdAt']) {
              item['Submission Date'] = moment(item['createdAt']).format(
                "MMM Do YYYY, h:mm:ss A"
              );
              delete item['createdAt'];
            }
            // if (item['createdAt']) {
            //   item['createdAt'] = moment(item['createdAt']).format("MMM Do YYYY, h:mm:ss A")
            // }
          })

          setWeekPlanExport(data);
          let und_res = data;
          const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
          const fileExtension = ".xlsx";
          const ws = XLSX.utils.json_to_sheet(und_res);

          ws["A1"].v = upperCase(ws["A1"].v);
          ws["A1"].bold = true;
          ws["B1"].v = upperCase(ws["B1"].v);
          ws["C1"].v = upperCase(ws["C1"].v);
          ws["D1"].v = upperCase(ws["D1"].v);
          ws["E1"].v = upperCase(ws["E1"].v);

          var wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, 
            // "Cancelled Orders"
            "QR Leads"
        );
          /* generate an XLSX file */
          XLSX.writeFile(
            wb,
            `QR Leads-export-${moment(new Date())
              .format("YYYY-MM-DD")
              .split("-")
              .join("")}.xlsx`
          );

        } else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
          setWeekPlanExport([]);
          // return [];
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  //TO SHOW TABLE HEADERS BASED ON STATUS
  const renderHeader = (props) => {
    return (
      <tr>
        {columns.map((item, idx) => {
          if (item.visible) return props.children[idx];
        })}
      </tr>
    );
  };

  /**
   * @author Mithun Prabhu
   * @param {props}
   * TO SHOW TABLE DATA BASED ON HEADER STATUS
   */
  const renderBody = (props) => {
    if (props.children.length > 0) {
      return (
        <tr className={props.className}>
          {columns.map((item, idx) => {
            if (item.visible) {
              return props.children[idx];
            }
          })}
        </tr>
      );
    } else {
      return (
        <tr className="bg-transparent text-info">
          <td colSpan="3">No data found!</td>
        </tr>
      );
    }
  };

  /**
    * @author Mithun Prabhu
    * @param {oldIndex, newIndex}
    * This is for rearranging array of columns items according to checking nd unchecking
    */

  const rearrange = (oldIndex, newIndex) => {
    oldIndex = oldIndex === 0 ? 2 : oldIndex;
    newIndex = newIndex === 0 ? 2 : newIndex;
    if (oldIndex !== 1 && newIndex !== 1) {
      setItems(arrayMove(items, oldIndex, newIndex));
      const itemClone = [...items];
      let values = rearrange_array(itemClone, oldIndex, newIndex);
      fetchNewOrder(values);
    }
  };


  /**
 * @author Mithun Prabhu
 * @param {arr, oldIndex, newIndex}
 * This is for rearranging array of columns items according to checking nd unchecking
 */
  const rearrange_array = (arr, oldIndex, newIndex) => {
    if (newIndex >= arr.length) {
      let i = newIndex - arr.length + 1;
      while (i--) {
        arr.push(undefined);
      }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    return arr;
  };


  /**
  * @author Mithun Prabhu
  * @param {items}
  * This is for fetching new items after checking and unchecking columns
  */
  const fetchNewOrder = async (items) => {
    let newStatus = [];
    items.map((item, index) => {
      let obj = {};
      obj.order = index;
      obj.status = item.status;
      obj.value = item.value;
      obj._id = item._id;
      newStatus.push(obj);
    });
    setItems(newStatus);
    set_new_header(newStatus);
  };


  /**
  * @author Mithun Prabhu
  * @param {e, id}
  * This is component for checking and unchecking columns in list page
  */
  const on_field_check_handler = (e, id) => {
    const itemClone = [...items];
    let objIndex = items.findIndex((obj) => obj._id === id);
    itemClone[objIndex].status = e.target.checked ? 1 : id === 2 ? 1 : 0
    console.log("items", itemClone);
    setItems(itemClone);
    fetchNewOrder(items);
  };


  /**
 * @author Mithun Prabhu
 * @param {Menu}
 * This is component for removing/adding columns with onClick event
 */
  const menu = (
    <Menu className="columns_list">
      <MovableListComponent
        listProps={{
          values: items,
          onChange: ({ oldIndex, newIndex }) => rearrange(oldIndex, newIndex),
          renderList: ({ children, props }) => (
            <ul {...props} style={{ listStyleType: "none" }}>
              {children}
            </ul>
          ),
          renderItem: ({ value, props }) => (
            <li {...props} key={value._id} className="columns_list_item">
              {value.value !== 'select' && <>
                <input
                  type="checkbox"
                  checked={value.status === 1 ? true : false}
                  onChange={(event) => on_field_check_handler(event, value._id)}
                />
                <span class="checkmark" style={{ cursor: "move" }}></span>
                <span>
                  {value.value.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
                    return str.toUpperCase();
                  })}
                  <img src={dots} alt="image" />
                </span>
              </>}
            </li>
          ),
        }}
      />
    </Menu>
  );


  /**
   * @author Mithun Prabhu
   * @param {e, data}
   * This is common filter handler for all filters
   */
  const commonFilterHandler = (e, data) => {
    const { checked } = e.target;
    let cloneSelectedFilters = [...selectedFilters]
    const cloneSelectFilter = [...selectFilter];
    let updatedFilter = {
      search: search,
      nextpage: 1,
      regionId: regionId,
      paymentMethod: paymentMethod,
      orderStatus: orderStatus,
      filterDays: filterDays,
      startDate: filteredDate.startDate,
      endDate: filteredDate.endDate,
      sortOrder: sortorder,
      amountOrder: amountOrder,
      purchaseOrder:purchaseOrder,
      cancelOrRefund
    };

    cloneSelectFilter.forEach(item => {
      if (item.id === data.id) {
        item.isChecked = checked;
        if (checked) {
          cloneSelectedFilters.push(item.value);
        } else {
          cloneSelectedFilters = cloneSelectedFilters.filter(item => data.value !== item);
        }
      };
      if (!item.isChecked && item.id === data.id) {
        if (item.value === "region") {
          updatedFilter.regionId = "";
          setRegionId('');
          setRegionName("All Region");
        }
        if (item.value === "PaymentMethod") {
          updatedFilter.paymentMethod = "";
          setPaymentMethod('');
          setPaymentName("All Payment");
        }
        if (item.value === "date") {
          updatedFilter.startDate = "";
          updatedFilter.filterDays = "";
          updatedFilter.endDate = "";
          setFilterDays('');
          setFilterDaysName("Date");
        }
        if (item.value === "DeliveryStatus") {
          updatedFilter.orderStatus = null;
          setOrderStatus('');
          setrderName("All Delivery Status");
        }
        if(item.value == "CancelOrRefund"){
          updatedFilter.cancelOrRefund = null
          setcancelOrRefund();
          setcancelOrRefundOption("All cancelled Orders")
        }
        debounceSearch(updatedFilter);
      }
    });
    setSelectedFilters(cloneSelectedFilters);
    setSelectFilter(cloneSelectFilter);
  }



  /**
   * @author Mithun Prabhu
   * @param {filterMenu}
   * This is card component created for filter dropdown
   */
  const filterMenu = (
    <Menu className="filter_dropdown canel_order_filter">
      <div className="card">
        <div className="card_contents">
          <div className="card_info">
            Filter by
          </div>
          <div className="row no-gutters">
            {/* <div className="card_dropdown col-md-6">
              <div className="filter_heading card_dropdown_heading">
                Select Filter <img src={arrowDown} width={10} height={20} />
              </div>
              <div className="card_dropdown_contents">
                {Array.isArray(selectFilter)
                  ? selectFilter
                  .filter((filter) => filter.name.toLowerCase() !== 'region')
                  .map((filter) => (
                    <span key={filter._id}>
                      <input type="checkbox" checked={filter.isChecked} onChange={(e) => commonFilterHandler(e, filter)}></input>
                      <label>{filter.name}</label>
                    </span>
                  ))
                  : null}
              </div>
            </div> */}
            {selectedFilters.length > 0 && (selectedFilters.find(x => (x === 'region')) === 'region') && regions?.length > 1 && (
              <div className="card_dropdown show col-md-6">
                <div style={{ width: "100%" }} className="filter_heading card_dropdown_heading">
                  {regionName}<img alt="" src={arrowDown} width={10} height={20} />
                </div>
                <div className="card_dropdown_contents card_slect_hover card_dropdown_no_padding">
                  {Array.isArray(regions)
                    ? regions.map((region) => (
                      <span key={region._id} onClick={() => filterRegionChange("regionId", region)}>
                        <label>{region.name}</label>
                      </span>
                    ))
                    : null}
                </div>
              </div>
            )}
            {selectedFilters.length > 0 && (selectedFilters.find(x => (x === 'PaymentMethod')) === 'PaymentMethod') && (
              <div className="card_dropdown show col-md-6">
                {/* <select
                  className="form_control"
                  name="paymentMethod"
                  value={paymentMethod}
                  onChange={filterOnChange}
                >
                  <option value="">All Payment</option>
                  {Array.isArray(payMethods)
                    ? payMethods.map((pay) => (
                      <option key={pay._id} value={pay.value}>
                        {pay.name}
                      </option>
                    ))
                    : null}
                </select> */}
                <div style={{ width: "100%" }} className="filter_heading card_dropdown_heading">
                  {paymentName}<img alt="" src={arrowDown} width={10} height={20} />
                </div>
                <div className="card_dropdown_contents card_slect_hover card_dropdown_no_padding">
                  {Array.isArray(payMethods)
                    ? payMethods.map((pay) => (
                      <span key={pay._id} onClick={() => filterRegionChange("paymentMethod", pay)}>
                        <label>{pay.name}</label>
                      </span>
                    ))
                    : null}
                </div>
              </div>
            )}
            {selectedFilters.length > 0 && (selectedFilters.find(x => (x === 'DeliveryStatus')) === 'DeliveryStatus') && (
              <div className="card_dropdown show col-md-6">
                {/* <select
                  className="form_control"
                  name="orderStatus"
                  value={orderStatus}
                  onChange={filterOnChange}
                >
                  <option value="">All Delivery Status</option>
                  {Array.isArray(deliveryStatus)
                    ? deliveryStatus.map((delivery) => (
                      <option key={delivery._id} value={delivery.value}>
                        {delivery.name}
                      </option>
                    ))
                    : null}
                </select> */}
                <div style={{ width: "100%" }} className="filter_heading card_dropdown_heading">
                  {orderName}<img alt="" src={arrowDown} width={10} height={20} />
                </div>
                <div className="card_dropdown_contents card_slect_hover card_dropdown_no_padding">
                  {Array.isArray(deliveryStatus)
                    ? deliveryStatus.map((delivery) => (
                      <span key={delivery._id} onClick={() => filterRegionChange("orderStatus", delivery)}>
                        <label>{delivery.name}</label>
                      </span>
                    ))
                    : null}
                </div>
              </div>
            )}
             {selectedFilters.length > 0 && (selectedFilters.find(x => (x === 'CancelOrRefund')) === 'CancelOrRefund') && (
              <div className="card_dropdown show col-md-6">
                <div style={{ width: "100%" }} className="filter_heading card_dropdown_heading">
                  {cancelOrRefundOption}<img alt="" src={arrowDown} width={10} height={20} />
                </div>
                <div className="card_dropdown_contents card_slect_hover card_dropdown_no_padding">
                  {Array.isArray(cancelOrRefundStatus)
                    ? cancelOrRefundStatus.map((refund) => (
                      <span key={refund._id} onClick={() => filterRegionChange("cancelOrRefund", refund)}>
                        <label>{refund.name}</label>
                      </span>
                    ))
                    : null}
                </div>
              </div>
            )}
            {/* {selectedFilters.length > 0 && (selectedFilters.find(x => (x === 'date')) === 'date') && ( */}
              <div className="card_dropdown show col-md-6">
                {/* <select
                  className="form_control"
                  name="filterDays"
                  value={filterDays}
                  onChange={filterOnChange}
                >
                  <option value="">Order Date</option>
                  {Array.isArray(filterdayArr)
                    ? filterdayArr.map((day) => (
                      <option key={day._id} value={day.value}>
                        {day.name}
                      </option>
                    ))
                    : null}
                </select> */}
                <div style={{ width: "100%" }} className="filter_heading card_dropdown_heading">
                  {filterDaysName}<img alt="" src={arrowDown} width={10} height={20} />
                </div>
                <div className="card_dropdown_contents card_slect_hover card_dropdown_no_padding">
                  {Array.isArray(filterdayArr)
                    ? filterdayArr.map((day) => (
                      <span key={day._id} onClick={() => filterRegionChange("filterDays", day)}>
                        <label>{day.name}</label>
                      </span>
                    ))
                    : null}
                </div>
              </div>
            {/* // )} */}
            {(filterDays === "custom") && (
              <div className="card_dropdown show col-md-12">
                <AntDateRangeSelectComponent
                  selectProps={{
                    placeholder: placeholder,
                    onChange: dateRangeOnchangeHandler,
                    name: "sessionDateRange",
                    format: dateFormatList,
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </Menu>
  );

  /**
  * @author Mithun Prabhu
  * @param {data}
  * This is for checking through different action objects and calling API for submit
  */
  const bulkStatusClickHandler = (data) => {
    const cloneBulkActionFilter = [...bulkActionFilter];
    cloneBulkActionFilter.forEach(item => {
      if (item._id === data._id) {
        item.isChecked = true;
      } else {
        item.isChecked = false;
      }
    });
    setBulkActionFilter(cloneBulkActionFilter);
  };


  /**
  * @author Mithun Prabhu
  * @param {item}
  * This is for checking through different action objects and calling API for submit
  */
  const applyBulkActionHandler = (item) => {
    if (localStorage.getItem('selectedOrder')) {
      let selectedOrder = JSON.parse(localStorage.getItem('selectedOrder'));
      if (selectedOrder.length > 0) {
        let data = {
          orderIds: selectedOrder,
          status: Number(item.value)
        };
        submitBulkAction(data);
      } else {
        showNotification({
          type: "error",
          message: "Error",
          description: "Please select the orders below",
        });
      }
    } else {
      showNotification({
        type: "error",
        message: "Error",
        description: "Please select the orders below",
      });
    }
  };


  /**
 * @author Mithun Prabhu
 * @param {data}
 * API call for bulk delete and bulk update coupons.
 */
  const submitBulkAction = (data) => {
    setIsLoading(true);
    AxiosInstance(PORTS.MARKETING, "application/json")
      .get("/qrleads", data)
      .then((res) => {
        if (res && (res.data.status === "200" || res.data.status === 200)) {
          setIsLoading(false);
          showNotification({
            type: "success",
            message: "Success",
            description: res.data.msg,
          });
          localStorage.removeItem('selectedOrder');
          const cloneOrderList = JSON.parse(localStorage.getItem('orderList'));
          cloneOrderList.forEach(item => {
            item.isChecked = false;
          });
          localStorage.setItem('orderList', JSON.stringify(cloneOrderList));
          setOrderList(cloneOrderList);
        }
      })
      .catch((er) => {
        const data = { type: "error" };
        if (er.response) {
          data.message = "Error";
          data.description = "Failed to get data";
          setIsLoading(false);
          showNotification(data);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const handleTableChange = (pagination, filters, sorter) => {
    // console.log(sorter);
    let updatedFilter = {
      search: search,
      nextpage: nextPage,
      regionId: regionId,
      paymentMethod: paymentMethod,
      orderStatus: orderStatus,
      filterDays: filterDays,
      startDate: filteredDate?.startDate,
      endDate: filteredDate?.endDate,
      // sortOrder: sorter.order === "descend" ? 1 : 0
    };
    if (sorter?.columnKey === "order_id") {
      let sortValue = sorter.order === "ascend" ? 0 : 1;
      updatedFilter.sortOrder = sortValue;
      setSortorder(sortValue);
      setAmountOrder();
      setPurchaseOrder();
    } else if (sorter?.columnKey === "amount") {
      let sortValue = sorter.order === "ascend" ? 0 : 1;
      updatedFilter.amountOrder = sortValue;
      setAmountOrder(sortValue);
      setSortorder();
      setPurchaseOrder();
    }else if (sorter?.columnKey === "purchase_date") {
      let sortValue = sorter.order === "ascend" ? 0 : 1;
      updatedFilter.purchaseOrder = sortValue;
      setPurchaseOrder(sortValue);
      setSortorder();
      setAmountOrder();
    }
    getOrderDetails(pageSize, nextPage, updatedFilter, true);
  };

  function searchClick() {
    let updatedFilter = {
      search: search,
      nextpage: 1,
      regionId: regionId,
      paymentMethod: paymentMethod,
      orderStatus: orderStatus,
      filterDays: filterDays,
      sortOrder: sortorder,
      amountOrder: amountOrder,
      purchaseOrder:purchaseOrder
    };
    debounceSearch(updatedFilter);
  }

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  return (
    <div className={commonstyles.management_contents}>
      <SearchComponent />
      <div className={commonstyles.management_header}>
        <h1 className={commonstyles.management_header_heading}>
            QR Leads
          {/* Cancelled Orders */}
        </h1>
        
        {permissionsArr.length > 0 &&
          permissionsArr.find((x) => x === "Marketing-QR Leads-view") === "Marketing-QR Leads-view" &&
          <div >
            <ButtonComponent
                className="rnd mr-3 outline_cancel_btn"
                attributes={{
                  onClick:showModal
                  // onClick: () => history.push(`/Content/testimonial`),
                }}
              >
                Banner Image
                {/* Title & Content */}
              </ButtonComponent>
            <span className={commonstyles.management_header_downloads_text}>
              {/* <Tooltip
                placement="topRight"
                title="Download pdf"
              >
                <img className={commonstyles.export_icon} src={pdf_icon} style={{ cursor: "pointer" }} onClick={(e) => exportToPDF(e)} />
              </Tooltip> */}
              <Tooltip
                placement="topRight"
                title="Download excel"
              >
                <img src={excelIcon} className={commonstyles.management_header_downloads_icon} alt="icon" style={{ cursor: "pointer" }} onClick={(e) => exportToCSV(e)} />
              </Tooltip>
            </span>
            <span className={commonstyles.management_header_downloads_text_ex}>
              Export <img src={exportIcon} alt="icon" />
            </span>
          </div>
        }
      </div>

      <div className={`${commonstyles.management_contents_search} ${commonstyles.management_contents_search_sale}`}>
        {permissionsArr.length > 0 &&
          permissionsArr.find((x) => x === "Sales-CancelledOrders-view") === "Sales-CancelledOrders-view" &&
          <div
          className={`${commonstyles.management_contents_search_bar} input-group relative`}
        >
          {/* // <div
          //   className={`${commonstyles.management_contents_search_bar} input-group`}
          // > */}
            <InputComponent
              // className="with-icon rnd theme-txt-xsm"
              className="with-icon rnd theme-txt-xsm pr-10" 
              formControl={{
                placeholder: "Search by Name, Mobile, Location, City",
                name: "search",
                style: { height: "38px" },
                value: search,
                onChange: handleInput,
                ["aria-label"]: "Search category",
                ["aria-describedby"]: "search-category",
              }}
            />
           {search ? (
            <img src={Search} alt="" />
        // <img 
        //   src={Close} 
        //   alt="clear search" 
        //   onClick={() => {
        //     const event = { target: { value: "" } };
        //     handleInput(event);
        //   }}
        //   style={{ cursor: 'pointer' }}
        // />
      ) : (
        <img src={Search} alt="" />
      )}
          </div>
        }
        <ButtonComponent
          className="theme-btn rnd pl-3 pr-4 ml-2"
          attributes={{
            onClick: () => {
              searchClick();
            },
          }}
        >
          Search
        </ButtonComponent>
        {/* {permissionsArr.length > 0 &&
          permissionsArr.find((x) => x === "Sales-CancelledOrders-view") === "Sales-CancelledOrders-view" &&
          <div className="action ml-3">
            <div className="action_header">
              Action
              <img src={arrowDown} alt="image" />
            </div>
            <div className="action_contents">
              {bulkActionFilter.length > 0 && bulkActionFilter.map(item => (
                <div className="action_contents_header" key={item._id} onClick={() => bulkStatusClickHandler(item)}>
                  <h2 className={`action_contents_header_h2 ${item.isChecked && "action_contents_header_h2_active"}`}>{item.name}</h2>
                  {item.isChecked && <span className="action_contents_btn" onClick={() => applyBulkActionHandler(item)}>Apply</span>}
                </div>
              ))}
            </div>
          </div>
        } */}
        {permissionsArr.length > 0 &&
          permissionsArr.find((x) => x === "Sales-CancelledOrders-view") === "Sales-CancelledOrders-view" &&
          <Dropdown 
            overlay={filterMenu} 
            className="usermanage_table_filter_columns ml-3"
            onVisibleChange={handleVisibleChange} visible={visible}
          >
            <div className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
              Filter <img src={arrowDown} width={10} height={20} />
            </div>
          </Dropdown>
        }
        {permissionsArr.length > 0 &&
          permissionsArr.find((x) => x === "Sales-CancelledOrders-view") === "Sales-CancelledOrders-view" &&
          <Dropdown overlay={menu} className="usermanage_table_filter_columns ml-3">
            <div className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
              Columns <img src={arrowDown} width={10} height={20} />
            </div>
          </Dropdown>
        }
      </div>
      {permissionsArr.length > 0 &&
        permissionsArr.find((x) => x === "Sales-CancelledOrders-view") === "Sales-CancelledOrders-view" &&
        <div className={`${commonstyles.management_table} table-responsive`}>
          {/* {!isLoading && orderList.length > 0 && <input type="checkbox" className={`${commonstyles.parentCheckbox}`} onChange={parentCheckbox}></input>} */}
          {isLoading === true ? (
            <div
              style={{
                marginLeft: "70vh",
                marginTop: "10vh",
              }}
            >
              <tr className="bg-transparent">
                <td colSpan="100%">
                  <LoadingIndicator loadingText="Fetching cancelled order details..." />
                </td>
              </tr>
            </div>
          ) :
            <PDFExport
              ref={pdfExportComponent}
              paperSize="auto"
              margin={40}
              fileName={`CancelorderList for ${new Date().getFullYear()}`}
              author="Damro Team"
            >
              <AntTableComponent
                className="order_table"
                tableProps={{
                  columns: columns,
                  dataSource: orderList,
                  pagination: false,
                  onChange: handleTableChange,
                  components: {
                    header: {
                      row: (props) => renderHeader(props),
                    },
                    body: {
                      row: (props) => renderBody(props),
                    },
                  },
                }}
              />
            </PDFExport>
          }
        </div>
      }
      {!isLoading && Array.isArray(orderList) && orderList.length ? (
        permissionsArr.length > 0 &&
        permissionsArr.find((x) => x === "Sales-CancelledOrders-view") === "Sales-CancelledOrders-view" && (
          <Pagination
            pageSizes={pageSizes}
            pageSize={pageSize}
            nextPage={nextPage}
            data={pagination}
            handlePaginationChanges={handlePaginationChanges}
          />
        )
      ) : null}

<Modal
      title="Title & Content"
      className="custom-modal-radius" 
      open={open}
       bodyStyle={{
        padding:"0px",
         borderRadius: '10px'
      }}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        // <Button key="cancel" onClick={handleCancel}>
        //   Cancel
        // </Button>,
            <ButtonComponent
            className="rnd mr-3 outline_cancel_btn"
            attributes={{
              onClick:handleCancel
            }}
          >
           Cancel
          </ButtonComponent>,

             <ButtonComponent
             className="rnd mr-3 outline_cancel_btn"
             attributes={{
               onClick:handleOk
             }}
           >
            Save
           </ButtonComponent>
     
      ]}
    //   closable={
    //     commonTitleData.maintitle.trim() !== "" &&    
    //     commonTitleData.mainsubtitle.trim() !== "" &&
    //         // commonTitleData.viewallurl.trim() !== "" &&
    //             commonTitleData.duration !== ""
    //   } // Prevent closing the modal when clicking outside
    //     maskClosable={false}
    >
          <div className="col-lg-12 mb-4">
              <div className={commonstyles.management_details_form}>
            <label >
             Store Banner Image 
              <span
                className="text-danger"
                      style={{ fontSize:"20px",
                        // bottom:-8,
                        position:"relative" }}
                    >
                      *
                    </span>
            </label>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              className="form-control"
              disabled={commonTitleData.bannerimages && commonTitleData.bannerimages.length > 0}
            />
              {commonTitleData.bannerimages && commonTitleData.bannerimages.length > 0 ? (
        <small className="text-muted">
            Please remove existing image to upload new ones
        </small>
    )
    :(
      <small className="text-muted">
      Please upload file less than 3MB size
  </small>
    )
    }
            {/* <FormErrorText error={currentForm.errors.images} /> */}
            <div className="col-lg-12 mb-4">
          <div className={commonstyles.management_details_form}>
            <label className={commonstyles.secondry_title}>Preview Images</label>
            <div className={commonstyles.banner_img_box_outline}>
              {commonTitleData?.bannerimages?.map((img, index) => (
                <div key={img.key} className={commonstyles.banner_img_preview}>
                  <img
                    src={img.url}
                    alt={`preview-${index}`}
                    className={commonstyles.previewImgs}
                  />
                  <img
                    src={bannerimgdelete}
                    alt="delete"
                    className={commonstyles.previewImgsdeleteicon}
                    onClick={() => removeImage(index)}
                  />
                  {/* <div className={styles.imgpreview_index}>{index + 1}</div> */}
                </div>
              ))}
            </div>
            </div>
          </div>
            </div>
          </div>
        
    </Modal>
    </div>
  );
}