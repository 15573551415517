import React, { useEffect, useState, useRef } from "react";
import commonstyles from "../../../../styles/Management_Common.module.css";
import styles from "../../../../styles/PaymentNew.module.css";
import WYSIWYGEditor from "../../../modules/ReusableComponent/WYSIWYGEditor";
import { Checkbox, DatePicker, notification, Select, Switch, TimePicker, Space } from 'antd';
import InputComponent from "../../../modules/ReusableComponent/InputComponent";
import ButtonComponent from "../../../modules/ReusableComponent/ButtonComponent";
import FormErrorText from "../../../modules/ProductManagement/FormErrorText";
import validationRules from "../../../../utils/validationRules";
import isObject from "lodash/isObject";
import { AxiosInstance, PORTS } from "../../../../utils/apiService";
import LoadingIndicator from "../../../modules/ReusableComponent/LoadingIndicator";
import ReactHtmlParser from "react-html-parser";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const editorOptions = {
    wrapperClassName: `${commonstyles.form_control} form-control-lg p-1 h-auto`,
    toolbarClassName: "bg-light rounded-lg theme-txt-sm",
    editorClassName: "px-2 mt-3 mb-1 ",
    //placeholder: "Type product features",
    toolbar: {
        options: [
            "inline",
            "blockType",
            "fontSize",
            "fontFamily",
            "list",
            "emoji",
            "link",
            "textAlign",
            "colorPicker",
            // "embedded",
            // "remove",
            "history",
        ],

        blockType: {
            inDropdown: true,
            options: [
                "Normal",
                "H1",
                "H2",
                "H3",
                "H4",
                "H5",
                "H6",
                "Blockquote",
                "Code",
            ],
            className: "theme-txt-sm",
            component: undefined,
            dropdownClassName: undefined,
        },

        fontSize: {
            icon: undefined,
            options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
            className: "theme-txt-sm",
            component: undefined,
            dropdownClassName: undefined,
        },

        fontFamily: {
            options: [
                "Arial",
                "Georgia",
                "Impact",
                "Spartan",
                "Tahoma",
                "Times New Roman",
                "Verdana",
            ],
            className: "theme-txt-sm",
            component: undefined,
            dropdownClassName: "",
        },
    },
};

export default function CreateEmail(props) {
    let permissionsArr = JSON.parse(localStorage.getItem("permissions")) || [];
    function initialState() {
        return {
            _id: "",
            type: 0,
            emailType: "",
            description: "",
            isActive: false,
            isAutomatic: false,
            emailSub: "",
            hours: "",
            coupon: "",
            couponType: 0,
            validity: "",
            isEnableCoupon: false,
            limit: "",
            // startDate: "",
            // startTime: "",
            // endDate: "",
            regionId: []
        };
    }
    const [form, setForm] = useState(initialState());
    const [formErrors, setFormErrors] = useState({});
    const [isEndDate, setIsEndDate] = useState(false);
    const [regions, setRegions] = useState([]);
    const [template, setTemplate] = useState([]);
    const [couponTypeArr, setCouponTypeArr] = useState([
        { _id: 1, name: "Percentage", value: 0 },
        { _id: 2, name: "Fixed", value: 1 },
    ]);

    const [rules, setRules] = useState({});
    const [isCoupon, setIsCoupon] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [emailContent, setEmailContent] = useState(false);

    useEffect(() => {
        getRegionList();
        templateList();
    }, []);
    /**
   * @author Rajkumar
   * TO GET REGION LIST
   */
    function getRegionList() {
        AxiosInstance(PORTS.REGION_SERVICE, "application/json")
            .get("/admin/region/getRegionList")
            .then((res) => {
                if (res.status === 200 && res.data.status === 200) {
                    setRegions(res.data.daaata);
                }
            })
            .catch((er) => {
                console.log(er);
            });
    }

    function templateList() {
        setIsLoading(true);
        AxiosInstance(process.env.REACT_APP_ADMIN_NEWSLETTER, "application/json")
            .get("/admin/template/0")
            .then((res) => {
                if (res.status === 200 && res.data.status === 200) {
                    setIsLoading(false);
                    setTemplate(res?.data?.data);
                    getTemplateDetails(res?.data?.data[0]?._id);
                } else {
                    setIsLoading(false);
                }
            })
            .catch((er) => {
                setIsLoading(false);
                console.log(er);
            });
    }
    /**
   * @author Rajkumar
   * @param {_id}
   * TO GET TEMPLATE DETAILS
   */
    function getTemplateDetails(_id) {
        setIsLoading(true);
        AxiosInstance(process.env.REACT_APP_ADMIN_NEWSLETTER, "application/json")
            .get(`/admin/template/detail/${_id}`)
            .then((res) => {
                if (res.status) {
                    let data = res?.data?.data;
                    const cloneForm = {};
                    setIsLoading(false);
                    cloneForm._id = data._id
                    cloneForm.type = 0
                    cloneForm.emailType = data.title
                    cloneForm.isActive = data.enable
                    cloneForm.isAutomatic = data.automatic
                    cloneForm.emailSub = data.subject
                    cloneForm.regionId = data?.region?.length > 0 ? data.region : [""];
                    cloneForm.hours = data.sendAfter
                    cloneForm.description = data?.content
                    if (data?.couponDetails?.couponValidity || data?.couponDetails?.couponValidity === 0) {
                        cloneForm.coupon = data?.couponDetails?.offerAmount
                        cloneForm.couponType = data?.couponDetails?.offerType
                        cloneForm.validity = data?.couponDetails?.couponValidity
                        cloneForm.isEnableCoupon = data?.couponDetails?.enable
                        cloneForm.limit = data?.couponDetails?.usesPerCustomer
                        let _rule = {
                            emailType: [validationRules.required],
                            description: [validationRules.required],
                            emailSub: [validationRules.required],
                            hours: [validationRules.required],
                            coupon: [validationRules.required],
                            validity: [validationRules.required],
                            limit: [validationRules.required],
                            regionId: [validationRules.required],
                        }
                        setRules(_rule);
                        setIsCoupon(true);
                    } else {
                        let _rule = {
                            emailType: [validationRules.required],
                            description: [validationRules.required],
                            emailSub: [validationRules.required],
                            hours: [validationRules.required],
                            regionId: [validationRules.required],
                        }
                        setRules(_rule);
                        setIsCoupon(false);
                    }
                    setForm(cloneForm);
                    setTimeout(() => {
                        setEmailContent(true);
                    }, 100)
                }
            })
            .catch((er) => {
                console.log(er);
                setIsLoading(false);
            });
    }

    function submit() {
        if (!isSaving) {
            setIsSaving(true);
            const data = { ...form };
            const result = runValidationChecks(rules, undefined, data);
            if (Object.keys(result).length) {
                setIsSaving(false);
                setFormErrors(result);
                showNotification({
                    type: "warning",
                    message: "There were few errors",
                    description: "Resolve the errors and try again",
                });
                return;
            }
            setFormErrors({});
            let is_region = false;
            form?.regionId?.forEach(item => {
                if (item === "") {
                    is_region = true;
                }
            })
            let params = {
                _id: form._id,
                type: 0,
                title: form.emailType,
                subject: form.emailSub,
                content: form.description,
                enable: form.isActive,
                automatic: form.isAutomatic,
                sendAfter: form.hours > 0 ? parseFloat(form.hours) : 0,
                region: is_region ? [] : form.regionId,
            }
            if (isCoupon) {
                params.couponDetails = {
                    offerType: form.couponType,
                    offerAmount: form.coupon > 0 ? parseFloat(form.coupon) : 0,
                    couponValidity: form.validity > 0 ? parseFloat(form.validity) : 0,
                    usesPerCoupon: form.limit > 0 ? parseFloat(form.limit) : 0,
                    usesPerCustomer: form.limit > 0 ? parseFloat(form.limit) : 0
                }
            }
            AxiosInstance(process.env.REACT_APP_ADMIN_NEWSLETTER, "application/json")
                .post("/admin/updateTemplate", params)
                .then((res) => {
                    if (res && (res.status === "200" || res.status === 200)) {
                        showNotification({
                            type: "success",
                            message: "Success",
                            description: res.data.msg,
                        });
                    }
                })
                .catch((er) => {
                    const data = { type: "error" };
                    if (er.response) {
                        data.message = "Error";
                        data.description = "Failed to get data";
                        showNotification(data);
                    }
                })
                .finally(() => {
                    setIsSaving(false);
                });
        }
    }

    function runValidationChecks(rules, name, data) {
        data = data || {};
        const result = {};
        for (const field in rules) {
            for (const rule of rules[field]) {
                const val = rule(data[field], data);
                if (typeof val === "string" || isObject(val)) {
                    result[field] = val;
                    break;
                }
            }
        }
        return result;
    }

    function showNotification({ type, message, description }) {
        let options = {
            message: message || "Message",
            description: description,
            duration: 5,
        };
        notification[type](options);
    }

    function onInputChange(e) {
        const { name, value } = e.target;
        const cloneForm = { ...form };
        if (value.trim()) {
            cloneForm[name] = value;
        } else {
            cloneForm[name] = "";
        }
        setForm(cloneForm);
    }

    function selectOnChange(e) {
        const { name, value } = e.target;
        const cloneForm = { ...form };
        cloneForm[name] = value;
        setForm(cloneForm);
        if (name === "emailType") {
            template?.forEach(item => {
                if (item.title === value) {
                    setForm(initialState());
                    getTemplateDetails(item._id);
                }
            })
        }
    }

    function handleEditorUpdate(htmlContent, formField) {
        const cloneForm = { ...form };
        cloneForm.description = htmlContent;
        setForm(cloneForm);
    }

    function onChangeCheckbox(e) {
        const { name, checked } = e.target;
        const cloneForm = { ...form };
        cloneForm[name] = checked;
        setForm(cloneForm);
    }

    function toggleStatusOnChange() {
        const cloneForm = { ...form };
        cloneForm.isEnableCoupon = !cloneForm.isEnableCoupon;
        setForm(cloneForm);
    }

    function handleRegionChange(e) {
        let selectedVal = [];
        let isAll = false;
        if (e?.length > 0) {
            e.forEach((item, index) => {
                if (e[e.length - 1] === "") {
                    isAll = true;
                }
            });
        };
        if (isAll) {
            selectedVal.push("");
        } else {
            selectedVal = e?.filter((x, indx) => x !== "");
        }
        const cloneForm = { ...form };
        cloneForm.regionId = selectedVal;
        setForm(cloneForm);
    }

    function uploadAdapter(loader) {
        return {
            upload: () => {
                return new Promise((resolve, reject) => {
                    const body = new FormData();
                    loader.file.then((file) => {
                        body.append("files", file);
                        console.log(file);
                        AxiosInstance(PORTS.CATALOG_SERVICE, "application/json")
                            .post(`/admin/uploadImages`, body)
                            .then((res) => {
                                if (res.data.status === 200) {
                                    resolve({
                                        default: `${res.data?.data[0]?.link}`,
                                    });
                                } else if (res.data.status === 400) {
                                    showNotification({
                                        type: "error",
                                        message: "Adding CMS failed",
                                        description: res.data?.msg || "Error occured",
                                    });
                                }
                            })
                            .catch((er) => {
                                showNotification({
                                    type: "error",
                                    message: "Adding CMS failed",
                                    description: "Error occured",
                                });
                            })
                            .finally(() => {
                                setIsLoading(false);
                            });
                    });
                });
            },
        };
    }

    function uploadPlugin(editor) {
        console.log(editor);
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
            return uploadAdapter(loader);
        };
    }

    return (
        permissionsArr.length > 0 &&
        permissionsArr.find((x) => x === "Marketing-Abandoned-add") === "Marketing-Abandoned-add" && (
            <div>
                {isLoading === true ? (
                    <div
                        style={{
                            marginLeft: "70vh",
                            marginTop: "10vh",
                        }}
                    >
                        <tr className="bg-transparent">
                            <td colSpan="100%">
                                <LoadingIndicator loadingText="Fetching details..." />
                            </td>
                        </tr>
                    </div>
                ) : (
                    <div className={`${styles.management_details_contents} ${styles.abandoned_dashboard_box}`}>
                        <div className="row">
                            <div className="col-lg-12">
                                <h4>Email Type</h4>
                            </div>
                            <div className="col-lg-4 mb-2">
                                <select
                                    className={`${styles.email_template_select} form-control-lg`}
                                    name="emailType"
                                    value={form?.emailType}
                                    onChange={(e) => selectOnChange(e)}
                                >
                                    {Array.isArray(template)
                                        ? template.map((item) => (
                                            <option key={item._id} value={item.title}>
                                                {item.title}
                                            </option>
                                        ))
                                        : null}
                                </select>
                                <FormErrorText error={formErrors.emailType} />
                            </div>
                            <div className="col-lg-12 mb-4">
                                <div className={`${commonstyles.management_details_form1} abandone_edit_notepad`}
                                    style={{
                                        boxShadow: formErrors.description ? "0 0 3px 1px #ff7575" : ''
                                    }}
                                >
                                    {(emailContent && form.description) ? <CKEditor
                                        editor={ClassicEditor}
                                        data={form.description || ""}
                                        config={{ extraPlugins: [uploadPlugin] }}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            const cloneForm = { ...form };
                                            cloneForm.description = data;
                                            setForm(cloneForm);
                                        }}
                                    /> :
                                        <tr className="bg-transparent">
                                            <td colSpan="100%">
                                                <LoadingIndicator loadingText="Please wait... Loading email content" />
                                            </td>
                                        </tr>
                                    }
                                    <FormErrorText error={formErrors.description} />
                                </div>
                            </div>
                        </div>
                        <div className={`${commonstyles.abandoned_email_form} row`}>
                            <div className="col-lg-6 mb-5 ">
                                <div className={commonstyles.abandoned_email}>
                                    <label className={commonstyles.secondry_title}>
                                        Email Template
                                    </label>
                                    <div className={commonstyles.abandoned_email}>
                                        <div className={`${commonstyles.abandoned_email_checkbox}`}>
                                            <Checkbox name="isActive" checked={form.isActive} onChange={onChangeCheckbox}>Activate/Deactivate Email Template</Checkbox>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 mb-5 ">
                                <div className={commonstyles.abandoned_email}>
                                    <label className={commonstyles.secondry_title}>
                                        Automatically send
                                    </label>
                                    <div className={`${commonstyles.abandoned_email_checkbox}`}>
                                        <Checkbox name="isAutomatic" checked={form.isAutomatic} onChange={onChangeCheckbox}>Activate/Deactivate automatic delivery of email.</Checkbox>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 mb-5 ">
                                <div className={commonstyles.abandoned_email}>
                                    <label className={commonstyles.secondry_title}>
                                        Email Subject
                                    </label>
                                    <div className={`${commonstyles.abandoned_email_checkbox}`}>
                                        <InputComponent
                                            className="form-control-lg"
                                            error={formErrors.emailSub}
                                            formControl={{
                                                name: "emailSub",
                                                placeholder: "Email subject",
                                                value: form.emailSub,
                                                onChange: onInputChange,
                                            }}
                                        />
                                        <FormErrorText error={formErrors.emailSub} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 mb-5 ">
                                <div className={commonstyles.abandoned_email}>
                                    <label className={commonstyles.secondry_title}>
                                        Send After
                                    </label>
                                    <div className={`${commonstyles.abandoned_email_checkbox} ${commonstyles.abandone_placeholder}`}>
                                        <InputComponent
                                            className="form-control-lg"
                                            error={formErrors.hours}
                                            formControl={{
                                                type: "number",
                                                name: "hours",
                                                placeholder: "12",
                                                value: form.hours,
                                                onChange: onInputChange,
                                            }}
                                        />
                                        <p>Hours</p>
                                        <FormErrorText error={formErrors.hours} />
                                    </div>
                                </div>
                            </div>
                            {isCoupon && <div className="col-lg-6 mb-5 ">
                                <div className={commonstyles.abandoned_email}>
                                    <label className={commonstyles.secondry_title}>
                                        Coupon Value
                                    </label>
                                    <div className={`${commonstyles.abandoned_email_checkbox}`}>
                                        <InputComponent
                                            className="form-control-lg"
                                            error={formErrors.coupon}
                                            formControl={{
                                                type: "number",
                                                name: "coupon",
                                                placeholder: "Coupon value",
                                                value: form.coupon,
                                                onChange: onInputChange,
                                            }}
                                        />
                                        <p>Fixed or percentage value for discount</p>
                                        <FormErrorText error={formErrors.coupon} />
                                    </div>
                                </div>
                            </div>}
                            {isCoupon && <div className="col-lg-6 mb-5 ">
                                <div className={commonstyles.abandoned_email}>
                                    <label className={commonstyles.secondry_title}>
                                        Coupon Type
                                    </label>
                                    <div className={`${commonstyles.abandoned_email_checkbox}`}>
                                        <select
                                            className={`${styles.email_template_select} form-control-lg`}
                                            name="couponType"
                                            value={form?.couponType}
                                            onChange={(e) => selectOnChange(e)}
                                        // error={formErrors.couponType}
                                        >
                                            {Array.isArray(couponTypeArr)
                                                ? couponTypeArr.map((item) => (
                                                    <option key={item._id} value={item._id}>
                                                        {item.name}
                                                    </option>
                                                ))
                                                : null}
                                        </select>
                                        <p>Select Coupon type I,e. Discount in fixed amount<br /> or percentage amount.</p>
                                        {/* <FormErrorText error={formErrors.couponType} /> */}
                                    </div>
                                </div>
                            </div>}
                            {isCoupon && <div className="col-lg-6 mb-5 ">
                                <div className={commonstyles.abandoned_email}>
                                    <label className={commonstyles.secondry_title}>
                                        Coupon Validity
                                    </label>
                                    <div className={`${commonstyles.abandoned_email_checkbox} ${commonstyles.abandone_placeholder}`}>
                                        <InputComponent
                                            className="form-control-lg"
                                            formControl={{
                                                type: "number",
                                                name: "validity",
                                                placeholder: "1",
                                                value: form.validity,
                                                onChange: onInputChange,
                                            }}
                                            error={formErrors.validity}
                                        />
                                        <p>Days</p>
                                        <FormErrorText error={formErrors.validity} />
                                    </div>
                                </div>
                            </div>}
                            {isCoupon && <div className="col-lg-6 mb-5 ">
                                <div className={commonstyles.abandoned_email}>
                                    <label className={commonstyles.secondry_title}>
                                        Enable/Disable Coupon
                                    </label>
                                    <div className={`${commonstyles.abandoned_email_checkbox}`}>
                                        <Switch
                                            checked={form.isEnableCoupon}
                                            onChange={toggleStatusOnChange}
                                            checkedChildren="Enable"
                                            unCheckedChildren="Disable"
                                        />
                                    </div>
                                </div>
                            </div>}
                            {isCoupon && <div className="col-lg-6 mb-5 ">
                                <div className={commonstyles.abandoned_email}>
                                    <label className={commonstyles.secondry_title}>
                                        Usage limit per user
                                    </label>
                                    <div className={`${commonstyles.abandoned_email_checkbox}`}>
                                        <InputComponent
                                            className="form-control-lg"
                                            formControl={{
                                                type: "number",
                                                name: "limit",
                                                placeholder: "1",
                                                value: form.limit,
                                                onChange: onInputChange,
                                            }}
                                            error={formErrors.limit}
                                        />
                                        <p>Fixed or percentage value for discount</p>
                                        <FormErrorText error={formErrors.limit} />
                                    </div>
                                </div>
                            </div>}
                            <div className="col-lg-6 mb-5 ">
                                <div className={commonstyles.abandoned_email}>
                                    <label className={commonstyles.secondry_title}>
                                        Select Warehouse
                                    </label>
                                    <div className={`${commonstyles.abandoned_email_checkbox} `}>
                                        <Select
                                            mode="multiple"
                                            name="regionId"
                                            value={form?.regionId}
                                            style={{ width: "100%" }}
                                            onChange={(e) => handleRegionChange(e)}
                                        >
                                            <option value="">All Warehouse</option>
                                            {regions?.length > 0
                                                ? regions.map((region) => (
                                                    <option key={region._id} value={region._id}>
                                                        {region.name}
                                                    </option>
                                                ))
                                                : null}
                                        </Select>
                                        <FormErrorText error={formErrors.regionId} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 text-right" onClick={submit}>
                                <ButtonComponent
                                    className="rnd pl-3 pr-4">
                                    {isSaving ? "Saving..." : "Save"}
                                </ButtonComponent>
                            </div>
                        </div>
                    </div>)}
            </div>
        ))
}