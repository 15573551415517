import React, { useState, useEffect } from "react";
import styles from "../../../styles/PaymentNew.module.css";
import BlackLeftArrow from "../../../assets/images/black_left_arrow.svg";
import { useHistory } from "react-router";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import { notification } from "antd";
import ButtonComponent from "../../modules/ReusableComponent/ButtonComponent";
import { runValidationChecks } from "../../../utils";
import InputComponent from "../../modules/ReusableComponent/InputComponent";
import FormErrorText from "../../modules/ProductManagement/FormErrorText";
import {
  minCharLimit,
  userNameWithSpace,
} from "../../../utils/RegularExp";
import styless from "../../../styles/AddProduct.module.css";
import LoadingIndicator from "../../modules/ReusableComponent/LoadingIndicator";
import commonstyles from "../../../styles/Management_Common.module.css";
import validationRules from "../../../utils/validationRules";
import ImagePreviewChip from "../../modules/ProductManagement/ImagePreviewChip";

const initialFormState = {
  categoryName: "",
  image: "",
  faqs: []
};

function validateStockItem(accumulator, currentStock, indx) {
  const error = {};
  ["question", "answer"].forEach((field) => {
    if (!currentStock[field]) {
      error[field] = "Required";
    }
  });
  if (Object.keys(error).length) {
    accumulator[indx] = error;
  }
  return accumulator;
}

const newFAQ = {
  question: "",
  answer: "",
};

const faqNew = JSON.parse(JSON.stringify(newFAQ));

export default function AddFAQComponent() {
  const history = useHistory();
  const [editForm, setEditForm] = useState(initialFormState);
  const [addForm, setAddForm] = useState([faqNew]); //seo
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState({ details: false, update: false });
  const [isLoading, setIsLoading] = useState(true);
  //file
  const [url, setUrl] = useState(null);
  const [file, setFile] = useState(null);
  const { state } = history.location;

  const rules = {
    categoryName: [validationRules.required],
    image: [(val, data) => (!url && !file ? "Icon required" : true)],

    faqs: [
      (val) => {
        if (Array.isArray(val)) {
          const errors = val.reduce(validateStockItem, {});
          if (Object.keys(errors).length) {
            return errors;
          }
          return true;
        }
      },
    ],
  };

  useEffect(() => {
    const { state } = history.location;
    if (state?._id) {
      getFaqDetails(state._id);
    } else {
      setIsLoading(false);
      setEditForm({ ...editForm, faqs: [{ question: "", answer: "" }] }); // addFAQ not validating the qus & ans field
      setAddForm([{ question: "", answer: "" }]);
    }
    return(() => {
      history['isFAQ'] = true;
    })
  }, []);

  /**
   * @author Rajkumar
   * @param {id}
   * TO GET FAQ DETAILS
   */
  function getFaqDetails(id) {
    setLoading({ details: true, update: false });
    AxiosInstance(PORTS.DASHBOARD_SERVICE, "application/json")
      .get(`/faq/faqDetail/${id}`)
      .then((res) => {
        if (res.data.status === 200) {
          const val = res.data.data;
          setEditForm(val);
          setUrl(val.icon);
          setIsLoading(false);
        } else {
          showNotification({
            type: "Error",
            message: "Error",
            description: "Couldnt fetch required data",
          });
        }
      })
      .catch((er) => {
        setIsLoading(false);
        return er;
      })
      .finally(() => {
        setIsLoading(false);
        setLoading({ details: false, update: false });
      });
  }

  // *** INPUT HANDLE CHANGE *** //
  function handleFormInput(e) {
    let { name, value } = e.target;
    if (name === "categoryName") {
      value = value.charAt(0).toUpperCase() + value.slice(1);
      if (!e.target.value.match(userNameWithSpace) || !e.target.value === "") {
        setFormErrors({
          ...formErrors,
          [name]: "Enter only alphabet and minimum 3",
        });
      } else {
        setFormErrors({ ...formErrors, [name]: "" });
      }
    }
    const updatedValues = { ...editForm };
    updatedValues[name] = value;
    setEditForm(updatedValues);
  }

  function submit(e) {
    let hasError = false;
    setFormErrors({ faqs: [] });
    let formErrors = { faqs: [] };
    editForm.faqs.forEach((faq, ind) => {
      formErrors.faqs[ind] = {};
      if (faq.question === "") {
        formErrors.faqs[ind].question = "Required";
      } else if (!minCharLimit.test(faq.question)) {
        hasError = true;
        formErrors.faqs[ind].question = "Minimum 2 letters";
      }
      if (faq.answer?.trim() === "") {
        hasError = true;
        formErrors.faqs[ind].answer = "Required";
      }
    });
    if (hasError) {
      showNotification({
        type: "warning",
        message: "There were few errors",
        description: "Resolve the errors and try again",
      });
      setFormErrors({ ...formErrors });
      return;
    }
    let data = {
      categoryName: editForm.categoryName? editForm.categoryName : editForm?.editForm?.categoryName,
      faqs: editForm.faqs,
      _id: state?._id,
    };

    if (data.categoryName) {
      if (
        !userNameWithSpace.test(data.categoryName) ||
        !data.categoryName === ""
      ) {
        setFormErrors({
          ...formErrors,
          categoryName: "Enter only alphabet and minimum 3",
        });
        return false;
      } else {
        setFormErrors({ ...formErrors, categoryName: "" });
      }
    }
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        accept: "*/*",
      },
    };
    const formData = new FormData();
    formData.append("data", JSON.stringify(data));
    formData.append("icon", file);

    const result = runValidationChecks(rules, undefined, data);

    if (Object.keys(result).length) {
      setFormErrors(result);
      showNotification({
        type: "warning",
        message: "There were few errors",
        description: "Resolve the errors and try again",
      });
      return;
    }

    delete data.createdAt;
    delete data.updatedAt;
    delete data.__v;
    setLoading({ ...loading, update: true });

    AxiosInstance(PORTS.DASHBOARD_SERVICE, "multipart/form-data")
      .post(`/faq/${state?._id ? "updateFaq" : "createFaq"}`, formData, config)
      .then((res) => {
        if (res.data.status === 200) {
          showNotification({
            type: "success",
            message: "Success",
            description: state?._id
              ? "FAQ updated successfully"
              : "FAQ added successfully",
          });
          history.push(`/Content`);
        } else if (res.data.status === 400) {
          showNotification({
            type: "error",
            message: "Adding zone failed",
            description: res.data?.msg || "Error occured",
          });
        }
      })
      .catch((er) => {
        showNotification({
          type: "error",
          message: "Adding product failed",
          description: "Error occured",
        });
      })
      .finally(() => {
        setLoading({ details: false, update: false });
      });
  }

  /**
   * @author Rajkuamr
   * @param {type, message, description}
   * SHOW NOTIFICATION USING ANTD
   */
  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }


  function handleImageInput(e) {
    const { name, files } = e.target;
    if (files[0].size > 1024 * 1024 * 3) {
      showNotification({
        type: "error",
        message: "There were few errors",
        description: "Please upload file less than 3MB size",
      });
    } else {
      setFile(e.target.files[0]);
      if (url) {
        URL.revokeObjectURL(url);
      }
      const generatedUrl = URL.createObjectURL(e.target.files[0]);
      setUrl(generatedUrl);
      editForm.image = generatedUrl;
    }
  }

  function removeFile() {
    if (url) {
      editForm.image = "";
      setFile(null);
      setUrl(null);
    }
  }

  function addFaq() {
    const updatedValue = JSON.parse(
      JSON.stringify(state?._id ? editForm.faqs : addForm)
    );
    const newSeo = JSON.parse(JSON.stringify({ question: "", answer: "" }));
    updatedValue.push(newSeo);
    if (state?._id) {
      setEditForm({ ...editForm, faqs: [...updatedValue] });
    } else {
      setAddForm(updatedValue);
    }
  }

  function removeFAQ(index) {
    const updatedValue = state?._id ? editForm.faqs : addForm;
    updatedValue.splice(index, 1);
    if (state?._id) {
      setEditForm({ editForm, faqs: [...updatedValue] });
    } else {
      setAddForm([...updatedValue]);
    }
  }

  function handleFAQInputChange(e, ind) {
    let { name, value } = e.target;
    value = value.charAt(0).toUpperCase() + value.slice(1);
    addForm[ind][name] = value;
    setAddForm([...addForm]);
    setEditForm({ ...editForm, faqs: addForm });
  }

  function handleFaqEditChange(e, ind) {
    let { name, value } = e.target;
    value = value.charAt(0).toUpperCase() + value.slice(1);
    editForm.faqs[ind][name] = value;
    setEditForm({ ...editForm, faqs: [...editForm.faqs] });
  }

  return (
    <div className={styles.management_contents}>
      <div className={styles.management_header}>
        <h1>
          <img
            src={BlackLeftArrow}
            alt=""
            className="cursor-pointer"
            onClick={() => history.push("/Content")}
          />
          {state?._id ? "Edit FAQ" : "Add FAQ"}
        </h1>
        <div className={styles.management_contents_upload}>
          <ButtonComponent
            className="rnd mr-3"
            attributes={{
              onClick: () => history.push(`/Content`),
            }}
          >
            Cancel
          </ButtonComponent>
          <ButtonComponent
            className="rnd"
            attributes={{
              disabled: loading.update,
              onClick: submit,
            }}
          >
            {loading.update ? "Saving" : "Save"}
          </ButtonComponent>
        </div>
      </div>
      {isLoading === true ? (
        <tr className="bg-transparent d-flex justify-content-center">
          <td colSpan="100%">
            <LoadingIndicator loadingText="Fetching FAQ detail..." />
          </td>
        </tr>
      ) : (
        <div className={styles.management_details}>
          <div className={styles.management_details_contents}>
            <div className="row">
              {/* category */}
              <div className="col-lg-6 mb-4">
                <label className="theme-form-label">
                  Categories
                  <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                    *
                  </span>
                </label>
                <InputComponent
                  className="form-control form-control-lg form_control mb-3"
                  error={formErrors.categoryName}
                  formControl={{
                    placeholder: "Type the category name",
                    name: "categoryName",
                    value: editForm?.categoryName,
                    onChange: handleFormInput,
                  }}
                />
                <FormErrorText error={formErrors.categoryName} />
              </div>
              {/* category end */}
              {/* image upload */}
              <div className="col-lg-6 mb-4">
                <div className={commonstyles.management_details_contents}>
                  <div className={styless.upload_img_header}>
                    <label className={commonstyles.secondry_title}>
                      Icon
                      <span
                        className="text-danger"
                        style={{ fontSize:"20px",bottom:-8,position:"relative" }}
                      >
                        *
                      </span>
                    </label>
                  </div>

                  <div className={commonstyles.management_details_contents}>
                    <div
                    >
                      {url ? (
                        <>
                          <ImagePreviewChip
                            url={url}
                            className={styless.added_image}
                            width="60px"
                            height="60px"
                            handleClose={removeFile}
                          />
                        </>
                      ) : (
                        <div
                          className={` ${styless.upload_img} text-center w-100`}
                        >
                          <div>
                            <label
                              htmlFor="categoryFileInput"
                              className={`${styless.btn} px-2 `}
                            >
                              Add file
                              <InputComponent
                                error={formErrors.image}
                                className="form-control-lg mb-3"
                                formControl={{
                                  type: "file",
                                  name: "url",
                                  style: { cursor: "pointer" },
                                  accept:
                                    "image/jpeg, image/png, image/svg+xml,image/gif, image/webp",
                                  id: "categoryFileInput",
                                  onChange: (e) => handleImageInput(e),
                                }}
                              />
                              <FormErrorText error={formErrors.image} />
                            </label>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* image upload end */}
              <div className="col-lg-12 mt-4 mb-5">
                <div
                  className="space_between mb-4"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    align: "center",
                  }}
                >
                  <h4 className="theme-form-section-title">FAQ</h4>
                  <div className="text-right">
                    <button className="theme-btn rnd" onClick={addFaq}>
                      Add FAQ
                    </button>
                  </div>
                </div>
                {!state?._id &&
                  addForm.map((faq, ind) => (
                    <React.Fragment key={ind}>
                      <div className="">
                        <div className="row">
                          <div className="col-lg-5 mb-4">
                            <div className={styles.management_details_form}>
                              <label for="">
                                Question
                                <span
                                  className="text-danger"
                                  style={{ fontSize:"20px",bottom:-8,position:"relative" }}
                                >
                                  *
                                </span>
                              </label>
                              <InputComponent
                                className="form-control form-control-lg form_control mb-3"
                                error={
                                  formErrors.faqs &&
                                  formErrors.faqs[ind] &&
                                  formErrors.faqs[ind].question
                                }
                                formControl={{
                                  placeholder: "Type the Question",
                                  name: "question",
                                  value: faq?.question,
                                  onChange: (e) => {
                                    handleFAQInputChange(e, ind);
                                  },
                                }}
                              />
                              <FormErrorText
                                error={
                                  formErrors.faqs &&
                                  formErrors.faqs[ind] &&
                                  formErrors.faqs[ind].question
                                }
                              />
                            </div>
                          </div>
                          <div className="col-lg-5 mb-4">
                            <div className={styles.management_details_form}>
                              <label for="">
                                Answer
                                <span
                                  className="text-danger"
                                  style={{ fontSize:"20px",bottom:-8,position:"relative" }}
                                >
                                  *
                                </span>
                              </label>
                              <textarea
                                className="form-control form-control-lg form_control mb-3"
                                error={
                                  formErrors.faqs &&
                                  formErrors.faqs[ind] &&
                                  formErrors.faqs[ind].answer
                                }
                                style={{ fontSize: "13px", height: "150px" }}
                                placeholder="Type the Answer"
                                name="answer"
                                value={faq?.answer}
                                onChange={(e) => {
                                  handleFAQInputChange(e, ind);
                                }}
                              />
                              <FormErrorText
                                error={
                                  formErrors.faqs &&
                                  formErrors.faqs[ind] &&
                                  formErrors.faqs[ind].answer
                                }
                              />
                            </div>
                          </div>
                          <div
                            className="col-lg-2 mb-4"
                            style={{ marginTop: "30px" }}
                          >
                            <ButtonComponent
                              className="rnd"
                              attributes={{
                                onClick: () => {
                                  removeFAQ(ind);
                                },
                                disabled: ind === 0 ? true : false,
                              }}
                            >
                              Remove
                            </ButtonComponent>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                ))}
                {state?._id &&
                  editForm.faqs.map((faq, ind) => (
                    <React.Fragment key={ind}>
                      <div className={styles.management_details_contents}>
                        <div className="row">
                          <div className="col-lg-5 mb-4">
                            <div className={styles.management_details_form}>
                              <label for="">
                                Question
                                <span
                                  className="text-danger"
                                  style={{ fontSize:"20px",bottom:-8,position:"relative" }}
                                >
                                  *
                                </span>
                              </label>
                              <InputComponent
                                className="form-control form-control-lg form_control mb-3"
                                error={
                                  formErrors.faqs &&
                                  formErrors.faqs[ind] &&
                                  formErrors.faqs[ind].question
                                }
                                formControl={{
                                  placeholder: "Type the Question",
                                  name: "question",
                                  value: faq?.question,
                                  onChange: (e) => {
                                    handleFaqEditChange(e, ind);
                                  },
                                }}
                              />
                              <FormErrorText
                                error={
                                  formErrors.faqs &&
                                  formErrors.faqs[ind] &&
                                  formErrors.faqs[ind].question
                                }
                              />
                            </div>
                          </div>
                          <div className="col-lg-5 mb-4">
                            <div className={styles.management_details_form}>
                              <label for="">
                                Answer
                                <span
                                  className="text-danger"
                                  style={{ fontSize:"20px",bottom:-8,position:"relative" }}
                                >
                                  *
                                </span>
                              </label>
                              <textarea
                                className="form-control form-control-lg form_control"
                                error={
                                  formErrors.faqs &&
                                  formErrors.faqs[ind] &&
                                  formErrors.faqs[ind].answer
                                }
                                style={{ fontSize: "13px", height: "150px" }}
                                placeholder="Type the Answer"
                                name="answer"
                                value={faq?.answer}
                                onChange={(e) => {
                                  handleFaqEditChange(e, ind);
                                }}
                              />
                              <FormErrorText
                                error={
                                  formErrors.faqs &&
                                  formErrors.faqs[ind] &&
                                  formErrors.faqs[ind].answer
                                }
                              />
                            </div>
                          </div>
                          <div
                            className="col-lg-2 mb-4"
                            style={{ marginTop: "30px" }}
                          >
                            <ButtonComponent
                              className="rnd"
                              attributes={{
                                onClick: () => {
                                  removeFAQ(ind);
                                },
                                disabled: ind === 0 ? true : false,
                              }}
                            >
                              Remove
                            </ButtonComponent>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
