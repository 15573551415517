import React, { useState, useEffect } from "react";
import styles from "../../../styles/PaymentNew.module.css";
import BlackLeftArrow from "../../../assets/images/black_left_arrow.svg";
import { useHistory } from "react-router";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import ButtonComponent from "../../modules/ReusableComponent/ButtonComponent";
import FormErrorText from "../../modules/ProductManagement/FormErrorText";
import LoadingIndicator from "../../modules/ReusableComponent/LoadingIndicator";
import commonstyles from "../../../styles/Management_Common.module.css";
import { notification } from "antd";
import ConfirmationBox from "../../modules/ReusableComponent/ConfirmationBox";
import { CloseCircleTwoTone } from '@ant-design/icons'

const initialFormState = {
    image: null,
};

export default function StoreLocator(props) {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const [regions, setRegions] = useState([]);
    const [regionId, setRegionId] = useState("");
    const [editForm, setEditForm] = useState(initialFormState);
    const [formErrors, setFormErrors] = useState({});
    const [confirmClear, setConfirmClear] = useState(false); // Added confirmation state

    useEffect(() => {
        getRegionList();
    }, []);
    useEffect(() => {
        getRegionWiseLanding()
    }, [regionId])
    function getRegionList() {
        AxiosInstance(PORTS.REGION_SERVICE, "application/json")
            .get("admin/region/getRegionList")
            .then(async (res) => {
                if (res.status === 200 && res.data.status === 200) {
                    const modifiedRegions = [...res.data.daaata];
                    modifiedRegions.unshift({ name: "All Region", _id: "0486fab6a2c4851da688ed95" });
                    setRegions(modifiedRegions);
                    if (modifiedRegions[0]) {
                        setRegionId(modifiedRegions[0]._id);

                    }
                }
            })
            .catch((error) => {
                console.error("Error fetching regions:", error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    function showNotification({ type, message, description }) {
        const options = {
            message: message || "Message",
            description: description,
            duration: 5,
        };
        notification[type](options);
    }

    function handleImageInput(e) {
        const { name, files } = e.target;
        setEditForm({ ...editForm, [name]: files[0] });

    }

    function removeFile(name) {
        setEditForm({ ...editForm, [name]: null });
    }

    function getRegionWiseLanding() {
        setIsLoading(true);
        AxiosInstance(PORTS.DASHBOARD_SERVICE, "application/json")
            .get(`/getRegionLanding/${props.state}`)
            .then((res) => {
                if (res.data.status === 200) {
                    const data = res.data.data[0];
                    let found = false; // Flag to check if data is found for selected region ID
                    data.region.forEach((region) => {
                        if (region.regionId === regionId) {
                            setEditForm(prevState => ({
                                ...prevState,
                                regionId: region.regionId || '',
                                image: region.image || '',
                            }));
                            found = true;
                        }
                    });
                    if (!found) {
                        // If no data found for selected region ID, set title and image to empty
                        setEditForm(prevState => ({
                            ...prevState,
                            image: null, // Set image to null to clear it
                        }));
                    }
                }
            })
            .catch((er) => {
                console.log(er);
                showNotification({
                    type: "error",
                    message: "Error",
                    description: "Failed to fetch region landing data",
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    }


    function submit() {
        const formData = new FormData();
        formData.append("regionId", regionId);
        formData.append("id", props.state);
        formData.append("image", editForm.image);

        setIsLoading(true);
        AxiosInstance(PORTS.DASHBOARD_SERVICE, "multipart/form-data")
            .post("/storeupload", formData)
            .then((res) => {
                if (res.data.status === 200) {
                    getRegionWiseLanding();
                    setIsLoading(false);
                    showNotification({
                        type: "success",
                        message: "Success",
                        description: res?.data?.msg,
                    });
                    history.push(`/Content`);
                } else {
                    showNotification({
                        type: "error",
                        message: "Error",
                        description: res?.data?.msg,
                    });
                }
            })
            .catch((er) => {
                showNotification({
                    type: "error",
                    message: "Error",
                    description: "Failed to communicate with the server",
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    function handleRegion(e) {
        const { value } = e.target;
        setRegionId(value === "0486fab6a2c4851da688ed95" ? null : value);
    }

    function cancelClear() {
        setConfirmClear(false);
    }

    function clearRegionData() {
        setConfirmClear(false);
        const finalData = {
            "id": props.state,
            "regionId": regionId,
        };
        setIsLoading(true);
        AxiosInstance(PORTS.DASHBOARD_SERVICE, "application/json")
            .post(`/clearRegionLanding`, finalData)
            .then((res) => {
                if (res.data.status === 200) {
                    getRegionWiseLanding();
                    showNotification({
                        type: "success",
                        message: "Success",
                        description: "Updated successfully",
                    });
                } else {
                    showNotification({
                        type: "Error",
                        message: "Error",
                        description: "Please try again",
                    });
                }
            })
            .catch((er) => {
                console.log(er);
                showNotification({
                    type: "Error",
                    message: "Error",
                    description: "Please try again",
                });
            })
            .finally(() => {
                // setIsLoading(false);
            });
    }

    function isValidURL(url) {
        try {
            const newUrl = new URL(url);
            return newUrl.protocol === 'http:' || newUrl.protocol === 'https:';
        } catch (err) {
            return false;
        }
    }

    return (
        <div className={styles.management_contents}>
            <div className={styles.management_header}>
                <h1>
                    <img
                        src={BlackLeftArrow}
                        alt=""
                        className="cursor-pointer"
                        onClick={() => history.push("/Content")}
                    />
                    Store Locator
                </h1>
            </div>
            {isLoading === true ? (
                <div
                    style={{
                        marginLeft: "50vh",
                        marginTop: "10vh",
                    }}
                >
                    <LoadingIndicator loadingText="Fetching landing page details..." />
                </div>
            ) : (
                <div>
                    <div className={`${styles.management_header} ${styles.banner_city_list}`}>
                        <div className={`${styles.banner_citys}`}>
                        <select
                         className={"form-control " + styles.form_control}
                         name="regionId"
                         value={regionId}
                         onChange={(e) => handleRegion(e)}
                         style={{cursor:'default !important',appearance:'none'}}
                         disabled={true}> // Disable the entire select element
                        <option value="0486fab6a2c4851da688ed95">All Region</option>
                        </select>


                        </div>

                        <div className={styles.management_contents_upload}>
                            <ButtonComponent
                                className="rnd mr-3 outline_cancel_btn"
                                attributes={{
                                    onClick: () => setConfirmClear(true),
                                }}
                            >
                                Clear
                            </ButtonComponent>
                            <ButtonComponent
                                className="rnd mr-3 outline_cancel_btn"
                                attributes={{
                                    onClick: () => history.push(`/Content`),
                                }}
                            >
                                Cancel
                            </ButtonComponent>
                            <ButtonComponent
                                className="rnd"
                                attributes={{
                                    onClick: submit,
                                }}
                            >
                                {isLoading ? "Saving..." : "Save"}
                            </ButtonComponent>
                        </div>
                    </div>

                    <div className={`${styles.management_details} ${styles.landing_category_management}`}>
                        <div className={styles.management_details_contents}>
                            <div className="row">
                                <div className="col-lg-12 mb-4">
                                    <div className={styles.management_details_form}>
                                        {/* <label htmlFor="">Page title</label>
                                        <InputComponent
                                            className={`form-control form-control-lg ${styles.form_control}`}
                                            name="title"
                                            formControl={{
                                                placeholder: "Type here",
                                                name: "title",
                                                value: editForm.title,
                                                maxLength: 30,
                                                onChange: handleFormInput,
                                            }}
                                        /> */}
                                        <FormErrorText error={formErrors.title} />
                                    </div>
                                </div>

                                <div className="col-lg-6 mb-4">
                                    <div className={commonstyles.management_details_contents}>
                                        <label>Image (1256X346 max:3MB)</label>
                                        <div className={commonstyles.management_details_contents}>
                                            <div>
                                                {editForm.image ? (
                                                    <div>
                                                        <img src={isValidURL(editForm.image) ? editForm.image : URL.createObjectURL(editForm.image)} alt=""
                                                            style={{ maxWidth: '200px', maxHeight: '200px' }} />
                                                        <button type="button" style={{ background: "none", border: "none" }} onClick={() => removeFile("image")}>
                                                            <CloseCircleTwoTone className="storelocator" />
                                                        </button>
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <input
                                                            type="file"
                                                            name="image"
                                                            onChange={handleImageInput}
                                                            accept="image/jpeg, image/png, image/svg+xml,image/gif, image/webp"
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {/* Confirmation Box Component */}
            <ConfirmationBox
                modal={{
                    show: confirmClear,
                    size: "md",
                    centered: true,
                }}
                handleCancel={cancelClear}
                handleConfirm={clearRegionData}
                title="Confirm the action"
                confirmText="Clear"
            >
                <div className="container-fluid">
                    <div className="row">
                        <p className="theme-txt-detail">
                            Are you sure want to clear?
                        </p>
                    </div>
                </div>
            </ConfirmationBox>
        </div>
    );
}
