import React, { useState, useEffect } from "react";
import styless from "../../../../styles/AddProduct.module.css";
import blackLeftArrow from "../../../../assets/images/black_left_arrow.svg";
import exclusionIcon from "../../../../assets/images/exclusion.svg";
import commonstyles from "../../../../styles/Management_Common.module.css";
import styles from "../../../../styles/AddCategory.module.css";
import stylees from "../../../../styles/PaymentNew.module.css";
import redstyles from "../../../../styles/AddProduct.module.css";
import SwitchComponent from "../../../modules/ReusableComponent/SwitchComponent";
import ButtonComponent from "../../../modules/ReusableComponent/ButtonComponent";
import InputComponent from "../../../modules/ReusableComponent/InputComponent";
import FormErrorText from "../../../modules/ProductManagement/FormErrorText";
import SelectComponent from "../../../modules/ReusableComponent/SelectComponent";
import { AxiosInstance, PORTS } from "../../../../utils/apiService";
import ImagePreviewChip from "../../../modules/ProductManagement/ImagePreviewChip";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import validationRules from "../../../../utils/validationRules";
import {
  formatServerValidtionErrors,
  runValidationChecks,
} from "../../../../utils";
import { Select, notification } from "antd";
import {
  noSpace,
  alphaNum,
  alphaNumwithSpace,
  alphanumericwithdashHyphen,
  onlyAlphabets,
  minCharWithNoMaxLimit
} from "../../../../utils/RegularExp";
import {
  onlyAlphabetsWithSpace,
  phoneRegx,
  passwordReg,
  minChar,
} from "../../../../utils/RegularExp";
import { useHistory } from "react-router";
// import Compressor from "compressorjs";

function initialState() {
  return {
    enable: true,
    name: "",
    slug: "",
    description: "",
    level: "",
    parentId: "" /* not required if level 0, */,
    ourcollection: false,
    trending: false,
    sortValue: "",
    metaTitle: "",
    metaDescription: "",
    metaKeywords: "",
    seoMarkup: "",
  };
}
// Bind body in data of formdata;
// files -
// icon or image required if ourcollection true;
// menuImage - optional

export default function AddCategoryComponent(props) {
  const [categoryDetail, setCategoryDetail] = useState(initialState());
  const [removedImages, setRemovedImages] = useState([]);
  const [parentCategories, setParentCategories] = useState([]);
  const [parentCategoriesALl, setParentCategoriesAll] = useState([]);
  
  const [isLoading, setIsLoading] = useState({
    parentCategory: false,
    update: false,
  });
  const [files, setFiles] = useState({
    image: null,
    icon: null,
    hoverIcon: null,
    menuImage: null,
    homeCategoryImg: null,
  });
  const [filePreview, setFilePreview] = useState({
    image: null,
    icon: null,
    hoverIcon: null,
    menuImage: null,
    homeCategoryImg: null,
    categoryspaceimage:null
  });
  const [formErrors, setFormErrors] = useState({});
  const [regionList, setRegionList] = useState([])
  const [selectedRegion, setSelectedRegion] = useState({
    image: null,
    icon: null,
    menuImage: null,
    homeCategoryImg: null,
    ourcollection: false,
    hoverIcon: null,
    regionId: null,
    categoryspace:false,
    categoryspaceimage:null,
    offer:"",
  })
  const [regionPreviewList,setRegionPreviewList] = useState([]);
  const filteredCategories = categoryDetail.level !== 0 ? parentCategories.filter((c) => c.level < categoryDetail.level) : [];
  // const [compressedFile, setCompressedFile] = useState(null);
  //

  //   const filteredCategories = categoryDetail.level !== 0 ? parentCategories.filter((c) => c.level < categoryDetail.level) : [];
  const history = useHistory();
 
  const rules = {
    name: [(val, data) => (!val ? "Name " : true)],
    slug: [(val, data) => (!val ? "Slug " : true)],
    level: [(val, data) => (categoryDetail.level >= 0 ? true : "Level ")],
    parentId: [(val, data) => (categoryDetail.level > 0 && !val ? "Parent " : true)],
    sortValue: [(val, data) => (!val && val !== 0 ? "Please enter a sort value" : true)],
    offer: [(val, data) => (categoryDetail.level!==2 && (selectedRegion.categoryspace && !selectedRegion.offer) ? "Please enter a offer value " : true)],
    categoryspaceimage: [(val, data) => (categoryDetail.level!==2 && (selectedRegion.categoryspace && !selectedRegion.categoryspaceimage) ? "Please add category space image " : true)],
    // homeCategoryImg: [(val, data) => (selectedRegion.ourcollection && !selectedRegion.homeCategoryImg ? "Please add our collection image " : true)],
    image: [(val, data) => (selectedRegion.ourcollection && !selectedRegion.image ? "Please add our collection image " : true)],
    // icon: [(val, data) => (selectedRegion.ourcollection && !selectedRegion.icon ? "Please add our collection icon image " : true)],
    
    files: [
      (val, data) => {
        const homeCategoryImg = selectedRegion.homeCategoryImg || filePreview.homeCategoryImg || files.homeCategoryImg;
        // const icon = selectedRegion.icon || filePreview.icon || files.icon;
        const image = selectedRegion.image || filePreview.image || files.image;
        const hoverIcon = selectedRegion.hoverIcon || filePreview.hoverIcon || files.hoverIcon;
        const menuImage = selectedRegion.menuImage || filePreview.menuImage || files.menuImage;
        return selectedRegion.ourcollection
        ? hoverIcon || menuImage
            ? true
            : true
            // "Add images for trending"
        : true
        // return (selectedRegion.ourcollection ? (homeCategoryImg && (icon || hoverIcon) ? true : false) : true) ? true : "Add images for our collection and trending";
      },
    ],
  };

  useEffect(() => {
    function getParentCategories() {
      AxiosInstance(PORTS.CATALOG_SERVICE, "application/json")
        .get("/admin/catalog/getParentCategory")
        .then((res) => {
          if (res.status === 200 && res.data.status === 200) {
            setParentCategories(res.data.data);
            setParentCategoriesAll(res.data.data);
          }
        })
        .catch((er) => {
          return er;
        });
    }
    getRegionList() 
    getParentCategories();
  }, []);

  function getRegionList() {
    // setIsLoading(true);
    AxiosInstance(PORTS.REGION_SERVICE, "application/json")
        .get("/admin/region/getRegionList")
        .then((res) => {
            if (res.status === 200 && res.data.status === 200) {
                const data = res.data.daaata;
              const filteredData = data.map(item => ({
                regionId: item._id,
                name: item.name,
                image: item.image,
                icon: item.icon,
                menuImage: item.menuImage,
                ourcollection: item.ourcollection,
                homeCategoryImg: item.homeCategoryImg,
                offer: item.offer,
                categoryspace: item.categoryspace,
                categoryspaceimage: item.categoryspaceimage,
              }));
                filteredData.unshift({
                  regionId:"",
                  name:"All Region",
                  image: null,
                  icon: null,
                  hoverIcon: null,
                  menuImage: null,
                  homeCategoryImg: null,
                  ourcollection: false,
                  categoryspace: false,
                  categoryspaceimage: null,
                  offer: "",
                })
                setSelectedRegion(filteredData[0])
                setRegionList(filteredData);
                setRegionPreviewList(filteredData)
            }
        }).catch((er) => {
            console.log(er);
            // setIsLoading(false);
        });
}

function handleRegionChange(e){
  const {name,value,children} = e.target;
  setSelectedRegion(...regionList.filter(region=> region.regionId == value))
}

  function handleInput(e) {
    const { name, value, checked } = e.target;
    let arr = [],
      relvel = value === "2" ? 1 : 0;
    function updateRegionList(changeData, name, value) {
      console.log({name, value})
      setSelectedRegion(changeData);
      if (selectedRegion.regionId) {
        const matchRegion = regionList.findIndex((region) => region.regionId === changeData.regionId);
        if (matchRegion !== -1) {
          const tempRegionList = [...regionList]; // Use spread operator for immutability
          tempRegionList[matchRegion] = changeData;
          setRegionList(tempRegionList);
        }
      } else {
        setCategoryDetail({ ...categoryDetail, [name]: value });
      }
    }
    if (name === "ourcollection" || name === "categoryspace") {
      // Handle 'ourcollection / categoryspace' checkbox
      const changeData = { ...selectedRegion, [name]: checked };
      updateRegionList(changeData, name, checked);
      return;
    } else if (name === "offer") {
      // Handle 'offer' text value for 'category space'
      const changeData = { ...selectedRegion, [name]: value };
      updateRegionList(changeData, name, value)
      return;
    }
    if (name === "level") {
      parentCategoriesALl.map((x) => {
        if (x.level === relvel) {
          arr.push(x);
        }
      });
      setParentCategories(arr);
    }
    // if (name === "metaTitle") {
    //   if (!e.target.value.match(onlyAlphabets) || !e.target.value === "") {
    //     formErrors.metaTitle = "Enter only alphabet";
    //   } else {
    //     formErrors.metaTitle = "";
    //   }
    // }
    // if (name === "metaDescription") {
    //   if (!e.target.value.match(onlyAlphabets) || !e.target.value === "") {
    //     formErrors.metaDescription = "Enter only alphabet";
    //   } else {
    //     formErrors.metaDescription = "";
    //   }
    // }
    // if (name === "metaKeywords") {
    //   if (!e.target.value.match(onlyAlphabets) || !e.target.value === "") {
    //     formErrors.metaKeywords = "Enter only alphabet";
    //   } else {
    //     formErrors.metaKeywords = "";
    //   }
    // }
    if (["level"].includes(name)) {
      const val = parseInt(value);
      setCategoryDetail({
        ...categoryDetail,
        parentId: "",
        [name]: !isNaN(val) ? val : value,
      });
    } else if (["trending", "ourcollection", "enable"].includes(name)) {
      setCategoryDetail({ ...categoryDetail, [name]: checked });
    } else if (["name"].includes(name)) {
      if (noSpace.test(value)) {
        formErrors.name = "Enter only alphabet and number";
      }
      if (!alphaNumwithSpace.test(value)) {
        formErrors.name = "Enter only alphabet and number";
      }
      setCategoryDetail({ ...categoryDetail, [name]: value });
    } else {
      setCategoryDetail({ ...categoryDetail, [name]: value });
    }
  }

  function handleInputSlug(event) {
    var k = event ? event.which : window.event.keyCode;
    if (k === 32) {
      return false;
    } else {
      if (!alphanumericwithdashHyphen.test(event.target.value)) {
        formErrors.slug = "Enter only alphabet and number";
      }
      setCategoryDetail({
        ...categoryDetail,
        slug: event.target.value.toLowerCase().split(" ").join(""),
      });
    }
  }
  
  function handleImageInput(e) {
    const { name, files } = e.target;
    const file_name = files[0].name.toLowerCase();
    if (name) {
      if (!file_name.match(/\.(jpg|jpeg|png|svg|gif|webp)$/)) {
        showNotification({
          type: "error",
          message: "There were few errors",
          description: "Please upload only jpg,jpeg and png format images",
        });
        return;
      } else if (files[0].size > 1024 * 1024 * 3) {
        showNotification({
          type: "error",
          message: "There were few errors",
          description: "Please upload file less than 3MB size",
        });
        return;
      }
    }
    if (filePreview[name]) {
      URL.revokeObjectURL(filePreview[name]);
    }
    const url = URL.createObjectURL(files[0]);

    //Region wise our collection
    const changeData = { ...selectedRegion, [name]: files[0] };
    let matchRegion = regionList.findIndex((region) => region.regionId == changeData.regionId);
    if (matchRegion != -1) {
      let tempRegionList = regionList;
      let tempPreviewList = regionPreviewList;
      tempRegionList[matchRegion] = changeData;
      if (tempPreviewList[matchRegion] && tempPreviewList[matchRegion][name]) {
        URL.revokeObjectURL(tempPreviewList[matchRegion][name]);
      }
      setRegionList(tempRegionList);
    }
    setSelectedRegion(changeData);
    setFilePreview({ ...filePreview, [name]: url });
  }

  function removeFile(name) {
    if (selectedRegion[name]) {
      URL.revokeObjectURL(selectedRegion[name]);
    }
    let changeData = { ...selectedRegion, [name]: null };
    let matchRegion = regionList.findIndex((region) => region.regionId == changeData.regionId);
    setSelectedRegion(changeData);
    if (matchRegion != -1) {
      let tempRegionList = regionList;
      tempRegionList[matchRegion] = changeData;
      setRegionList(tempRegionList);
    }
    if (filePreview[name] && typeof filePreview[name] === "string" && filePreview[name].startsWith("http")) {
      removedImages.push(name);
      setRemovedImages(removedImages);
    }
    setFiles({ ...files, [name]: null });
    setFilePreview({ ...filePreview, [name]: null });
  }

  function isValidURL(url) {
    try {
      const newUrl = new URL(url);
      return newUrl.protocol === "http:" || newUrl.protocol === "https:";
    } catch (err) {
      return false;
    }
  }

  function createRequestData() {
    const data = JSON.parse(JSON.stringify(categoryDetail));
    data.level = parseInt(data.level);
    data.sortValue = parseInt(data.sortValue);
    if (data.level === 0) {
      delete data.parentId;
    }
    categoryDetail.seoMarkup == "" && delete data.seoMarkup;
    data.metaTitle =
      categoryDetail.metaTitle === "" ? null : categoryDetail.metaTitle;
    data.metaKeywords =
      categoryDetail.metaKeywords === "" ? null : categoryDetail.metaKeywords;
    data.metaDescription =
      categoryDetail.metaDescription === "" ? null : categoryDetail.metaDescription;
    return data;
  }

  function submit() {
    setFormErrors({});
    const data = createRequestData();
    const result = runValidationChecks(rules, undefined, { ...data, files });

    if (Object.keys(result).length > 0) {
      setFormErrors(result);
      showNotification({
        type: "warning",
        message: "There were few errors",
        description: Object.values(result) + "is required",
      });
      return;
    }

    if (noSpace.test(categoryDetail.name)) {
      showNotification({
        type: "warning",
        message: "There were few errors",
        description: "Enter only alphabet and number for category name",
      });
      return;
    }

    if (
      !alphanumericwithdashHyphen.test(categoryDetail.slug) ||
      !alphaNumwithSpace.test(categoryDetail.name)
    ) {
      showNotification({
        type: "warning",
        message: "There were few errors",
        description: "Enter only alphabet and number",
      });
      return;
    }

    const formData = new FormData();
    const regionData = regionList.map(region => {return {regionId : region.regionId,ourcollection:region.ourcollection}})

    formData.append("data", JSON.stringify(data));
    formData.append("regionData", JSON.stringify(regionData));
    
    if(regionList.length > 0){
      regionList.forEach((region) => {
        const files = region; 
          Object.keys(files).forEach((fileKey) => {
            if(fileKey != "name" && fileKey != "regionId" && fileKey != "ourcollection"){
              const file = files[fileKey];
            console.log(file,"file")
            if(file){
              formData.append(`${[files.regionId]}_${fileKey}`, file);
            }
            }
          });
        
      });
    }

    const config = {
      headers: {
        "content-type": "multipart/form-data",
        // accept: "*/*",
      },
    };

    setIsLoading({ ...isLoading, update: true });
    AxiosInstance(PORTS.CATALOG_SERVICE)
      .post("/admin/catalog/addCategory", formData, config)
      .then((res) => {
        if (res.data.status === 200) {
          showNotification({
            type: "success",
            message: "Success",
            description: "Category details added successfully",
          });
          props.handleClose(true);
        } else if (res.data.status === 400) {
          const errors = formatServerValidtionErrors(res.data.error);
          setFormErrors(errors);
          showNotification({
            type: "error",
            message: "Adding category failed",
            description: res.data?.msg || "Error occured",
          });
        }
      })
      .catch((er) => {
        showNotification({
          type: "error",
          message: "Adding category failed",
          description: "Error occured",
        });
      })
      .finally(() => {
        setIsLoading({ ...isLoading, update: false });
      });
  }

  /**
 * @author Mithun Prabhu
 * @param {type, message, description}
 * SHOW NOTIFICATION USING ANTD
 */
  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("files", file);
            AxiosInstance(PORTS.CATALOG_SERVICE, "application/json")
              .post(`/admin/uploadImages`, body)
              .then((res) => {
                if (res.data.status === 200) {
                  resolve({
                    default: `${res.data?.data[0]?.link}`,
                  });
                } else if (res.data.status === 400) {
                  showNotification({
                    type: "error",
                    message: "Adding CMS failed",
                    description: res.data?.msg || "Error occured",
                  });
                }
              })
              .catch((er) => {
                showNotification({
                  type: "error",
                  message: "Adding CMS failed",
                  description: "Error occured",
                });
              })
              .finally(() => {
                setIsLoading(false);
              });
          });
        });
      },
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  return (
    <div className={styles.management_contents}>
      <div className={styles.management_header}>
        <h1 className={styles.management_header_heading}>
          <img
            src={blackLeftArrow}
            onClick={props.handleClose}
            className="cursor-pointer"
            title="Back"
            alt="Back action image"
          />
          Add Category
        </h1>
        <div className={`${styless.onoffswitchbtn}`}>
          <div className={`${styless.onoffswitch} d-inline-block align-top`}>
            <input
              type="checkbox"
              name="enable"
              className={styless.onoffswitch_checkbox}
              onChange={handleInput}
              checked={categoryDetail.enable}
              id="statusSwitch"
            />
            <label className={styless.onoffswitch_label} htmlFor="statusSwitch">
              <span className={styless.onoffswitch_inner}></span>
              <span className={styless.onoffswitch_switch}></span>
            </label>
          </div>
          <ButtonComponent
            className="rnd outline_cancel_btn mr-3"
            attributes={{
              onClick: props.handleClose,
            }}
          >
            Cancel
          </ButtonComponent>
          <ButtonComponent
            className="rnd"
            attributes={{
              disabled: isLoading.update,
              onClick: submit,
            }}
          >
            {isLoading.update ? "Saving" : "Save"}
          </ButtonComponent>
        </div>
      </div>
      {/*  */}
      <div className={styles.management_details}>
        <div className="row mb-4">
          {/* Category name  */}
          <div className="col-lg-12">
            <div className={styles.management_details_contents}>
              <label>
                Category name
                <span className="text-danger" style={{ fontSize: "20px" }}>
                  *
                </span>
              </label>
              <InputComponent
                className="form-control-lg"
                error={formErrors.name}
                formControl={{
                  placeholder: "Type category name",
                  name: "name",
                  value: categoryDetail.name,
                  onChange: (e) => {
                    if (!minChar.test(e.target.value)) {
                      if (!formErrors.name) {
                        formErrors.name = {};
                      }
                      formErrors.name = "Enter minimum 3 characters";
                    } else {
                      if (formErrors.name) {
                        formErrors.name = "";
                      }
                    }

                    handleInput(e);
                  },
                }}
              />
              <FormErrorText error={formErrors.name} />
            </div>
          </div>
          {/* Category name ends */}

          {/* Slug name start */}
          <div className="col-lg-12">
            <div className={styles.management_details_contents}>
              <label>
                Slug
                <span className="text-danger" style={{ fontSize: "20px" }}>
                  *
                </span>
              </label>
              <InputComponent
                className="form-control-lg"
                error={formErrors.slug}
                formControl={{
                  placeholder: "Type slug name",
                  name: "slug",
                  value: categoryDetail.slug,
                  onChange: (e) => {
                    if (!minChar.test(e.target.value)) {
                      if (!formErrors.slug) {
                        formErrors.slug = {};
                      }
                      formErrors.slug = "Enter minimum 3 characters";
                    } else {
                      if (formErrors.slug) {
                        formErrors.slug = "";
                      }
                    }
                    handleInputSlug(e);
                  },
                }}
              />
              <FormErrorText error={formErrors.slug} />
            </div>
          </div>
          {/* Slug name ends */}

          {/* Description  */}
          <div className="col-lg-12">
            <div className={styles.management_details_contents}>
              <label>Description</label>
              <textarea
                className="form-control theme-form-control form-control-lg"
                name="description"
                rows="3"
                value={categoryDetail.description}
                onChange={handleInput}
                placeholder="Type description"
              />
            </div>
          </div>
          {/* Description ends */}

          <div className="col-lg-12">
            <div className={styles.management_details_contents}>
              <label>Schema markup</label>
              <textarea
                className="form-control theme-form-control form-control-lg"
                name="seoMarkup"
                rows="3"
                // value={categoryDetail.seoMarkup}
                onChange={handleInput}
                placeholder="Type schema markup"
              />
            </div>
          </div>
          {/* Level  */}
          <div className="col-lg-6">
            <div className={styles.management_details_contents}>
              <label>
                Category level
                <span className="text-danger" style={{ fontSize: "20px" }}>
                  *
                </span>
              </label>
              <SelectComponent
                error={formErrors.level}
                className="form-control-md"
                attributes={{
                  name: "level",
                  value: categoryDetail.level,
                  onChange: handleInput,
                }}
              >
                <option value="" disabled>
                  Select a level
                </option>
                <option value="0">Parent category</option>
                <option value="1">Sub category</option>
                <option value="2">Second sub category</option>
              </SelectComponent>
              <FormErrorText error={formErrors.level} />
            </div>
          </div>

            {/* Level ends */}
            {/* ParentId  */}
            <div className={`${categoryDetail.level === 0 || categoryDetail.level === "0" ? "fade" : ""} col-lg-6`}>
              <div className={styles.management_details_contents}>
                <label>
                  Choose Parent
                  <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>*</span>
                </label>
                <SelectComponent
                  error={formErrors.parentId}
                  className="form-control-md"
                  attributes={{
                    name: "parentId",
                    value: categoryDetail.parentId,
                    onChange: handleInput,
                  }}
                >
                  <option value="" disabled>
                    Select parent category
                  </option>
                  {Array.isArray(filteredCategories) && filteredCategories.length
                    ? filteredCategories.map((category) => (
                        <option key={category._id} value={category._id}>
                          {category.name}
                        </option>
                      ))
                    : null}
                </SelectComponent>
                <FormErrorText error={formErrors.parentId} />
              </div>
            </div>
            {/* ParentId ends */}
            {/* Sort value  */}
            <div className="col-lg-6">
              <div className={styles.management_details_contents}>
                <label>
                  Sort value
                  <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>*</span>
                </label>
                <SelectComponent
                  error={formErrors.sortValue}
                  className="form-control-md"
                  attributes={{
                    name: "sortValue",
                    value: categoryDetail.sortValue,
                    onChange: handleInput,
                  }}
                >
                  <option value="" disabled>Select an option</option>
                  <option value={0}>0</option>
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                  <option value={6}>6</option>
                  <option value={7}>7</option>
                  <option value={8}>8</option>
                  <option value={9}>9</option>
                  <option value={10}>10</option>
                  <option value={11}>11</option>
                </SelectComponent>
                <FormErrorText error={formErrors.sortValue} />
              </div>
            </div>
            {/* Sort value ends */}
            <div className="col-lg-6">
              <label className={commonstyles.secondry_title}>Select Region<span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>*</span></label>
              <SelectComponent
                className="form-control-md"
                attributes={{
                  name: selectedRegion?.name,
                  value: selectedRegion?.regionId,
                  onChange: handleRegionChange,
                }}
              >
                {regionList.map((region) => (
                  <>
                    <option value={region.regionId} name={region.name}>
                      {region.name}
                    </option>
                  </>
                ))}
              </SelectComponent>
            </div>
            <div className="col-lg-12 mb-4">
              <div className={`${commonstyles.management_details_form} ${styles.label_toggle}`}>
                <label htmlFor="" className={commonstyles.secondry_title}>Our collection</label>
                <div className={`${redstyles.onoffswitch} ${redstyles.yes_no}`}>
                  {selectedRegion.regionId ? ( // region
                    <input
                      type="checkbox"
                      name="ourcollection"
                      className={redstyles.onoffswitch_checkbox}
                      id="ourcollection"
                      onChange={handleInput}
                      // checked={categoryDetail.ourcollection}
                      checked={selectedRegion.ourcollection}
                    />
                  ) : (
                    <input //all region
                      type="checkbox"
                      name="ourcollection"
                      className={redstyles.onoffswitch_checkbox}
                      id="ourcollection"
                      onChange={handleInput} //function
                      // checked={categoryDetail.ourcollection}
                      checked={categoryDetail.ourcollection} //category
                    />
                  )}
                  <label className={redstyles.onoffswitch_label} htmlFor="ourcollection">
                    <span className={redstyles.onoffswitch_inner}></span>
                    <span className={redstyles.onoffswitch_switch}></span>
                  </label>
                </div>
              </div>
            </div>
            <div className={`${(selectedRegion.regionId ? selectedRegion.ourcollection : categoryDetail.ourcollection) ? "" : "d-none"} col-lg-12`}>
              <div className="row">
                <div className="col-lg-3 ">
                  <label className={commonstyles.secondry_title} htmlFor="">Parent/Sub Category (PLP)<span className="text-danger" style={{ fontSize: "20px", bottom: -8, position: "relative" }}>*</span></label>
                  <div className={styles.management_details_contents}>
                    <div className={`${styles.management_details_form} ${styles.add_image_card} ${formErrors.image ? "theme-error-blk" : ""} d-flex align-items-center justify-content-center`} style={{ overflow: "hidden" }}>
                      {selectedRegion.image ? (
                        <ImagePreviewChip url={isValidURL(selectedRegion.image) ? selectedRegion.image : URL.createObjectURL(selectedRegion.image)} handleClose={() => removeFile("image")} />
                      ) : (
                        <div className={`${styles.upload_img} text-center`}>
                          <img src={exclusionIcon} alt="" />
                          <div>
                            <label htmlFor="imageInput" className={`${commonstyles.btn} px-2 cursor-pointer`}>
                              Add image
                            </label>
                            <input name="image" className="d-none" type="file" accept="image/jpeg, image/png, image/svg+xml" id="imageInput" onInput={handleImageInput} />
                          </div>
                          <span>or drop a file to Upload</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 ">
                  {/* <span className="text-danger" style={{ fontSize: "20px", bottom: -8, position: "relative" }}>*</span> */}
                  <label className={commonstyles.secondry_title} htmlFor="">Our Collections Image (Home)</label>
                  <div className={styles.management_details_contents}>
                    <div className={`${styles.management_details_form} ${styles.add_image_card} ${formErrors.homeCategoryImg ? "theme-error-blk" : ""} d-flex align-items-center justify-content-center`} style={{ overflow: "hidden" }}>
                      {selectedRegion.homeCategoryImg ? (
                        <ImagePreviewChip url={isValidURL(selectedRegion.homeCategoryImg) ? selectedRegion.homeCategoryImg : URL.createObjectURL(selectedRegion.homeCategoryImg)} handleClose={() => removeFile("homeCategoryImg")} />
                      ) : (
                        <div className={`${styles.upload_img} text-center`}>
                          <img src={exclusionIcon} alt="" />
                          <div>
                            <label htmlFor="homeCategoryImgInput" className={`${commonstyles.btn} px-2 cursor-pointer`}>
                              Add image
                            </label>
                            <input name="homeCategoryImg" className="d-none" type="file" accept="image/*" id="homeCategoryImgInput" onInput={handleImageInput} />
                          </div>
                          <span>or drop a file to Upload</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 ">
                  {/* <span className="text-danger" style={{ fontSize: "20px", bottom: -8, position: "relative" }}>*</span> */}
                  <label className={commonstyles.secondry_title} htmlFor="">Our Collections Icon (Home)</label>
                  <div className={styles.management_details_contents}>
                    <div className={`${styles.management_details_form} ${styles.add_image_card} ${formErrors.icon ? "theme-error-blk" : ""} d-flex align-items-center justify-content-center`} style={{ overflow: "hidden" }}>
                      {selectedRegion.icon ? (
                        <ImagePreviewChip url={isValidURL(selectedRegion.icon) ? selectedRegion.icon : URL.createObjectURL(selectedRegion.icon)} handleClose={() => removeFile("icon")} />
                      ) : (
                        <div className={`${styles.upload_img} text-center`}>
                          <img src={exclusionIcon} alt="" />
                          <div>
                            <label htmlFor="iconInput" className={`${commonstyles.btn} px-2 cursor-pointer`}>
                              Add image
                            </label>
                            <input name="icon" className="d-none" type="file" accept="image/jpeg, image/png, image/svg+xml" id="iconInput" onInput={handleImageInput} />
                          </div>
                          <span>or drop a file to Upload</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 ">
                  <label className={commonstyles.secondry_title} htmlFor="">Our Collections Hover (Home)</label>
                  <div className={styles.management_details_contents}>
                    <div className={`${styles.management_details_form} ${styles.add_image_card} ${formErrors.hoverIcon ? "theme-error-blk" : ""} d-flex align-items-center justify-content-center`} style={{ overflow: "hidden" }}>
                      {selectedRegion.hoverIcon ? (
                        <ImagePreviewChip url={isValidURL(selectedRegion.hoverIcon) ? selectedRegion.hoverIcon : URL.createObjectURL(selectedRegion.hoverIcon)} handleClose={() => removeFile("hoverIcon")} />
                      ) : (
                        <div className={`${styles.upload_img} text-center`}>
                          <img src={exclusionIcon} alt="" />
                          <div>
                            <label htmlFor="iconInput" className={`${commonstyles.btn} px-2 cursor-pointer`}>
                              Add image
                            </label>
                            <input name="hoverIcon" className="d-none" type="file" accept="image/jpeg, image/png, image/svg+xml" id="iconInput" onInput={handleImageInput} />
                          </div>
                          <span>or drop a file to Upload</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {formErrors.files && <div className="col-lg-12 theme-txt-sm theme-error-txt mb-4">{formErrors.files}</div>}
            {categoryDetail.level!==2 && (
              <div className="col-lg-12 mb-4">
                <div className={`${commonstyles.management_details_form} ${styles.label_toggle}`}>
                <label className={commonstyles.secondry_title}>Category Space<span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>*</span></label>
                  <div className={`${redstyles.onoffswitch} ${redstyles.yes_no}`}>
                    {selectedRegion.regionId ? ( // region
                      <input
                        type="checkbox"
                        name="categoryspace"
                        className={redstyles.onoffswitch_checkbox}
                        id="categoryspace"
                        onChange={handleInput}
                        checked={selectedRegion.categoryspace}
                      />
                    ) : (
                      <input //all region
                        type="checkbox"
                        name="categoryspace"
                        className={redstyles.onoffswitch_checkbox}
                        id="categoryspace"
                        // checked={categoryDetail.categoryspace}
                        onChange={handleInput} //function
                        checked={categoryDetail.categoryspace} //category
                      />
                    )}
                    <label className={redstyles.onoffswitch_label} htmlFor="categoryspace">
                      <span className={redstyles.onoffswitch_inner}></span>
                      <span className={redstyles.onoffswitch_switch}></span>
                    </label>
                  </div>
                </div>
              </div>
            )}
            <div className="col-lg-6 ">
              <div className="row mt-4 pt-2">
                {/* <div className="col-lg-6">
                <div
                  className={`${commonstyles.management_details_form} ${styles.label_toggle}`}
                >
                  <label htmlFor="" className={commonstyles.secondry_title}>
                    Trending
                  </label>
                  <div
                    className={`${redstyles.onoffswitch} ${redstyles.yes_no}`}
                  >
                    <input
                      type="checkbox"
                      name="trending"
                      className={redstyles.onoffswitch_checkbox}
                      id="trending"
                      onChange={handleInput}
                      checked={categoryDetail.trending}
                    />

                    <label
                      className={redstyles.onoffswitch_label}
                      htmlFor="trending"
                    >
                      <span className={redstyles.onoffswitch_inner}></span>
                      <span className={redstyles.onoffswitch_switch}></span>
                    </label>
                  </div>
                </div>
              </div> */}
              </div>
            </div>
            {(categoryDetail.level!==2 && (selectedRegion.regionId ? selectedRegion.categoryspace : categoryDetail.categoryspace)) && (
            <div className="col-lg-12">
              <div className={`row`}>
                <div className={`col-lg-6`}>
                  <label className={commonstyles.secondry_title} htmlFor="">Category Space Image (Home)<span className="text-danger" style={{ fontSize: "20px", bottom: -8, position: "relative" }}>*</span></label>
                  <div className={styles.management_details_contents}>
                    <div className={`${styles.management_details_form} ${styles.add_image_card} ${formErrors.categoryspaceimage ? "theme-error-blk" : ""} d-flex align-items-center justify-content-center`} style={{ overflow: "hidden" }}>
                      {selectedRegion.categoryspaceimage ? (
                        <ImagePreviewChip url={isValidURL(selectedRegion.categoryspaceimage) ? selectedRegion.categoryspaceimage : URL.createObjectURL(selectedRegion.categoryspaceimage)} handleClose={() => removeFile("categoryspaceimage")} />
                      ) :(
                        <div className={`${styles.upload_img} text-center`}>
                          <img src={exclusionIcon} alt="" />
                          <div>
                            <label htmlFor="categorySpaceImageInput" className={`${commonstyles.btn} px-2 cursor-pointer`}>
                              Add image
                            </label>
                            <input name="categoryspaceimage" className="d-none" type="file" accept="image/jpeg, image/png, image/svg+xml" id="categorySpaceImageInput" onInput={handleImageInput} />
                          </div>
                          <span>or drop aclassName file to Upload</span>
                        </div>
                      )}
                    </div>
                  </div>
                  {formErrors.categoryspaceimage && <div className="col-lg-12 theme-txt-sm theme-error-txt mb-4">{formErrors.categoryspaceimage}</div>}
                </div>
                <div className={`${(selectedRegion?.regionId && selectedRegion?.categoryspace) || (!(selectedRegion?.regionId) && categoryDetail.categoryspace) ? "" : "fade"} col-lg-6`}>
                  <div className={styles.management_details_contents}>
                    <label>Offer(Eg:UPTO 40%)<span className="text-danger" style={{ fontSize: "20px", bottom: -8, position: "relative" }}>*</span>
                    </label>
                    <InputComponent
                      className="form-control-lg"
                      error={formErrors.offer}
                      formControl={{
                        placeholder: "Type Offer Eg:UPTO 40%",
                        name: "offer",
                        value: selectedRegion.offer,
                        onChange: (e) => {
                          if (!minChar.test(e.target.value)) {
                            if (!formErrors.offer) {
                              formErrors.offer = {};
                            }
                            formErrors.offer = "Enter Offer";
                          } else {
                            if (formErrors.offer) {
                              formErrors.offer = "";
                            }
                          }
                          handleInput(e);
                        },
                      }}
                    />
                    <FormErrorText error={formErrors.offer} />
                  </div>
                </div>
              </div>
            </div>
            )}
            <div className="col-lg-12 mt-4">
              <div className="row">
                <div className="col-lg-6">
                  <label className={commonstyles.secondry_title}>Menu Image</label>
                  <div className={styles.management_details_contents}>
                    <div className={`${styles.management_details_form} ${styles.add_image_card}  d-flex align-items-center justify-content-center`} style={{ overflow: "hidden" }}>
                      {selectedRegion.menuImage ? (
                        <ImagePreviewChip url={isValidURL(selectedRegion.menuImage) ? selectedRegion.menuImage : URL.createObjectURL(selectedRegion.menuImage)} handleClose={() => removeFile("menuImage")} />
                      ) : (
                        <div className={`${styles.upload_img} text-center`}>
                          <img src={exclusionIcon} alt="" />
                          <div>
                            <label htmlFor="menuImageInput" className={`${commonstyles.btn} px-2 cursor-pointer`}>
                              Add image
                            </label>
                            <input name="menuImage" className="d-none" type="file" accept="image/jpeg, image/png, image/svg+xml" id="menuImageInput" onInput={handleImageInput} />
                          </div>
                          <span>or drop a file to Upload</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">{/* </div> */}</div>

          {/* <div className="row mb-4"> */}
          <div className="col-lg-3">{/* </div> */}</div>
        </div>
        <div className={styles.management_details_contents}>
          <h4 className="theme-form-section-title">
            Search Engine Optimization
          </h4>
          <div className="row">
            <div className="col-lg-6 mb-4">
              <div className={styles.management_details_form}>
                <label for="">Meta Title</label>
                <InputComponent
                  className={`form-control form-control-lg ${styles.form_control}`}
                  error={formErrors.metaTitle}
                  formControl={{
                    placeholder: "",
                    name: "metaTitle",
                    value: categoryDetail.metaTitle,
                    onChange: handleInput,
                  }}
                />
                <FormErrorText error={formErrors.metaTitle} />
              </div>
            </div>
            <div className="col-lg-6 mb-4">
              <div className={styles.management_details_form}>
                <label for="">Meta Keywords</label>
                <InputComponent
                  className={`form-control form-control-lg ${styles.form_control}`}
                  error={formErrors.metaKeywords}
                  formControl={{
                    placeholder: "",
                    name: "metaKeywords",
                    value: categoryDetail.metaKeywords,
                    onChange: handleInput,
                  }}
                />
                <FormErrorText error={formErrors.metaKeywords} />
              </div>
            </div>
            <div className="col-lg-12 mb-4">
              <div className={styles.management_details_form}>
                <label for="">Meta Description</label>
                <textarea
                  className={`form-control form-control-lg ${styles.form_control}`}
                  error={formErrors.metaDescription}
                  // formControl={{
                  rows="10"
                  placeholder=""
                  name="metaDescription"
                  value={categoryDetail.metaDescription}
                  onChange={handleInput}
                // }}
                />
                <FormErrorText error={formErrors.metaDescription} />
              </div>
            </div>
            <div className="col-lg-12 mb-4">
              <div
                className={`${styles.management_details_form} rich_text_hidden`}
              >
                <label for="">
                  Top Content

                </label>

                {/* {page?._id && editForm.content && ( */}
                <CKEditor
                  error={formErrors.headerContent}
                  editor={ClassicEditor}
                  // data={categoryDetail.headerContent || ""}
                  config={{ extraPlugins: [uploadPlugin] }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    const values = { ...categoryDetail };
                    console.log(data);
                    // console.log(values);
                    values.topContent = data;
                    if (!minCharWithNoMaxLimit.test(data)) {
                      if (!formErrors.headerContent) {
                        formErrors.headerContent = {};
                      }
                      formErrors.headerContent = "Header content required";
                    } else {
                      if (formErrors.headerContent) {
                        formErrors.headerContent = "";
                      }
                    }
                    setCategoryDetail(values);
                  }}
                />
                {/* )} */}
                {/*  {!(page?._id && editForm.content) && (
                                        <CKEditor
                                        editor={ ClassicEditor }
                                        data={editForm?.content || ""}
                                        onChange={ ( event, editor ) => {
                                            const data = editor.getData();
                                            const values = editForm;
                                            values.content = data;
                                            setEditForm(values);
                                        } }
                                    />
                                    )} */}

                <FormErrorText error={formErrors.headerContent} />
              </div>
            </div>
            <div className="col-lg-12 mb-4">
              <div
                className={`${styles.management_details_form} rich_text_hidden`}
              >
                <label for="">
                  Bottom Content

                </label>
                <CKEditor
                  error={formErrors.footerContent}
                  editor={ClassicEditor}
                  config={{ extraPlugins: [uploadPlugin] }}
                  // data={categoryDetail?.footerContent || ""}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    const values = { ...categoryDetail };
                    // console.log(data);
                    // console.log(values);
                    values.bottomContent = data;
                    if (!minCharWithNoMaxLimit.test(data)) {
                      if (!formErrors.footerContent) {
                        formErrors.footerContent = {};
                      }
                      formErrors.footerContent = "Footer content required";
                    } else {
                      if (formErrors.footerContent) {
                        formErrors.footerContent = "";
                      }
                    }
                    setCategoryDetail(values);
                  }}
                />
                <FormErrorText error={formErrors.footerContent} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
