import { Popover } from "antd";
import { useState } from "react";
import commonstyles from "../../../styles/Management_Common.module.css";
import styles from "../../../styles/AddProduct.module.css";
import Exclusion from "../../../assets/images/exclusion.svg";
import ImagePreviewChip from "./ImagePreviewChip";
import MediaURLInputModalComponent from "./MediaURLInputModalComponent";
import { VideoPreviewChip } from "./VideoPreviewChip";
import { notification } from "antd";

const initialMediaURLModalState = { show: false, type: "" };

export default function ProductMediaUploadForm(props) {
  // console.log(props)
  const [mediaURLModal, setMediaURLModal] = useState(initialMediaURLModalState);

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  function handleInput(e) {
    const { name, files } = e.target;
    // console.log(files[0].size);
    const updatedFiles = { ...props.localFileData };

    if (name === "files") {
      // console.log(files);
      const totalFiles = files.length;
      if (files[0].size > 1024 * 1024 * 3) {
        showNotification({
          type: "error",
          message: "There were few errors",
          description: "Please upload file less than 3MB size",
        });
      }else{
        for (let i = 0; i < totalFiles; i++) {
        updatedFiles.files.push(files[i]);
        updatedFiles.filesPreview.push(generatePreview(files[i]));
      }
      props.updateMediaForm(updatedFiles, "files");
      }
      
    }
  }

  function removeMedia(indx, source) {
    // console.log(indx);
    const updatedGallery = [...props.gallery];
    const updatedFiles = { ...props.localFileData };

    if (source === "files" && updatedFiles.files[indx]) {
      updatedFiles.files.splice(indx, 1);
      URL.revokeObjectURL(updatedFiles.filesPreview[indx]);
      updatedFiles.filesPreview.splice(indx, 1);

      props.updateMediaForm(updatedFiles, source);
    } else if (source === "gallery" && updatedGallery[indx]) {
      updatedGallery.splice(indx, 1);

      props.updateMediaForm(updatedGallery, source);
    }
  }

  function generatePreview(file) {
    const url = URL.createObjectURL(file);
    return url;
  }

  function addNewMediaURL(link, type) {
    // const updatedForm = { ...form };
    const updatedGallery = props.gallery;
    updatedGallery.push({ link, type });
    props.updateMediaForm(updatedGallery, "gallery");
    if (mediaURLModal.show) {
      setMediaURLModal({ show: false, type: "" });
    }
  }

  function changeMediaURLModalVisibility(show, type) {
    setMediaURLModal({ show, type });
  }

  return (
    <div className="col-lg-6 mb-4">
      <div className={styles.upload_img_header}>
        <h4 className={`${commonstyles.medium_title} theme-form-section-title`}>
          Add Image{" "}
          {/* <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
            *
          </span> */}
        </h4>
        <Popover
          content={
            <div className="py-1">
              <button
                type="button"
                className="btn btn-light btn-sm mr-2 btn-block"
                onClick={() => changeMediaURLModalVisibility(true, "image")}
              >
                Add Image URL
              </button>
              <button
                type="button"
                className="btn btn-light btn-sm btn-block"
                onClick={() => changeMediaURLModalVisibility(true, "video")}
              >
                Add Video URL
              </button>
            </div>
          }
          trigger="focus"
          placement="bottom"
        >
          <a href="#" onClick={(e) => e.preventDefault()}>
            Add Image/Video URL
          </a>
        </Popover>
        <MediaURLInputModalComponent
          show={mediaURLModal.show}
          type={mediaURLModal.type}
          handleClose={() => changeMediaURLModalVisibility(false, "")}
          handleAddURL={addNewMediaURL}
        />
        {/* <a href="#">Add Image/Video URL</a> */}
      </div>
      <div
        className={`${commonstyles.management_details_form} ${styles.add_image_card} ml-0`}
      >
        {/* Image previews */}

        {Array.isArray(props.gallery) &&
          props.gallery.map((item, indx) => {
            return item.type === "image" ? (
              <ImagePreviewChip
                key={indx}
                url={item.link}
                title={item.link}
                handleClose={() => removeMedia(indx, "gallery")}
              />
            ) : (
              <VideoPreviewChip
                key={indx}
                url={item.link}
                handleClose={() => removeMedia(indx, "gallery")}
              />
            );
          })}

        {props.localFileData?.filesPreview.map((url, indx) =>
          props.localFileData.files[indx]?.type?.includes("video") ? (
            <VideoPreviewChip
              key={indx}
              name={props.localFileData.files[indx]?.name}
              handleClose={() => removeMedia(indx, "files")}
            />
          ) : (
            <ImagePreviewChip
              key={indx}
              url={url}
              handleClose={() => removeMedia(indx, "files")}
            />
          )
        )}

        {/* Image preveiws ends */}

        <div className={` ${styles.upload_img} text-center`}>
          <img src={Exclusion} alt="" />
          <div>
            <label
              htmlFor="categoryFileInput"
              className={`${styles.btn} px-2 `}
            >
              Add file
              {/* <button className={styles.btn}>Add files</button> */}
              <input
                className="d-none"
                name="files"
                onChange={handleInput}
                type="file"
                multiple
                accept="image/jpeg, image/png, image/svg+xml,image/gif, image/webp"
                id="categoryFileInput"
              />
            </label>
          </div>
          <span>or drop a file to Upload</span>
        </div>
      </div>
    </div>
  );
}
