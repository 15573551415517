import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router";
import { AxiosInstance, PORTS } from "../../../../utils/apiService";
import commonstyles from "../../../../styles/Management_Common.module.css";
import moment from "moment";
import AddTemplateComponent from "../Communications/AddTemplateComponent";
import isObject from "lodash/isObject";
import isString from "lodash/isString";
import { notification } from "antd";
import ReactHtmlParser from "react-html-parser";

const user_grps = [
    { id: 0, name: "Order Value", value: 0 },
    { id: 1, name: "Product Purchased", value: 1 },
];

let selectedTab = {
    id: 3,
    key: 3,
    name: "Newsletter Templates",
    link: "communications/newstemplate",
    isActive: false,
}

export default function AddTemplate() {
    const history = useHistory();
    const { state } = history.location;
    const initialFormState = {
        type: 1,
        title: "",
        subject: "",
        content: "",
        startDate: "",
        startTime: "",
        region: []
    };
    const [editForm, setEditForm] = useState(initialFormState);
    const rules = {
        title: [(val, data) => (!val ? "Required Template Name" : true)],
        subject: [(val, data) => (!val ? "Required Template Subject " : true)],
        content: [(val, data) => (!val ? "Required Email Content" : true)],
        startDate: [(val, data) => (!val ? "Required Start date" : true)],
        startTime: [(val, data) => (!val ? "Required start time" : true)],
    };
    const [formErrors, setFormErrors] = useState({});
    const editorOptions = {
        wrapperClassName: `${commonstyles.form_control} ${formErrors.content && "theme-error-input"} form-control-lg p-1 h-auto`,
        toolbarClassName: "bg-light rounded-lg theme-txt-sm",
        editorClassName: "px-2 mt-3 mb-1 ",
        placeholder: "Type content",
        toolbar: {
            options: [
            "inline",
            "blockType",
            "fontSize",
            "fontFamily",
            "list",
            "emoji",
            "link",
            "textAlign",
            "colorPicker",
            "history",
            ],
    
            blockType: {
                inDropdown: true,
                options: [
                    "Normal",
                    "H1",
                    "H2",
                    "H3",
                    "H4",
                    "H5",
                    "H6",
                    "Blockquote",
                    "Code",
                ],
                className: "theme-txt-sm",
                component: undefined,
                dropdownClassName: undefined,
            },
    
            fontSize: {
                icon: undefined,
                options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
                className: "theme-txt-sm",
                component: undefined,
                dropdownClassName: undefined,
            },
    
            fontFamily: {
                options: [
                    "Arial",
                    "Georgia",
                    "Impact",
                    "Spartan",
                    "Tahoma",
                    "Times New Roman",
                    "Verdana",
                ],
                className: "theme-txt-sm",
                component: undefined,
                dropdownClassName: "",
            },
        },
    };
    const [isSaving, setIsSaving] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if(state?._id) {
            getTemplateDetails(state._id);
        }
        // getRegionList();
    }, [])
    /**
   * @author Rajkumar
   * @param {id}
   * TO GET TEMPLATE DETAILS
   */
    function getTemplateDetails(id) {
        setIsLoading(true);
        AxiosInstance(process.env.REACT_APP_ADMIN_NEWSLETTER, "application/json")
            .get(`/admin/template/detail/${id}`)
            .then((res) => {
                if (res.data.status === '200' || res.data.status === 200) {
                    setIsLoading(false);
                    let data = res?.data?.data;
                    const initialFormState = {
                        _id: data._id,
                        type: 1,
                        title: data.title,
                        subject: data.subject,
                        content: data.content,
                        startDate: moment(data.startDate).format("YYYY-MM-DD"),
                        startTime: moment(data.startDate).format("hh:mm:00 A"),
                        region: []
                    };
                    setEditForm(initialFormState);
                }
        })
        .catch((er) => {
            console.log(er);
            setIsLoading(false)
        });
    }

    // *** ONCHANGE HANDLER FOR TIME *** //
    const onChangeTimeHandler = (time, type) => {
        const cloneEditForm = {...editForm};
        const cloneFormErrors = {...formErrors};
        cloneEditForm.startTime = moment(type, "hh:mm a").format("HH:mm");
        cloneFormErrors['startTime'] = '';
        setEditForm(cloneEditForm);
        setFormErrors(cloneFormErrors);
    }

    // *** ONCHANGE HANDLER FOR DATE *** //
    const onChangeDateHandler = (dateString, item) => {
        const cloneEditForm = {...editForm};
        const cloneFormErrors = {...formErrors};
        let converted_Date = moment(dateString).format('YYYY-MM-DD');
        if (converted_Date === "Invalid date") {
            cloneEditForm.startDate = '';
        } else {
            cloneEditForm.startDate = converted_Date;
            cloneFormErrors['startDate'] = '';
            setFormErrors(cloneFormErrors);
        }
        setEditForm(cloneEditForm);
    }

    // *** EMAIL CONTENT EDITER ONCHANGE *** //
    function handleEditorUpdate(htmlContent) {
        const cloneEditForm = {...editForm};
        const cloneFormErrors = {...formErrors};
        cloneEditForm.content = htmlContent;
        setEditForm(cloneEditForm);
        cloneFormErrors['content'] = '';
        setFormErrors(cloneFormErrors);
    }

    // *** ORDER VALUE ONCHANGE *** //
    function inputOnChange(e) {
        const {name, value} = e.target;
        const cloneEditForm = {...editForm};
        const cloneFormErrors = {...formErrors};
        if(value.trim()) {
            cloneEditForm[name] = value;
            cloneFormErrors[name] = "";
        } else {
            cloneEditForm[name] = "";
            cloneFormErrors[name] = "";
        }
        setEditForm(cloneEditForm);
        setFormErrors(cloneFormErrors);
    }

    // *** SUBMIT CHANGES FUNCTION *** //
    const saveChanges = () => {
        if(!isSaving) {
            let localDate = editForm.startDate;
            let localTime = editForm.startTime;
            let stringDate = moment(localDate + " " + localTime, "YYYY-MM-DD HH:mm");
            editForm.startDate = stringDate.toISOString();
            let data = JSON.parse(JSON.stringify(editForm));
            const result = validate(undefined, data);
            if (Object.keys(result).length) {
                setFormErrors(result);
                showNotification({
                    type: "warning",
                    message: "There were few errors",
                    description: "Resolve the errors and try again",
                });
                return;
            }
            if (editForm.content) {
            } else {
                showNotification({
                    type: "warning",
                    message: "There were few errors",
                    description: "Please enter valid email content",
                });
                return;
            }
            let url = "";
            if(state?._id) {
                data._id = state?._id;
                url = "/admin/updateTemplate"
            } else {
                url = "/admin/createTemplate"
            }
            setIsSaving(true);
            AxiosInstance(process.env.REACT_APP_ADMIN_NEWSLETTER, "application/json")
                .post(url, data)
                .then((res) => {
                    if (res.data.status === '200' || res.data.status === 200) {
                        showNotification({
                            type: "success",
                            message: "Success",
                            description: res.data.msg,
                        });
                        setIsSaving(false);
                        history.push({
                            pathname: `/communications/newstemplate`,
                            state: selectedTab,
                        });
                    } else if (res.data.status === 400) {
                        setIsSaving(false);
                        showNotification({
                            type: "error",
                            message: "error",
                            description: res.data.msg,
                        });
                    } else {
                        setIsSaving(false);
                    }
                })
                .catch((er) => {
                    console.log(er);
                    setIsSaving(false);
                });
        }
    }

    // *** VALIDATE FUNCTION *** //
    function validate(name, data) {
        data = data || {};
        const result = {};
        //Validate only field
        if (name) {
        const fieldRules = rules[name];
        if (Array.isArray(fieldRules)) {
            for (const rule in fieldRules) {
            const val = rule(data[name]);
            if (isString(val) || isObject(val)) {
                setFormErrors({ ...formErrors, [name]: val });
            } else if (name in formErrors) {
                const { name, ...restOfErros } = formErrors;
                setFormErrors(restOfErros);
            }
            }
        }
        //all data validation
        } else {
        for (const field in rules) {
            for (const rule of rules[field]) {
            const val = rule(data[field], data);
            if (typeof val === "string" || isObject(val)) {
                result[field] = val;
                break;
            }
            }
        }
        }
        return result;
    }

    // *** SHOW NOTIFICATION FUNCTION *** //
    function showNotification({ type, message, description }) {
        let options = {
        message: message || "Message",
        description: description,
        duration: 5,
        };
        notification[type](options);
    }

    return (
        <AddTemplateComponent
            editForm={editForm}
            formErrors={formErrors}
            editorOptions={editorOptions}
            handleEditorUpdate={handleEditorUpdate}
            onChangeTimeHandler={onChangeTimeHandler}
            onChangeDateHandler={onChangeDateHandler}
            inputOnChange={inputOnChange}
            isSaving={isSaving}
            saveChanges={saveChanges}
            isLoading={isLoading}
            selectedTab={selectedTab}
        />
    );
}
