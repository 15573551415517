import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router";
import styles from "../../../styles/PaymentNew.module.css";
import BlackLeftArrow from "../../../assets/images/black_left_arrow.svg";
import LoadingIndicator from "../../modules/ReusableComponent/LoadingIndicator";
import ButtonComponent from "../../modules/ReusableComponent/ButtonComponent";
import InputComponent from "../../modules/ReusableComponent/InputComponent";
import FormErrorText from "../../modules/ProductManagement/FormErrorText";
import commonstyles from "../../../styles/Management_Common.module.css";
import bannerimgdelete from "../../../assets/images/Iconly-Bulk-Close Square.svg";
import { runValidationChecks } from "../../../utils";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import { notification,Modal,Button } from "antd";
import {
  minChar,
  noSpace,
  onlyAlphabets
} from "../../../utils/RegularExp";
import { Rating } from 'react-simple-star-rating';
import { onlyNumbers } from '../../../utils/RegularExp';
function AddTestimonialPage() {

  const rules = {
    name: [(val, data) => (!val ? "Name " : true)],
    description: [(val, data) => (!val ? "Description " : true)],
    designation: [(val, data) => (!val ? "City" : true)],
    // duration: [(val, data) => (!val ? "Duration" : true)],
    rating: [(val, data) => (!val ? "Rating" : true)],

  };


  const history = useHistory();
  const { state } = history.location;
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [page, setPage] = useState(state);
  const updateData = state?.id;



  const initialFormState = {
    name: "",
    description: "",
    designation: "",
    rating: "",
    // duration: "",
    enable: true,
  
  };
 

  const [editForm, setEditForm] = useState(
    JSON.parse(JSON.stringify(initialFormState))
  );
  const [galleryImgs, setGalleryImgs] = useState([]);
  const [formErrors, setFormErrors] = useState({
    description: "",
    designation: "",
    // duration: "",
    rating: "",
    name: "",
    image: "",
  
  });


  function handleDuration(e) {


    const durationInMilliseconds = e.target.value * 1000;
    setEditForm({ ...editForm, duration: durationInMilliseconds });
  }

  // new
  const [ratingPrice, setRatingPrice] = useState(editForm?.rating || 0);
  function handleRating(rate) {
    setRatingPrice(rate);
    setEditForm({ ...editForm, rating: rate });
    setFormErrors({ ...formErrors, rating: '' });
  }
  useEffect(() => {
    setPage(state ? state : 10);
    if (updateData) {
      setIsLoading(true)
      setEditForm({
        name: updateData?.name || "",
        description: updateData?.description || "",
        designation: updateData?.designation || "",
        // duration: updateData?.duration / 1000 || "",
        rating: updateData?.rating || "",
        enable: updateData?.enable || false,
      
      })
      setRatingPrice(updateData?.rating || 0)
      console.log("update data", updateData);


      if (updateData?.image) {
        setGalleryImgs([updateData?.image])
      }
      setIsLoading(false)
    }

    setFormErrors({
      description: "",
      designation: "",
      name: "",
      image: "",
      // duration: "",
      rating: "",
   
    })
    return () => {
      history["isTestimonialPage"] = true;
    };
  }, []);


  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }


  function handleFormInput(e) {
    let { name, value, checked } = e.target;

    const updatedValues = { ...editForm, [name]: value };
    if (["enable"].includes(name)) {
      updatedValues[name] = checked;
    } else {
      updatedValues[name] = value;
    }
    if (["name"].includes(name)) {
      if (noSpace.test(value)) {
        formErrors.name = "Enter only alphabet";
      }
      if (!onlyAlphabets.test(value)) {
        formErrors.name = "Enter only alphabet";
      }
      setEditForm({ ...editForm, [name]: value.trim() });
    }
    if (["designation"].includes(name)) {
      if (noSpace.test(value)) {
        formErrors.designation = "Enter only alphabet";
      }
      if (!onlyAlphabets.test(value)) {
        formErrors.designation = "Enter only alphabet";
      }
      setEditForm({ ...editForm, [name]: value });
    }
    setEditForm(updatedValues);
  }

  function handleDescriptionInput(e) {
    const descriptionInputValue = e.target.value
    let { name } = e.target;
    if (["description"].includes(name)) {
      if (noSpace.test(descriptionInputValue)) {
        formErrors.description = "Enter only alphabet and Number";
      }
      setEditForm({ ...editForm, [name]: descriptionInputValue });
    }
  }

  // *** SAVE CHANGES *** //
  function submit() {
    setIsUpdating(true);
    if (state?.id) {
      editForm._id = updateData._id
    }
    // if (editForm?.duration && editForm.duration < 1000) {

    //   editForm.duration = editForm.duration * 1000; // Convert to milliseconds
    // }
    const formData = new FormData();
    console.log("formdata test", editForm);
    if (galleryImgs.length > 0) {
      galleryImgs.forEach((field, indx) => {
        if (field?.name) {
          formData.append("image", field);
        }
      })
    } else {
      formData.append("image", "");
    }
    let data = JSON.parse(JSON.stringify(editForm));
    
    formData.append("editForm", JSON.stringify({ ...data }));
    if (formErrors.description !== "" || formErrors.designation !== "" || formErrors.name !== "" || formErrors.rating !== "" 
      
    ) {
      showNotification({
        type: "warning",
        message: "There were few errors",
        description: formErrors.description || formErrors.designation || formErrors.name || formErrors.rating  + "is required",
      });
      setIsUpdating(false);
      return;
    }
    const result = runValidationChecks(rules, undefined, { ...data });
    if (Object.keys(result).length) {
      setFormErrors(result);
      showNotification({
        type: "warning",
        message: "There were few errors",
        description: Object.values(result) + "is required",
      });
      setIsUpdating(false);
      return;
    }

    if (state?.id) {
      AxiosInstance(PORTS.CATALOG_SERVICE, "application/json")
        .patch(`/testimonial`, formData)
        .then((res) => {
          if (res.data.status === 200) {
            showNotification({
              type: "success",
              message: "Success",
              description: "Testimonial Updated Successfully",
            });
            setIsUpdating(true);
            setIsLoading(false);
            history.push(`/Content/testimonial`);
            setIsUpdating(false);
          } else if (res.data.status === 400) {
            showNotification({
              type: "error",
              message: "Updating testimonial failed",
              description: res.data?.msg || "Error occured",
            });
          } else {
            setIsUpdating(false);
          }
        })
        .catch((er) => {
          // console.log(er);
          return er;
        })
        .finally(() => {
          setIsLoading(false);
          setIsUpdating(false);
        });

    } else {
      AxiosInstance(PORTS.CATALOG_SERVICE, "application/json")
        .post(`/testimonial`, formData)
        .then((res) => {
          if (res.data.status === 200) {
            showNotification({
              type: "success",
              message: "Success",
              description: "Testimonial added successfully",
            });
            setIsUpdating(true);
            setIsLoading(false);
            history.push(`/Content/testimonial`);
            setIsUpdating(false);
          } else if (res.data.status === 400) {
            showNotification({
              type: "error",
              message: "Adding testimonial failed",
              description: res.data?.msg || "Error occured",
            });
          } else {
            setIsUpdating(false);
          }
        })
        .catch((er) => {
          return er;
        })
        .finally(() => {
          setIsLoading(false);
          setIsUpdating(false);
        });
    }
  }

  //image upload
  const handleImageInput = (e) => {
    const { name, files: inputFiles } = e.target;
    const file_name = inputFiles[0].name.toLowerCase();
    if (name) {
      if (!file_name.match(/\.(jpg|jpeg|png|webp|gif)$/)) {
        showNotification({
          type: "error",
          message: "There were few errors",
          description: "Please upload only jpg,jpeg and png format images",
        });
      } else if (inputFiles[0].size > 1024 * 1024 * 3) {
        showNotification({
          type: "error",
          message: "There were few errors",
          description: "Please upload file less than 3MB size",
        });
      } else {
        const cloneGalleryImgs = [...galleryImgs];
        if (cloneGalleryImgs?.length < 10) {
          cloneGalleryImgs?.push(inputFiles[0]);
          setGalleryImgs(cloneGalleryImgs);
        } else {
          showNotification({
            type: "error",
            message: "There were few errors",
            description: "You can't able to add image",
          });
        }
      }
    }
  };

  function removeImages(img, img_indx) {
    let arr = galleryImgs?.filter((x, indx) => indx !== img_indx);
    setGalleryImgs(arr);
  }

  return (
    <>
    <div className={styles.management_contents}>
      <div className={styles.management_header}>
        <h1>
          <img
            src={BlackLeftArrow}
            alt=""
            className="cursor-pointer"
            onClick={() => history.push({
              pathname: "/Content",
              isTestimonialPage: "10"
            })}
          />
          Testimonial Page Management
        </h1>
      </div>
      {isLoading === true ? (
        <div
          style={{
            marginLeft: "50vh",
            marginTop: "10vh",
          }}
        >
          <tr className="bg-transparent">
            <td colSpan="100%">
              <LoadingIndicator loadingText="Fetching landing page details..." />
            </td>
          </tr>
        </div>
      ) : (
        <div>
          <div
            className={`${styles.management_header} ${styles.banner_city_list}`}
          >
            <div className={`${styles.banner_citys}`}>
            </div>

            <div className={styles.management_contents_upload}>
              <div className={`${styles.onoffswitch} d-inline-block align-top`}>
                <input
                  type="checkbox"
                  name="enable"
                  className={styles.onoffswitch_checkbox}
                  onChange={handleFormInput}
                  checked={editForm?.enable}
                  id="statusSwitch"
                />
                <label
                  className={styles.onoffswitch_label}
                  htmlFor="statusSwitch"
                >
                  <span className={styles.onoffswitch_inner}></span>
                  <span className={styles.onoffswitch_switch}></span>
                </label>
              </div>
            
              <ButtonComponent
                className="rnd mr-3 outline_cancel_btn"
                attributes={{
                  onClick: () => history.push(`/Content/testimonial`),
                }}
              >
                Cancel
              </ButtonComponent>
              <ButtonComponent
                className="rnd"
                attributes={{
                  onClick: submit,
                }}
              >
                {isUpdating ? "Saving..." : "Save"}
              </ButtonComponent>
            </div>
          </div>

          <div
            className={`${styles.management_details} ${styles.landing_category_management}`}
          >
            <div className={styles.management_details_contents}>
              <div className="row">
                <div className="col-lg-6 mb-4">
                  <div className={styles.management_details_form}>
                    <label for="">
                      Name
                      <span
                        className="text-danger"
                        style={{ fontSize:"20px",bottom:-8,position:"relative" }}
                      >
                        *
                      </span>
                    </label>
                    <InputComponent
                      className={`form-control form-control-lg ${styles.form_control}`}
                      error={formErrors.name}
                      formControl={{
                        placeholder: "",
                        name: "name",
                        value: editForm?.name,
                        onChange: (e) => {
                          if (!minChar.test(e.target.value)) {
                            if (!formErrors.name) {
                              formErrors.name = {};
                            }
                            formErrors.name = "Enter minimum 3 characters";
                          } else {
                            if (formErrors.name) {
                              formErrors.name = "";
                            }
                          }
                          handleFormInput(e);
                        },
                      }}
                    />
                    <FormErrorText
                      error={formErrors.name}
                    />
                  </div>
                </div>
                <div className="col-lg-6 mb-4">
                  <div className={styles.management_details_form}>
                    <label for="">
                      City
                      <span
                        className="text-danger"
                        style={{ fontSize:"20px",bottom:-8,position:"relative" }}
                      >
                        *
                      </span>
                    </label>
                    <InputComponent
                      className={`form-control form-control-lg ${styles.form_control}`}
                      error={formErrors.designation}
                      formControl={{
                        placeholder: "",
                        name: "designation",
                        value: editForm?.designation,
                        onChange: (e) => {
                          if (!minChar.test(e.target.value)) {
                            if (!formErrors.designation) {
                              formErrors.designation = {};
                            }
                            formErrors.designation = "Enter minimum 3 characters";
                          } else {
                            if (formErrors.designation) {
                              formErrors.designation = "";
                            }
                          }
                          handleFormInput(e);
                        },
                      }}
                    />
                    <FormErrorText
                      error={formErrors.designation}
                    />
                  </div>
                </div>
                <div className="col-lg-12 mb-4">
                  <div className={styles.management_details_form}>
                    <label for="">
                      Description
                      <span
                        className="text-danger"
                        style={{ fontSize:"20px",bottom:-8,position:"relative" }}
                      >
                        *
                      </span>
                    </label>
                    <div class="form-group">
                      <textarea
                        name="description"
                        value={editForm?.description}
                        onChange={(e) => {
                          if (!minChar.test(e.target.value) && editForm?.description === "") {
                            if (!formErrors.description) {
                              formErrors.description = {};
                            }
                            formErrors.description = "Enter minimum 3 characters";
                          } else {
                            if (formErrors.description) {
                              formErrors.description = "";
                            }
                          }
                          handleDescriptionInput(e);
                        }}
                        class="form-control"
                        id="exampleFormControlTextarea1" rows="3"
                      >
                      </textarea>
                    </div>
                    <FormErrorText
                      error={formErrors.description}
                    />
                  </div>

                </div>
                {/* new rate and sort */}
                <div className="col-lg-6 mb-4">
                  {/* new try */}
                  <div
                    className={styles.management_details_form}
                  >
                    <label for="">Rating<span
                      className="text-danger"
                      style={{ fontSize:"20px",bottom:-8,position:"relative" }}
                    >
                      *
                    </span></label>
                    {[...Array(1)].map((star, indx) => {
                      return (
                        <Rating
                          size={30}
                          onClick={handleRating}
                          //  allowFraction

                          name="price"
                          initialValue={ratingPrice}
                          className={formErrors.rating ? 'error-border' : ''}
                        />



                      );

                    })}
                   <FormErrorText
                      error={formErrors.rating}
                    />
                  </div>
                 
                </div>
            


                <div className="col-lg-6 mb-4">
                  <div className="d-flex align-items-right justify-content-between">
                    <label className={commonstyles.secondry_title}>
                      Profile Image{" "}
                    </label>
                  </div>
                  <div className={`${commonstyles.banner_img_box_outline}`}>
                    <div
                      className={`${commonstyles.management_details_contents} mb-2 mt-2`}
                    >
                      <div
                        className={`${commonstyles.management_details_form} ${commonstyles.add_image_card} d-flex align-items-center justify-content-center`}
                      >
                        <input
                          name="image"
                          type="file"
                          accept="image/*"
                          id="imageInput"
                          value=""
                          disabled={galleryImgs.length > 0 ? true : false}
                          onChange={(e) => handleImageInput(e)}
                        />
                      </div>
                    </div>

                  </div>
                  <FormErrorText
                    error={formErrors.image}
                  />


                </div>
                <div className="col-lg-6 mb-4">
                  <label className={commonstyles.secondry_title}>
                    Preview Image
                  </label>
                  <div className={`${commonstyles.banner_img_box_outline} `}>
                    {galleryImgs?.length > 0 &&
                      galleryImgs?.map((imgs, index) => (
                        <div className={`${commonstyles.banner_img_preview}`}>
                          <img
                            class={commonstyles.previewImgs}
                            key={index}
                            src={
                              imgs?.name
                                ? URL.createObjectURL(imgs)
                                : imgs
                            }
                            alt="profileImg"
                          />
                          <img
                            onClick={() => removeImages(imgs, index)}
                            class={commonstyles.previewImgsdeleteicon}
                            src={bannerimgdelete}
                            alt="delete"
                          />
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        
        </div>
        
      )}
    </div>
     
  
    </>
  );
}

export default AddTestimonialPage