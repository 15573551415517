import commonstyles from "../../../styles/Management_Common.module.css";
import styles from "../../../styles/EditUser.module.css";
import Search from "../../../assets/images/search_icon.svg";
import React, { useEffect, useState } from "react";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import { notification, Space, Spin, Select } from "antd";
import {
  formatServerValidtionErrors,
  runValidationChecks,
} from "../../../utils";
import ButtonComponent from "../../modules/ReusableComponent/ButtonComponent";
import Plus from "../../../assets/images/plus_white_icon.svg";
import BlackLeftArrow from "../../../assets/images/black_left_arrow.svg";
import { useHistory, useParams } from "react-router-dom";
import styless from "../../../styles/AddProduct.module.css";
import FormErrorText from "../../modules/ProductManagement/FormErrorText";
import InputComponent from "../../modules/ReusableComponent/InputComponent";
import eyeOpen from "../../../assets/images/eye.svg";
import exclusionIcon from "../../../assets/images/exclusion.svg";
import eyeClose from "../../../assets/images/eye_close.svg";
import validationRules from "../../../utils/validationRules";
import {
  emailRegx,
  minChar,
  passwordReg,
  phoneRegx,
  Saleswithletters,
  Catalogwithletters,
  Discountwithletters,
  Customerwithletters,
} from "../../../utils/RegularExp";
import isObject from "lodash/isObject";
import isString from "lodash/isString";
import { Link } from "react-router-dom";
import LoadingIndicator from "../../modules/ReusableComponent/LoadingIndicator";
// import { handlePermission } from "./HandlePermission";
import ImagePreviewChip from "../../modules/ProductManagement/ImagePreviewChip";
import { AllModules_, buttons, treeData_ } from "../../modules/ReusableComponent/PermissionObject";
import PermissionTable from "../UserManagement/PermissionTable";
import cameraIcon from "../../../assets/images/camera_icon.svg";
import customerProfile from "../../../assets/images/customer_profile.svg";

const intialImagePreviews = { categoryImage: undefined };

const rules = {
  name: [(val, data) => (!val ? "Name " : true)],
  email: [(val, data) => (!val ? "Email " : true)],
  phone: [(val, data) => (!val ? "Phone " : true)],
};

export default function EditUserComponent(props) {
  const id = props?.customer?._id;
  const { Option } = Select;

  const [editForm, setEditForm] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [file, setFile] = useState("");
  const [url, setUrl] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [imagePreviews, setImagePreviews] = useState(intialImagePreviews);
  const [role, setRole] = useState("");
  const [newpa, setNewPa] = useState("");
  const [confirm, setConfirm] = useState("");
  const [isenabled, setIsenabled] = useState(true);
  const [Addphone, setAddphone] = useState("");
  const [regions, setRegions] = useState([]);
  const [region, setRegion] = useState("");
  const [permission, setPermission] = useState([]);
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [details, setDetails] = useState({});
  const [btns, setBtns] = useState(buttons);
  const [tableList, setTableList] = useState(AllModules_[0].data);
  const [newTableList, setNewTableList] = useState(AllModules_)
  const [catalogShow, setCatalogShow] = useState(false);
  const [salesShow, setSalesShow] = useState(false);
  const [discountShow, setDiscountShow] = useState(false);
  const [customerShow, setCustomerShow] = useState(false);
  const [image,setImage] = useState("");
  const [filePreview, setFilePreview] = useState({
    image: null,
  });
  const [files, setFiles] = useState({
    image: null,
  });
  const [choosenBtn, setChoosenBtn] = useState([
    {
      "id": 1, name: "Dashboard", value: "Dashboard", active: true
    }
  ]);
  const [AllModules, setAllModules] = useState(AllModules_);

  const [allChecked, setAllChecked] = useState(true);

  const [roles, setRoles] = useState([]);
  const history = useHistory();

  useEffect(() => {
    getRoleList();
    buttons.forEach(item => {
      if (item.value === "Dashboard") {
        item.active = true;
      } else {
        item.active = false;
      }
    });
    setBtns(buttons);


    return () => {
      AllModules_.forEach(item => {
        item.data.forEach(subItem => {
          subItem.add = false;
          subItem.edit = false;
          subItem.view = false;
        })
      });
      setAllModules(AllModules_);
      history['isRole'] = false;
    }


  }, []);

  useEffect(() => {
    let cloneModules = [...AllModules_];

    setTimeout(() => {
      cloneModules.forEach((item) => {
        item.data.forEach((subItem) => {

          if (subItem.add === true && subItem.edit === true && subItem.view === true) {
            // console.log("All true all checked");
            setAllChecked(true);

          } else {
            // console.log(subItem);
            // console.log("not gave all permission");

            setAllChecked(false);
          }
        })
      })
    }, 1000);

    // console.log(AllModules_);
  }, [allChecked]);

  function getRoleList() {
    AxiosInstance(PORTS.USER_SERVICE, "application/json")
      .get("admin/role/listRole")
      .then((res) => {
        if (res.data.status === 200) {
          setRoles(res.data.data);
          const cloneAllModules = [...AllModules_]
          res?.data?.data?.permission?.forEach(permission => {
            let split = permission.split('-');
            cloneAllModules.forEach(item => {
              if (item.value === split[0]) {
                item.data.forEach(subItem => {
                  if (subItem.value === split[1]) {
                    subItem[split[2]] = true;
                  }
                })
              }
            });
            setTableList(cloneAllModules[0].data);
            setAllModules(cloneAllModules);
          });
          getUserDetails(res.data.data);
        } else if (res.data.status === 400) {
          const errors = formatServerValidtionErrors(res.data.msg);
          setFormErrors(errors);
        }
      })
      .catch((er) => {
        showNotification({
          type: "error",
          message: "Failed",
          description: "Error occured",
        });
      });
  }

  function getUserDetails(roles_arr) {
    AxiosInstance(PORTS.USER_SERVICE, "application/json")
      .get(`admin/user/${id || history?.location?.pathname?.split("/")[3]}`)
      .then((res) => {
        if (res.data.status === 200) {
          const cloneAllModules = [...AllModules]
          const userInformation = res.data;
          setEmail(userInformation.data.email);
          setName(userInformation.data.name);
          setPhone(userInformation.data.phone);
          // let sel_role = "";
          // roles_arr?.forEach(x => {
          //   if (x.name === userInformation.data.role) {
          //     sel_role = x._id
          //   }
          // })
          if(userInformation.data.roleType === 0){
            setAllChecked(false)
          }
          setRole(userInformation.data.role);
          setRegion(userInformation.data.region);
          setPermission(userInformation.data.permission);
          setImage(userInformation.data.profileImg);
          setIsenabled(userInformation.data.status);
          setAddphone(userInformation.data?.additionalPhone);
          setFiles({ ...files, image: userInformation.data?.profileImg });
          setDetails(res.data);
          setTimeout(() => {
            setNewPa('');
          }, 1000)

          userInformation?.data?.permission?.forEach(permission => {
            let split = permission.split('-');
            cloneAllModules.forEach(item => {
              if (item.value === split[0]) {
                item.data.forEach(subItem => {
                  if (subItem.value === split[1]) {
                    subItem[split[2]] = true;
                  }
                })
              }
            });
          });
          setAllModules(cloneAllModules);
          // userInformation.data.permission.includes("Catalog") &&
          //   setCatalogShow(true);
          // userInformation.data.permission.includes("Discount") &&
          //   setDiscountShow(true);
          // userInformation.data.permission.includes("Sales") &&
          //   setSalesShow(true);
          // userInformation.data.permission.includes("Customer") &&
          //   setCustomerShow(true);
        } else if (res.data.status === 400) {
          const errors = formatServerValidtionErrors(res.data.error);
          setFormErrors(errors);
        }
      })
      .catch((er) => {
        showNotification({
          type: "error",
          message: "Failed",
          description: "Error occured",
        });
        return;
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  // useEffect(() => {
  //   setCatalogShow(permission.includes("Catalog") ? true : false);
  //   setSalesShow(permission.includes("Sales") ? true : false);
  //   setDiscountShow(permission.includes("Discount") ? true : false);
  //   setCustomerShow(permission.includes("Customer") ? true : false);
  // }, []);

  // useEffect(() => {
  //   setTimeout(() => {
  //     permission.includes("Catalog") = true;
  //   }, 2000);
  // }, [catalogShow, salesShow, discountShow, customerShow]);

  useEffect(() => {
    function getRegionList() {
      AxiosInstance(PORTS.REGION_SERVICE, "application/json")
        .get("admin/region/getRegionList")
        .then((res) => {
          if (res.data.status === 200) {
            // res.data.daaata.unshift({
            //   name: "",
            //   _id: "",
            // });
            setRegions(res.data.daaata);
          } else if (res.data.status === 400) {
            const errors = formatServerValidtionErrors(res.data.msg);

            setFormErrors(errors);
          }
        })
        .catch((er) => {
          showNotification({
            type: "error",
            message: "Failed",
            description: "Error occured",
          });
        });
    }
    getRegionList();
  }, []);

  function validate(name, data) {
    data = data || {};
    const result = {};
    //Validate only field
    if (name) {
      const fieldRules = rules[name];
      if (Array.isArray(fieldRules)) {
        for (const rule in fieldRules) {
          const val = rule(data[name]);
          if (isString(val) || isObject(val)) {
            setFormErrors({ ...formErrors, [name]: val });
          } else if (name in formErrors) {
            const { name, ...restOfErros } = formErrors;
            setFormErrors(restOfErros);
          }
        }
      }
      //all data validation
    } else {
      for (const field in rules) {
        for (const rule of rules[field]) {
          const val = rule(data[field], data);

          if (typeof val === "string" || isObject(val)) {
            result[field] = val;
            break;
          }
        }
      }
    }
    return result;
  }

  function updateCustomer() {
    const newpermission = [...permission];
    // console.log([...new Set(newpermission)]);
    // let sel_role = "";
    // roles?.forEach(x => {
    //   if (x._id === role) {
    //     sel_role = x.name
    //   }
    // })
    const data = {
      _id: id,
      name: name,
      email: email,
      phone: phone,
      newpassword: newpa,
      confirmpassword: confirm,
      role: role,
      permission: [...new Set(newpermission)],
      enable: isenabled,
    };

    if (region) {
      data.region = region;
    }
    if (Addphone) {
      data.additionalPhone = Addphone;
    }

    let permissionArr = [];
    let roleType = 1;
    AllModules.forEach(item => {
      item.data.forEach(subItem => {
        if (subItem.add) {
          let permission = item.value + '-' + subItem.value + '-add'
          permissionArr.push(permission);
        }
        if (subItem.edit) {
          let permission = item.value + '-' + subItem.value + '-edit'
          permissionArr.push(permission);
        }
        if (subItem.view) {
          let permission = item.value + '-' + subItem.value + '-view'
          permissionArr.push(permission);
        }
        if (subItem.add === false || subItem.edit === false || subItem.view === false) {
          roleType = 0;
        }
      })
    });

    if (permissionArr?.length > 0) {
      data.permission = permissionArr;
      data.roleType = roleType;
    } else {
      showNotification({
        type: "error",
        message: "Error",
        description: "Plese choose any one permission",
      });
      return;
    }

    if (data.newpassword || data.confirmpassword) {
      if (
        !passwordReg.test(data.newpassword) ||
        !passwordReg.test(data.confirmpassword)
      ) {
        showNotification({
          type: "warning",
          message: "There were few errors",
          description:
            "At least 1 number, upper & lower case and special character",
        });
        return;
      }
    }

    const result = runValidationChecks(rules, undefined, data);

    if (Object.keys(result).length) {
      setFormErrors(result);
      showNotification({
        type: "warning",
        message: "There were few errors",
        description: Object.values(result) + "is required",
      });
      return;
    }
    const formData1 = new FormData();
    formData1.append("data", JSON.stringify(data));
    if (files?.image) {
      formData1.append("profileImg", files.image);
    }
    if (name && phone) {
      AxiosInstance(PORTS.USER_SERVICE, "application/json")
        .post("admin/user/updateUser", formData1)
        .then((res) => {
          if (res.data.status === 200) {
            showNotification({
              type: "success",
              message: "Success",
              description: "User details updated successfully",
            });
            props.closeAll();
            // history.push(`/site-settings/user`);
            // getUserDetails()
            // return;
          } else if (
            res.data.status === 400 &&
            res.data.msg === "New password and confirm password not match"
          ) {
            showNotification({
              type: "warning",
              message: "There were few errors",
              description: "New password and confirm password not match",
            });
            return;
          } else if (res.data.status === 400) {
            showNotification({
              type: "warning",
              message: "There were few errors",
              description: res.data.msg,
            });

            const errors = formatServerValidtionErrors(res.data.error);
            setFormErrors(errors);
          }
        })
        .catch((er) => {
          showNotification({
            type: "error",
            message: "Failed",
            description: "Error occured",
          });
        });
    }
  }

  function handlePermission(e) {
    const { name, checked } = e.target;

    if (Customerwithletters.test(name) && checked == true) {
      setCustomerShow(true);
      permission.push(name);
      setPermission([...permission]);
    } else if (Customerwithletters.test(name)) {
      if (name == "Customer" && checked == false) {
        let updatedPermissions = permission.filter(
          (value, index) => !value.includes("Customer")
        );
        setPermission([...updatedPermissions]);
        setCustomerShow(false);
      } else {
        setCustomerShow(true);
        const index = permission.indexOf(name);
        permission.splice(index, 1);
        setPermission([...permission]);
      }
    }

    if (Discountwithletters.test(name) && checked == true) {
      setDiscountShow(true);
      permission.push(name);
      setPermission([...permission]);
    } else if (Discountwithletters.test(name)) {
      if (name == "Discount" && checked == false) {
        // setUpdatedPermissions(
        //   permission.filter((value, index) => !value.includes("Discount"))
        // );
        let updatedPermissions = permission.filter(
          (value, index) => !value.includes("Discount")
        );
        setPermission([...updatedPermissions]);
        setDiscountShow(false);
      } else {
        setDiscountShow(true);
        const index = permission.indexOf(name);
        permission.splice(index, 1);
        setPermission([...permission]);
      }
    }

    if (Catalogwithletters.test(name) && checked == true) {
      setCatalogShow(true);
      permission.push(name);
      setPermission([...permission]);
    } else if (Catalogwithletters.test(name)) {
      if (name == "Catalog" && checked == false) {
        // setUpdatedPermissions(
        //   permission.filter((value, index) => !value.includes("Catalog"))
        // );
        let updatedPermissions = permission.filter(
          (value, index) => !value.includes("Catalog")
        );

        setPermission([...updatedPermissions]);
        setCatalogShow(false);
      } else {
        setCatalogShow(true);
        const index = permission.indexOf(name);
        permission.splice(index, 1);
        setPermission([...permission]);
      }
    }

    if (Saleswithletters.test(name) && checked == true) {
      setSalesShow(true);
      permission.push(name);
      setPermission([...permission]);
    } else if (Saleswithletters.test(name)) {
      if (name == "Sales" && checked == false) {
        // setUpdatedPermissions(
        //   permission.filter((value, index) => !value.includes("Sales"))
        // );
        let updatedPermissions = permission.filter(
          (value, index) => !value.includes("Sales")
        );

        setPermission([...updatedPermissions]);
        setSalesShow(false);
      } else {
        setSalesShow(true);
        const index = permission.indexOf(name);
        permission.splice(index, 1);
        setPermission([...permission]);
      }
    }
  }

  const onPasswordVisible = () => {
    setVisible(!visible);
  };

  const onPasswordVisible2 = () => {
    setVisible2(!visible2);
  };

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  function handleFormInput(e) {
    const { name, value, checked } = e.target;
    setIsenabled(checked);
  }

  function handleFormInput(e) {
    const { name, checked } = e.target;

    setIsenabled(checked);
  }

  function onRoleChange(e) {
    if (e.target.value == "super_admin") {
      setRegion([]);
      setPermission([]);
      setRole(e.target.value);
    } else {
      setRole(e.target.value);
    }
  }

  function userList() {
    history.push("/site-settings/user");
  }

  function removeFile() {
    let name = "image";

    if (filePreview[name]) {
      URL.revokeObjectURL(filePreview[name]);
    }
    setFiles({ ...files, [name]: null });
    setFilePreview({ ...filePreview, [name]: null });
    setImage();
  }

  function handleImageInput(e) {
    const { name, files: inputFiles } = e.target;
    const file_name = inputFiles[0].name.toLowerCase();
    if (name) {
      if (!file_name.match(/\.(jpg|jpeg|png|webp|gif)$/)) {
        showNotification({
          type: "error",
          message: "There were few errors",
          description: "Please upload only jpg,jpeg and png format images",
        });
        return;
      } else if (inputFiles[0].size > 1024 * 1024 * 3) {
        showNotification({
          type: "error",
          message: "There were few errors",
          description: "Please upload file less than 3MB size",
        });
        return;
      } else {
        if (filePreview[name]) {
          URL.revokeObjectURL(filePreview[name]);
        }
        const url = URL.createObjectURL(inputFiles[0]);
        setFiles({ ...files, [name]: inputFiles[0] });
        setFilePreview({ ...filePreview, [name]: url });
      }
    }
  }

  function addcheckbox(e, record, type) {
    const { checked } = e.target;
    const cloneAllModules = [...AllModules]
    if (checked) {
      cloneAllModules.forEach(item => {
        item?.data?.forEach(subItem => {
          if (subItem.value === record.value) {
            subItem[type] = true;
            setTableList(item?.data);
          }
        });
      })
    } else {
      cloneAllModules.forEach(item => {
        item?.data?.forEach(subItem => {
          if (subItem.value === record.value) {
            subItem[type] = false;
            setTableList(item?.data);
          }
        });
      })
    }
    setAllModules(cloneAllModules);
  }

  const selectedBtn = (btn) => {
    const cloneBtns = [...btns];
    const cloneAllModules = [...AllModules]
    setChoosenBtn(btn);
    cloneBtns.forEach(item => {
      if (btn.id === item.id) {
        item.active = true;
      } else {
        item.active = false;
      }
    })
    setBtns(cloneBtns);
    cloneAllModules.forEach(item => {
      if (item.value === btn.value) {
        setTableList(item.data);
      }
    });
  }

  const handleRoleChange = (e) => {
    const { value } = e.target;
    const cloneAllModules = [...AllModules]
    setRole(value);
    let data = [];
    roles?.forEach(x => {
      if (x._id === value) {
        data = x.permission
      }
    })
    if (data?.length > 0) {
      data?.forEach(permission => {
        let split = permission.split('-');
        cloneAllModules.forEach(item => {
          if (item.value === split[0]) {
            item.data.forEach(subItem => {
              if (subItem.value === split[1]) {
                subItem[split[2]] = true;
              }
            })
          }
        });
      });
    } else {
      cloneAllModules.forEach(item => {
        item.data.forEach(subItem => {
          subItem.add = false;
          subItem.view = false;
          subItem.edit = false;
        })
      });
    }
    setAllModules(cloneAllModules);
  }

  const parentCheckbox = (e, type) => {

  }


  const SelectAllCheckBox = (e) => {
    const { checked } = e.target;
    const cloneAllModules = [...AllModules];

    if (checked == true) {
      setAllChecked(true)
      cloneAllModules.forEach((data) => data.data.forEach((item) => {
        item.add = true
        item.edit = true
        item.view = true
      }
      ));


      // console.log(newTableList);
    } else if (checked == false) {
      setAllChecked(false)
      cloneAllModules.forEach((data) => data.data.forEach((item) => {
        item.add = false
        item.edit = false
        item.view = false
      }
      ));
      // console.log(newTableList);
    }

    setNewTableList(cloneAllModules)
  }

  return (
    <div className={commonstyles.management_contents}>
      <div className={commonstyles.management_header}>
        <h1 className={commonstyles.management_header_heading}>
          <img
            src={BlackLeftArrow}
            alt=""
            onClick={props.closeAll ? props.closeAll : ()=> history.push("/site-settings/user")}
            style={{ cursor: "pointer" }}
          />{" "}
          Edit User
        </h1>
        <div className={styless.management_contents_upload}>
          <div className={`${styless.onoffswitch} d-inline-block align-top`}>
            <input
              type="checkbox"
              name="enable"
              className={styless.onoffswitch_checkbox}
              onChange={(e) => handleFormInput(e)}
              checked={isenabled}
              id="statusSwitch"
            />

            <label className={styless.onoffswitch_label} htmlFor="statusSwitch">
              <span className={styless.onoffswitch_inner}></span>
              <span className={styless.onoffswitch_switch}></span>
            </label>
          </div>
          <ButtonComponent
            className="rnd outline_cancel_btn mr-3"
            // attributes={{
            //   onClick: () => history.push(`/site-settings/user`),
            // }}
            attributes={{
              onClick: props.closeAll ? props.closeAll : ()=> history.push("/site-settings/user")
            }}
          >
            Cancel
          </ButtonComponent>
          <button
            className={`theme-btn rnd d-inline-block`}
            onClick={updateCustomer}
            style={{ cursor: "pointer" }}
          >
            Save
          </button>
        </div>
      </div>

      {isLoading === true ? (
        <div
          style={{
            marginLeft: "70vh",
            marginTop: "40vh",
          }}
        >
          <tr className="bg-transparent">
            <td colSpan="100%">
              <LoadingIndicator loadingText="Fetching user details..." />
            </td>
          </tr>
        </div>
      ) : (
        <React.Fragment>
          <div className={styles.user_admin}>
            <div className={"media " + styles.user_admin_detail}>
              <div className="media-body">
                <div className="row">

                  <div className="col-lg-2">
                    <div className={styles.add_user_img}>
                      {filePreview.image || image ? (
                        <ImagePreviewChip
                          url={filePreview.image || image}
                          handleClose={removeFile}
                        />
                      ) : (
                        <div className={styles.upload_img + " text-center "}>
                          <img src={customerProfile} className={styles.profile_img} alt="profile_img" />
                          <div>
                            <label
                              htmlFor="imageInput"
                              className={`px-2 cursor-pointer`}
                            >
                              <img src={cameraIcon} className={styles.camera_img} alt='camera' />
                            </label>
                            <input
                              name="image"
                              className="d-none"
                              type="file"
                              accept="image/jpeg, image/png, image/svg+xml,image/gif, image/webp"
                              id="imageInput"
                              onInput={handleImageInput}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-10">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group">

                          <label className={styles.label_title}>Name</label>
                          <span
                            className="text-danger"
                            style={{ fontSize:"20px",bottom:-8,position:"relative" }}
                          >
                            *
                          </span>

                          <InputComponent
                            error={formErrors.name}
                            className={` ${styles.form_control} form-control`}
                            formControl={{
                              maxLength: "20",
                              type: "text",
                              value: name,
                              onChange: (e) => {
                                if (!minChar.test(e.target.value)) {
                                  if (!formErrors.name) {
                                    formErrors.name = {};
                                  }
                                  formErrors.name = "Enter minimum 3 characters";
                                } else {
                                  if (formErrors.name) {
                                    formErrors.name = "";
                                  }
                                }
                                setName(e.target.value);
                              },
                              placeholder: "Enter name",
                            }}
                            aria-describedby="emailHelp"
                          />
                          <FormErrorText
                            error={formErrors.name && "Enter minimum 3 characters"}
                          />
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="form-group">
                          <label className={styles.label_title}>Email</label>
                          <span
                            className="text-danger"
                            style={{ fontSize:"20px",bottom:-8,position:"relative" }}
                          >
                            *
                          </span>

                          <InputComponent
                            error={formErrors.email}
                            formControl={{
                              type: "email",
                              value: email,
                              maxLength: "40",
                              onChange: (e) => {
                                if (!emailRegx.test(e.target.value)) {
                                  if (!formErrors.email) {
                                    formErrors.email = {};
                                  }
                                  formErrors.email = "Enter only valid email";
                                } else {
                                  if (formErrors.email) {
                                    formErrors.email = "";
                                  }
                                }
                                setEmail(e.target.value);
                              },
                              placeholder: "Enter email",
                            }}
                            className={"form-control " + styles.form_control}
                            aria-describedby="emailHelp"
                          />
                          <FormErrorText
                            error={formErrors.email && "Enter only valid email"}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className={"form-group"}>
                          <label className={styles.label_title}>
                            Mobile
                            <span
                              className="text-danger"
                              style={{ fontSize:"20px",bottom:-8,position:"relative" }}
                            >
                              *
                            </span>{" "}
                          </label>
                          <div className={styles.adduser_input_countycode}>
                            <span>+91</span>
                            <InputComponent
                              error={formErrors.phone}
                              formControl={{
                                type: "number",
                                placeholder: "Enter phone number",
                                value: phone,
                                onChange: (e) => {
                                  if (!phoneRegx.test(e.target.value)) {
                                    if (!formErrors.phone) {
                                      formErrors.phone = {};
                                    }
                                    formErrors.phone = "Enter valid phone number";
                                  } else {
                                    if (formErrors.phone) {
                                      formErrors.phone = "";
                                    }
                                  }
                                  setPhone(e.target.value);
                                },
                                maxLength: 10,
                              }}
                              className={"form-control " + styles.form_control}
                              aria-describedby="emailHelp"
                            />
                          </div>
                          <FormErrorText
                            error={formErrors.phone && "Enter valid phone number"}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className={"form-group"}>
                          <label className={styles.label_title}>
                            Additional Phone Number (Optional)
                          </label>
                          <div className={styles.adduser_input_countycode}>
                            <span>+91</span>
                            <InputComponent
                              error={formErrors.Addphone}
                              formControl={{
                                type: "number",
                                placeholder: "Enter additional phone number",
                                value: Addphone,
                                onChange: (e) => {
                                  if (!phoneRegx.test(e.target.value)) {
                                    if (!formErrors.Addphone) {
                                      formErrors.Addphone = {};
                                    }
                                    formErrors.Addphone = "Enter valid phone number";
                                  } else {
                                    if (formErrors.Addphone) {
                                      formErrors.Addphone = "";
                                    }
                                  }
                                  setAddphone(e.target.value);
                                },
                                maxLength: 10,
                              }}
                              className={"form-control " + styles.form_control}
                              aria-describedby="emailHelp"
                            />
                          </div>
                          <FormErrorText
                            error={formErrors.Addphone && "Enter valid phone number"}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <label>New Password</label>
                        <input
                          type={visible ? "text" : "password"}
                          value={newpa}
                          // visible={visible}
                          // onPasswordVisible={onPasswordVisible}
                          className={` ${styles.form_control} form-control form-control-lg mb-4 `}
                          placeholder="-"
                          onChange={(e) => {
                            if (!passwordReg.test(e.target.value)) {
                              if (!formErrors.newpa) {
                                formErrors.newpa = {};
                              }
                              formErrors.newpa =
                                "At least 1 number, upper & lower case and special character and minimum 8 characters";
                            } else {
                              if (formErrors.newpa) {
                                formErrors.newpa = "";
                              }
                            }
                            setNewPa(e.target.value);
                          }}
                        />
                        <span
                          id="password"
                          className="pass_eye_icon"
                          onClick={onPasswordVisible}
                          style={{
                            position: "absolute",
                            top: "40px",
                            right: "30px",
                          }}
                        >
                          <img
                            src={visible ? eyeClose : eyeOpen}
                            alt="visible"
                            onClick={onPasswordVisible}
                            className={styles.eyeIcon}
                          />
                        </span>
                        <FormErrorText error={formErrors.newpa} />
                      </div>
                      <div className="col-lg-6">
                        <label>Confirm Password</label>
                        <input
                          type={visible2 ? "text" : "password"}
                          // visible2={visible2}
                          // onPasswordVisible2={onPasswordVisible2}
                          className={` ${styles.form_control} form-control form-control-lg mb-4 `}
                          placeholder="-"
                          onChange={(e) => {
                            if (newpa !== e.target.value) {
                              formErrors.password =
                                "Confirm password should be same as new password";
                            } else {
                              formErrors.password = "";
                            }

                            setConfirm(e.target.value);
                          }}
                        />
                        <span
                          id="password"
                          className="pass_eye_icon"
                          onClick={onPasswordVisible2}
                          style={{
                            position: "absolute",
                            top: "40px",
                            right: "30px",
                          }}
                        >
                          <img
                            src={visible2 ? eyeClose : eyeOpen}
                            alt="visible"
                            onClick={onPasswordVisible2}
                            className={styles.eyeIcon}
                          />
                        </span>
                        <FormErrorText error={formErrors.password} />
                      </div>
                      <div className={`form-group col-md-6`}>
                        {/* {role !== "super_admin" && ( */}
                        <div>
                          <label className={styles.label_title}>
                            Warehouse
                          </label>
                          <select
                            className={"form-control " + styles.form_control}
                            value={region}
                            onChange={(e) => setRegion(e.target.value)}
                            style={{ cursor: "pointer" }}
                          >
                            <option value="">All region</option>
                            {regions.map((region) => (
                              <>
                                <option value={region._id} label={region.name}>
                                  {region.name}
                                </option>
                              </>
                            ))}
                          </select>
                        </div>
                        {/* )} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.user_permission}>
              <div className="row">
                <div className={`col-md-6 ${styles.uesr_permission_heading} `}>
                  <h4 className={styles.user_heading}>Role</h4>
                  <select
                    className={"form-control " + styles.form_control}
                    onChange={(e) => handleRoleChange(e)}
                    value={role}
                    style={{ cursor: "pointer" }}
                  >
                    {roles.map((role) => (
                      <>
                        <option key={role._id} value={role._id} label={role.name}>
                          {role.name}
                        </option>
                      </>
                    ))}
                  </select>
                </div>
                <div className="col-md-6 text-right">
                  <input className="mr-2 mt-1" type="checkbox" id="allCheckBox" onChange={SelectAllCheckBox} name="allCheckBox" checked={allChecked} />
                  <label for="allCheckBox"> Select all modules</label>
                </div>



              </div>
              <PermissionTable
                btns={btns}
                isEnable={true}
                addcheckbox={addcheckbox}
                tableList={tableList}
                selectedBtn={selectedBtn}
                parentCheckbox={parentCheckbox}
              />
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
}
