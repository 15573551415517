import React, { useState, useEffect } from "react";
import styles from "../../../styles/PaymentNew.module.css";
import BlackLeftArrow from "../../../assets/images/black_left_arrow.svg";
import { useHistory } from "react-router";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import ButtonComponent from "../../modules/ReusableComponent/ButtonComponent";
import { lte, reject } from "lodash";
import { isAuthenticated, runValidationChecks } from "../../../utils";
import InputComponent from "../../modules/ReusableComponent/InputComponent";
import SelectComponent from "../../modules/ReusableComponent/SelectComponent";
import FormErrorText from "../../modules/ProductManagement/FormErrorText";
import {
    alphaNum,
    noSpace,
    alphaNumwithSpace,
    alphanumericwithdashHyphen,
    minChar,
    minCharWithNoMaxLimit,
    userNameWithSpace,
} from "../../../utils/RegularExp";
import styless from "../../../styles/AddProduct.module.css";
import LoadingIndicator from "../../modules/ReusableComponent/LoadingIndicator";
import commonstyles from "../../../styles/Management_Common.module.css";
import validationRules from "../../../utils/validationRules";
import { isObject, isString, isEqual } from "lodash";
import ImagePreviewChip from "../../modules/ProductManagement/ImagePreviewChip";
import ProductCategoryTreeSelectComponent from "../../modules/ProductManagement/ProductCategoryTreeSelectComponent";
import Plus from "../../../assets/images/plus_lynch_icon.svg";
import exclusionIcon from "../../../assets/images/exclusion.svg";
import MainLeftBanner from "../CMSManagement/MainLeftBanner";
import LeftRightTopBanner from "../CMSManagement/LeftRightTopBanner";
import MadeForBanner from "../CMSManagement/MadeForBanner";
import moment from "moment";
import LoginWithOtpBanner from "./LoginWithOtpBanner";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { DatePicker, notification, Select, TimePicker } from "antd";
import Exclusion from "../../../assets/images/exclusion.svg";
// import ImageInsert from '@ckeditor/ckeditor5-image/src/imageinsert';

const { Option } = Select;
const initialFormState = {
    _id: "",
    title: "",
    slug: "",
    description: "",
    discount: "",
    startDate: "",
    startTime: "",
    endDate: "",
    endTime: "",
    categoryId: [],
    enable: true,
    image: '',
    productId: ''
};

const format = "HH:mm";

export default function HotDealLandingPage() {
    const rules = {
        title: [(val, data) => (!val ? "Title " : true)],
        // slug: [(val, data) => (!val ? "Slug " : true)],
        regionId: [(val, data) => (data.regionId ? true : "Region ")],
        description: [(val, data) => (!val ? "description " : true)],
        // discount: [(val, data) => (!val ? "discount " : true)],
        startDate: [(val, data) => (!val ? "startDate " : true)],
        startTime: [(val, data) => (!val ? "startTime " : true)],
        endDate: [(val, data) => (!val ? "endDate " : true)],
        endTime: [(val, data) => (!val ? "endTime " : true)],
        image: [(val, data) => (!val ? "image " : true)],
        productId: [(val, data) => (!val ? "Product " : true)],
        categoryId: [(val, data) => ((val || data?.categoryId?.length > 0) ? true : "Category")],
    };

    const history = useHistory();

    const { state } = history.location;

    // const [loading, setLoading] = useState({ details: false, update: false });
    const [isLoading, setIsLoading] = useState(true);
    const [isUpdating, setIsUpdating] = useState(false);
    const [regions, setRegions] = useState([]);
    const [filterRegions, setFilterRegions] = useState([]);
    const [regionId, setRegionId] = useState("");
    const [page, setPage] = useState(state);
    const [bannerDetail, setBannerDetail] = useState([]);
    const [editForm, setEditForm] = useState(
        JSON.parse(JSON.stringify(initialFormState))
    );
    const [formErrors, setFormErrors] = useState({});
    const [categoryNames, setCategoryNames] = useState([]);
    const [files, setFiles] = useState({
        image: null,
    });
    const [productArr, setProductArr] = useState([]);
    const [isNew, setIsNew] = useState(true);

    useEffect(() => {
        function getRegionList() {
            AxiosInstance(PORTS.REGION_SERVICE, "application/json")
                .get("admin/region/getRegionList")
                .then((res) => {
                    if (res.status === 200 && res.data.status === 200) {
                        res.data.daaata.unshift({name:"All Region",_id:"0486fab6a2c4851da688ed95"})
                        setRegions(res.data.daaata);
                        if (res.data.daaata[0]) {
                            setRegionId(res.data.daaata[0]?._id);
                        }
                    }
                })
                .catch((er) => {
                    return er;
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
        getRegionList();
    }, []);

    useEffect(() => {
        setPage(state ? state : 3);
        setFormErrors({});
        setEditForm([]);
        getCategories();
        regionId && getLandingList();
        return () => {
            history["isHotDeal"] = true;
        };
    }, [regionId]);
    /**
   * @author Rajkumar
   * To get landing list
   */
    function getLandingList() {
        AxiosInstance(PORTS.REGION_SERVICE, "application/json")
            .get(`/deals/getDeals/${regionId}`)
            .then((res) => {
                if (res.status === 200 && res.data.status === 200) {
                    if (res?.data?.data?.length > 0) {
                        const val = res.data.data[0];
                        const startTime1 = new Date(val.startDate).toLocaleTimeString('en',{ timeStyle: 'short', hour12: false, timeZone: 'UTC' });
                        const endTime1 = new Date(val.endDate).toLocaleTimeString('en',{ timeStyle: 'short', hour12: false, timeZone: 'UTC' });
                        val['startTime'] = startTime1;
                        val['endTime'] = endTime1;
                        console.log(val);
                        setEditForm(val);
                            setIsNew(false);
                        setIsLoading(false);
                        let catogoryData = {
                            categoryId: val.categoryId
                        };
                        getProductList(catogoryData)
                    } else {
                        setEditForm(initialFormState);
                        setIsNew(true)
                    }
                }
            })
            .catch((er) => {
                return er;
            });
    }
    /**
   * @author Rajkumar
   * To get categories
   */
    function getCategories() {
        AxiosInstance(PORTS.CATALOG_SERVICE, "application/json")
            .get("/admin/catalog/getCategoryName/")
            .then((res) => {
                //console.log(res.data)
                if (res && res.data && res.status == "200") {
                    setCategoryNames(res.data.daaata);
                }
            })
            .catch((er) => {
                // console.log("Failed to get data on Category tree select");
                console.log(er);
            });
    }
    /**
   * @author Rajkumar
   * @param {data}
   * To get product list
   */
    function getProductList(data) {
        AxiosInstance(PORTS.REGION_SERVICE, "application/json")
            .post("/deals/getProductsOfCategory", data)
            .then((res) => {
                //console.log(res.data)
                if (res && res.data && res.status == "200") {
                    setProductArr(res.data.data);
                }
            })
            .catch((er) => {
                // console.log("Failed to get data on Category tree select");
                console.log(er);
            });
    }

    // ** DISABLE POST DAYS *** //
    function disabledDate(current) {
        return current < moment().startOf('day');
    }

    function showNotification({ type, message, description }) {
        let options = {
            message: message || "Message",
            description: description,
            duration: 5,
        };
        notification[type](options);
    }

    function handleInputSlug(event) {
        var k = event ? event.which : window.event.keyCode;
        if (k === 32) {
            return false;
        } else {
            if (!alphanumericwithdashHyphen.test(event.target.value)) {
                formErrors.slug = "Enter only alphabet and number";
            }
            setEditForm({
                ...editForm,
                slug: event.target.value.toLowerCase().split(" ").join(""),
            });
        }
    }

    // *** INPUT HANDLE CHANGE *** //
    function handleFormInput(e) {
        let { name, value, checked } = e.target;
        if (["title"].includes(name)) {
            if (noSpace.test(value)) {
                formErrors.title = "Enter only alphabet and number";
            }
            if (!alphaNumwithSpace.test(value)) {
                formErrors.title = "Enter only alphabet and number";
            }
            setEditForm({ ...editForm, [name]: value });
        }

        if (["description"].includes(name)) {
            setEditForm({ ...editForm, [name]: value });
        }

        if (["discount"].includes(name)) {
            const inputtedValue = e.currentTarget.value
            if (isNaN(inputtedValue)) return
            setEditForm({ ...editForm, [name]: inputtedValue });
        }

        const updatedValues = { ...editForm, [name]: value };
        if (["enable"].includes(name)) {
            updatedValues[name] = checked;
        } else {
            updatedValues[name] = value;
        }
        setEditForm(updatedValues);
    }

    function validate(name, data) {
        data = data || {};
        const result = {};
        //Validate only field
        if (name) {
            const fieldRules = rules[name];
            if (Array.isArray(fieldRules)) {
                for (const rule in fieldRules) {
                    const val = rule(data[name]);
                    if (isString(val) || isObject(val)) {
                        setFormErrors({ ...formErrors, [name]: val });
                    } else if (name in formErrors) {
                        const { name, ...restOfErros } = formErrors;
                        setFormErrors(restOfErros);
                    }
                }
            }
            //all data validation
        } else {
            for (const field in rules) {
                for (const rule of rules[field]) {
                    const val = rule(data[field], data);
                    if (typeof val === "string" || isObject(val)) {
                        result[field] = val;
                        break;
                    }
                }
            }
        }
        return result;
    }

    // *** SAVE CHANGES *** //
    function submit() {
        let data = JSON.parse(JSON.stringify(editForm));
        data.slug = data?.slug?.trim();
        data.regionId = regionId ? regionId : "";

        delete data?.userName;
        delete data?.updatedAt;
        const result = runValidationChecks(rules, undefined, { ...data });

        if (Object.keys(result).length) {
            setFormErrors(result);
            showNotification({
                type: "warning",
                message: "There were few errors",
                description: Object.values(result) + "is required",
            });
            return;
        }

        let url = "";
        if (isNew) {
            url = "/deals/createHotDeals"
        } else {
            url = "/deals/updateHotDeals"
            data.id = data._id;
        }
        if (data?.categoryId?.value) {
            data.categoryId = data?.categoryId?.value
        }
        data.region = data.regionId;
        data.discount = parseFloat(data.discount);
        data.startTime = moment(data?.startTime, 'HH:mm').format('hh:mm:ss A')
        data.endTime = moment(data?.endTime, 'HH:mm').format('hh:mm:ss A');
        data.startDate = moment(data?.startDate).format('YYYY-MM-DD');
        data.endDate = moment(data?.endDate).format('YYYY-MM-DD');
        if (!data?.productId) delete data?.productId;
        delete data?.__v;
        delete data?.createdAt;
        delete data?._id;
        delete data?.image;
        delete data?.regionId;
        delete data?.slug;

        const formData = new FormData();

        formData.append("data", JSON.stringify(data));
        if(files?.image) {
            formData.append("image", files?.image);
        }
        setIsUpdating(true);
        AxiosInstance(PORTS.REGION_SERVICE, "application/json")
            .post(url, formData)
            .then((res) => {
                if (res.data.status === 200) {
                    setIsUpdating(false);
                    setIsLoading(false);
                    getLandingList();
                    showNotification({
                        type: "success",
                        message: "Success",
                        description: editForm?._id
                            ? res?.data?.msg
                            : res?.data?.msg,
                    });
                    history.push(`/Content`);
                } else {
                    showNotification({
                        type: "error",
                        message: "Error",
                        description: res?.data?.msg,
                    });
                }
            })
            .catch((er) => {
                // console.log(er);
                return er;
            })
            .finally(() => {
                setIsLoading(false);
                setIsUpdating(false);
            });
    }

    function uploadAdapter(loader) {
        return {
            upload: () => {
                return new Promise((resolve, reject) => {
                    const body = new FormData();
                    loader.file.then((file) => {
                        body.append("files", file);
                        console.log(file);
                        AxiosInstance(PORTS.CATALOG_SERVICE, "application/json")
                            .post(`/admin/uploadImages`, body)
                            .then((res) => {
                                if (res.data.status === 200) {
                                    resolve({
                                        default: `${res.data?.dlandingata[0]?.link}`,
                                    });
                                } else if (res.data.status === 400) {
                                    showNotification({
                                        type: "error",
                                        message: "Adding CMS failed",
                                        description: res.data?.msg || "Error occured",
                                    });
                                }
                            })
                            .catch((er) => {
                                showNotification({
                                    type: "error",
                                    message: "Adding CMS failed",
                                    description: "Error occured",
                                });
                            })
                            .finally(() => {
                                setIsLoading(false);
                            });
                    });
                });
            },
        };
    }

    function uploadPlugin(editor) {
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
            return uploadAdapter(loader);
        };
    }

    function handleRegion(e) {
        setIsLoading(true);
        const { name, value } = e.target;
        let values
        values=value === "All Region" ? "" : value
        setRegionId(values);
        AxiosInstance(PORTS.REGION_SERVICE, "application/json")
            .get(`/deals/getDeals/${value}`)
            .then((res) => {
                if (res.status === 200 && res.data.status === 200) {
                    const val =
                        res.data.data[0] || JSON.parse(JSON.stringify(initialFormState));
                    setEditForm(val);
                    setIsLoading(false);
                }
            })
            .catch((er) => {
                return er;
            });
    }

    // *** ONCHANGE HANDLER FOR DATE *** //
    const onChangeDateHandler = (dateString, type) => {
        const cloneEditForm = { ...editForm };
        const cloneFormErrors = { ...formErrors };
        let converted_Date = moment(dateString).format('YYYY-MM-DD');
        if (type === "startDate") {
            if (converted_Date === "Invalid date") {
                cloneEditForm.startDate = '';
            } else {
                cloneEditForm.startDate = converted_Date;
                cloneFormErrors['startDate'] = '';
                setFormErrors(cloneFormErrors);
            }
        } else {
            if (converted_Date === "Invalid date") {
                cloneEditForm.endDate = '';
            } else {
                cloneEditForm.endDate = converted_Date;
                cloneFormErrors['endDate'] = '';
                setFormErrors(cloneFormErrors);
            }
        }
        setEditForm(cloneEditForm);
    }

    // *** ONCHANGE HANDLER FOR TIME *** //
    const onChangeStartTimeHandler = (time, type) => {
        const cloneEditForm = { ...editForm };
        const cloneFormErrors = { ...formErrors };
        cloneEditForm.startTime = type
        cloneFormErrors['startTime'] = '';
        setEditForm(cloneEditForm);
        setFormErrors(cloneFormErrors);
    }

    const onChangeEndTimeHandler = (item, type) => {
        const cloneEditForm = { ...editForm };
        const cloneFormErrors = { ...formErrors };
        cloneEditForm.endTime = type;
        cloneFormErrors['endTime'] = '';
        setEditForm(cloneEditForm);
        setFormErrors(cloneFormErrors);
    }

    const searchSelectOnChange = (value, name) => {
        // let arr = [];
        // arr.push(value[value?.length - 1]);
        const cloneEditForm = { ...editForm };
        const cloneFormErrors = { ...formErrors };
        if (name === "categoryId") {
            cloneEditForm[name] = value[value?.length - 1];
        }
        setEditForm({
            ...cloneEditForm,
            slug: "",
            productId:""
        });
        setFormErrors(cloneFormErrors);
        let catogoryData = {
            categoryId: value[value?.length - 1]?.value
        };
        getProductList(catogoryData)
    }

    useEffect(() => {
        if(editForm.categoryId?.value && editForm.productId) {
            let params = {
                categoryId: editForm.categoryId?.value,
                productId: editForm.productId
            }
            fetchSlugValue(params);
        }
    }, [editForm.categoryId, editForm.productId])

    function fetchSlugValue(params) {
        const cloneEditForm = { ...editForm };
        if(!params?.productId) delete params?.productId;
        AxiosInstance(PORTS.REGION_SERVICE, "application/json")
            .post(`/deals/getHotDealsSlug`, params)
            .then((res) => {
                if (res.status === 200 && res.data.status === 200) {
                    cloneEditForm.slug = (res.data.data[0]?.slug);
                    setEditForm(cloneEditForm);
                }
            })
            .catch((er) => {
                return er;
            });
    }

    function removeFile(name) {
        if (editForm[name]) {
            URL.revokeObjectURL(editForm[name]);
        }
        setFiles({ ...files, [name]: null });
        setEditForm({ ...editForm, [name]: null });
    }

    function handleImageInput(e) {
        const { name, files: inputFiles } = e.target;
        if (inputFiles[0].size > 1024 * 1024 * 3) {
            showNotification({
                type: "error",
                message: "There were few errors",
                description: "Please upload file less than 3MB size",
            });
            return;
        } else {
            if (editForm[name]) {
                URL.revokeObjectURL(editForm[name]);
            }
            const url = URL.createObjectURL(inputFiles[0]);
            setFiles({ ...files, [name]: inputFiles[0] });
            setEditForm({ ...editForm, [name]: url });
        }
    }

    function handlePrd(e) {
        const { name, value } = e.target;
        const cloneEditForm = { ...editForm };
        cloneEditForm.productId = value
        setEditForm(cloneEditForm);
    }

    return (
        <div className={styles.management_contents}>
            <div className={styles.management_header}>
                <h1>
                    <img
                        src={BlackLeftArrow}
                        alt=""
                        className="cursor-pointer"
                        onClick={() => history.push("/Content")}
                    />
                    Hot Deal Page Management
                </h1>
            </div>
            {isLoading === true ? (
                <div
                    style={{
                        marginLeft: "50vh",
                        marginTop: "10vh",
                    }}
                >
                    <tr className="bg-transparent">
                        <td colSpan="100%">
                            <LoadingIndicator loadingText="Fetching landing page details..." />
                        </td>
                    </tr>
                </div>
            ) : (
                <div>
                    <div
                        className={`${styles.management_header} ${styles.banner_city_list}`}
                    >
                        <div className={`${styles.banner_citys}`}>
                            <select
                                className={"form-control " + styles.form_control}
                                name="regionId"
                                error={formErrors.regionId}
                                value={regionId}
                                onChange={(e) => handleRegion(e)}
                                style={{ cursor: "pointer" }}
                            >
                                {regions.map((region) => (
                                    <>
                                        <option value={region._id} label={region.name}>
                                            {region.name}
                                        </option>
                                    </>
                                ))}
                            </select>
                            <FormErrorText error={formErrors.regionId} />
                            <div className={`${styles.banner_citys_update}`}>
                                {editForm?.userName && (
                                    <p style={{ textTransform: "capitalize" }}>
                                        Last updated by: <span>{editForm?.userName}</span>
                                    </p>
                                )}
                                {editForm?.updatedAt && (
                                    <p>
                                        Last updated on:{" "}
                                        <span>
                                            {moment(editForm?.updatedAt).format(
                                                "DD/MM/YYYY | hh:mm A"
                                            )}
                                        </span>
                                    </p>
                                )}
                            </div>
                        </div>

                        <div className={styles.management_contents_upload}>
                            <div className={`${styles.onoffswitch} d-inline-block align-top`}>
                                <input
                                    type="checkbox"
                                    name="enable"
                                    className={styles.onoffswitch_checkbox}
                                    onChange={handleFormInput}
                                    checked={editForm?.enable}
                                    id="statusSwitch"
                                />
                                <label
                                    className={styles.onoffswitch_label}
                                    htmlFor="statusSwitch"
                                >
                                    <span className={styles.onoffswitch_inner}></span>
                                    <span className={styles.onoffswitch_switch}></span>
                                </label>
                            </div>
                            <ButtonComponent
                                className="rnd mr-3 outline_cancel_btn"
                                attributes={{
                                    onClick: () => history.push(`/Content`),
                                }}
                            >
                                Cancel
                            </ButtonComponent>
                            <ButtonComponent
                                className="rnd"
                                attributes={{
                                    onClick: submit,
                                }}
                            >
                                {isUpdating? "Saving...":"Save"}
                            </ButtonComponent>
                        </div>
                    </div>

                    <div
                        className={`${styles.management_details} ${styles.landing_category_management}`}
                    >
                        <div
                            className={styles.management_details_contents}
                        >
                            <div className="row">
                                <div className="col-lg-12 mb-4">
                                    <div className={styles.management_details_form}>
                                        <label for="">
                                            Page title
                                            <span
                                                className="text-danger"
                                                style={{ fontSize:"20px",bottom:-8,position:"relative" }}
                                            >
                                                *
                                            </span>
                                        </label>
                                        <InputComponent
                                            className={`form-control form-control-lg ${styles.form_control}`}
                                            error={formErrors.title}
                                            formControl={{
                                                placeholder: "",
                                                name: "title",
                                                value: editForm?.title,
                                                onChange: (e) => {
                                                    if (!minChar.test(e.target.value)) {
                                                        if (!formErrors.title) {
                                                            formErrors.title = {};
                                                        }
                                                        formErrors.title = "Enter minimum 3 characters";
                                                    } else {
                                                        if (formErrors.title) {
                                                            formErrors.title = "";
                                                        }
                                                    }

                                                    handleFormInput(e);
                                                },
                                            }}
                                        />
                                        <FormErrorText error={formErrors.title} />
                                    </div>
                                </div>

                                <div className="col-lg-12 mb-4">
                                    <label className={commonstyles.secondry_title}>
                                        Discount
                                        {/* <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                                            *
                                        </span> */}
                                    </label>
                                    <InputComponent
                                        // error={formErrors.discount}
                                        className="form-control-lg mb-3"
                                        formControl={{
                                            placeholder: "Type discount",
                                            name: "discount",
                                            value: editForm?.discount,
                                            onChange: handleFormInput,
                                        }}
                                    />
                                    {/* <FormErrorText error={formErrors.discount} /> */}
                                </div>

                                <div className="col-lg-12 mb-4">
                                    <div className={styles.management_details_form}>
                                        <label for="">
                                            Description
                                            <span
                                                className="text-danger"
                                                style={{ fontSize:"20px",bottom:-8,position:"relative" }}
                                            >
                                                *
                                            </span>
                                        </label>
                                        <div class="form-group">
                                            <textarea
                                                name="description"
                                                value={editForm?.description}
                                                onChange={(e) => {
                                                    if (!minChar.test(e.target.value)) {
                                                        if (!formErrors.description) {
                                                            formErrors.description = {};
                                                        }
                                                        formErrors.description = "Enter minimum 3 characters";
                                                    } else {
                                                        if (formErrors.description) {
                                                            formErrors.description = "";
                                                        }
                                                    }
                                                    handleFormInput(e);
                                                }}
                                                class="form-control"
                                                id="exampleFormControlTextarea1" rows="3"
                                            >
                                            </textarea>
                                        </div>
                                        <FormErrorText error={formErrors.description} />
                                    </div>
                                </div>

                                <div className="col-lg-6 mb-4">
                                    <div className={styles.management_details_form}>
                                        <label className={commonstyles.secondry_title}>
                                            Start Date
                                            <span className={`${commonstyles.asterik} text-danger`}>
                                                *
                                            </span>
                                        </label>
                                        <DatePicker
                                            disabledDate={disabledDate}
                                            value={
                                                editForm?.startDate
                                                    ? moment(editForm?.startDate, "YYYY-MM-DD")
                                                    : ""
                                            }
                                            onChange={(dateString) =>
                                                onChangeDateHandler(dateString, "startDate")
                                            }
                                            style={{
                                                width: "100%",
                                                border: formErrors.startDate ? "#ff7575" : "1px solid #d9d9d9",
                                                boxShadow: formErrors.startDate ? "0 0 3px 1px #ff7575" : ''
                                            }}
                                        />
                                        <FormErrorText error={formErrors.startDate} />
                                    </div>
                                </div>

                                <div className="col-lg-6 mb-4">
                                    <div className={styles.management_details_form}>
                                        <label className={commonstyles.secondry_title}>
                                            Start Time (EDT)
                                            <span className={`${commonstyles.asterik} text-danger`}>
                                                *
                                            </span>
                                        </label>

                                        <TimePicker
                                            placeholder="01:00"
                                            value={
                                                editForm?.startTime &&
                                                moment(editForm?.startTime, "HH:mm")
                                            }
                                            onChange={onChangeStartTimeHandler}
                                            format={format}
                                            style={{
                                                width: "100%",
                                                border: formErrors.startTime ? "#ff7575" : "1px solid #d9d9d9",
                                                boxShadow: formErrors.startTime ? "0 0 3px 1px #ff7575" : ''
                                            }}
                                        />
                                        <FormErrorText error={formErrors.startTime} />
                                    </div>
                                </div>

                                <div className="col-lg-6 mb-4">
                                    <div className={styles.management_details_form}>
                                        <label className={commonstyles.secondry_title}>
                                            End Date
                                            <span className={`${commonstyles.asterik} text-danger`}>
                                                *
                                            </span>
                                        </label>
                                        <DatePicker
                                            disabledDate={disabledDate}
                                            value={
                                                editForm?.endDate
                                                    ? moment(editForm?.endDate, "YYYY-MM-DD")
                                                    : ""
                                            }
                                            onChange={(dateString) =>
                                                onChangeDateHandler(dateString, "endDate")
                                            }
                                            style={{
                                                width: "100%",
                                                border: formErrors.endDate ? "#ff7575" : "1px solid #d9d9d9",
                                                boxShadow: formErrors.endDate ? "0 0 3px 1px #ff7575" : ''
                                            }}
                                        />
                                        <FormErrorText error={formErrors.endDate} />
                                    </div>
                                </div>

                                <div className="col-lg-6 mb-4">
                                    <div className={styles.management_details_form}>
                                        <label className={commonstyles.secondry_title}>
                                            End Time (EDT)
                                            <span className={`${commonstyles.asterik} text-danger`}>
                                                *
                                            </span>
                                        </label>

                                        <TimePicker
                                            placeholder="01:00"
                                            value={
                                                editForm?.endTime &&
                                                moment(editForm?.endTime, "HH:mm")
                                            }
                                            onChange={onChangeEndTimeHandler}
                                            format={format}
                                            style={{
                                                width: "100%",
                                                border: formErrors.endTime ? "#ff7575" : "1px solid #d9d9d9",
                                                boxShadow: formErrors.endTime ? "0 0 3px 1px #ff7575" : ''
                                            }}
                                        />
                                        <FormErrorText error={formErrors.endTime} />
                                    </div>
                                </div>

                                <div className="col-lg-6 mb-4">
                                    <div className={styles.management_details_form}>
                                        <label className={commonstyles.secondry_title}>
                                            Categories
                                            <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                                                *
                                            </span>
                                        </label>
                                        <ProductCategoryTreeSelectComponent
                                            value={editForm.categoryId}
                                            category={categoryNames}
                                            handleChange={(v) => searchSelectOnChange(v, 'categoryId')}
                                        />
                                        <FormErrorText error={formErrors.categoryId} />
                                    </div>
                                </div>

                                <div className="col-lg-6 mb-4">
                                    <div className={styles.management_details_form}>
                                        <label className={commonstyles.secondry_title}>
                                            Product
                                            <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                                                *
                                            </span>
                                        </label>
                                        <select
                                            className={"form-control " + styles.form_control}
                                            name="productId"
                                            error={formErrors.productId}
                                            value={editForm.productId}
                                            onChange={(e) => handlePrd(e)}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <option value=""></option>
                                            {productArr?.length > 0 && productArr?.map((prd) => (
                                                <>
                                                    <option value={prd._id} label={prd.name}>
                                                        {prd.name}
                                                    </option>
                                                </>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                {/* Slug */}
                                <div className="col-lg-12 mb-4">
                                    <label className={commonstyles.secondry_title}>
                                        Slug
                                        <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                                            *
                                        </span>
                                    </label>
                                    <InputComponent
                                        // error={formErrors.slug}
                                        className="form-control-lg"
                                        formControl={{
                                            placeholder: "Type slug",
                                            name: "slug",
                                            value: editForm?.slug,
                                            disabled: true,
                                            onChange: (e) => {
                                                if (!minChar.test(e.target.value)) {
                                                    if (!formErrors.slug) {
                                                        formErrors.slug = {};
                                                    }
                                                    formErrors.slug = "Enter minimum 3 characters";
                                                } else {
                                                    if (formErrors.slug) {
                                                        formErrors.slug = "";
                                                    }
                                                }
                                                handleInputSlug(e);
                                            },
                                        }}
                                    />
                                    {/* <FormErrorText error={formErrors.slug} /> */}
                                </div>
                                {/* Slug ends */}

                                <div className="col-lg-6 mb-4">
                                    <div className={commonstyles.management_details_contents}>
                                        <div className={styless.upload_img_header}>
                                            <label className={commonstyles.secondry_title}>
                                                Image
                                                <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                                                    *
                                                </span>
                                            </label>
                                        </div>

                                        <div className={commonstyles.management_details_contents}>
                                            <div className={styless.upload_img_header}></div>
                                            <div
                                                className={`${commonstyles.management_details_form} ${styless.add_featureImage_card}`}
                                            >
                                                {editForm.image ? (
                                                    <>
                                                        <ImagePreviewChip
                                                            url={editForm.image}
                                                            className={styless.added_image}
                                                            width="60px"
                                                            height="60px"
                                                            handleClose={() => removeFile("image")}
                                                        />
                                                    </>
                                                ) : (
                                                    <div
                                                        className={` ${styless.upload_img} ${styless.storeaddupload_img} text-center`}
                                                    >
                                                        <img src={Exclusion} alt="" />
                                                        <div>
                                                            <label
                                                                htmlFor="categoryFileInput"
                                                                className={`${styless.btn} ${styless.storeupload_img} px-2 `}
                                                            >
                                                                Add file
                                                                <InputComponent
                                                                    error={formErrors.image}
                                                                    className="form-control-lg"
                                                                    formControl={{
                                                                        type: "file",
                                                                        name: "image",
                                                                        style: { cursor: "pointer" },
                                                                        accept: "image/jpeg, image/png, image/svg+xml,image/gif, image/webp",
                                                                        id: "categoryFileInput",
                                                                        onChange: (e) => handleImageInput(e),
                                                                    }}
                                                                />
                                                                <FormErrorText error={formErrors.image} />
                                                            </label>
                                                        </div>
                                                        <div className={styless.upload_drop_file}>
                                                            <span>or drop a file to Upload</span>
                                                        </div>
                                                        {/* <span>or drop a file to Upload</span> */}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}