import React, { useState, useEffect } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import styles from "../../../styles/PaymentNew.module.css";
import commonstyles from "../../../styles/Management_Common.module.css";
import BlackLeftArrow from "../../../assets/images/black_left_arrow.svg";
import { useHistory } from "react-router";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import { notification, Select } from "antd";
import ButtonComponent from "../../modules/ReusableComponent/ButtonComponent";
import { lte } from "lodash";
import { runValidationChecks } from "../../../utils";
import InputComponent from "../../modules/ReusableComponent/InputComponent";
import SelectComponent from "../../modules/ReusableComponent/SelectComponent";
import FormErrorText from "../../modules/ProductManagement/FormErrorText";
import {
  alphaNum,
  alphanumericwithdashHyphen,
  alphanumericwithdashHyphenWithLimit,
  minChar,
  minCharLimit,
  minCharLimit1,
  onlyAlphabets,
  onlyNumbers,
  userNameWithSpace,
} from "../../../utils/RegularExp";
import styless from "../../../styles/AddProduct.module.css";
import LoadingIndicator from "../../modules/ReusableComponent/LoadingIndicator";
import { Tabs } from "antd";
import { isObject, isString, isEqual } from "lodash";
import validationRules from "../../../utils/validationRules";
import WYSIWYGEditor from "../../modules/ReusableComponent/WYSIWYGEditor";
import SearchComponent from "../SearchManagement/SearchComponent";
import ImagePreviewChip from "../../modules/ProductManagement/ImagePreviewChip";
import exclusionIcon from "../../../assets/images/exclusion.svg";
import bannerimgdelete from "../../../assets/images/Iconly-Bulk-Close Square.svg";

const { Option } = Select;

const initialFormState = {
  enable: 1,
  pageTitle: "",
  slug: "",
  seoMarkup: "",
  content: "",
  metaTitle: "",
  metaDescription: "",
  metaKeywords: "",
};

const rules = {
  pageTitle: [validationRules.required],
  slug: [validationRules.required],
  content: [validationRules.required],
  pageType: [validationRules.required]
};

export default function AddCMS(props) {
  let permissionsArr = JSON.parse(localStorage.getItem("permissions")) || [];
  const { TabPane } = Tabs;
  const history = useHistory();
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState({ details: false, update: false });
  const [editForm, setEditForm] = useState(initialFormState);
  // const [addForm, setAddForm] = useState([seoNew]);//seo
  //   const [faqForm, setfaqForm] = useState([]);
  const [pageList, setPageList] = useState([]);
  const [regionList, setRegionList] = useState([]);
  const [pagesTitle, setPagesTitle] = useState();
  const [pageId, setPageId] = useState();
  const [currentTab, setCurrentTab] = useState("FAQ");
  const [isLoading, setIsLoading] = useState(true);
  const [chagedId, setChagedId] = useState([]);
  const [selectedId, setSelectId] = useState("");
  const { state } = history.location;
  const [isLoadingCMS, setIsLoadingCMS] = useState(true);
  const [images, setImages] = useState([]);

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("files", file);
            AxiosInstance(PORTS.CATALOG_SERVICE, "application/json")
              .post(`/admin/uploadImages`, body)
              .then((res) => {
                if (res.data.status === 200) {
                  resolve({
                    default: `${res.data?.data[0]?.link}`,
                  });
                } else if (res.data.status === 400) {
                  showNotification({
                    type: "error",
                    message: "Adding CMS failed",
                    description: res.data?.msg || "Error occured",
                  });
                }
              })
              .catch((er) => {
                showNotification({
                  type: "error",
                  message: "Adding CMS failed",
                  description: "Error occured",
                });
              })
              .finally(() => {
                setLoading({ details: false, update: false });
              });
          });
        });
      },
    };
  }

  // *** INPUT HANDLE CHANGE *** //
  function handleFormInput(e, indx) {
    let { name, value, checked } = e.target;
    if (name === "pageTitle") {
      value = value.charAt(0).toUpperCase() + value.slice(1);
      if (!e.target.value.match(userNameWithSpace) || !e.target.value === "") {
        setFormErrors({
          ...formErrors,
          [name]: "Enter only alphabet and minimum 3",
        });
      } else {
        setFormErrors({ ...formErrors, [name]: "" });
      }
    }
    // if (name === "slug") {
    //   value = value.trim();
    //   if (!value.match(alphanumericwithdashHyphenWithLimit)) {
    //     setFormErrors({
    //       ...formErrors,
    //       slug: "Enter min 3 letters or Incorrect match",
    //     });
    //   } else {
    //     setFormErrors({ ...formErrors, [name]: "" });
    //   }
    // }
    // if (name === "slug") {
    //   value = value.trim();
    
    //   // Check if the value matches the specific URL to skip validation
    //   if (value === "https://blog.damroindia.com/") {
    //     setFormErrors({ ...formErrors, [name]: "" }); // Clear errors
    //   } else if (!value.match(alphanumericwithdashHyphenWithLimit)) {
    //     // Perform validation only if the URL doesn't match
    //     setFormErrors({
    //       ...formErrors,
    //       slug: "Enter min 3 letters or Incorrect match",
    //     });
    //   } else {
    //     setFormErrors({ ...formErrors, [name]: "" });
    //   }
    // }
    if (name === "slug") {
      value = value.trim();
      console.log("value",value);
      
      // Check if the value matches the specific URL to skip validation
      if (value == "https://blog.damroindia.com/" || value == "https://blog.damroindia.com") {
        // Clear any errors for this field
        setFormErrors({ ...formErrors, [name]: "" });
      } else if (!value.match(alphanumericwithdashHyphenWithLimit)) {
        // Perform validation only if the URL doesn't match the specific URL
        setFormErrors({
          ...formErrors,
          slug: "Enter min 3 letters or Incorrect match",
        });
      } else {
        // Clear errors if the value passes validation
        setFormErrors({ ...formErrors, [name]: "" });
      }
    }
    
    // if (name === "metaTitle") {
    //     value = value.trim();
    // }
    // if (name === "metaDescription") {
    //     value = value.trim();
    // }
    // if (name === "metaKeywords") {
    //     value = value.trim();
    // }
    const updatedValues = { ...editForm };
    if (["enable"].includes(name)) {
      updatedValues[name] = checked;
    } else {
      updatedValues[name] = value;
    }
    setEditForm(updatedValues);
  }

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  const handleImageInput = (e) => {
    const { name, files: inputFiles } = e.target;
    let cloneImages = [...images];
    const file_name = inputFiles[0].name.toLowerCase();
    if (name) {
      if (!file_name.match(/\.(jpg|jpeg|png|webp|gif)$/)) {
        showNotification({
          type: "error",
          message: "There were few errors",
          description: "Please upload only jpg,jpeg and png format images",
        });
        return;
      } else if (inputFiles[0].size > 1024 * 1024 * 3) {
        showNotification({
          type: "error",
          message: "There were few errors",
          description: "Please upload file less than 3MB size",
        });
        return;
      } else {
        if (images?.length > 4) {
          showNotification({
            type: "error",
            message: "There were few errors",
            description:
              "You can't able to add more than five image for this banner",
          });
          return;
        } else {
          cloneImages.push(inputFiles[0]);
          setImages(cloneImages);
        }
      }
    }
  };

  function removeImages(img, img_indx) {
    let cloneImages = [...images];
    cloneImages = cloneImages?.filter((x, indx) => indx !== img_indx);
    setImages(cloneImages);
  }

  function handleSelectChanges(value, name) {
    if (name === "pageType") {
      value = parseInt(value);
      if (isNaN(value)) {
        setFormErrors({
          ...formErrors,
          [name]: "Please select options available",
        });
      } else {
        setFormErrors({ ...formErrors, [name]: "" });
      }
      const updatedValues = { ...editForm };

      updatedValues.pageType = value;

      setEditForm(updatedValues);
    }
  }

  function submit(e) {
    let data = JSON.parse(JSON.stringify(editForm));
    if (data.pageTitle) {
      if (!userNameWithSpace.test(data.pageTitle) || !data.pageTitle === "") {
        setFormErrors({
          ...formErrors,
          pageTitle: "Enter only alphabet and minimum 3",
        });
        return false;
      } else {
        setFormErrors({ ...formErrors, pageTitle: "" });
      }
    }
    // if (data.slug) {
    //   if (
    //     !alphanumericwithdashHyphenWithLimit.test(data.slug) ||
    //     !data.slug === ""
    //   ) {
    //     setFormErrors({
    //       ...formErrors,
    //       slug: "Enter min 3 letters or Incorrect match",
    //     });
    //     return false;
    //   } else {
    //     setFormErrors({ ...formErrors, slug: "" });
    //   }
    // }
    if (data.slug) {
      // Skip validation for the specific URL
      if (
        data.slug === "https://blog.damroindia.com/" || 
        data.slug === "https://blog.damroindia.com"
      ) {
        // Clear any slug errors and continue
        setFormErrors({ ...formErrors, slug: "" });
      } else if (
        !alphanumericwithdashHyphenWithLimit.test(data.slug) || 
        data.slug === ""
      ) {
        setFormErrors({
          ...formErrors,
          slug: "Enter min 3 letters or Incorrect match",
        });
        return false;
      } else {
        setFormErrors({ ...formErrors, slug: "" });
      }
    }
    data.seoMarkup = JSON.stringify(editForm.seoMarkup);
    data.enable = editForm.enable ? 1 : 0;
    const result = runValidationChecks(rules, undefined, data);
    if (Object.keys(result).length) {
      setFormErrors(result);
      showNotification({
        type: "warning",
        message: "There were few errors",
        description: "Resolve the errors and try again",
      });
      return;
    }
    setLoading({ ...loading, update: true });
    const formData = new FormData();
    if (editForm.seoMarkup === "") delete editForm.seoMarkup;
    if (editForm.metaTitle === "") delete editForm.metaTitle;
    if (editForm.metaDescription === "") delete editForm.metaDescription;
    if (editForm.metaKeywords === "") delete editForm.metaKeywords;
    formData.append("data", JSON.stringify(editForm));
    images.forEach((field, indx) => {
      if (field?.name) {
        formData.append("files", field);
      }
    });
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    AxiosInstance(PORTS.DASHBOARD_SERVICE, "application/json")
      .post(`/admin/cms/addpage`, formData, config)
      .then((res) => {
        if (res.data.status === 200) {
          showNotification({
            type: "success",
            message: "Success",
            // description : "successfully added"
            description: "CMS added successfully",
          });
          history.push({
            pathname: "/Content/CMS",
            state: state,
          });
          setEditForm(initialFormState);
        } else if (res.data.status === 400) {
          showNotification({
            type: "error",
            message: "Adding CMS failed",
            description: res.data?.msg || "Error occured",
          });
        }
      })
      .catch((er) => {
        showNotification({
          type: "error",
          message: "Adding CMS failed",
          description: "Error occured",
        });
      })
      .finally(() => {
        setLoading({ details: false, update: false });
      });
  }

  return (
    <div className={styles.management_contents}>
      <div className={styles.management_header}>
        <h1>
          <img
            src={BlackLeftArrow}
            alt=""
            className="cursor-pointer"
            onClick={() => {
              history.push({
                pathname: `/Content/CMS`,
                state: state,
              });
            }}
          />
          Add CMS
        </h1>
      </div>
      <div className={styles.management_details}>
        <div className={styles.management_details_contents}>
          <div className="row">
            <div className="col-lg-12 mb-4 d-flex justify-content-end">
              <ButtonComponent
                className="rnd"
                attributes={{
                  onClick: submit,
                }}
              >
                {loading?.update ? "Saving..." : "Save"}
              </ButtonComponent>
            </div>

            <div className="col-lg-12 mb-4">
              <div className={styles.management_details_form}>
                <label for="">
                  Page title
                  <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                    *
                  </span>
                </label>
                <InputComponent
                  className={`form-control form-control-lg ${styles.form_control}`}
                  error={formErrors.pageTitle}
                  formControl={{
                    placeholder: "",
                    name: "pageTitle",
                    value: editForm?.pageTitle,
                    onChange: (e) => handleFormInput(e),
                  }}
                />
                <FormErrorText error={formErrors.pageTitle} />
              </div>
            </div>
            {/* Slug */}
            <div className="col-lg-12 mb-4">
              <label className={commonstyles.secondry_title}>
                Slug
                <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                  *
                </span>
              </label>
              <InputComponent
                error={formErrors.slug}
                className="form-control-lg"
                formControl={{
                  placeholder: "Type slug",
                  name: "slug",
                  value: editForm?.slug,
                  onChange: (e) => handleFormInput(e),
                }}
              />
              <FormErrorText error={formErrors.slug} />
            </div>
            <div className="col-lg-12 mb-4">
              <label className={commonstyles.secondry_title}>
                Page type
                <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                  *
                </span>
              </label>
              {/* <SelectComponent
                className={`form-control form-control-md ${styles.form_control}`}
                error={formErrors.pageType}
                attributes={{
                  name: "pageType",
                  value: editForm?.pageType,
                  onChange: (e) => handleSelectChanges(e),
                }}
              >
                <option value="">Select an option</option>
                <option value="1">Company</option>
                <option value="2">Help</option>
              </SelectComponent> */}

              <Select defaultValue="Select an option"
                className={`${styles.fullwidth}`}
                error={formErrors.pageType}
                // attributes={{
                // name="pageType"
                onChange={(e) => handleSelectChanges(e, "pageType")}
              // }}
              >
                <option value="1" label="Company">Company</option>
                <option value="2" label="Help">Help</option>
              </Select>
              <FormErrorText error={formErrors.pageType} />


            </div>
          </div>
          {/* Slug ends */}
          {/* Schema Markup */}
          <div className="row">
            <div className="col-lg-12 mb-4">
              <label className={commonstyles.secondry_title}>
                Schema Markup
              </label>
              <textarea
                className={`${commonstyles.form_control} 
                                    form-control form-control-lg `}
                placeholder="Type schema markup"
                rows="4"
                autoComplete="off"
                name="seoMarkup"
                value={editForm?.seoMarkup}
                onChange={handleFormInput}
              />
              {/*  <FormErrorText error={formErrors.description} /> */}
            </div>
            {/* Schema Markup ends */}
            <div className="col-lg-12 mb-4">
              <div
                className={styles.management_details_form}
                style={{
                  boxShadow: formErrors.content ? "0 0 3px 1px #ff7575" : "",
                }}
              >
                <label for="">
                  Content
                  <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                    *
                  </span>
                </label>
                {/* https://damroimages.s3.ap-south-1.amazonaws.com/screenshot-nimbus-capture-2022_1646638194346.png */}
                <CKEditor
                  config={{
                    extraPlugins: [uploadPlugin],
                  }}
                  editor={ClassicEditor}
                  data={editForm?.content || ""}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    console.log(data);
                    const values = editForm;
                    values.content = data;
                    setEditForm(values);
                  }}
                />
                <FormErrorText error={formErrors.content} />
              </div>
            </div>
            <div className="col-lg-12 mb-4">
              <div className="row">
                <div className="col-lg-6 mb-4">
                  <div className="d-flex align-items-right justify-content-between">
                    <label className={commonstyles.secondry_title}>
                      Add Image{" "}
                      {/* <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                                            *
                                        </span> */}
                    </label>
                  </div>
                  <div className={`${commonstyles.banner_img_box_outline}`}>
                    <div
                      className={`${commonstyles.management_details_contents} mb-2 mt-2`}
                    >
                      <div
                        className={`${commonstyles.management_details_form} ${commonstyles.add_image_card} d-flex align-items-center justify-content-center`}
                      >
                        <input
                          name="image"
                          type="file"
                          accept="image/*"
                          id="imageInput"
                          value=""
                          onChange={(e) => handleImageInput(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 mb-4">
                  <label className={commonstyles.secondry_title}>
                    Preview image
                  </label>
                  <div className={`${commonstyles.banner_img_box_outline} `}>
                    {images?.length > 0 &&
                      images?.map((imgs, index) => (
                        <div className={`${commonstyles.banner_img_preview}`}>
                          <img
                            class={commonstyles.previewImgs}
                            key={index}
                            src={imgs?.name ? URL.createObjectURL(imgs) : imgs}
                            alt={imgs?.name}
                          />
                          <img
                            onClick={() => removeImages(imgs, index)}
                            class={commonstyles.previewImgsdeleteicon}
                            src={bannerimgdelete}
                            alt="delete"
                          />
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.management_details_contents}>
        <h4 className="theme-form-section-title">
          Search Engine Optimization
        </h4>
        <div className="row">
          <div className="col-lg-6 mb-4">
            <div className={styles.management_details_form}>
              <label for="">Meta Title</label>
              <InputComponent
                className={`form-control form-control-lg ${styles.form_control}`}
                error={formErrors.metaTitle}
                formControl={{
                  placeholder: "",
                  name: "metaTitle",
                  value: editForm?.metaTitle,
                  onChange: (e) => handleFormInput(e),
                }}
              />
              <FormErrorText error={formErrors.metaTitle} />
            </div>
          </div>
          <div className="col-lg-6 mb-4">
            <div className={styles.management_details_form}>
              <label for="">Meta Keywords</label>
              <InputComponent
                className={`form-control form-control-lg ${styles.form_control}`}
                error={formErrors.metaKeywords}
                formControl={{
                  placeholder: "",
                  name: "metaKeywords",
                  value: editForm?.metaKeywords,
                  onChange: (e) => handleFormInput(e),
                }}
              />
              <FormErrorText error={formErrors.metaKeywords} />
            </div>
          </div>
          <div className="col-lg-12 mb-4">
            <div className={styles.management_details_form}>
              <label for="">Meta Description</label>
              <textarea
                className={`form-control form-control-lg ${styles.form_control}`}
                error={formErrors.metaDescription}
                // formControl={{
                placeholder=""
                name="metaDescription"
                value={editForm?.metaDescription}
                onChange={(e) => handleFormInput(e)}
              // }}
              />
              <FormErrorText error={formErrors.metaDescription} />
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}
