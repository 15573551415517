import React, { useState, useEffect } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import styles from "../../../styles/PaymentNew.module.css";
import commonstyles from "../../../styles/Management_Common.module.css";
import BlackLeftArrow from "../../../assets/images/black_left_arrow.svg";
import { useHistory } from "react-router";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import { notification, Select } from "antd";
import ButtonComponent from "../../modules/ReusableComponent/ButtonComponent";
import { lte } from "lodash";
import { runValidationChecks } from "../../../utils";
import InputComponent from "../../modules/ReusableComponent/InputComponent";
import SelectComponent from "../../modules/ReusableComponent/SelectComponent";
import FormErrorText from "../../modules/ProductManagement/FormErrorText";
import {
  alphaNum,
  alphanumericwithdashHyphen,
  alphanumericwithdashHyphenWithLimit,
  minChar,
  minCharLimit,
  minCharLimit1,
  onlyAlphabets,
  userNameWithSpace,
  emailRegx,
  phoneRegx
} from "../../../utils/RegularExp";
import styless from "../../../styles/AddProduct.module.css";
import LoadingIndicator from "../../modules/ReusableComponent/LoadingIndicator";
import { Tabs } from "antd";
import { isObject, isString, isEqual } from "lodash";
import validationRules from "../../../utils/validationRules";
import WYSIWYGEditor from "../../modules/ReusableComponent/WYSIWYGEditor";
import SearchComponent from "../SearchManagement/SearchComponent";
import bannerimgdelete from "../../../assets/images/Iconly-Bulk-Close Square.svg";

const initialFormState = {
  enable: false,
  pageTitle: "",
  slug: "",
  seoMarkup: "",
  content: "",
  gallery: [],
  // seo : [],
  metaTitle: "",
  metaDescription: "",
  metaKeywords: "",
  phone:"",
  email:"",
  bottomContent:"",
  description:""
};
//   const faqNew = {
//     question: "",
//     answer: "",
//   };
//   const faq = JSON.parse(JSON.stringify(faqNew));

const rules = {
  pageTitle: [validationRules.required],
  slug: [validationRules.required],
  content: [validationRules.required],
  // email: [validationRules.required],
  // phone: [validationRules.required],
  // description: [validationRules.required]
  //seo:[validationRules.required],
  /* seo: [
      (val) => {
        //console.log(val)
        if (Array.isArray(val)) {
          const errors = val.reduce(validateStockItem, {});
          if (Object.keys(errors).length) {
            return errors;
          }
          return true;
        }
      },
    ], */
  // faqForm: [
  //     (val) => {
  //       //console.log(val)
  //       if (Array.isArray(val)) {
  //         const errors = val.reduce(validateFaqformItem, {});
  //         if (Object.keys(errors).length) {
  //           return errors;
  //         }
  //         return true;
  //       }
  //     },
  //   ],
};

function validateStockItem(accumulator, currentStock, indx) {
  const error = {};
  /* if (!currentStock?.region?.id) {
    error["region"] = "Requried";
  } */
  ["name", "value"].forEach((field) => {
    if (!currentStock[field]) {
      error[field] = "Required";
    } /* else if (currentStock.oldPrice <= 0 && field !== "quantity") {
        error[field] = "Value should be greater than 0";
      } */
  });
  if (Object.keys(error).length) {
    accumulator[indx] = error;
  }
  return accumulator;
}


export default function CMSManagementComponent(props) {
  let permissionsArr = JSON.parse(localStorage.getItem("permissions")) || [];
  const { TabPane } = Tabs;
  const history = useHistory();
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState({ details: false, update: false });
  const [editForm, setEditForm] = useState(initialFormState);
  // const [addForm, setAddForm] = useState([seoNew]);//seo
  //   const [faqForm, setfaqForm] = useState([]);
  const [pageList, setPageList] = useState([]);
  const [regionList, setRegionList] = useState([]);
  const [pagesTitle, setPagesTitle] = useState();
  const [pageId, setPageId] = useState();
  const [currentTab, setCurrentTab] = useState("FAQ");
  const [isLoading, setIsLoading] = useState(true);
  const [chagedId, setChagedId] = useState([]);
  const [selectedId, setSelectId] = useState("");
  const { state } = history.location;
  const [isLoadingCMS, setIsLoadingCMS] = useState(true);
  const [images, setImages] = useState([]);
  const [impimages, setimpImages] = useState([]);
  // *** INPUT FAQ CHANGE *** //
  //    function handlefaqFormChange(e, indx) {
  //     let { name, value } = e.target;
  //     console.log(name, value);

  //     faqForm[indx][name] = value;
  //     setfaqForm([...faqForm]);
  //   }

  useEffect(() => {
    const { state } = history.location;
    // console.log("state",state);
    setIsLoadingCMS(true);
    if (state) {
      getPageList(state);
    } else {
      getPageList("614b392930b5d30acc92666d");
    }
    // getPageList("");
  }, []);
  /**
   * @author Rajkumar
   * @param {id}
   * To get page list
   */
  function getPageList(id) {
    // console.log("page list", id);
    setIsLoading(true);
    AxiosInstance(PORTS.DASHBOARD_SERVICE, "application/json")
      .get(`/admin/cms/pageList`)
      .then((res) => {
        if (res.data.status === 200) {
          //   console.log(res.data.data);
          setPageList(res.data.data);
          if (id === "") {
            getCMSDetails(res.data.data[0]._id);
          } else {
            getCMSDetails(id);
          }
          //   setIsLoading(false);
          //   setTCForm(res.data.data.teams);
          //   setfaqForm(res.data.data.ccFaq);
        } else {
          showNotification({
            type: "Error",
            message: "Error",
            description: "Couldnt fetch required data",
          });
        }
      })
      .catch((er) => {
        // console.log(er);
        return er;
      })
      .finally(() => {
        setIsLoading(false);
        setLoading({ details: false, update: false });
      });
  }

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("files", file);
            AxiosInstance(PORTS.CATALOG_SERVICE, "application/json")
              .post(`/admin/uploadImages`, body)
              .then((res) => {
                if (res.data.status === 200) {
                  resolve({
                    default: `${res.data?.data[0]?.link}`,
                  });
                } else if (res.data.status === 400) {
                  showNotification({
                    type: "error",
                    message: "Adding CMS failed",
                    description: res.data?.msg || "Error occured",
                  });
                }
              })
              .catch((er) => {
                showNotification({
                  type: "error",
                  message: "Adding CMS failed",
                  description: "Error occured",
                });
              })
              .finally(() => {
                setLoading({ details: false, update: false });
              });
          });
        });
      },
    };
  }
  /**
   * @author Rajkumar
   * @param {id}
   * To get cms details
   */
  function getCMSDetails(id) {
    setSelectId(id);
    setChagedId(id);
    // console.log("change id", chagedId);
    setLoading({ details: true, update: false });
    setIsLoading(true);
    AxiosInstance(PORTS.DASHBOARD_SERVICE, "application/json")
      .get(`/admin/cms/pageDetail/${id}`)
      .then((res) => {
        // console.log(res)
        if (res.data.status === 200) {
          const val = res.data.data;
          // console.log("test",val.pageTitle)
          setPagesTitle(val.pageTitle);
          // setAddForm(val.seo)
          setPageId(val._id);
          setImages(val?.gallery);
          setimpImages(val?.galleryImplink)
          setEditForm(val);
          setIsLoading(false);
          setFormErrors("");
          setIsLoadingCMS(false);
        } else {
          showNotification({
            type: "Error",
            message: "Error",
            description: "Couldnt fetch required data",
          });
        }
      })
      .catch((er) => {
        return er;
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
        setLoading({ details: false, update: false });
      });
  }

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  // *** INPUT HANDLE CHANGE *** //
  function handleFormInput(e, indx) {
    let { name, value, checked } = e.target;
    // console.log(name, value);
    if(name==="phone"){
      value = value.trim();
      if (!value.match(phoneRegx)) {
        setFormErrors({
          ...formErrors,
          phone: "Enter valid phone number",
        });
      } else {
        setFormErrors({ ...formErrors, [name]: "" });
      }
    }
    if(name==="email"){
      value = value.trim();
      if (!value.match(emailRegx)) {
        setFormErrors({
          ...formErrors,
          email: "Enter valid email",
        });
      } else {
        setFormErrors({ ...formErrors, [name]: "" });
      }
    }
    if (name === "pageTitle") {
      value = value.charAt(0).toUpperCase() + value.slice(1);
      if (!e.target.value.match(userNameWithSpace) || !e.target.value === "") {
        setFormErrors({
          ...formErrors,
          [name]: "Enter only alphabet and minimum 3",
        });
      } else {
        setFormErrors({ ...formErrors, [name]: "" });
      }
    }
    if (name === "description") {
      if (!e.target.value === "") {
        setFormErrors({
          ...formErrors,
          [name]: "Enter some description",
        });
      } else {
        setFormErrors({ ...formErrors, [name]: "" });
      }
    }
    if (name === "slug") {
      value = value.trim();
      // alert(value.length>3 && "yes")
      if (value == "https://blog.damroindia.com/" || value == "https://blog.damroindia.com") {
        // Clear any errors for this field
        setFormErrors({ ...formErrors, [name]: "" });
      }
     else if (!value.match(alphanumericwithdashHyphenWithLimit)) {
        setFormErrors({
          ...formErrors,
          slug: "Enter min 3 letters or Incorrect match",
        });
      } else {
        setFormErrors({ ...formErrors, [name]: "" });
      }
      if (name === "metaTitle") {
        value = value.trim();
      }
      if (name === "metaDescription") {
        value = value.trim();
      }
      if (name === "metaKeywords") {
        value = value.trim();
      }
    }
    const updatedValues = { ...editForm };
    if (["enable"].includes(name)) {
      updatedValues[name] = checked;
    } else {
      updatedValues[name] = value;
    }
    // updatedValues[name] = value;
    setEditForm(updatedValues);
  }
  // *** VALIDATE FUNCTION *** //
  function validate(name, data) {
    data = data || {};
    const result = {};
    //Validate only field
    if (name) {
      const fieldRules = rules[name];
      if (Array.isArray(fieldRules)) {
        for (const rule in fieldRules) {
          const val = rule(data[name]);
          if (isString(val) || isObject(val)) {
            setFormErrors({ ...formErrors, [name]: val });
          } else if (name in formErrors) {
            const { name, ...restOfErros } = formErrors;
            setFormErrors(restOfErros);
          }
        }
      }
      //all data validation
    } else {
      for (const field in rules) {
        for (const rule of rules[field]) {
          const val = rule(data[field], data);
          if (typeof val === "string" || isObject(val)) {
            result[field] = val;
            break;
          }
        }
      }
    }
    return result;
  }

  const handleImageInput = (e) => {
    const { name, files: inputFiles } = e.target;
    let cloneImages = [...images];
    const file_name = inputFiles[0].name.toLowerCase();
    if (name) {
      if (!file_name.match(/\.(jpg|jpeg|png|gif)$/)) {
        showNotification({
          type: "error",
          message: "There were few errors",
          description: "Please upload only jpg,jpeg,png,gif  format images",
        });
        return;
      } else if (inputFiles[0].size > 1024 * 1024 * 3) {
        showNotification({
          type: "error",
          message: "There were few errors",
          description: "Please upload file less than 3MB size",
        });
        return;
      } else {
        if (images?.length > 4) {
          showNotification({
            type: "error",
            message: "There were few errors",
            description:
              "You can't able to add more than five image for this banner",
          });
          return;
        } else {
          cloneImages.push(inputFiles[0]);
          setImages(cloneImages);
        }
      }
    }
  };
  // important links banner image
   const handleimportantlinkImageInput = (e) => {
    const { name, files: inputFiles } = e.target;
    let cloneImages = [...impimages];
    const file_name = inputFiles[0].name.toLowerCase();
    if (name) {
      if (!file_name.match(/\.(jpg|jpeg|png|gif)$/)) {
        showNotification({
          type: "error",
          message: "There were few errors",
          description: "Please upload only jpg,jpeg,png,gif  format images",
        });
        return;
      } else if (inputFiles[0].size > 1024 * 1024 * 3) {
        showNotification({
          type: "error",
          message: "There were few errors",
          description: "Please upload file less than 3MB size",
        });
        return;
      } else {
        if (impimages?.length > 4) {
          showNotification({
            type: "error",
            message: "There were few errors",
            description:
              "You can't able to add more than five image for this banner",
          });
          return;
        } else {
          cloneImages.push(inputFiles[0]);
          setimpImages(cloneImages);
        }
      }
    }
  };

  function removeImages(img, img_indx) {
    let cloneImages = [...images];
    cloneImages = cloneImages?.filter((x, indx) => indx !== img_indx);
    setImages(cloneImages);
  }
  function removeimportantlinkImages(img, img_indx) {
    let cloneImages = [...impimages];
    cloneImages = cloneImages?.filter((x, indx) => indx !== img_indx);
    setimpImages(cloneImages);
  }

  function submit(e) {
    // if(pagesTitle==="faq" ? "faq" : "empty");
    // if(pagesTitle==="FAQ"){
    //     let data;
    //     data = faqForm.map((item) => {
    //         return {
    //             // _id: item._id,
    //             question: item.question,
    //             answer: item.answer,
    //         };
    //     });
    //     console.log(data)
    //     const result = runValidationChecks(
    //         { faqForm: rules.faqForm },
    //         undefined,
    //         { faqForm: data }
    //       );

    //     console.log(result)
    //     if (Object.keys(result).length) {
    //         //console.log(result);
    //         setFormErrors(result);
    //         showNotification({
    //         type: "warning",
    //         message: "There were few errors",
    //         description: "Resolve the errors and try again",
    //         });
    //         return;
    //     }

    // } else {
    // setFormErrors({});
    /* let hasError=false;
    setFormErrors({seo:[]});
    let formErrors={seo:[]}
    addForm.forEach((faq, ind) => {
      formErrors.seo[ind]={};
        if(!minCharLimit.test(faq.name)){
          hasError=true;
          formErrors.seo[ind].name = "Minimum 2 letters";
        }
        if(!minCharLimit.test(faq.value)){
          hasError=true;
          formErrors.seo[ind].value = "Minimum 2 letters";
        }
    });
    if(hasError){
        showNotification({
            type: "warning",
            message: "There were few errors",
            description: "Resolve the errors and try again",
          });
      setFormErrors({...formErrors});
      return 
    } */
    let data = JSON.parse(JSON.stringify(editForm));
    if (data.pageTitle) {
      if (!userNameWithSpace.test(data.pageTitle) || !data.pageTitle === "") {
        setFormErrors({
          ...formErrors,
          pageTitle: "Enter only alphabet and minimum 3",
        });
        return false;
      } else {
        setFormErrors({ ...formErrors, pageTitle: "" });
      }
    }
    if (data.description) {
      if (!data.description === "") {
        setFormErrors({
          ...formErrors,
          description: "Enter some description",
        });
        return false;
      } else {
        setFormErrors({ ...formErrors, description: "" });
      }
    }
    if (data.email) {
      if (!emailRegx.test(data.email) || !data.email === "") {
        setFormErrors({
          ...formErrors,
          email: "Enter valid email",
        });
        return false;
      } else {
        setFormErrors({ ...formErrors, email: "" });
      }
    }

    if (data.phone) {
      if (!phoneRegx.test(data.phone) || !data.phone === "") {
        setFormErrors({
          ...formErrors,
          phone: "Enter valid phone number",
        });
        return false;
      } else {
        setFormErrors({ ...formErrors, phone: "" });
      }
    }

    // if (data.slug) {
    //   if (
    //     !alphanumericwithdashHyphenWithLimit.test(data.slug) ||
    //     !data.slug === ""
    //   ) {
    //     setFormErrors({
    //       ...formErrors,
    //       slug: "Enter min 3 letters or Incorrect match",
    //     });
    //     return false;
    //   } else {
    //     setFormErrors({ ...formErrors, slug: "" });
    //   }
    // }
    if (data.slug) {
      // Skip validation for the specific URL
      if (
        data.slug === "https://blog.damroindia.com/" || 
        data.slug === "https://blog.damroindia.com"
      ) {
        // Clear any slug errors and continue
        setFormErrors({ ...formErrors, slug: "" });
      } else if (
        !alphanumericwithdashHyphenWithLimit.test(data.slug) || 
        data.slug === ""
      ) {
        setFormErrors({
          ...formErrors,
          slug: "Enter min 3 letters or Incorrect match",
        });
        return false;
      } else {
        setFormErrors({ ...formErrors, slug: "" });
      }
    }
   
    data.seoMarkup = JSON.stringify(editForm.seoMarkup);

    /* if (data.content) {
        if (!minChar.test(data.content) || !data.content === "") {
          setFormErrors({ ...formErrors, content: "Incorrect match" });
          return false;
        } else if (!data.content.length > 3) {
          setFormErrors({ ...formErrors, content: "Enter min 3 letters" });
          return false;
        } else {
          setFormErrors({ ...formErrors, content: "" });
        }
    } */
    // console.log(data);
    // data.seo=addForm;
    data.enable = editForm.enable ? 1 : 0;
    
    if(!data.metaTitle) delete editForm.metaTitle
    if(!data.metaDescription) delete editForm.metaDescription
    if(!data.metaKeywords) delete editForm.metaKeywords

    const result = runValidationChecks(rules, undefined, data);
    if (Object.keys(result).length) {
      setFormErrors(result);
      showNotification({
        type: "warning",
        message: "There were few errors",
        description: "Resolve the errors and try again",
      });
      return;
    }
    /* const formData = {
      question:data.question,
      answer:data.answer,
    } */

    delete editForm.createdAt;
    delete editForm.updatedAt;
    delete editForm.__v;
    delete editForm.userId;
    delete editForm.seo;

    setLoading({ ...loading, update: true });
    let remainingGalleryImg = [];
    images.forEach((field, indx) => {
      if (field?.link) {
        remainingGalleryImg.push(field);
      }
    });
    editForm.gallery = remainingGalleryImg;
    editForm.enable = editForm.enable ? 1 : 0;
    const formData = new FormData();
    formData.append("data", JSON.stringify(editForm));
    images.forEach((field, indx) => {
      if (field?.name) {
        formData.append("files", field);
      }
    });
    impimages.forEach((field, indx) => {
      if (field?.name) {
        formData.append("impfiles", field);
      }
    });
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    AxiosInstance(PORTS.DASHBOARD_SERVICE, "application/json")
      //    .post("/admin/cms/addpage", data)
      .post(
        `/admin/cms/${pageId ? "pageUpdate " : "addpage"}`,
        formData,
        config
      )
      .then((res) => {
        //  console.log(res.data);
        if (res.data.status === 200) {
          showNotification({
            type: "success",
            message: "Success",
            // description : "successfully added"
            description: pageId
              ? "CMS updated successfully"
              : "CMS added successfully",
          });
          //setFilter(intialState());
          getPageList(chagedId);

          //   getCMSDetails(chagedId);
          //   history.push(`/CMS`);
        } else if (res.data.status === 400) {
          showNotification({
            type: "error",
            message: "Adding CMS failed",
            description: res.data?.msg || "Error occured",
            // description: res.data?.msg==="Invalid parameters" ? "Resolve the errors and try again" : res.data?.msg,
          });
        }
      })
      .catch((er) => {
        showNotification({
          type: "error",
          message: "Adding CMS failed",
          description: "Error occured",
        });
      })
      .finally(() => {
        setLoading({ details: false, update: false });
      });
    // }
  }
  const editorOptions = {
    wrapperClassName: `${commonstyles.form_control} ${formErrors.content && "theme-error-input"
      } form-control-lg p-1 h-auto`,
    toolbarClassName: "bg-light rounded-lg theme-txt-sm",
    editorClassName: "px-2 mt-3 mb-1 ",
    placeholder: "Type description",
    toolbar: {
      options: [
        "inline",
        "blockType",
        "fontSize",
        "fontFamily",
        "list",
        "emoji",
        "link",
        "textAlign",
        "colorPicker",
        // "embedded",
        // "remove",
        "history",
      ],

      blockType: {
        inDropdown: true,
        options: [
          "Normal",
          "H1",
          "H2",
          "H3",
          "H4",
          "H5",
          "H6",
          "Blockquote",
          "Code",
        ],
        className: "theme-txt-sm",
        component: undefined,
        dropdownClassName: undefined,
      },

      fontSize: {
        icon: undefined,
        options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
        className: "theme-txt-sm",
        component: undefined,
        dropdownClassName: undefined,
      },

      fontFamily: {
        options: [
          "Arial",
          "Georgia",
          "Impact",
          "Spartan",
          "Tahoma",
          "Times New Roman",
          "Verdana",
        ],
        className: "theme-txt-sm",
        component: undefined,
        dropdownClassName: "",
      },

      marginBottom: "10px",
      lineHeight: "8px"
    },
  };
  /* function handleEditorUpdate(htmlContent) {
    console.log(htmlContent);
    const values = editForm;
    values.content = htmlContent;
    setEditForm(values);
  } */
  // useEffect(()=>{
  //   //console.log(addForm);
  //   // setEditForm({...editForm,seo:[{name:"",value:""}]});

  // },[addForm])

  //   function addNew() {
  //       console.log("new");
  //       const updatedValue = JSON.parse(JSON.stringify(faqForm));
  //       console.log(updatedValue);
  //       const newFaq = JSON.parse(JSON.stringify(faq));
  //       updatedValue.push(newFaq);
  //       setfaqForm(updatedValue);
  //   }

  //   function handleRemove(e){
  //     console.log(e);
  //   }

  //   function addNewSeo() {
  //     const updatedValue = JSON.parse(JSON.stringify(addForm));
  //     const newSeo = JSON.parse(JSON.stringify(seoNew));
  //     updatedValue.push(newSeo);
  //     setAddForm(updatedValue);
  //   }
  //   function removeSeoOption(index) {
  //     // console.log("index");
  //     // console.log(index);
  //     const updatedValue = addForm;
  //     updatedValue.splice(index, 1);
  //     setAddForm([...updatedValue]);
  //   }
  //   function handleSeoOptionInputChange(e,ind){
  //     let { name, value } = e.target;
  //     console.log(name, value);
  //     value=value.charAt(0).toUpperCase() + value.slice(1)
  // /*
  //     if(!minCharLimit.test(value)){
  //         if(!formErrors.seo){
  //           formErrors.seo={};
  //         }
  //         if(formErrors.seo && !formErrors.seo[ind]){
  //           formErrors.seo[ind]={};
  //         }
  //         formErrors.seo[ind][name] = "Minimum 2 letters";
  //         // console.log(alphaNum.test(value))
  //         }else{
  //         if (
  //           // !value &&
  //           formErrors.seo &&
  //           formErrors.seo[ind] &&
  //           formErrors.seo[ind][name]
  //         ) {
  //           formErrors.seo[ind][name] = "";
  //         }
  //       } */
  //     addForm[ind][name] = value;
  //     setAddForm([...addForm]);
  //     // console.log(addForm);
  //   }
  //   console.log(pagesTitle==="About Us" ? "open" : "close");
  /* return isLoading === true ? (
     <div
       style={{
         marginLeft: "70vh",
         marginTop: "40vh",
       }}
     >
       <tr className="bg-transparent">
         <td colSpan="100%">
           <LoadingIndicator loadingText="Fetching cms details..." />
         </td>
       </tr>
     </div>
   ) : ( */

  function handleSelectChanges(value, name) {

    if (name === "pageType") {
      value = parseInt(value);
      if (isNaN(value)) {
        setFormErrors({
          ...formErrors,
          [name]: "Please select options available",
        });
      } else {
        setFormErrors({ ...formErrors, [name]: "" });
      }
      const updatedValues = { ...editForm };

      updatedValues.pageType = value;

      setEditForm(updatedValues);
    }
  }
  return (
    <div className={styles.management_contents}>
      <SearchComponent />
      <div className={styles.management_header}>
        <h1>
          <img
            src={BlackLeftArrow}
            alt=""
            className="cursor-pointer"
            onClick={() => history.push("/Content")}
          />
          CMS Pages
        </h1>
        {permissionsArr.length > 0 &&
          permissionsArr.find((x) => x === "Content-CMSPage-edit") ===
          "Content-CMSPage-edit" && (
            <div className={`${styles.management_contents_upload} d-flex`}>
              {/* <button className={`btn ${styles.btn_filled}`}>Save</button> */}
              {/* <ButtonComponent
            className="rnd mr-3"
            attributes={{
              onClick: () => history.push(`/site-settings`),
            }}
          >
            Cancel
          </ButtonComponent> */}
              {/* <div
            className={`${styles.management_details_form} ${styles.label_toggle}`}
          >
            <div className={styles.onoffswitch}>
              <input
                type="checkbox"
                name="enable"
                className={styles.onoffswitch_checkbox}
                id={"myonoffswitch1"}
                checked={editForm?.enable}
                onChange={(e) => handleFormInput(e)}
              />
              <label
                className={styless.onoffswitch_label}
                for={"myonoffswitch1"}
              >
                <span className={styless.onoffswitch_inner}></span>
                <span className={styless.onoffswitch_switch}></span>
              </label>
            </div>
          </div> */}
              {/* <a className={`${styles.btn_borderd} ${styles.btn_square} ${styles.btn} mr-4`}>Drafts</a> */}
              {/* <ButtonComponent
            className="rnd"
            attributes={{
              onClick: submit,
            }}
          >
            Save
          </ButtonComponent> */}
            </div>
          )}
      </div>
      {permissionsArr.length > 0 &&
        permissionsArr.find((x) => x === "Content-CMSPage-add") ===
        "Content-CMSPage-add" && (
          <div className={commonstyles.management_header}>
            <ButtonComponent
              className="rnd pl-3 pr-4 outline_btn"
              attributes={{
                onClick: () => {
                  history.push({
                    pathname: `/Content/addCMS`,
                    state: state,
                  });
                },
              }}
            >
              ADD CMS
            </ButtonComponent>
          </div>
        )}
      {isLoadingCMS === true ? (
        <tr className="bg-transparent d-flex justify-content-center">
          <td colSpan="100%">
            <LoadingIndicator loadingText={`Fetching CMS...`} />
          </td>
        </tr>
      ) : (
        permissionsArr.length > 0 &&
        permissionsArr.find((x) => x === "Content-CMSPage-edit") ===
        "Content-CMSPage-edit" && (
          <>
            {pageList?.length > 0 && (
              <Tabs
                activeKey={selectedId}
                onChange={getCMSDetails}
                className="content_tab"
              >
                {Array.isArray(pageList) &&
                  pageList.map((page, indx) => (
                    <TabPane tab={page.pageTitle} key={page._id}>
                      {/* {page.pageTitle ==="FAQ" ? (
                        <div className={styles.management_details}>
                           {faqForm.map((faqs, indx) => (
                            <div className={styles.management_details_contents}>
                                <div className="row">
                                    <div className="col-lg-12 mt-4 mb-5">
                                    <h4 className="theme-form-section-title">FAQ - {indx + 1}</h4>
                                        <label for="">Question
                                            <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>*</span>
                                        </label>
                                        <InputComponent
                                            className="form-control form-control-lg form_control mb-3"
                                            // error={formErrors.question}
                                            error={
                                                formErrors.faqForm &&
                                                formErrors.faqForm[indx] &&
                                                formErrors.faqForm[indx].question
                                              }
                                            formControl={{
                                            placeholder: "Type the Question",
                                            name: "question",
                                            value: faqs?.question,
                                            onChange: (e) => handlefaqFormChange(e, indx),
                                            }}
                                        />
                                        <FormErrorText error={formErrors.question} /> 
                                        <FormErrorText
                                            error={formErrors.faqForm &&
                                            formErrors.faqForm[indx] &&
                                            formErrors.faqForm[indx].question ? "Required" : ""}
                                        />

                                        <label for=""> Answer
                                            <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>*</span>
                                        </label>
                                        <InputComponent
                                            className="form-control form-control-lg form_control"
                                            // error={formErrors.answer}
                                            error={
                                                formErrors.faqForm &&
                                                formErrors.faqForm[indx] &&
                                                formErrors.faqForm[indx].answer
                                              }
                                            formControl={{
                                            placeholder: "Type the Answer",
                                            name: "answer",
                                            value: faqs?.answer,
                                            onChange: (e) => handlefaqFormChange(e, indx),
                                            }}
                                        />
                                        <FormErrorText error={formErrors.question} />
                                        <FormErrorText
                                            error={formErrors.faqForm &&
                                            formErrors.faqForm[indx] &&
                                            formErrors.faqForm[indx].answer ? "Required" : ""}
                                        />

                                    </div>
                                </div>
                                <div className="col-lg-12 text-right add_text mt-3 theme-btn-link text-danger">
                                <span onClick={() => handleRemove(faqs)}>Remove</span>
                            </div>
                            </div>
                            
                         ))}
                            <div className={styles.payment_btn}>
                                <button className={`btn ${styles.btn_filled}`} onClick={addNew}>
                                    Add FAQ
                                </button>
                            </div>
                            
                        </div>
                        ):( */}
                      {isLoading === true ? (
                        <tr className="bg-transparent d-flex justify-content-center">
                          <td colSpan="100%">
                            <LoadingIndicator
                              loadingText={`Fetching ${page.pageTitle} details...`}
                            />
                          </td>
                        </tr>
                      ) : (
                        <div className={styles.management_details}>
                          <div className={styles.management_details_contents}>
                            <div className="row">
                              <div className="col-lg-12 mb-4 d-flex justify-content-end">
                                <div
                                  className={`${styles.management_details_form} ${styles.label_toggle}`}
                                >
                                  <div className={styless.onoffswitch}>
                                    <input
                                      type="checkbox"
                                      name="enable"
                                      className={styless.onoffswitch_checkbox}
                                      id={"myonoffswitch1" + indx}
                                      checked={editForm?.enable}
                                      onChange={(e) => handleFormInput(e, indx)}
                                    />
                                    <label
                                      className={styless.onoffswitch_label}
                                      for={"myonoffswitch1" + indx}
                                    >
                                      <span
                                        className={styless.onoffswitch_inner}
                                      ></span>
                                      <span
                                        className={styless.onoffswitch_switch}
                                      ></span>
                                    </label>
                                  </div>
                                </div>
                                <ButtonComponent
                                  className="rnd"
                                  attributes={{
                                    onClick: submit,
                                  }}
                                >
                                  {loading?.update ? "Saving..." : "Save"}
                                </ButtonComponent>
                              </div>

                              <div className="col-lg-12 mb-4">
                                <div className={styles.management_details_form}>
                                  <label for="">
                                    Page title
                                    <span
                                      className="text-danger"
                                      style={{ fontSize:"20px",bottom:-8,position:"relative" }}
                                    >
                                      *
                                    </span>
                                  </label>
                                  <InputComponent
                                    className={`form-control form-control-lg ${styles.form_control}`}
                                    error={formErrors.pageTitle}
                                    formControl={{
                                      placeholder: "",
                                      name: "pageTitle",
                                      value: editForm?.pageTitle,
                                      onChange: (e) => handleFormInput(e, indx),
                                    }}
                                  />
                                  <FormErrorText error={formErrors.pageTitle} />
                                </div>
                              </div>
                              <div className="col-lg-12 mb-4">
                                <label className={commonstyles.secondry_title}>
                                  Page type
                                  <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                                    *
                                  </span>
                                </label>
                                {/* <SelectComponent
                className={`form-control form-control-md ${styles.form_control}`}
                error={formErrors.pageType}
                attributes={{
                  name: "pageType",
                  value: editForm?.pageType,
                  onChange: (e) => handleSelectChanges(e),
                }}
              >
                <option value="">Select an option</option>
                <option value="1">Company</option>
                <option value="2">Help</option>
              </SelectComponent> */}

                                <Select defaultValue="Select an option"
                                  className={`${styles.fullwidth}`}
                                  error={formErrors.pageType}
                                  // attributes={{
                                  // name="pageType"
                                  value={editForm?.pageType == 2 ? "Help" : "Company"}
                                  onSelect={(e) => handleSelectChanges(e, "pageType")}
                                // }}
                                >

                                  <option value="1" key="1">Company</option>
                                  <option value="2" key="2">Help</option>
                                </Select>
                                <FormErrorText error={formErrors.pageType} />


                              </div>
                              {/* Slug */}

                              <div className="col-lg-12 mb-4">
                                <label className={commonstyles.secondry_title}>
                                  Slug
                                  <span
                                    className="text-danger"
                                    style={{ fontSize:"20px",bottom:-8,position:"relative" }}
                                  >
                                    *
                                  </span>
                                </label>
                                <InputComponent
                                  error={formErrors.slug}
                                  className="form-control-lg"
                                  formControl={{
                                    disabled: editForm?.pageTitle !== "Our Stores" && editForm?.pageTitle !== "Bulk Enquiry" ? false : true,
                                    placeholder: "Type slug",
                                    name: "slug",
                                    value: editForm?.slug,
                                    onChange: (e) => handleFormInput(e, indx),
                                  }}
                                />
                                <FormErrorText error={formErrors.slug} />
                              </div>
                              {/* Slug ends */}
                              {/* Schema Markup */}
                              <div className="col-lg-12 mb-4">
                                <label className={commonstyles.secondry_title}>
                                  Schema Markup
                                </label>
                                <textarea
                                  className={`${commonstyles.form_control} 
                                  form-control form-control-lg `}
                                  placeholder="Type schema markup"
                                  rows="4"
                                  autoComplete="off"
                                  name="seoMarkup"
                                  value={editForm?.seoMarkup}
                                  onChange={handleFormInput}
                                />
                                {/*  <FormErrorText error={formErrors.description} /> */}
                              </div>
                                {/* Schema Markup ends */}
                                {(editForm?.pageTitle?.toLowerCase() !== "faq") ? (
                                  page?.pageTitle?.toLowerCase() !== "contact us" ?
                                    (<div className="col-lg-12 mb-4">
                                      <div className={styles.management_details_form}>
                                        <label for="">
                                          Content
                                          <span
                                            className="text-danger"
                                            style={{ fontSize:"20px",bottom:-8,position:"relative" }}
                                          >
                                            *
                                          </span>
                                        </label>

                                        {page?._id && editForm.content && (
                                          <WYSIWYGEditor
                                            content={editForm?.content || ""}
                                            options={editorOptions}
                                            onEditorStateChange={(content) => {
                                              const values = editForm;
                                              values.content = content;
                                              setEditForm(values);
                                            }}
                                          />
                                        )}
                                        {!(page?._id && editForm.content) && (
                                          <WYSIWYGEditor
                                            content={editForm?.content || ""}
                                            options={editorOptions}
                                            onEditorStateChange={(content) => {
                                              const values = editForm;
                                              values.content = content;
                                              setEditForm(values);
                                            }}
                                          />
                                        )}

                                        <FormErrorText error={formErrors.content} />
                                      </div>
                                    </div>) : (
                                      <div className="col-lg-12 mb-4">
                                        <label className={commonstyles.secondry_title}>
                                          Phone
                                          <span
                                            className="text-danger"
                                            style={{ fontSize:"20px",bottom:-8,position:"relative" }}
                                          >
                                            *
                                          </span>
                                        </label>
                                        <InputComponent
                                          error={formErrors.phone}
                                          className="form-control-lg"
                                          formControl={{
                                            placeholder: "Type Phone number",
                                            name: "phone",
                                            value: editForm?.phone,
                                            onChange: (e) => handleFormInput(e, indx),
                                          }}
                                        />
                                        <FormErrorText error={formErrors.phone} />
                                      </div>)
                                ) : ""}
                                {(page?.pageTitle?.toLowerCase() !== "contact us") ?
                                <>
                                  <div className="col-lg-12 mb-4">
                                    <div className="row">
                                      <div className="col-lg-6 mb-4">
                                        <div className="d-flex align-items-right justify-content-between">
                                          <label
                                            className={commonstyles.secondry_title}
                                          >
                                            Add Image{" "}
                                            {/* <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                                            *
                                        </span> */}
                                          </label>
                                        </div>
                                        <div
                                          className={`${commonstyles.banner_img_box_outline}`}
                                        >
                                          <div
                                            className={`${commonstyles.management_details_contents} mb-2 mt-2`}
                                          >
                                            <div
                                              className={`${commonstyles.management_details_form} ${commonstyles.add_image_card} d-flex align-items-center justify-content-center`}
                                            >
                                              <input
                                                name="image"
                                                type="file"
                                                accept="image/*"
                                                id="imageInput"
                                                value=""
                                                onChange={(e) =>
                                                  handleImageInput(e)
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 mb-4">
                                        <label
                                          className={commonstyles.secondry_title}
                                        >
                                          Preview image
                                        </label>
                                        <div
                                          className={`${commonstyles.banner_img_box_outline} `}
                                        >
                                          {images?.length > 0 &&
                                            images?.map((imgs, index) => (
                                              <div
                                                className={`${commonstyles.banner_img_preview}`}
                                              >
                                                <img
                                                  class={commonstyles.previewImgs}
                                                  key={index}
                                                  src={
                                                    imgs?.name
                                                      ? URL.createObjectURL(imgs)
                                                      : imgs?.link
                                                  }
                                                  alt={imgs?.name}
                                                />
                                                <img
                                                  onClick={() =>
                                                    removeImages(imgs, index)
                                                  }
                                                  class={
                                                    commonstyles.previewImgsdeleteicon
                                                  }
                                                  src={bannerimgdelete}
                                                  alt="delete"
                                                />
                                              </div>
                                            ))}
                                        </div>
                                      </div>
                                    </div>
                                  </div> 
                                  {/* about us footer image */}
                                  {page?.pageTitle?.toLowerCase().replace(/\s/g, '') === 'aboutus' && (
                                  <div className="col-lg-12 mb-4">
                                    <div className="row">
                                      <div className="col-lg-6 mb-4">
                                        <div className="d-flex align-items-right justify-content-between">
                                          <label
                                            className={commonstyles.secondry_title}
                                          >
                                            Add Banner Image(Important Links){" "}
                                            {/* <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                                            *
                                        </span> */}
                                          </label>
                                        </div>
                                        <div
                                          className={`${commonstyles.banner_img_box_outline}`}
                                        >
                                          <div
                                            className={`${commonstyles.management_details_contents} mb-2 mt-2`}
                                          >
                                            <div
                                              className={`${commonstyles.management_details_form} ${commonstyles.add_image_card} d-flex align-items-center justify-content-center`}
                                            >
                                              <input
                                                name="image"
                                                type="file"
                                                accept="image/*"
                                                id="imageInput"
                                                value=""
                                                onChange={(e) =>
                                                  handleimportantlinkImageInput(e)
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 mb-4">
                                        <label
                                          className={commonstyles.secondry_title}
                                        >
                                          Preview image
                                        </label>
                                        <div
                                          className={`${commonstyles.banner_img_box_outline} `}
                                        >
                                          {impimages?.length > 0 &&
                                            impimages?.map((imgs, index) => (
                                              <div
                                                className={`${commonstyles.banner_img_preview}`}
                                              >
                                                <img
                                                  class={commonstyles.previewImgs}
                                                  key={index}
                                                  src={
                                                    imgs?.name
                                                      ? URL.createObjectURL(imgs)
                                                      : imgs?.link
                                                  }
                                                  alt={imgs?.name}
                                                />
                                                <img
                                                  onClick={() =>
                                                    removeimportantlinkImages(imgs, index)
                                                  }
                                                  class={
                                                    commonstyles.previewImgsdeleteicon
                                                  }
                                                  src={bannerimgdelete}
                                                  alt="delete"
                                                />
                                              </div>
                                            ))}
                                        </div>
                                      </div>
                                    </div>
                                  </div> 
                                  )}
                                  </>
                                  :
                                  <>
                                  <div className="col-lg-12 mb-4">
                                    <label className={commonstyles.secondry_title}>
                                      Email
                                      <span
                                        className="text-danger"
                                        style={{ fontSize:"20px",bottom:-8,position:"relative" }}
                                      >
                                        *
                                      </span>
                                    </label>
                                    <InputComponent
                                      error={formErrors.email}
                                      className="form-control-lg"
                                      formControl={{
                                        placeholder: "Type Email",
                                        name: "email",
                                        value: editForm?.email,
                                        onChange: (e) => handleFormInput(e, indx),
                                      }}
                                    />
                                    <FormErrorText error={formErrors.email} />
                                  </div>
                                  <div className="col-lg-12 mb-4">
                                    <label className={commonstyles.secondry_title}>
                                      Description
                                      <span
                                        className="text-danger"
                                        style={{ fontSize:"20px",bottom:-8,position:"relative" }}
                                      >
                                        *
                                      </span>
                                    </label>
                                    <InputComponent
                                      error={formErrors.description}
                                      className="form-control-lg"
                                      formControl={{
                                        placeholder: "Type Description",
                                        name: "description",
                                        value: editForm?.description,
                                        onChange: (e) => handleFormInput(e, indx),
                                      }}
                                    />
                                    <FormErrorText error={formErrors.description} />
                                  </div>
                                    <div className="col-lg-12 mb-4">
                                      <label className={commonstyles.secondry_title}>
                                        Top Content
                                      </label>
                                      <WYSIWYGEditor
                                        content={editForm?.content || ""}
                                        options={editorOptions}
                                        onEditorStateChange={(content) => {
                                          const values = editForm;
                                          values.content = content;
                                          setEditForm(values);
                                        }}
                                      />
                                    </div>
                                    <div className="col-lg-12 mb-4">
                                      <label className={commonstyles.secondry_title}>
                                        Bottom Content
                                      </label>
                                      <WYSIWYGEditor
                                        content={editForm?.bottomContent || ""}
                                        options={editorOptions}
                                        onEditorStateChange={(content) => {
                                          const values = editForm;
                                          values.bottomContent = content;
                                          setEditForm(values);
                                        }}
                                      />
                                    </div>
                                  </>
                                }
                              </div>
                          </div>
                          {/* <h4>SEO</h4>
                        <div className={styles.management_details_contents}>
                        <div className={styles.management_details_form}>
                            <div className="text-right">
                            <button className="theme-btn rnd" onClick={addNewSeo}>Add SEO</button>
                            </div>
                            <div className="table-responsive">
                          <table className="table table-borderless table_attributes">
                              <thead>
                                <tr>
                                <th scope="col" className="th_radio_button"></th>
                                  <th scope="col" className={styles.th_admin}>Name <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>*</span> </th>
                                  <th scope="col" className={styles.th_store_view}>Value <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>*</span></th>
                                </tr>
                              </thead>
                              <tbody>
                                {addForm.map((seo,ind)=>
                                  (
                                    <tr key={ind}>
                                  <td>
                                    <div className="color_selector">
                                    <span className="color_picker color1">
                                    </span>
                                    <img src="img/down_arrow.svg" className="down_arrow_img" alt=""/>
                                    </div>
                                  </td>
                                  <td>
                                    <InputComponent
                                        className="form-control form_control"
                                        error={
                                            formErrors.seo &&
                                            formErrors.seo[ind] &&
                                            formErrors.seo[ind].name
                                          }
                                        formControl={{
                                          placeholder: "Name",
                                          name: "name",
                                          value: seo?.name,
                                          onChange: (e)=>{handleSeoOptionInputChange(e,ind)},
                                          
                                        }}
                                      />
                                    <FormErrorText
                                        error={formErrors.seo &&
                                        formErrors.seo[ind] &&
                                        formErrors.seo[ind].name}
                                    />
                                  </td>
                                  <td>
                                    <InputComponent
                                        className="form-control form_control"
                                        error={
                                            formErrors.seo &&
                                            formErrors.seo[ind] &&
                                            formErrors.seo[ind].value
                                          }
                                        formControl={{
                                          placeholder: "Value",
                                          name: "value",
                                          value: seo?.value,
                                          onChange: (e)=>{handleSeoOptionInputChange(e,ind)},
                                          
                                        }}
                                      />
                                    <FormErrorText
                                        error={formErrors.seo &&
                                        formErrors.seo[ind] &&
                                        formErrors.seo[ind].value}
                                    />
                                  </td>
                                  <td>
                                    <ButtonComponent
                                      className="rnd"
                                      attributes={{
                                        onClick: ()=>{removeSeoOption(ind)},
                                        disabled:ind===0 ?true:false
                                      }}
                                    >
                                      Remove
                                    </ButtonComponent>
                                  </td>
                                </tr>
                                ))}
                                
                              </tbody>
                            </table>
                        </div>
                      </div>
                    </div> */}
                          <div className={styles.management_details_contents}>
                            <h4 className="theme-form-section-title">
                              Search Engine Optimization
                            </h4>
                            <div className="row">
                              <div className="col-lg-6 mb-4">
                                <div className={styles.management_details_form}>
                                  <label for="">Meta Title</label>
                                  <InputComponent
                                    className={`form-control form-control-lg ${styles.form_control}`}
                                    error={formErrors.metaTitle}
                                    formControl={{
                                      placeholder: "",
                                      name: "metaTitle",
                                      value: editForm?.metaTitle,
                                      onChange: (e) => handleFormInput(e, indx),
                                    }}
                                  />
                                  <FormErrorText error={formErrors.metaTitle} />
                                </div>
                              </div>
                              <div className="col-lg-6 mb-4">
                                <div className={styles.management_details_form}>
                                  <label for="">Meta Keywords</label>
                                  <InputComponent
                                    className={`form-control form-control-lg ${styles.form_control}`}
                                    error={formErrors.metaKeywords}
                                    formControl={{
                                      placeholder: "",
                                      name: "metaKeywords",
                                      value: editForm?.metaKeywords,
                                      onChange: (e) => handleFormInput(e, indx),
                                    }}
                                  />
                                  <FormErrorText
                                    error={formErrors.metaKeywords}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-12 mb-4">
                                <div className={styles.management_details_form}>
                                  <label for="">Meta Description</label>
                                  <textarea
                                    className={`form-control form-control-lg ${styles.form_control}`}
                                    error={formErrors.metaDescription}
                                    // formControl={{
                                    placeholder=""
                                    name="metaDescription"
                                    value={editForm?.metaDescription}
                                    onChange={(e) => handleFormInput(e, indx)}
                                  // }}
                                  />
                                  <FormErrorText
                                    error={formErrors.metaDescription}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {/* ) 
                    }*/}
                    </TabPane>
                  ))}
              </Tabs>
            )}
          </>
        )
      )}
    </div>
  );
}
