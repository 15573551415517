import React, { useState, useEffect } from "react";
import styles from "../../../styles/PaymentNew.module.css";
import stylles from "../../../styles/AddDiscount.module.css";
import BlackLeftArrow from "../../../assets/images/black_left_arrow.svg";
import { useHistory } from "react-router";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import { notification, Select } from "antd";
import ButtonComponent from "../../modules/ReusableComponent/ButtonComponent";
import { lte } from "lodash";
import { runValidationChecks } from "../../../utils";
import InputComponent from "../../modules/ReusableComponent/InputComponent";
import TextArea from "../../modules/ReusableComponent/TextArea";
import SelectComponent from "../../modules/ReusableComponent/SelectComponent";
import FormErrorText from "../../modules/ProductManagement/FormErrorText";
import {
  alphaNum,
  floatingZero,
  pinCode,
  onlyTenNumbers,
  minChar,
  userNameWithSpace,
  telephoneNumber,
  phoneRegx,
  telePhoneRegex,
} from "../../../utils/RegularExp";
import ProductMediaUploadForm from "../../modules/ProductManagement/ProductMediaUploadForm";
import styless from "../../../styles/AddProduct.module.css";
import LoadingIndicator from "../../modules/ReusableComponent/LoadingIndicator";
import FaqFormComponent from "../../modules/ProductManagement/FaqFormComponent";
import commonstyles from "../../../styles/Management_Common.module.css";
import validationRules from "../../../utils/validationRules";
import { isObject, isString, isEqual } from "lodash";
import ImagePreviewChip from "../../modules/ProductManagement/ImagePreviewChip";
import { Link, useParams } from "react-router-dom";
import { TimePicker } from "antd";
import moment from "moment";

const { Option } = Select;

export default function AddBannerComponent() {
  const { id } = useParams();
  const initialFormState = {
    state: "",
    regionId: "",
    cityId:"",
    telephone: "",
    landLine: "",
    latitude: "",
    longitude: "",
    location: "",
    workingStartDay: "",
    workingEndDay: "",
    startTime: "",
    endTime: "",
    pincode: "",
    address: "",
    enable: "",
    gallery: [] || localFileData.filesPreview,
  };

  const history = useHistory();

  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState({ details: false, update: false });
  const [isLoading, setIsLoading] = useState(true);
  const [regions, setRegions] = useState([]);
  const [localFileData, setLocalFileData] = useState({
    files: [],
    filesPreview: [],
  });

  //file
  const [file, setFile] = useState(null);
  const [url, setUrl] = useState("");
  const [editForm, setEditForm] = useState(initialFormState);
  const [startTime, setStartTime] = useState("");

  const [endTime, setEndTime] = useState("");
  const [cities, setCities] = useState([])
  const rules = {
    state: [(val, data) => (!val ? "State " : true)],
    latitude: [(val, data) => (!val ? "Latitude " : true)],
    longitude: [(val, data) => (!val ? "Longitude " : true)],
    location: [(val, data) => (!val ? "Location " : true)],
    workingStartDay: [(val, data) => (!val ? "Working start day " : true)],
    workingEndDay: [(val, data) => (!val ? "Working end day " : true)],
    startTime: [(val, data) => (!val ? "Start time " : true)],
    endTime: [(val, data) => (!val ? "End time " : true)],
    feature_image: [(val, data) => (!url ? "Feature image" : true)],
    regionId: [(val, data) => (!val ? "Region " : true)],
    cityId: [(val, data) => (!val ? "City " : true)],
    pincode: [(val, data) => (!val ? "Pincode " : true)],
    address: [(val, data) => (!val ? "Address " : true)],
  };

  useEffect(() => {
    function getRegionList() {
      AxiosInstance(PORTS.REGION_SERVICE, "application/json")
        .get("admin/region/getRegionList")
        .then((res) => {
          if (res.data.status === 200) {
            setRegions(res.data.daaata);
          }
        })
        .catch((er) => {
          showNotification({
            type: "error",
            message: "Failed",
            description: "Error occured",
          });
        });
    }
    function getCityList(){
      AxiosInstance(PORTS.REGION_SERVICE, "application/json")
        .get(`/cities?pagesize=10&nextpage=1`)
        .then((res) => {
          if (res && res.data && res.status == "200") {
            if (res.data?.dropDown?.length > 0) {
              setCities(res.data?.dropDown)
            } else {
              setCities([]);
            }
          }
        })
        .catch((er) => {
          const data = { type: "error" };
          if (er.response) {
            data.message = "Error";
            data.description = "Failed to get data";
            setIsLoading(false);
            showNotification(data);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    getCityList()
    getRegionList();
  }, [editForm.pincode]);

  function handleRegionChange(e) {
    editForm.regionId = e;
    setEditForm({ ...editForm });
  }

  function handleImageInput(e) {
    const { name, files: inputFiles } = e.target;

    if (inputFiles[0].size > 1024 * 1024 * 3) {
      showNotification({
        type: "error",
        message: "There were few errors",
        description: "Please upload file less than 3MB size",
      });
      return;
    } else {
      setFile(e.target.files[0]);
      if (url) {
        URL.revokeObjectURL(url);
      }
      const generatedUrl = URL.createObjectURL(e.target.files[0]);
      setUrl(generatedUrl);
    }
  }

  function removeFile() {
    if (url) {
      setUrl("");

      setFile(null);
    }
  }

  useEffect(() => {
    if (id) {
      getWarehouseDetails(id);
    } else {
      setIsLoading(false);
    }
  }, []);

  const [editZoneId, setEditZoneId] = useState("");

  /**
   * @author Mithun Prabhu
   * @param {ID}
   * To get WAREHOUSE DETAILS BY CALLING SHOWRROMDETAILS API
   */
  function getWarehouseDetails(id) {
    setLoading({ details: true, update: false });
    AxiosInstance(PORTS.REGION_SERVICE, "application/json")
      .get(`/admin/shworoom/getshowroomDetails/${id}`)
      .then((res) => {
        if (res.data.status === 200) {
          const val = res.data.data;
          setEndTime(val.endTime);
          setStartTime(val.startTime);
          setUrl(val.feature_image);
          setEditForm(val);
          setIsLoading(false);
        } else {
          showNotification({
            type: "Error",
            message: "Error",
            description: "Couldnt fetch required data",
          });
        }
      })
      .catch((er) => {
        return er;
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
        setLoading({ details: false, update: false });
      });
  }

  const onChangeTimeHandlerStart = (time, type) => {
    const startTime = moment(type, "HH:mm").format("hh:mm:00 A");
    setStartTime(startTime);
  };

  const onChangeTimeHandlerEnd = (time, type) => {
    const endTime = moment(type, "HH:mm").format("hh:mm:00 A");
    setEndTime(endTime);
  };

  /**
   * @author Mithun Prabhu
   * @param {DATA}
   * STRINGIFY WHOLE JSON FORM
   */
  function createRequestData() {
    let data = JSON.parse(JSON.stringify(editForm));
    data.state = editForm.state.trim();
    data.regionId = editForm.regionId;
    data.telephone = editForm.telephone;
    data.landLine = editForm.landLine;
    data.latitude = Number(editForm.latitude);
    data.longitude = Number(editForm.longitude);
    data.location = editForm.location;
    data.workingStartDay = editForm.workingStartDay;
    data.workingEndDay = editForm.workingEndDay;
    data.startTime = startTime;
    data.endTime = endTime;
    data.pincode = editForm.pincode.toString();
    data.address = editForm.address;
    data.description = editForm.description;
    data.gallery = editForm.gallery;
    data.cityId = editForm.cityId;

    if (data.workingDays) {
      delete data.workingDays;
    }

    if (data.createdAt || data.updatedAt || data.__v) {
      delete data.createdAt;
      delete data.updatedAt;
      delete data.__v;
    }

    if (data.feature_image) {
      delete data.feature_image;
    }
    return data;
  }

  /**
   * @author Mithun Prabhu
   * @param {EVENT}
   * To get WAREHOUSE DETAILS BY CALLING SHOWRROMDETAILS API
   */
  function submit(e) {
    setLoading({ details: false, update: true })
    const data = createRequestData();

    if (formErrors.latitude || formErrors.latitude || formErrors.address) {
      setLoading({ details: false, update: false })
      return;
    }
    if((formErrors?.landLine && formErrors?.landLine !== "")){
      setLoading({ details: false, update: false });
      return showNotification({
       type: "warning",
       message: "Please enter a valid Telephone number",
     })
   }
    //validation
    if (data.state) {
      if (!userNameWithSpace.test(data.state) || !data.state === "") {
        setFormErrors({
          ...formErrors,
          name: "Enter only alphabet and minimum 3",
        });
        return false;
      } else {
        setFormErrors({ ...formErrors, state: "" });
      }
    }
    const formData = new FormData();

    delete data.title;

    // console.log(data);

    formData.append("data", JSON.stringify(data));
    formData.append("feature_image", file);

    for (let i = 0; i < localFileData.files.length; i++) {
      formData.append("gallery", localFileData.files[i]);
    }

    const result = runValidationChecks(rules, undefined, {
      ...data,
      file,
    });

    if (Object.keys(result).length) {
      setFormErrors(result);
      showNotification({
        type: "warning",
        message: "There were few errors",
        description: Object.values(result) + " is required",
      });
      setLoading({ details: false, update: false })
      return;
    }

    if (!phoneRegx.test(data.telephone)) {
      showNotification({
        type: "warning",
        message: "There were few errors",
        description: "Please enter valid phone number",
      });
      result.telephone = "Please enter valid phone number"
      setFormErrors(result);
      setLoading({ details: false, update: false })
      return;
    }

    AxiosInstance(PORTS.REGION_SERVICE, "application/json")
      //    .post("/banner/createBanner", formData)
      .post(
        `/admin/${id ? "shworoom/updateShowroom" : "addShowroom"}`,
        formData
      )
      .then((res) => {
        console.log(res.data);
        if (res.data.status === 200) {
          showNotification({
            type: "success",
            message: "Success",
            description: id
              ? "Store updated successfully"
              : "Store added successfully",
          });
          //setFilter(intialState());
          history.push(`/catalog/store`);
        } else if (res.data.status === 400) {
          showNotification({
            type: "error",
            message: "Adding store failed",
            description: res.data?.msg || "Error occured",
          });
        }
      })
      .catch((er) => {
        showNotification({
          type: "error",
          message: "Adding store failed",
          description: "Error occured",
        });
      })
      .finally(() => {
        setLoading({ details: false, update: false });
      });
  }

  /**
   * @author Mithun Prabhu
   * @param {lat}
   * pattern for validate latitude and longitude
   */
  function validateLatLng(data) {
    let pattern = new RegExp("^-?([1-8]?[1-9]|[1-9]0)\\.{1}\\d{1,6}");

    return pattern.test(data);
  }

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  function handleMediaUpdate(fileDetails, form) {
    if (form === "gallery") {
      setEditForm({ ...editForm, gallery: fileDetails });
    } else if (form === "files") {
      setLocalFileData(fileDetails);
    }
  }

  function handleStartTime(time, timeString) {
    console.log(time, timeString);

    editForm.startTime = timeString;
    setStartTime(timeString);
  }

  function handleEndTime(time, timeString) {
    console.log(time, timeString);

    editForm.endTime = timeString;

    setEndTime(timeString);
  }

  function handleFormInput(e) {
    const { value, name, checked } = e.target;

    if (name === "address") {
      if (!value.match(userNameWithSpace) || !value === "") {
        setFormErrors({
          ...formErrors,
          [name]: "Enter only alphabet and minimum 3",
        });
      } else {
        setFormErrors({ ...formErrors, [name]: "" });
      }
    }

    const updatedValues = { ...editForm };
    if (["enable"].includes(name)) {
      updatedValues["enable"] = checked;
    }
    if(name == "cityId"){
      setFormErrors({...formErrors,cityId:""})
    }
    if (
      name == "state" ||
      name == "regionId" ||
      name == "telephone" ||
      name == "landLine" ||
      name == "description" ||
      name == "pincode" ||
      name == "latitude" ||
      name == "longitude" ||
      name == "location" ||
      name == "workingStartDay" ||
      name == "workingEndDay" ||
      name == "address" || 
      name == "cityId"
    ) {
      updatedValues[name] = value;
    }

    setEditForm(updatedValues);
  }

  // console.log(editForm.startTime.slice(12));

  return (
    <div className={styles.management_contents}>
      <div className={styles.management_header}>
        <h1>
          <img
            src={BlackLeftArrow}
            alt=""
            className="cursor-pointer"
            onClick={() => history.push("/catalog/store")}
          />
          {id ? "Edit Store" : "Add Store"}
        </h1>
        <div className={styles.management_contents_upload}>
          {/* <button className={`btn ${styles.btn_filled}`}>Save</button> */}
          <div className={`${styless.onoffswitch} d-inline-block align-top`}>
            <input
              type="checkbox"
              name="enable"
              className={styless.onoffswitch_checkbox}
              onChange={handleFormInput}
              checked={editForm?.enable}
              id="statusSwitch"
            />
            <label className={styless.onoffswitch_label} htmlFor="statusSwitch">
              <span className={styless.onoffswitch_inner}></span>
              <span className={styless.onoffswitch_switch}></span>
            </label>
          </div>
          <ButtonComponent
            className="rnd outline_cancel_btn mr-3"
            attributes={{
              onClick: () => history.push(`/catalog/store`),
            }}
          >
            Cancel
          </ButtonComponent>
          <ButtonComponent
            className="rnd"
            attributes={{
              disabled: loading.update,
              onClick: submit,
            }}
          >
            {loading.update ? "Saving" : "Save"}
          </ButtonComponent>
        </div>
      </div>
      {isLoading === true ? (
        <tr className="bg-transparent d-flex justify-content-center">
          <td colSpan="100%">
            <LoadingIndicator loadingText="Fetching store details..." />
          </td>
        </tr>
      ) : (
        <div className={styles.management_details}>
          <div className={styles.management_details_contents}>
            <div className="row">
              <div className="col-lg-6 mb-4">
                <label className={commonstyles.secondry_title}>
                  Title{" "}
                  <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                    *
                  </span>
                </label>

                <InputComponent
                  error={formErrors.state}
                  className="form-control-lg"
                  formControl={{
                    maxLength: 50,
                    placeholder: "Type store title",
                    name: "state",
                    value: editForm?.state,
                    onChange: (e) => {
                      if (
                        !minChar.test(e.target.value) &&
                        e.target.value.trim() !== " "
                      ) {
                        if (!formErrors.state) {
                          formErrors.state = {};
                        }
                        formErrors.state = "Enter minimum 3 characters";
                      } else {
                        if (formErrors.state) {
                          formErrors.state = "";
                        }
                      }
                      handleFormInput(e);
                    },
                  }}
                />
                {/* <FormErrorText error={formErrors.name ? "Required" : ""}/> */}
                <FormErrorText error={formErrors.state} />
              </div>

              {/* Region startTime */}

              <div className="col-lg-6 mb-4">
                <label>
                  Warehouse
                  <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                    *
                  </span>
                </label>
                <SelectComponent
                  error={formErrors.regionId}
                  className="form-control-md"
                  attributes={{
                    name: "regionId",
                    value: editForm.regionId,
                    onChange: (e) => {
                      handleFormInput(e);
                    },
                  }}
                >
                  <option value="" disabled>
                  Select Warehouse
                  </option>
                  {Array.isArray(regions) && regions.length
                    ? regions.map((region) => (
                      <option key={region._id} value={region._id}>
                        {region.name}
                      </option>
                    ))
                    : null}
                </SelectComponent>
                <FormErrorText error={formErrors.regionId} />
              </div>
              {/* region End */}
              {/* Slug */}

              <div className="col-lg-6 mb-4">
                <label className={commonstyles.secondry_title}>
                  Phone
                  {/* <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                    *
                  </span> */}
                </label>
                <div className={commonstyles.adduser_input_countycode}>
                  <span>+91</span>
                  <InputComponent
                    error={formErrors.telephone}
                    className={`form-control-lg ${commonstyles.adduser_input_countycode_form}`}
                    formControl={{
                      placeholder: "Type phone number",
                      name: "telephone",
                      value: editForm?.telephone,
                      onChange: (e) => {
                        if(e.target.value.trim() === ""){
                          setFormErrors({...formErrors,telephone:""})
                        }
                        else if (
                          !phoneRegx.test(e.target.value) 
                        ) {
                          if (!formErrors.telephone) {
                            formErrors.telephone = {};
                          }
                          formErrors.telephone =
                            "Enter valid phone number";
                        } else {
                          if (formErrors.telephone) {
                            formErrors.telephone = "";
                          }
                        }
                        handleFormInput(e);
                      },
                    }}
                  />
                </div>
                <FormErrorText error={formErrors.telephone} />
              </div>

              <div className="col-lg-6 mb-4">
                <label className={commonstyles.secondry_title}>
                  Telephone
                </label>
                <div className={commonstyles.adduser_input_countycode}>
                  <InputComponent
                    error={formErrors.landLine}
                    className={`form-control-lg ${commonstyles.adduser_input_countycode_form}`}
                    style={{"padding":"0.5rem 1rem"}}
                    formControl={{
                      placeholder: "Type telephone number",
                      name: "landLine",
                      value: editForm?.landLine?.replace(/ /g,'')?.replace(/^(.{6})/,"$1 ")?.replace(/^(.{3})/,"$1 ").trim(),
                      onChange: (e) => {
                        if(e.target.value.trim() === ""){
                          setFormErrors({...formErrors,landLine:""})
                        }
                        else if (
                          !telephoneNumber.test(e.target.value.replace(/\s/g, '')) &&
                          !telePhoneRegex.test(e.target.value.replace(/\s/g, '')) 
                        ) {
                          if (!formErrors.landLine) {
                            formErrors.landLine = {};
                            setFormErrors({...formErrors,landLine:""})
                          }
                          formErrors.landLine =
                            "Enter valid telephone number";
                            setFormErrors({...formErrors,landLine:"Enter valid telephone number"})
                        } else {
                          if (formErrors.landLine) {
                            setFormErrors({...formErrors,landLine:""})
                          }
                        }
                        handleFormInput(e);
                      },
                    }}
                  />
                </div>
                <FormErrorText error={formErrors.landLine} />
              </div>

              <div className="col-lg-6 mb-4">
                <label className={commonstyles.secondry_title}>
                  Pincode
                  <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                    *
                  </span>
                </label>
                <InputComponent
                  error={formErrors.pincode}
                  className="form-control-lg"
                  formControl={{
                    placeholder: "Type pincode",
                    name: "pincode",
                    value: editForm?.pincode,
                    onChange: (e) => {
                      if (
                        !pinCode.test(e.target.value) &&
                        e.target.value.trim() !== " "
                      ) {
                        if (!formErrors.pincode) {
                          formErrors.pincode = {};
                        }
                        formErrors.pincode = "Enter valid pincode";
                      } else {
                        if (formErrors.pincode) {
                          formErrors.pincode = "";
                        }
                      }
                      handleFormInput(e);
                    },
                  }}
                />
                <FormErrorText error={formErrors.pincode} />
              </div>
              <div className="col-lg-6 mb-4">
                <label className={commonstyles.secondry_title}>
                  Latitude
                  <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                    *
                  </span>
                </label>
                <InputComponent
                  error={formErrors.latitude}
                  className="form-control-lg"
                  formControl={{
                    placeholder: "Type latitude",
                    name: "latitude",
                    value: editForm?.latitude,
                    onChange: (e) => {
                      if (validateLatLng(e.target.value)) {
                        formErrors.latitude = "";
                      } else {
                        formErrors.latitude = "Enter valid latitude";
                      }

                      handleFormInput(e);
                    },
                  }}
                />
                <FormErrorText error={formErrors.latitude} />
              </div>

              <div className="col-lg-6 mb-4">
                <label className={commonstyles.secondry_title}>
                  Longitude
                  <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                    *
                  </span>
                </label>
                <InputComponent
                  error={formErrors.longitude}
                  className="form-control-lg"
                  formControl={{
                    placeholder: "Type longitude",
                    name: "longitude",
                    value: editForm?.longitude,
                    onChange: (e) => {
                      if (validateLatLng(e.target.value)) {
                        formErrors.longitude = "";
                      } else {
                        formErrors.longitude = "Enter valid longitude";
                      }

                      handleFormInput(e);
                    },
                  }}
                />
                <FormErrorText error={formErrors.longitude} />
              </div>

              <div className="col-lg-6 mb-4">
                <label className={commonstyles.secondry_title}>
                  Location
                  <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                    *
                  </span>
                </label>
                <InputComponent
                  error={formErrors.location}
                  className="form-control-lg"
                  formControl={{
                    placeholder: "Type location",
                    name: "location",
                    value: editForm?.location,

                    onChange: (e) => {
                      if (
                        !minChar.test(e.target.value) &&
                        e.target.value.trim() !== " "
                      ) {
                        if (!formErrors.location) {
                          formErrors.location = {};
                        }
                        formErrors.location = "Enter minimum 3 characters";
                      } else {
                        if (formErrors.location) {
                          formErrors.location = "";
                        }
                      }
                      handleFormInput(e);
                    },
                  }}
                />
                <FormErrorText error={formErrors.location} />
              </div>

              <div className="col-lg-6 mb-4">
                <label className={commonstyles.secondry_title}>
                  Work start day
                  <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                    *
                  </span>
                </label>
                <SelectComponent
                  error={formErrors.workingStartDay}
                  className="form-control-md"
                  attributes={{
                    name: "workingStartDay",
                    value: editForm.workingStartDay,
                    onChange: (e) => {
                      if (
                        !minChar.test(e.target.value) &&
                        e.target.value.trim() !== " "
                      ) {
                        if (!formErrors.workingStartDay) {
                          formErrors.workingStartDay = {};
                        }
                        formErrors.workingStartDay =
                          "Enter minimum 3 characters";
                      } else {
                        if (formErrors.workingStartDay) {
                          formErrors.workingStartDay = "";
                        }
                      }
                      handleFormInput(e);
                    },
                  }}
                >
                  <option value="" disabled>
                    Select start day
                  </option>
                  <option value="Sunday">Sunday</option>
                  <option value="Monday">Monday</option>
                  <option value="Tuesday">Tuesday</option>
                  <option value="Wednesday">Wednesday</option>
                  <option value="Thursday">Thursday</option>
                  <option value="Friday">Friday</option>
                  <option value="Saturday">Saturday</option>
                </SelectComponent>
                <FormErrorText error={formErrors.workingStartDay} />
              </div>
              <div className="col-lg-6 mb-4">
                <label className={commonstyles.secondry_title}>
                  Work end day
                  <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                    *
                  </span>
                </label>
                <SelectComponent
                  error={formErrors.workingEndDay}
                  className="form-control-md"
                  attributes={{
                    name: "workingEndDay",
                    value: editForm.workingEndDay,
                    onChange: (e) => {
                      if (
                        !minChar.test(e.target.value) &&
                        e.target.value.trim() !== " "
                      ) {
                        if (!formErrors.workingEndDay) {
                          formErrors.workingEndDay = {};
                        }
                        formErrors.workingEndDay = "Enter minimum 3 characters";
                      } else {
                        if (formErrors.workingEndDay) {
                          formErrors.workingEndDay = "";
                        }
                      }
                      handleFormInput(e);
                    },
                  }}
                >
                  <option value="" disabled>
                    Select end day
                  </option>
                  <option value="Sunday">Sunday</option>
                  <option value="Monday">Monday</option>
                  <option value="Tuesday">Tuesday</option>
                  <option value="Wednesday">Wednesday</option>
                  <option value="Thursday">Thursday</option>
                  <option value="Friday">Friday</option>
                  <option value="Saturday">Saturday</option>
                </SelectComponent>
                <FormErrorText error={formErrors.workingEndDay} />
              </div>
              <div className="col-lg-6 mb-4">
                <div className="row store-timepicker">
                  <div className="col-lg-6 mb-4">
                    <label className={commonstyles.secondry_title}>
                      Start time
                      <span
                        className="text-danger"
                        style={{ fontSize:"20px",bottom:-8,position:"relative" }}
                      >
                        *
                      </span>
                    </label>
                    <TimePicker
                      error={formErrors.startTime}
                      // use12Hours
                      format="HH:mm"
                      name="startTime"
                      onChange={onChangeTimeHandlerStart}
                      // placeholder={editForm.startTime}
                      value={moment(startTime, "HH:mm:ss A")}
                    />

                    <FormErrorText error={formErrors.startTime} />
                  </div>
                  <div className="col-lg-6 mb-4">
                    <label className={commonstyles.secondry_title}>
                      End time
                      <span
                        className="text-danger"
                        style={{ fontSize:"20px",bottom:-8,position:"relative" }}
                      >
                        *
                      </span>
                    </label>
                    <TimePicker
                      error={formErrors.endTime}
                      name="endTime"
                      // use12Hours
                      format="HH:mm"
                      onChange={onChangeTimeHandlerEnd}
                      // placeholder={editForm.endTime}
                      value={moment(endTime, "HH:mm:ss A")}
                    />

                    <FormErrorText error={formErrors.endTime} />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 mb-4">
                <label>
                  City
                  <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                    *
                  </span>
                </label>
                <SelectComponent
                  error={formErrors.cityId}
                  className="form-control-md"
                  attributes={{
                    name: "cityId",
                    value: cities.filter(city=>  city._id == editForm.cityId ).length > 0 ? editForm.cityId:""  ,
                    onChange: (e) => {
                      handleFormInput(e);
                    },
                  }}
                >
                  <option value="" disabled>
                    Select city
                  </option>
                  {Array.isArray(cities) && cities.length
                    ? cities.map((city) => (
                      <option key={city._id} value={city._id}>
                        {city.name}
                      </option>
                    ))
                    : null}
                </SelectComponent>
                <FormErrorText error={formErrors.cityId} />
              </div>
              <div className="col-lg-12 mb-4">
                <div
                  className={stylles.management_details_contents}
                // style={{ marginRight: "270px" }}
                >
                  <label className={commonstyles.secondry_title}>
                    Address
                    <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                      *
                    </span>
                  </label>
                  <TextArea
                    className={`${commonstyles.form_control} form-control form-control-lg `}
                    error={formErrors.address}
                    formControl={{
                      name: "address",
                      value: editForm?.address,
                      placeholder: "Type address",
                      rows: 4,
                      onChange: (e) => handleFormInput(e),
                    }}
                  />
                </div>
                <FormErrorText error={formErrors.address} />
              </div>
              {/* Slug ends */}
              {/* type */}
              <div className="col-lg-12 mb-4">
                <div className={stylles.management_details_contents}>
                  <label className={commonstyles.secondry_title}>
                    Description
                  </label>
                  <textarea
                    className={`${commonstyles.form_control} form-control form-control-lg `}
                    placeholder="Type description"
                    rows="4"
                    cols="20"
                    value={editForm.description}
                    autoComplete="off"
                    name="description"
                    onChange={(e) => {
                      if (
                        !minChar.test(e.target.value) &&
                        e.target.value.trim() !== " "
                      ) {
                        if (!formErrors.description) {
                          formErrors.description = {};
                        }
                        formErrors.description = "Enter minimum 3 characters";
                      } else {
                        if (formErrors.description) {
                          formErrors.description = "";
                        }
                      }
                      handleFormInput(e);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row store-addimages">
            <div className="col-lg-6 mb-4">
              <div className={commonstyles.management_details_contents}>
                <div className={styless.upload_img_header}>
                  <label className={commonstyles.secondry_title}>
                    Feature image
                    <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                      *
                    </span>
                  </label>
                </div>

                <div className={commonstyles.management_details_contents}>
                  <div className={styless.upload_img_header}></div>
                  <div
                    className={`${commonstyles.management_details_form} ${styless.add_featureImage_card} ml-0`}
                  >
                    {url ? (
                      <>
                        <ImagePreviewChip
                          url={url}
                          className={styless.added_image}
                          width="60px"
                          height="60px"
                          handleClose={() => removeFile("feature_image")}
                        />
                      </>
                    ) : (
                      <div className={` ${styless.upload_img} text-center`}>
                        <div>
                          <label
                            htmlFor="categoryFileInput"
                            className={`${styless.btn} px-2 `}
                          >
                            Add file
                            <InputComponent
                              error={formErrors.feature_image}
                              className="form-control-lg"
                              formControl={{
                                type: "file",
                                name: "feature_image",
                                style: { cursor: "pointer" },
                                accept: "image/jpeg, image/png, image/svg+xml,image/gif, image/webp",
                                id: "categoryFileInput",
                                onChange: (e) => handleImageInput(e),
                              }}
                            />
                            <FormErrorText error={formErrors.feature_image} />
                          </label>
                        </div>
                        {/* <span>or drop a file to Upload</span> */}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <ProductMediaUploadForm
              localFileData={localFileData}
              gallery={editForm.gallery}
              updateMediaForm={handleMediaUpdate}
            />
            {/* image upload end */}
          </div>

        </div>
      )}
    </div>
  );
}
